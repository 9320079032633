import Box from '@mui/material/Box'
import Button from '../stories/Button'
import TextField from '../stories/TextField'

const ResetPasswordForm = props => (
  <form title='reset-password-flow' {...props}>
    <TextField title='email' name='email' type='email' placeholder='Email' label='Email' required />
    <Box mt={2}>
      <Button type='submit' value='Reset Password' fullWidth>
        Reset Password
      </Button>
    </Box>
  </form>
)

export default ResetPasswordForm
