// src/utils/voucherUtils.js

import { collection } from '../firebase/firestore'

export const getSubVouchers = async (uid, userId) => {
  try {
    const vouchers = await collection('vouchers')
      .doc(uid)
      .collection('subVouchers')
      .where('user.id', '==', userId)
      .get()

    if (!vouchers.empty) {
      return vouchers.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        uid: doc.id,
      }))
    }

    return [] // Return an empty array if no vouchers found
  } catch (error) {
    console.error('Error getting sub vouchers', error)
    return [] // Return an empty array in case of error
  }
}
