import { FC, ReactNode } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useCurrentUser } from '@app/context/CurrentUser'

const PrivateRoute: FC = () => {
  const { pathname, search } = useLocation()
  const { isAnonymous } = useCurrentUser()

  let node: ReactNode = 'Loading'
  if (isAnonymous === true) {
    node = <Navigate to='/signin' state={pathname + search} replace />
  } else if (isAnonymous === false) {
    node = <Outlet />
  }

  return node
}

export default PrivateRoute
