import { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Button from '../stories/Button'
import { useCurrentProfile, useCurrentUser } from '../context/CurrentUser'
import { collection } from '../firebase/firestore'
// import InstantSearchContainer from '../containers/InstantSearchContainer'
// import FeeScheduleCard from './FeeScheduleCard'
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge'
// import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import SelectField from '../stories/Select'

const PlansPatientDashboardContent = () => {
  const { plansAssociated = {} } = useCurrentProfile()
  const { uid } = useCurrentUser()

  const [vouchers, setVouchers] = useState([])
  const [planOptions, setPlanOptions] = useState([])
  const [plans, setPlans] = useState([])
  const [selectedPlanID, setSelectedPlanID] = useState(null)

  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])

  const [value, setValue] = useState(0)
  const [valueMax, setValueMax] = useState(0)

  // const lowerButton = () => {
  //   value > 0 && setValue(value - 100)
  // }

  // const upperButton = () => {
  //   value < valueMax && setValue(value + 100)
  // }

  useEffect(() => {
    // get all plans
    const getPlans = async ids => {
      // for each network id in the array of networks associated with the clinic get the data from firebase and add it to the plans array
      const promises = ids.map(async planID => {
        const planRef = await collection('networks').doc(planID).get()

        if (!planRef.exists) {
          console.log('No such plan!')
          return null
        }

        const spendData = await planRef.ref
          .collection('members')
          .doc(uid)
          .get()
          .then(doc => {
            if (!doc.exists) {
              console.warn('No such member doc!', planID, uid)
              return null
            }

            return doc.data()
          })
          .catch(err => {
            console.error('Error getting member doc:', err)
            return null
          })

        const { name, slug } = planRef.data()

        const { spendRules } = spendData || {}

        return {
          id: planID,
          name,
          slug,
          spendRules: spendRules || {
            limit: 0,
            spent: 0,
          },
        }
      })
      const results = await Promise.all(promises)
      const formattedPlanOptions = results.map(plan => ({
        value: plan.id,
        label: plan.name,
      }))
      setPlanOptions(formattedPlanOptions)
      setPlans(results)
    }

    const planIDs = Object.keys(plansAssociated)
    console.log('Plan IDs:', planIDs)
    if (planIDs.length > 0 && uid) {
      console.log('Getting plans')
      getPlans(planIDs)
    } else {
      console.log('No plans or uid', planIDs, uid)
    }
  }, [plansAssociated, uid])

  useEffect(() => {
    const setSpendAmount = async () => {
      console.log('Getting vouchers')
      const vouchers = await collection('vouchers')
        .where('user.id', '==', uid)
        .where('network.id', '==', selectedPlanID)
        .get()

      console.log('Vouchers:', vouchers.docs)

      if (!vouchers.docs) {
        console.log('No vouchers found')
        setValue(0)
        setValueMax(limit)
        return
      }

      const voucherData = vouchers.docs.map(doc => doc.data())

      setVouchers(voucherData)

      const spent = vouchers?.docs?.reduce((acc, doc) => {
        const { service = 0 } = doc?.data() || {}
        return acc + service.price
      }, 0)

      console.log('Spent:', spent)

      setValue(limit - spent)
      setValueMax(limit)
    }

    if (!selectedPlanID) {
      console.log('No plan selected')
      return
    }

    const plan = plans.find(p => p.id === selectedPlanID)
    if (!plan) {
      console.log('No plan found')
      return
    }

    const { spendRules } = plan
    const { limit = 0, spent = 0 } = spendRules

    console.log('Plan:', plan)
    console.log('Spend Rules:', spendRules)
    console.log('Limit:', limit)
    console.log('Spent:', spent)

    setSpendAmount()
  }, [selectedPlanID, plans, uid])

  const PlanNameCard = ({ plan }) => {
    return (
      <Box
        style={{
          width: '100%',
          marginBottom: '16px',
        }}
      >
        <Button
          id={plan.id}
          // conditionally add the variant prop using this selectedPlanID === plan.id
          variant={selectedPlanID === plan.id ? 'contained' : 'outlined'}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={async () => {
            setSelectedPlanID(plan.id)
          }}
        >
          <Typography variant='h5' style={{ marginBottom: '0px' }}>
            {plan.name}
          </Typography>
        </Button>
      </Box>
    )
  }

  PlanNameCard.propTypes = {
    plan: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
      }}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        width='100%'
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          sx={{
            flexWrap: {
              xs: 'wrap',
              sm: 'wrap',
              md: 'nowrap',
            },
            padding: {
              xs: '0px',
              sm: '0px',
              md: '16px',
            },
          }}
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            padding='16px'
            sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '40%',
              },
            }}
          >
            <SelectField
              variant='standard'
              width='100%'
              size='large'
              options={planOptions}
              onChange={e => setSelectedPlanID(e.target.value)}
              titleSelect
            />
            <Typography
              variant='h4'
              align='center'
              style={{
                marginTop: '16px',
              }}
            >
              Amount Remaining
            </Typography>
            <Typography
              variant='h2'
              fontWeight='500'
              style={{
                marginTop: '16px',
              }}
            >
              {value > 0 ? `$${value}` : '$0'}
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '60%',
              },
              padding: {
                xs: '0px',
                sm: '0px',
                md: '16px',
              },
            }}
          >
            <Gauge
              width={600}
              height={340}
              value={value}
              valueMax={valueMax}
              startAngle={-100}
              endAngle={100}
              sx={theme => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 40,
                  fontFamily: 'Poppins',
                  fontWeight: 300,
                  transform: 'translate(0px, 0px)',
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: '#652d92',
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: theme.palette.text.disabled,
                },
              })}
              text={({ value, valueMax }) => `$${value} / $${valueMax}`}
            />
          </Box>
        </Box>
        {/* <Box display='flex' justifyContent='center' alignItems='center' padding='16px'>
          <Button
            onClick={lowerButton}
            style={{
              marginRight: '8px'
            }}
          >
            Lower
          </Button>
          <Button onClick={upperButton}>Upper</Button>
        </Box> */}
        {/* <Box width='100%'>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: true,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            sx={{
              minHeight: '200px',
            }}
          />
        </Box> */}
      </Box>
    </Box>
  )
}

export default PlansPatientDashboardContent
