import { useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import SignInFormContainer from '../containers/SignInForm'
import SignInFormPhoneContainer from '../containers/SignInFormPhone'
import Box from '@mui/material/Box'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import Link from '../stories/Link'
import Modal from '../stories/Modal'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import EmailIcon from '@mui/icons-material/Email'
import Divider from '../stories/Divider'

const SignInModal = ({ open, handleClose, loginMethods = [], preventRegister, callback }) => {
  const [loginMethod, setLoginMethod] = useState('')

  useEffect(() => {
    if (!open) {
      setLoginMethod('')
    }
  }, [open])

  const LoginMethodsBuilder = ({ methods }) => {
    let components = []
    console.log('loginMethods', methods)
    if (methods.includes('email') || methods.length === 0) {
      components.push(
        <Box mb={2}>
          <Button
            fullWidth
            size='medium'
            variant='outlined'
            display='standard'
            onClick={() => {
              setLoginMethod('email')
            }}
          >
            <EmailIcon fontSize='medium' style={{ color: '#662D91', marginRight: '5px' }} />
            Login with Email
          </Button>
        </Box>,
      )
    }

    if (methods.includes('phone') || methods.length === 0) {
      components.push(
        <Box mb={2}>
          <Button
            fullWidth
            size='medium'
            variant='outlined'
            display='standard'
            onClick={() => {
              setLoginMethod('phone')
            }}
          >
            <PhoneIphoneIcon fontSize='medium' style={{ color: '#662D91', marginRight: '5px' }} />
            Phone Number
          </Button>
        </Box>,
      )
    }

    return components
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box
        padding={1}
        sx={{
          width: { xs: '100%', sm: '400px' },
        }}
      >
        <Typography variant='h2' gutterBottom>
          Login
        </Typography>
        <Divider
          style={{
            marginTop: '20px',
            marginBottom: '20px',
          }}
        />
        {loginMethod === '' && (
          <>
            <LoginMethodsBuilder methods={loginMethods} />
          </>
        )}
        {loginMethod === 'email' && (
          <>
            <SignInFormContainer callback={callback} />
            <br />
            <Link to='/reset-password' display='standard' onClick={handleClose}>
              Forgot your password?
            </Link>
          </>
        )}
        {loginMethod === 'phone' && <SignInFormPhoneContainer callback={callback} />}
        {loginMethod !== '' && (
          <Box mt={2}>
            <Button
              fullWidth
              size='medium'
              variant='outlined'
              display='standard'
              onClick={() => {
                setLoginMethod('')
              }}
            >
              Back
            </Button>
          </Box>
        )}
        <Divider
          style={{
            marginTop: '20px',
            marginBottom: '20px',
          }}
        />
        {!preventRegister && (
          <Box mb={2}>
            <Typography variant='body2' weight='light'>
              Don&apos;t have an account?{' '}
              <Link to='/signup' onClick={handleClose} display='standard'>
                Register here.
              </Link>
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default SignInModal
