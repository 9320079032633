import PropTypes from 'prop-types'
import VoucherList from './VoucherList'
import PatientVouchersRow from './PatientVouchersRow'
import VoucherUpcomingAppointment from './VoucherUpcomingAppointment'

const HEADERS = [
  { id: 'info', label: 'info', align: 'center', type: 'icon', user: 'consumer' },
  { id: 'clinic', label: 'Clinic', align: 'left', type: 'text' },
  { id: 'service', label: 'Service', align: 'left', type: 'text' },
  { id: 'appDate', label: 'Appt Date', align: 'left', type: 'text' },
  { id: 'status', label: 'Status', align: 'center', type: 'text' },
]

const PatientVouchers = ({ rows = [] }) => (
  <>
    <VoucherUpcomingAppointment rows={rows} />
    <VoucherList headers={HEADERS}>
      {rows.map(each => {
        return <PatientVouchersRow headers={HEADERS} key={each.uid} row={each} />
      })}
    </VoucherList>
  </>
)

PatientVouchers.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      code: PropTypes.string,
      service: PropTypes.shape({
        name: PropTypes.string,
      }),
      organization: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
}

export default PatientVouchers
