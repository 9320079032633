import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import Chip from './VoucherChip'
import VoucherDetailsEntry from '../containers/voucherDetailsEntry'
import OrderChatView from './OrderChatView'
import Typography from '../stories/Typography'
import { formatDateForUI } from '../libs/formatters'
import { useState, useEffect } from 'react'
import { collection } from '../firebase/firestore'
import Button from '../stories/Button'
import { getSubVouchers } from '../utils/voucherUtils'

const PatientVouchersBundle = ({ open = false, row = {} }) => {
  const [subVouchers, setSubVouchers] = useState([])

  useEffect(() => {
    const fetchSubVouchers = async () => {
      if (row?.bundle) {
        const subVouchersData = await getSubVouchers(row.uid, row.user.id)
        setSubVouchers(subVouchersData)
      }
    }

    fetchSubVouchers()
  }, [row])

  return (
    <TableCell style={{ padding: '0px' }} colSpan={8}>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ padding: '10px', backgroundColor: '#f6eeff' }} align='center'>
                  <Typography variant='body2' fontWeight='medium' style={{ textAlign: 'center' }}>
                    <Box justifyContent='center' display='flex' alignItems='center'>
                      <OrderChatView
                        order={row}
                        chatID={row?.id || row?.uid}
                        color='#652d92'
                        variant='bundle'
                      />
                    </Box>
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px', backgroundColor: '#f6eeff' }}>
                  <Typography variant='body2' fontWeight='medium'>
                    Clinic
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px', backgroundColor: '#f6eeff' }}>
                  <Typography variant='body2' fontWeight='medium'>
                    Service
                  </Typography>
                </TableCell>
                <TableCell colSpan={2} style={{ padding: '10px', backgroundColor: '#f6eeff' }}>
                  <Typography variant='body2' fontWeight='medium'>
                    Appt Date
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px', backgroundColor: '#f6eeff' }} align='center'>
                  <Typography variant='body2' fontWeight='medium' style={{ textAlign: 'center' }}>
                    Status
                  </Typography>
                </TableCell>
              </TableRow>
              {subVouchers.map(subVoucher => (
                <TableRow key={subVoucher.id}>
                  <TableCell style={{ padding: '10px', backgroundColor: '#faf5ff' }} align='center'>
                    <VoucherDetailsEntry voucher={subVoucher}>
                      <Button
                        size='small'
                        variant='outlined'
                        style={{
                          display: 'inline-block',
                        }}
                      >
                        <Typography variant='body1' family='poppins'>
                          Details
                        </Typography>
                      </Button>
                    </VoucherDetailsEntry>
                  </TableCell>
                  <TableCell style={{ padding: '10px', backgroundColor: '#faf5ff' }}>
                    {subVoucher?.organization?.name || '---'}
                  </TableCell>
                  <TableCell style={{ padding: '10px', backgroundColor: '#faf5ff' }}>
                    {subVoucher?.service?.name || '---'}
                  </TableCell>
                  <TableCell colSpan={2} style={{ padding: '10px', backgroundColor: '#faf5ff' }}>
                    {formatDateForUI(subVoucher?.appointmentDate, true) || '---'}
                  </TableCell>
                  <TableCell style={{ padding: '10px', backgroundColor: '#faf5ff' }} align='center'>
                    <Chip label={subVoucher.status} style={{ margin: '0px' }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </TableCell>
  )
}

export default PatientVouchersBundle
