import { Box, Hidden } from '@mui/material'
import Button from '../stories/Button'
import MisheBeach from '../assets/images/MisheBeach.png'
import MisheManBench from '../assets/images/MisheManBench.png'
import MisheLeaves from '../assets/images/MisheLeaves.png'
import PageSection from '../components/PageSection'
import Typography from '../stories/Typography'
import background from '../assets/images/backgroundTest2.png'
import HomePageLanderHoverInfo from '../components/HomePageLanderHoverInfo'
import PhoneBlank from '../assets/images/PhoneBlank.png'
import PhoneMisheClinicMap from '../assets/images/PhoneMisheClinicMap.png'
import PhoneMisheMedicationSearch from '../assets/images/PhoneMisheMedicationSearch.png'
import WordShifterFade from '../components/WordShifterFade'
import ComponentShifterFade from '../components/ComponentShifterFade'
import ComponentSequentialLoader from '../components/ComponentSequentialLoader'

const MembersPage = () => {
  const phoneImages = [PhoneBlank, PhoneMisheClinicMap, PhoneMisheMedicationSearch]
  const phoneText = [
    'We offer easy to access Clinics at a better price',
    'We deliver your prescriptions to your door',
  ]

  // Ask James about this if you're curious.
  const phoneTextWithKeys = phoneText.map((text, index) => {
    return { text, key: index }
  })

  return (
    <Box>
      <Hidden mdDown>
        <PageSection
          startLoaded
          image={MisheBeach}
          height={700}
          imgWidth='60%'
          infoWidth='530px'
          bgImage={background}
        >
          <Box
            padding='1rem'
            bgcolor='rgba(217, 193, 244, 0.2)'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
          >
            <Box padding='2rem' bgcolor='#652d92' marginRight='2rem'>
              <Typography
                variant='h5'
                family='poppins'
                weight='medium'
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  margin: '0',
                }}
              >
                {/* No surprise bills. Concierge care coordination. Personal health
                passport. */}
                Healthcare is too confusing. We&apos;re here to guide you at every step of the way.
              </Typography>
            </Box>
            {/* <Box
              padding='1rem 2rem 0 0'
              display='flex'
              justifyContent='space-between'
            >
              <Typography
                variant='body1'
                family='poppins'
                weight='light'
                color='primary'
              >
                Are you a patient?
              </Typography>
              <Button
                variant='text'
                style={{
                  padding: '0',
                  height: 'auto'
                }}
              >
                <Typography
                  variant='body1'
                  family='poppins'
                  weight='light'
                  color='primary'
                >
                  Sign In
                </Typography>
              </Button>
            </Box> */}
          </Box>
        </PageSection>
        <PageSection height={100} bgColorSlide='#652d92' bgColor='#6d319e'>
          <ComponentSequentialLoader cardsPerRow={3}>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Typography
                variant='h2'
                family='poppins'
                weight='light'
                style={{
                  color: '#fff',
                  margin: '0',
                }}
              >
                No Surprises
              </Typography>
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Typography
                variant='h2'
                family='poppins'
                weight='light'
                style={{
                  color: '#fff',
                  margin: '0',
                  whiteSpace: 'nowrap',
                }}
              >
                Concierge Support
              </Typography>
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Typography
                variant='h2'
                family='poppins'
                weight='light'
                style={{
                  color: '#fff',
                  margin: '0',
                }}
              >
                Central Records
              </Typography>
            </Box>
          </ComponentSequentialLoader>
        </PageSection>
        <PageSection
          height={700}
          direction='up'
          slideBorderRadius='80px 80px 0 0'
          slideBoxShadow='0px -8px 8px 0px rgba(0,0,0,0.1)'
          childrenOnSlide
          bgColor='#f6eeff'
          bgColorSlide='#fff'
          infoWidth='0%'
        >
          <Box display='flex' justifyContent='center' height='700px' padding='20px'>
            <Box padding='2rem' height='100%' marginRight='1rem' width='100%'>
              <Typography
                variant='h2'
                family='poppins'
                align='center'
                weight='light'
                style={{
                  color: '#652d92',
                }}
              >
                How Mishe Works For You
              </Typography>
              <HomePageLanderHoverInfo inputImages={phoneImages} inputText={phoneTextWithKeys} />
            </Box>
            {/* <ComponentShifterFade length={5000}>
              <Typography variant='h2' family='poppins' weight='light'>
                Hello this is where we can put some interestin paragraphs about
                things or maybe reviews or something who knows
              </Typography>
              <Typography variant='h2' family='poppins' weight='light'>
                Hello this 2
              </Typography>
              <Typography variant='h2' family='poppins' weight='light'>
                Hello this 3
              </Typography>
            </ComponentShifterFade> */}
          </Box>
          <Box display='flex' alignItems='center'>
            <img src={MisheManBench} alt='Mishe Network' width='100%' />
          </Box>
        </PageSection>
        <PageSection
          height={400}
          direction='up'
          slideBorderRadius='80px 80px 0 0'
          slideBoxShadow='0px -8px 8px 0px rgba(0,0,0,0.1)'
          childrenOnSlide
          bgColor='#fff'
          bgColorSlide='#eaf4ff'
          infoWidth='0%'
        >
          <Box display='flex' justifyContent='center' height='400px' padding='20px' width='100%'>
            <Box padding='2rem' height='100%' marginRight='1rem' width='100%'>
              <ComponentShifterFade length={5000}>
                <Typography variant='h2' family='poppins' weight='light'>
                  Review 1
                </Typography>
                <Typography variant='h2' family='poppins' weight='light'>
                  Review 2
                </Typography>
                <Typography variant='h2' family='poppins' weight='light'>
                  Review 3
                </Typography>
              </ComponentShifterFade>
            </Box>
          </Box>
        </PageSection>
        <PageSection
          height={100}
          direction='up'
          slideBoxShadow='0px -8px 8px 0px rgba(0,0,0,0.1)'
          childrenOnSlide
          bgColor='#eaf4ff'
          bgColorSlide='#f5f5f5'
          infoWidth='0%'
        />
      </Hidden>
      <Hidden mdUp>
        <PageSection
          startLoaded
          height={500}
          imgWidth='100%'
          infoWidth='100%'
          bgImage={MisheLeaves}
        >
          <Box
            padding='1rem'
            bgcolor='rgba(217, 193, 244, 0.9)'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
            margin='1rem'
          >
            <Box padding='1rem' bgcolor='#652d92'>
              <Typography
                variant='h1'
                family='poppins'
                style={{
                  color: '#d9c1f4',
                  fontSize: '16px',
                  margin: '0',
                }}
              >
                Members
              </Typography>
              <Typography
                variant='h5'
                family='poppins'
                weight='light'
                style={{
                  color: '#fff',
                  fontSize: '24px',
                  margin: '0',
                }}
              >
                We don&apos;t just make your benefits better. We help you use them wherever you are.
              </Typography>
            </Box>
            <Box padding='1rem 2rem 0 2rem' display='flex' justifyContent='space-between'>
              <Typography variant='body1' family='poppins' weight='light' color='primary'>
                Are you a member?
              </Typography>
              <Button
                variant='text'
                style={{
                  margin: '0 1rem',
                  padding: '0',
                  height: 'auto',
                }}
              >
                <Typography variant='body1' family='poppins' weight='light' color='primary'>
                  Sign In
                </Typography>
              </Button>
            </Box>
          </Box>
        </PageSection>
        <PageSection height={100} bgColorSlide='#652d92' bgColor='#6d319e' width='100%'>
          <WordShifterFade inputText={['Find Care', 'Book Care', 'Get Care']} />
        </PageSection>
      </Hidden>
    </Box>
  )
}

export default MembersPage
