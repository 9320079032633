import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const NavButtonContainer = ({ children, color }) => {
  const [open, setOpen] = useState(false)

  const outlineStyle = `1px solid ${color || '#fff'}`
  return (
    <>
      <Box
        position='relative'
        onMouseEnter={() => {
          setOpen(true)
        }}
        onMouseLeave={() => {
          setOpen(false)
        }}
        borderRadius='21.125px'
        margin='0 0.25rem'
        outline={open ? outlineStyle : 'none'}
        color='#fff'
      >
        {children}
      </Box>
    </>
  )
}

NavButtonContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NavButtonContainer
