import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Heading from '../Heading'
import Text from '../Text'
import Link from '../Link'
import Divider from '../Divider'

export const TermsBlock = ({ children, ...props }) => (
  <Box mb={5} {...props}>
    {children}
  </Box>
)

TermsBlock.propTypes = {
  children: PropTypes.node.isRequired,
}

export const TermsText = ({ children, ...props }) => (
  <Text display='block' {...props}>
    {children}
  </Text>
)

TermsText.propTypes = {
  children: PropTypes.node.isRequired,
}

export const TermsLink = ({ children, ...props }) => (
  <Link type='external' display='inline' target='_blank' {...props}>
    {children}
  </Link>
)

TermsLink.propTypes = {
  children: PropTypes.node.isRequired,
}

export const TermsList = styled.ul`
  padding-inline-start: ${props => props.theme.spacing(4)};
  font-size: 1rem;
`

export const TermsOrderedList = styled.ol`
  padding-inline-start: ${props => props.theme.spacing(4)};
  font-size: 1rem;
`

export const TermsListItem = styled.li`
  margin: ${props => props.theme.spacing(1)} 0px;
  line-height: 1.5;
`

export const TermsTitle = ({ children, ...props }) => (
  <>
    <Heading variant='h5' component='h2' {...props}>
      {children}
    </Heading>
    <Divider spacing='vertical' />
  </>
)

TermsTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TermsBlock
