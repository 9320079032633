import firebase from 'firebase/compat/app'
import { getFirestore } from 'firebase/firestore'

const appId = import.meta.env.VITE_FIREBASE_APP_ID
const apiKey = import.meta.env.VITE_FIREBASE_API_KEY
const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID
const messagingSenderId = import.meta.env.VITE_FIREBASE_SENDER_ID

export const defaultApp = firebase.initializeApp({
  appId,
  apiKey,
  projectId,
  messagingSenderId,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  storageBucket: `${projectId}.appspot.com`,
})

export default firebase

// Initialize Cloud Firestore and get a reference to the service
export const getFirestoreDB = getFirestore(defaultApp)
