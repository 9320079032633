import { useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Icon from '../stories/Icons'
import { useNotesModal } from '../context/AddNoteModal'

const VoucherNewNoteDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { setNoteType, handleClick } = useNotesModal()

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const noteOptions = {
    text: 'Text',
    file: 'File',
    image: 'Image',
  }

  const handleClose = e => {
    if (noteOptions[e.target.id] !== undefined) {
      setNoteType(e.target.id)
      handleClick(e)
    }
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls='add-note-menu'
        aria-haspopup='true'
        onClick={handleMenuClick}
        style={{
          width: '100%',
        }}
      >
        <Icon name='add' fontSize='small' color='purple' />
        Add Visit Note
      </Button>
      <Menu
        id='add-note-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem id='text' onClick={handleClose}>
          Text
        </MenuItem>
        <MenuItem id='file' onClick={handleClose}>
          File
        </MenuItem>
        <MenuItem id='image' onClick={handleClose}>
          Image
        </MenuItem>
      </Menu>
    </div>
  )
}

export default VoucherNewNoteDropdown
