import { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import ReactPlayer from 'react-player'
import { Box, Grid } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import TextField from '../stories/TextField'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import Icon from '../stories/Icons'
import PillLogo from '../assets/images/pill.svg'
import parseQueryString from '../libs/parseQueryStrings'
import { useCurrentProfile, useCurrentUser } from '../context/CurrentUser'
import spin from '../assets/images/spin.svg'

import { collection, serverTimestamp } from '../firebase/firestore'
import Peer from 'peerjs'
import useChat from '../hooks/useChat'
import { MessageInOrders as MessageIn } from '../components/Chats/MessageIn'
import { MessageOutOrders as MessageOut } from '../components/Chats/MessageOut'

const mediaConfig = {
  video: {
    facingMode: 'user',
    width: { min: 1024, ideal: 1280, max: 1920 },
    height: { min: 576, ideal: 720, max: 1080 },
  },
  audio: true,
}

const VideoChatContainer = () => {
  const theme = useTheme()

  const [chatID, setChatID] = useState(null) // eslint-disable-line no-unused-vars
  const { isProvider, isPatient } = useCurrentProfile()
  const { uid } = useCurrentUser()

  const { search } = useLocation()
  const [peerVideoStream, setPeerVideoStream] = useState()
  const [srcVideoStream, setSrcVideoStream] = useState()
  const [callActive, setCallActive] = useState(false)
  const [displayLinks, setDisplayLinks] = useState(false)
  const [messages, setMessages] = useState(null)
  const [currentImage, setCurrentImage] = useState(null)
  const [currentFile, setCurrentFile] = useState(null)
  const [messageType, setMessageType] = useState(null)
  const [meetingInitited, setMeetingInitiated] = useState(false)
  const [patientJoin, setPatientJoin] = useState(false)
  const [providerPeerGenId, setProviderPeerGenId] = useState(false)
  const [displayMenu, setDisplayMenu] = useState(false)
  const [displayChatLinks, setDisplayChatLinks] = useState(false)
  // const [fullScreen, setFullScreen] = useState(true)
  // const [chatMessage, setChatMessage] = useState(false)
  const [chatToggle, setChatToggle] = useState(false)
  const [microphoneToggle, setMicrophoneToggle] = useState(false)
  const [videoCameraToggle, setVideoCameraToggle] = useState(false)
  // const [screenShareToggle, setScreenShareToggle] = useState(false)
  const [waitingForPeer, setWaitingForPeer] = useState(false)
  const [peerArrived, setPeerArrived] = useState(false)
  const [callEndedBy, setCallEndedBy] = useState(null)
  const [streamState, setStreamState] = useState(null)
  const [refreshPeerAfterDiscount, setRefreshPeerAfterDiscount] = useState(null)

  const [peer, setPeer] = useState({})
  const [callPeer, setCallPeer] = useState()
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const desktopFileRef = useRef()
  const desktopImageRef = useRef()
  const profile = useCurrentProfile()
  const unsubscribe = useRef(null)
  const [textChatFocus, setTextChatFocus] = useState(false)
  const { subscribeToChat, sendMessage } = useChat()

  const mediaQuerySM = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    const { id } = parseQueryString(search)

    if (id) {
      collection('videoChatRooms')
        .doc(id)
        .get()
        .then(doc => {
          console.log('doc.exists', doc.exists)
          if (doc.exists) {
            isProvider &&
              initiateVideoFlow({
                chatId: id,
                data: {
                  providerUID: uid,
                  providerStartMeeting: false,
                  endedBy: null,
                  endedAt: null,
                  patientJoined: false,
                },
              })
            isPatient &&
              initiateVideoFlow({
                chatId: id,
                data: {
                  patientJoined: false,
                  endedBy: null,
                  endedAt: null,
                },
              })
          } else {
            initiateVideoFlow({ chatId: id, data: {} })
          }
        })
        .catch(error => {
          console.log('Error getting document:', error)
        })
    }
  }, [search, isPatient, isProvider])

  const initiateVideoFlow = async ({ chatId, data }) => {
    await collection('videoChatRooms')
      .doc(chatId)
      .set(data, { merge: true })
      .then(() => {
        console.log('Chat room created', chatId)
      })
    setChatID(chatId)
  }

  useEffect(() => {
    chatID &&
      collection('videoChatRooms')
        .doc(chatID)
        .onSnapshot(doc => {
          const { providerStartMeeting, providerPeerId, patientJoined, endedBy } = doc.data()
          console.log('doc.data()', doc.data())
          setMeetingInitiated(providerStartMeeting)
          setProviderPeerGenId(providerPeerId)
          setPatientJoin(patientJoined)
          setCallEndedBy(endedBy)
        })
  }, [uid, isPatient, chatID, isProvider])

  useEffect(() => {
    if (isPatient && meetingInitited === false) {
      setCallActive(false)
      setPeerArrived(false)
      setWaitingForPeer(false)
      setPeerVideoStream(null)
    }
  }, [meetingInitited])

  useEffect(() => {
    const peer = new Peer()
    setPeer(peer)
    if ((isProvider || isPatient) && chatID) {
      peer.on('open', peerGeneratedId => {
        collection('videoChatRooms')
          .doc(chatID)
          .set(
            {
              uid,
              [(isProvider && 'providerPeerId') || (isPatient && 'patientPeerId')]: peerGeneratedId,
              createdAt: serverTimestamp(),
            },
            { merge: true },
          )
          .then(async () => {
            console.log('Signal sent')
          })
          .catch(err => {
            console.log('err', err)
          })
      })
    }

    if (chatID && isProvider) {
      peer.on('call', call => {
        setCallPeer(call)
      })
    }

    peer.on('error', peerError => {
      console.log('peerError', peerError.name)
      console.log('peerError', peerError.message)
      peer.destroy()
      onPeerError()
    })

    peer.on('disconnected', () => {
      onPeerError()
    })

    peer.on('close', () => {
      onPeerError()
    })
  }, [chatID, isProvider, isPatient, refreshPeerAfterDiscount])

  const onPeerError = () => {
    setCallActive(false)
    setPeerArrived(false)
    setWaitingForPeer(false)
    setSrcVideoStream(null)
    setPeerVideoStream(null)
    window.location.reload()
  }

  const handleImageChange = e => {
    setCurrentFile(null) // was 0
    setCurrentImage(null)
    setCurrentImage({
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    })
  }

  const handleImageClick = () => {
    if (currentImage) {
      setCurrentImage(null)
    } else {
      desktopImageRef.current.click()
    }
  }

  const handleFileClick = () => {
    if (currentFile) {
      setCurrentFile(null)
    } else {
      desktopFileRef.current.click()
    }
  }

  const MessageForm = ({ children }) => {
    return (
      <form
        onSubmit={e => {
          e.preventDefault()
          setDisplayLinks(displayLinks && !displayLinks)
          const chatMessageBoxValue = document.getElementById('orderChatMessageBox').value
          sendMessage({
            chatID: chatID,
            message: chatMessageBoxValue,
            profile,
            currentFile,
            currentImage,
            fileType: messageType,
          })
          document.getElementById('orderChatMessageBox').value = ''
          setCurrentFile(null)
          setCurrentImage(null)
          setMessageType(null)
        }}
        style={{
          width: '100%',
        }}
      >
        {children}
      </form>
    )
  }

  MessageForm.propTypes = {
    children: PropTypes.node.isRequired,
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    if (!chatID) {
      return undefined
    }
    unsubscribe.current = subscribeToChat({
      chatID: chatID,
      callback: setMessages,
    })

    return unsubscribe.current
  }, [chatID]) // eslint-disable-line react-hooks/exhaustive-deps

  const providerStartStatus = async ({ startMeetingStatus }) => {
    await collection('videoChatRooms')
      .doc(chatID)
      .set(
        {
          providerStartMeeting: startMeetingStatus,
          createdAt: serverTimestamp(),
          patientJoined: false,
          endedBy: null,
          endedAt: null,
        },
        { merge: true },
      )
      .then(() => {
        console.log('Provider joined')
      })
  }

  useEffect(() => {
    if (callEndedBy) {
      setSnackBarOpen(true)
      setCallActive(false)
      setPeerArrived(false)
      setWaitingForPeer(false)
      setSrcVideoStream(null)
      setPeerVideoStream(null)
      // refresh
      setRefreshPeerAfterDiscount(!refreshPeerAfterDiscount)
    }
  }, [callEndedBy])

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpen(false)
  }

  const patientJoined = () => {
    collection('videoChatRooms')
      .doc(chatID)
      .set(
        {
          patientJoined: true,
        },
        { merge: true },
      )
      .then(() => {
        console.log('patient joined')
      })
      .catch(err => {
        alert(err)

        console.log('err', err)
      })
  }

  const ForPatientMeetingInitiated = () => {
    if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
      navigator.userMedia = navigator.mozGetUserMedia || navigator.getUserMedia
      if (!navigator.userMedia) {
        alert('Please Update or Use Different Browser')
        return
      }
      navigator.userMedia(
        mediaConfig,
        stream => {
          setSrcVideoStream(stream)
          const call = peer.call(providerPeerGenId, stream)
          call.on(
            'stream',
            remoteStream => {
              setPeerVideoStream(remoteStream)
              setPeerArrived(true)
              setCallActive(true)
            },
            error => {
              alert(`error ${error}`)
              console.log('error', error)
            },
          )
          patientJoined()
        },
        err => showMediaError(err),
      )
      return
    }

    navigator.mediaDevices
      .getUserMedia(mediaConfig)
      .then(stream => {
        setSrcVideoStream(stream)
        const call = peer.call(providerPeerGenId, stream)
        call.on(
          'stream',
          remoteStream => {
            setPeerVideoStream(remoteStream)
            setPeerArrived(true)
            setCallActive(true)
          },
          error => {
            alert(`error ${error}`)
            console.log('error', error)
          },
        )
        patientJoined()
      })
      .catch(err => showMediaError(err))
  }

  const ForProviderMeetingInitiated = () => {
    if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
      navigator.userMedia = navigator.mozGetUserMedia || navigator.getUserMedia
      if (!navigator.userMedia) {
        alert('Please Update or Use Different Browser')
        return
      }
      navigator.userMedia(
        mediaConfig,
        stream => {
          setSrcVideoStream(stream)
          callPeer.answer(stream)
          callPeer.on('stream', remoteStream => {
            setPeerVideoStream(remoteStream)
          })
          setCallActive(true)
          setPeerArrived(true)
        },
        err => showMediaError(err),
      )
      return
    }
    console.log('callPeer', callPeer)
    navigator.mediaDevices
      .getUserMedia(mediaConfig)
      .then(stream => {
        setSrcVideoStream(stream)
        callPeer.answer(stream)
        callPeer.on('stream', remoteStream => {
          setPeerVideoStream(remoteStream)
        })
        setCallActive(true)
        setPeerArrived(true)
      })
      .catch(err => showMediaError(err))
  }

  const startMeeting = useCallback(async () => {
    if (!chatID) {
      console.warn('No chat ID')
      return
    }
    setWaitingForPeer(!waitingForPeer)
    isProvider && providerStartStatus({ startMeetingStatus: true })
    if (isPatient && meetingInitited) {
      try {
        ForPatientMeetingInitiated()
      } catch (error) {
        alert(`error ${error}`)
      }
    }
  }, [chatID, peer, meetingInitited])

  useEffect(() => {
    if (isProvider && patientJoin && meetingInitited) {
      callPeer && ForProviderMeetingInitiated()
    }
  }, [patientJoin, isProvider, callPeer])

  useEffect(() => {
    if (window?.Intercom) {
      window.Intercom('update', { hide_default_launcher: true })
    }
    return () => {
      if (window?.Intercom) {
        window.Intercom('update', { hide_default_launcher: false })
      }
    }
  }, [window?.Intercom]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateDatabaseOnLeave = () => {
    collection('videoChatRooms')
      .doc(chatID)
      .set({
        endedAt: serverTimestamp(),
        endedBy: isProvider ? 'provider' : isPatient ? 'patient' : '',
      })
      .then(() => {
        console.log('reset')
        setCallActive(false)
        setPeerArrived(false)
        setWaitingForPeer(false)
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  function showMediaError(err) {
    let message =
      err.name === 'NotFoundError'
        ? 'Please Attach Camera'
        : err.name === 'NotAllowedError'
          ? 'Please Grant Permission to Access Camera'
          : err

    console.log('err-----', err)
    alert(message)
  }

  const initialBtn =
    chatID && isProvider
      ? { btnText: 'Start Meeting', btnDisable: false }
      : isPatient
        ? meetingInitited
          ? { btnText: 'Join Meeting', btnDisable: false }
          : { btnText: 'Host didnt start the meeting', btnDisable: true }
        : { btnText: 'Loading...', btnDisable: true }

  const leaveBtn = isProvider
    ? { btnDisable: meetingInitited ? false : true }
    : isPatient
      ? { btnDisable: patientJoin ? false : true }
      : { btnDisable: true }

  return (
    <>
      {/* desktop */}
      <Box
        style={{
          position: 'fixed',
          zIndex: '10000',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'white',
          top: '0px',
          left: '0px',
        }}
      >
        <Box
          style={{
            backgroundColor: '#eee',
            width: '100%',
            height: `calc(100vh -  60px)`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            // Move button to center of screen?
            style={{
              position: 'absolute',
              zIndex: '10002',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!peerArrived && (
              <>
                {!waitingForPeer && (
                  <>
                    <Button disabled={initialBtn.btnDisable} onClick={startMeeting}>
                      {initialBtn.btnText}
                    </Button>
                  </>
                )}
                {waitingForPeer && (
                  <>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography
                        family='poppins'
                        variant='h5'
                        style={{
                          marginBottom: '0px',
                          marginRight: '10px',
                        }}
                      >
                        Waiting for Peer
                      </Typography>
                      <img
                        src={spin}
                        alt='loading'
                        style={{
                          width: '25px',
                          height: '25px',
                        }}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>

          <ReactPlayer
            playing
            playsinline
            pip={true}
            width='100%'
            height='100%'
            url={peerVideoStream}
            style={{
              transform: 'scaleX(1)',
              objectFit: 'cover',
              zIndex: '10001',
            }}
          />
        </Box>
        <Box
          style={{
            position: 'absolute',
            zIndex: '10001',
            right: '2%',
            top: '2%',
            backgroundColor: '#fff',
            borderRadius: '4px',
            border: 'solid 3px #652D92',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            // minHeight: '200px',
          }}
          sx={{
            width: {
              xs: '120px',
              sm: '120px',
              md: '300px',
              lg: '300px',
              xl: '300px',
            },
            height: {
              xs: '200px',
              sm: '200px',
              md: 'auto',
              lg: 'auto',
              xl: 'auto',
            },
          }}
        >
          {/* self view */}
          <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
            <ReactPlayer
              playing
              playsinline
              pip={true}
              width='100%'
              height='100%'
              url={srcVideoStream}
              muted={true}
              style={{
                transform: 'scaleX(-1)',
                display: 'inline-flex',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              }}
            />
          </Box>
        </Box>
        <Box
          style={{
            // position: 'absolute',
            // left: '0px',
            // bottom: mediaQuerySM ? '80px' : '0px',
            zIndex: '10001',
            width: '100vw',
            height: '60px',
            backgroundColor: 'rgba(255,255,255,0.8)',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '0px 5px',
                }}
              >
                <Button
                  variant='outlined'
                  size='small'
                  type='button'
                  onClick={() => {
                    setDisplayMenu(!displayMenu)
                    setChatToggle(false)
                  }}
                  style={{
                    borderRadius: '50%',
                    border: 'solid 1px #652D92',
                    minHeight: '40px',
                    minWidth: '40px',
                    maxWidth: '40px',
                    margin: '5px',
                    boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {!displayMenu && <Icon name='add' color='primary' />}
                  {displayMenu && <Icon name='openArrow' color='primary' />}
                </Button>
                {displayMenu && (
                  <Box
                    style={{
                      display: 'flex',
                      zIndex: '100000',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      position: 'absolute',
                      left: '10px',
                      bottom: '70px',
                    }}
                  >
                    <Box
                      style={{
                        marginTop: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        boxShadow: 'inset 0px 0px 0px 1px rgba(101, 45, 146, 0.5)',
                        borderRadius: '20px',
                      }}
                    >
                      <Button
                        variant='outlined'
                        size='small'
                        style={{
                          borderRadius: '20px',
                          border: 'solid 1px #652D92',
                          minHeight: '40px',
                          minWidth: '40px',
                          maxWidth: '40px',
                        }}
                      >
                        <Icon name='noteAddOutlined' color='white' />
                      </Button>
                      <Box>
                        <Typography
                          variant='h5'
                          color='primary'
                          family='poppins'
                          style={{
                            margin: '0px 20px 0px 15px',
                            whiteSpace: 'no-wrap',
                          }}
                        >
                          Add a Note
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        marginTop: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        boxShadow: 'inset 0px 0px 0px 1px rgba(101, 45, 146, 0.5)',
                        borderRadius: '20px',
                      }}
                    >
                      <Button
                        variant='outlined'
                        size='small'
                        style={{
                          borderRadius: '20px',
                          border: 'solid 1px #652D92',
                          minHeight: '40px',
                          minWidth: '40px',
                          maxWidth: '40px',
                        }}
                      >
                        <Icon name='assignmentOutlined' color='white' />
                      </Button>
                      <Box>
                        <Typography
                          variant='h5'
                          color='primary'
                          family='poppins'
                          style={{
                            margin: '0px 20px 0px 15px',
                            whiteSpace: 'no-wrap',
                          }}
                        >
                          Referral Order
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        marginTop: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        boxShadow: 'inset 0px 0px 0px 1px rgba(101, 45, 146, 0.5)',
                        borderRadius: '20px',
                      }}
                    >
                      <Button
                        variant='outlined'
                        size='small'
                        style={{
                          borderRadius: '20px',
                          border: 'solid 1px #652D92',
                          minHeight: '40px',
                          minWidth: '40px',
                          maxWidth: '40px',
                        }}
                      >
                        <img
                          src={PillLogo}
                          alt='Pill Logo'
                          style={{
                            height: '24px',
                            width: '24px',
                            transform: 'rotate(-45deg)',
                          }}
                        />
                      </Button>
                      <Box>
                        <Typography
                          variant='h5'
                          color='primary'
                          family='poppins'
                          style={{
                            margin: '0px 20px 0px 15px',
                            whiteSpace: 'no-wrap',
                          }}
                        >
                          Medication Order
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Button
                  variant='outlined'
                  size='small'
                  type='button'
                  onClick={() => {
                    setChatToggle(!chatToggle)
                    setDisplayMenu(false)
                  }}
                  style={{
                    borderRadius: '50%',
                    border: 'solid 1px #652D92',
                    minHeight: '40px',
                    minWidth: '40px',
                    maxWidth: '40px',
                    margin: '5px',
                    boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Icon name='chatOutlined' color='primary' />
                  {/* {!chatMessage && (
                  )}
                  {chatMessage && (
                    <Icon name='announcement' color='primary' />
                  )} */}
                </Button>
                {chatToggle && (
                  <Box
                    mt={2}
                    style={{
                      position: 'absolute',
                      zIndex: '100000',
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                      flexDirection: 'column',
                      border: 'solid 2px #652D92',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                      borderRadius: '4px',
                      backgroundColor: '#fff',
                    }}
                    sx={{
                      maxHeight: {
                        xs: '90vh',
                        md: '81vh',
                      },
                      minHeight: {
                        xs: '48vh',
                        md: '50vh',
                      },
                      maxWidth: {
                        xs: '92vw',
                        md: '25vw',
                      },
                      minWidth: {
                        xs: '92vw',
                        md: '25vw',
                      },
                      left: {
                        xs: '4vw',
                        md: '60px',
                      },
                      bottom: {
                        xs: '50%',
                        md: '70px',
                      },
                    }}
                  >
                    <Box
                      style={{
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        alignItems: 'flex-end',
                        overflow: 'scroll',
                        borderRadius: '4px',
                        padding: '4px 10px',
                      }}
                    >
                      {messages &&
                        messages.map((message, index) => {
                          if (message.sentById === uid) {
                            return (
                              <MessageOut
                                key={index} // eslint-disable-line react/no-array-index-key
                                author={message.sentByName}
                                content={message.content}
                                sendTime={message.sentAt}
                                date={message.date}
                              />
                            )
                          }
                          return (
                            <MessageIn
                              key={index} // eslint-disable-line react/no-array-index-key
                              author={message.sentByName}
                              content={message.content}
                              sendTime={message.sentAt}
                              date={message.date}
                            />
                          )
                        })}
                    </Box>
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'space-around',
                        alignItems: 'flex-end',
                        marginTop: '0px',
                        borderTop: 'solid #eee 1px',
                        padding: '10px',
                      }}
                    >
                      <Box
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                          left: '10px',
                          bottom: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          variant='outlined'
                          size='small'
                          type='button'
                          onClick={() => {
                            setDisplayLinks(!displayLinks)
                          }}
                          style={{
                            borderRadius: '50%',
                            minHeight: '40px',
                            minWidth: '40px',
                            maxWidth: '40px',
                            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
                          }}
                        >
                          {!displayLinks && <Icon name='add' color='primary' />}
                          {displayLinks && <Icon name='openArrow' color='primary' />}
                        </Button>
                        {displayLinks && (
                          <Box
                            style={{
                              display: 'flex',
                              zIndex: '100000',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              position: 'absolute',
                              left: '0px',
                              bottom: '60px',
                              maxWidth: '300px',
                              minWidth: 'fit-content',
                            }}
                          >
                            <Box
                              style={{
                                position: 'relative',
                              }}
                            >
                              <Button
                                variant='outlined'
                                size='small'
                                onClick={() => {
                                  handleImageClick()
                                }}
                                style={{
                                  marginTop: '8px',
                                  borderRadius: '50%',
                                  minHeight: '40px',
                                  minWidth: '40px',
                                  maxWidth: '40px',
                                  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
                                }}
                              >
                                {!currentImage && (
                                  <input
                                    hidden
                                    type='file'
                                    ref={desktopImageRef}
                                    name='chatImageInput'
                                    accept='image/png, image/gif, image/jpeg'
                                    onChange={e => {
                                      setMessageType('image')
                                      handleImageChange(e)
                                    }}
                                  />
                                )}
                                <Icon name={currentImage ? 'close' : 'addPhoto'} />
                              </Button>
                              {currentImage && (
                                <Box
                                  style={{
                                    position: 'absolute',
                                    bottom: '-5px',
                                    left: '50px',
                                  }}
                                >
                                  <img
                                    src={currentImage.url}
                                    alt='preview'
                                    style={{
                                      border: 'solid 2px #652d92',
                                      borderRadius: '4px',
                                      objectFit: 'contain',
                                      maxWidth: '200px',
                                      maxHeight: '200px',
                                      boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.5)',
                                    }}
                                  />
                                </Box>
                              )}
                            </Box>
                            <Box
                              style={{
                                marginTop: '8px',
                                maxHeight: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#fff',
                                boxShadow: 'inset 0px 0px 0px 1px rgba(101, 45, 146, 0.5)',
                                borderRadius: '20px',
                              }}
                            >
                              <Button
                                variant='outlined'
                                size='small'
                                onClick={() => {
                                  handleFileClick()
                                }}
                                style={{
                                  borderRadius: '50%',
                                  minHeight: '40px',
                                  minWidth: '40px',
                                  maxWidth: '40px',
                                  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
                                }}
                              >
                                {!currentFile && (
                                  <input
                                    hidden
                                    type='file'
                                    ref={desktopFileRef}
                                    onChange={e => {
                                      setCurrentImage(null)
                                      setMessageType('file')
                                      setCurrentFile(e.target.files[0])
                                    }}
                                  />
                                )}
                                <Icon name={currentFile ? 'close' : 'attachFile'} />
                              </Button>
                              {currentFile && (
                                <Box
                                  style={{
                                    whiteSpace: 'nowrap',
                                    maxWidth: '250px',
                                    padding: '0px 10px',
                                  }}
                                >
                                  <Typography
                                    variant='body1'
                                    color='primary'
                                    family='poppins'
                                    style={{
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      width: '100%',
                                      maxHeight: '24px',
                                    }}
                                  >
                                    {currentFile.name}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <MessageForm>
                        <Box
                          style={{
                            width: '100%',
                          }}
                        >
                          <TextField
                            id='orderChatMessageBox'
                            autoFocus
                            name='message'
                            size='small'
                            margin='none'
                            placeholder='Message...'
                            fullWidth
                            multiline
                            style={{
                              padding: '0px 50px',
                            }}
                          />
                        </Box>
                        <Button
                          size='small'
                          type='submit'
                          style={{
                            color: '#fff',
                            position: 'absolute',
                            right: '10px',
                            bottom: '10px',
                            borderRadius: '50%',
                            minHeight: '40px',
                            minWidth: '40px',
                            maxWidth: '40px',
                            marginLeft: '16px',
                            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
                          }}
                        >
                          <Icon name='send' color='inherit' />
                        </Button>
                      </MessageForm>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant='outlined'
                  size='small'
                  type='button'
                  onClick={() => {
                    srcVideoStream.getAudioTracks().forEach(track => {
                      track.enabled = microphoneToggle
                    })
                    setMicrophoneToggle(!microphoneToggle)
                  }}
                  style={{
                    borderRadius: '50%',
                    border: 'solid 1px #652D92',
                    minHeight: '40px',
                    minWidth: '40px',
                    maxWidth: '40px',
                    margin: '5px 20px',
                    boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {!microphoneToggle && <Icon name='micNoneOutlined' color='primary' />}
                  {microphoneToggle && <Icon name='micOffOutlined' color='primary' />}
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  type='button'
                  onClick={() => {
                    setVideoCameraToggle(!videoCameraToggle)
                    // pause video feed

                    srcVideoStream.getVideoTracks().forEach(track => {
                      track.enabled = !track.enabled
                    })
                  }}
                  style={{
                    borderRadius: '50%',
                    border: 'solid 1px #652D92',
                    minHeight: '40px',
                    minWidth: '40px',
                    maxWidth: '40px',
                    margin: '5px 20px',
                    boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {!videoCameraToggle && <Icon name='videocamOutlined' color='primary' />}
                  {videoCameraToggle && <Icon name='videocamOffOutlined' color='primary' />}
                </Button>
                {/* <Button
                  variant='outlined'
                  size='small'
                  type='button'
                  onClick={() => {
                    setScreenShareToggle(!screenShareToggle)
                  }}
                  style={{
                    borderRadius: '50%',
                    border: 'solid 1px #652D92',
                    minHeight: '40px',
                    minWidth: '40px',
                    maxWidth: '40px',
                    margin: '5px 20px',
                    boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  {!screenShareToggle && <Icon name='desktopWindowsOutlined' color='primary' />}
                  {screenShareToggle && <Icon name='desktopAccessDisabledOutlined' color='primary' />}
                </Button> */}
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  padding: '0px 5px',
                }}
              >
                <Button
                  variant='outlined'
                  size='small'
                  type='button'
                  disabled={leaveBtn.btnDisable}
                  style={{
                    borderRadius: '50%',
                    border: 'solid 1px #f44336',
                    backgroundColor: leaveBtn.btnDisable ? 'grey' : '#f44336',
                    minHeight: '40px',
                    minWidth: '40px',
                    maxWidth: '40px',
                    margin: '5px',
                    boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.1)',
                  }}
                  onClick={async () => {
                    setCallActive(false)
                    setPeerArrived(false)
                    srcVideoStream.getTracks().forEach(track => {
                      track.stop()
                    })
                    setPeerVideoStream(null)
                    updateDatabaseOnLeave()
                  }}
                >
                  <Icon name='callEndOutlined' color='white' />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackBarOpen}
          onClose={handleSnackClose}
          autoHideDuration={3000}
          message={`Call Ended`}
        />
      </Box>
    </>
  )
}

export default VideoChatContainer
