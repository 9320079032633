import {
  TermsBlock as Block,
  TermsText as Text,
  TermsTitle as Title,
  TermsLink as Link,
  TermsOrderedList as List,
  TermsListItem as Item,
} from '../../stories/Terms'
import Bold from '../../stories/Bold'
import CapsLock from '../../stories/CapsLock'

const EULAConsumerContent = () => (
  <div>
    <Block id='eulaC0'>
      <Text>
        <CapsLock>THIS APP IS NOT INTENDED FOR USE BY INDIVIDUALS UNDER 18 YEARS OLD.</CapsLock>
      </Text>
      <Text>
        This End User License Agreement (<Bold>&quot;Agreement&quot;</Bold>) sets forth the terms
        and conditions upon which you may download and use the Mishe, INC website, software
        application, related services, and all updates, enhancements, and upgrades provided to you
        (collectively, <Bold>&quot;App&quot;</Bold>) by Mishe, INC (<Bold>&quot;Mishe&quot;</Bold>)
        and/or its affiliates. &quot;You&quot;, &quot;your&quot;, &quot;yourself&quot; means you, as
        an individual, and the legal entity on behalf of which you are acting as the authorized
        agent or legal representative as identified by you during registration with the App, if
        applicable.
      </Text>
      <Text>
        <CapsLock>
          PLEASE READ THIS AGREEMENT CAREFULLY IN ITS ENTIRETY BEFORE USING THE APP. IF YOU DO NOT
          AGREE TO BE BOUND BY THIS AGREEMENT, PLEASE DO NOT USE THE APP. THIS AGREEMENT CONTAINS
          WARRANTY DISCLAIMERS AND OTHER PROVISIONS THAT LIMIT MISHE&apos;S LIABILITY TO YOU. BY
          USING THE APP, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT,
          THE PROVISIONS, DISCLOSURES, AND DISCLAIMERS SET FORTH IN THIS AGREEMENT ARE FAIR AND
          REASONABLE, AND YOUR AGREEMENT TO FOLLOW AND BE BOUND BY THIS AGREEMENT IS VOLUNTARY AND
          IS NOT THE RESULT OF FRAUD, DURESS, OR UNDUE INFLUENCE EXERCISED UPON YOU BY ANY PERSON OR
          ENTITY.
        </CapsLock>
      </Text>
    </Block>

    <Block id='eulaC1'>
      <Title>1. In General.</Title>
      <Text>
        The terms of this Agreement will govern the App and any software updates, enhancements, and
        upgrades that replace and/or supplement the original App, unless such upgrade is accompanied
        by a separate license in which case the terms of that license will govern. You agree to
        comply with all terms, conditions, and restrictions set forth in this Agreement. You
        acknowledge that any use of the App not in compliance with this Agreement invalidates this
        license and may be prosecuted to the full extent of the law. By downloading and using this
        App, you represent and warrant that you are at least 18 years old.
      </Text>
    </Block>

    <Block id='eulaC2'>
      <Title>2. Permitted Use and Restrictions.</Title>
      <List type='a'>
        <Item>
          <Bold>Grant of License and Reservations.</Bold> Subject to the terms of this Agreement,
          Mishe grants you a limited, non-exclusive, revocable, non-transferable license to install
          and use the App and any future fixes, updates and upgrades provided to you, solely for use
          in connection with your medical care by transmitting, accessing, managing, collecting, and
          displaying Patient Information (defined below). This App is licensed, not sold, to you by
          Mishe for use only under the terms of this Agreement. Mishe reserves all rights not
          expressly granted to you. The rights granted in this Agreement are limited to Mishe&apos;s
          intellectual property rights in and/or related to the App and do not include any other
          patents or intellectual property rights.
        </Item>
        <Item>
          <Bold>Reverse Engineering.</Bold> Except as expressly set forth in this Agreement, you
          agree not to reverse engineer, de-compile, disassemble, alter, duplicate, modify, rent,
          lease, loan, sublicense, make copies, create derivative works from, distribute or provide
          others with the App, in whole or part, or transmit or communicate the App over a network
          or to any third party. Further, you agree not to develop, sell, or distribute applications
          that are capable of launching, being launched from, or are otherwise integrated with, the
          App or Content accessed through the App without Mishe&apos;s express written permission.
        </Item>
        <Item>
          <Bold>Further Restrictions.</Bold> You further agree not to use the App to do the
          following:
          <List type='i'>
            <Item>
              Transmit, access, or communicate any data that you do not have the right to transmit
              under applicable Law (defined below), including, without limitation, the Health
              Insurance Portability and Accountability Act of 1996 as modified by the Health
              Information Technology for Economic and Clinical Health Act and all rules,
              regulations, and related laws and acts promulgated under and in connection therewith
              (collectively, <Bold>&quot;HIPAA&quot;</Bold>
              ), under similar more stringent state laws, or under a contractual or fiduciary
              relationship;
            </Item>
            <Item>
              Transmit, access or communicate any data that infringes upon any patent, trademark,
              trade secret, copyright or other proprietary rights of any party;
            </Item>
            <Item>
              Transmit or communicate any data that contains software viruses or any other computer
              code, files, or programs designed, intended, or likely to interrupt, destroy or limit
              the functionality of any computer software or hardware or any telecommunications
              equipment;
            </Item>
            <Item>Interfere with, disrupt, or circumvent the App;</Item>
            <Item>
              Intentionally or unintentionally violate any applicable local, state, national or
              international law, including securities exchange commission regulations, and any
              regulations, requirements, procedures or policies in force from time to time relating
              to the App; or
            </Item>
            <Item>
              Transmit or communicate any data that is unlawful, harmful, threatening, abusive,
              harassing, defamatory, vulgar, obscene, invasive of another&apos;s privacy, hateful,
              or racially, ethnically or otherwise objectionable.
            </Item>
          </List>
        </Item>
      </List>
    </Block>

    <Block id='eulaC3'>
      <Title>3. Patient Information.</Title>
      <Text>
        You acknowledge and agree that the App may be used to transmit, collect, access, manage, and
        display Patient Information by you and authorized Healthcare Providers, and their employees
        and contractors. You acknowledge and agree that Patient Information related to your
        treatment may be stored by Mishe and/or its licensees and service providers in connection
        with providing the App and its related services, as well as shared among other users of the
        App and its related services. You acknowledge and agree that the Patient Information stored
        by Mishe shall not serve as the system of record for any patient, personal representative of
        a patient, health care provider, any business associate of a health care provider, or any
        affiliates of the foregoing. <Bold>&quot;Patient Information&quot;</Bold> means,
        collectively, information and data related to the provision of health care to patients,
        their health status, medical records, and related information and documents, including
        consent to treatment forms, authorization to disclose medical information forms, Medicare
        forms, Medicaid forms, living wills, Directives to Physicians and Family or Surrogates,
        Medical Powers of Attorney, Out-of-Hospital Do-Not-Resuscitate Orders, Declarations of
        Mental Health Treatment, images, reports, and lab and test results, medical treatments
        performed by you and/or other Healthcare Providers, and other &quot;protected health
        information,&quot; as defined under HIPAA and similar terms as defined by state law.
      </Text>
    </Block>

    <Block id='eulaC4'>
      <Title>4. Medical Advice and Treatment.</Title>
      <Text>
        Mishe does not provide medical advice, diagnosis, or treatment. You acknowledge and agree
        that the App is merely a conduit of information related to patients and the provision of
        healthcare to patients by independent third party healthcare providers including physicians,
        physician assistants, nurses, paramedics, emergency care responders, other physician
        extenders, healthcare systems, healthcare facilities, or other providers of healthcare
        services (collectively, <Bold>&quot;Healthcare Provider(s)&quot;</Bold>). You acknowledge
        and agree that the Healthcare Providers are solely responsible for and will have complete
        authority, responsibility, supervision, and control over the provision of all medical
        services, advice, instructions, treatment decisions, and other professional health care
        services performed, and that all diagnoses, treatments, procedures, and other professional
        health care services will be provided and performed exclusively by or under the supervision
        of Healthcare Providers as they, in their sole discretion, deem appropriate. You further
        acknowledge and agree that Mishe does not provide or endorse any medical advice on or
        through the App, that no information obtained through the App can be so construed or used,
        and that no medical professional/patient relationship exists between you and Mishe. Mishe
        will have and exercise absolutely no control, authority, or supervision over the provision
        of any medical services or other professional health care services. The use of all text,
        graphics, images, audio content, audiovisual content, other materials and any other
        information provided on or made available through the App, including all healthcare related
        information, instructions, advice, or other guidance, whether provided by Healthcare
        Providers, or other third parties (collectively, <Bold>&quot;Content&quot;</Bold>) is solely
        your responsibility. Mishe will make all reasonable efforts in accordance with applicable
        laws and agreements to safeguard the integrity and availability of the Content. Further,
        when using the App, information may be transmitted over a medium that may be beyond the
        control and jurisdiction of Mishe and its suppliers. Accordingly, Mishe assumes no liability
        for or relating to the delay, failure, interruption, or corruption of any data or other
        information transmitted in connection with use of the App. Mishe does not recommend or
        endorse any specific therapies, treatments, tests, health care providers, procedures,
        opinions, or other information that may be transmitted through the App. Accordingly, you
        acknowledge and agree that Mishe is not responsible for any and all medical services,
        advice, instructions, diagnoses, treatments, procedures, and other services provided to you
        in connection with using the App or otherwise.
      </Text>
    </Block>

    <Block id='eulaC5'>
      <Title>5. Registration.</Title>
      <Text>
        Upon registration, you agree to provide current, accurate, and complete information about
        you as is required to register to use the App and at other points as may be required in the
        course of using the App, including your complete legal name, street address, phone
        number(s), email address, and such other information as may be requested by Mishe (
        <Bold>&quot;Registration Data&quot;</Bold>). Further, you agree to maintain and update your
        Registration Data as required to keep it current, accurate, and complete. You agree that
        Mishe may store and use the Registration Data you provide in connection with your use of the
        App in accordance with the Privacy Policy (which is incorporated into this Agreement by
        reference). Mishe has the right, but not the duty, to confirm or otherwise verify or check,
        in its sole discretion, the truth and accuracy of any registration information at any time.
        Verification of your registration information, specifically, your name, address and/or tax
        identification number, against a third party database may be considered to constitute a
        &quot;credit check&quot; under certain laws. Mishe is not making, and will not otherwise
        make, any type of inquiry to any third party regarding any individual&apos;s credit history
        and personal financial information without first obtaining such individual&apos;s express
        authorization to do so. Mishe may terminate your rights to the entire App if any information
        you provide is false, incomplete or inaccurate.
      </Text>
    </Block>

    <Block id='eulaC6'>
      <Title>6. Third Party Technology.</Title>
      <Text>
        Any and all third party technology provided, made available, linked to, or otherwise
        accessible through the App (<Bold>&quot;Third Party Technology&quot;</Bold>) is provided
        solely as a convenience to you and is not under the control of Mishe. Mishe does not
        endorse, recommend, or otherwise make any representations or warranties with respect to any
        Third Party Technology. Mishe does not have any responsibility or liability to you for any
        Third Party Technology, which you access and you use at your own risk. Further, you agree to
        comply with any and all terms and conditions applicable to the use of Third Party Technology
        and otherwise ensure that you have obtained all rights, licenses, and clearances that may be
        necessary to use such Third Party Technology.
      </Text>
    </Block>

    <Block id='eulaC7'>
      <Title>7. Third Party Websites.</Title>
      <Text>
        This App may contain links to other independent third party websites (
        <Bold>&quot;Linked Websites&quot;</Bold>). These Linked Websites are provided solely as a
        convenience to our visitors. Such Linked Websites are not under the control of Mishe, and
        Mishe is not responsible for and does not endorse the content of such Linked Websites,
        including any information or materials contained on such Linked Websites. Mishe does not
        have any responsibility or liability for any information, data, communications or materials
        available on such third-party sites. You therefore access these Linked Websites at your own
        risk.
      </Text>
    </Block>

    <Block id='eulaC8'>
      <Title>8. Collection of Information.</Title>
      <Text>
        You grant Mishe the perpetual, non-exclusive, worldwide, royalty-free irrevocable license to
        use, copy, print, display, reproduce, modify, publish, post, transmit, distribute, and
        preserve any transmittal, communication, or other Content provided by or to you through the
        App, or any other service offered on or through the App, and data related to your use of the
        App, including your name, profile, and biography. Mishe may disclose any such information
        and Content to other users of the App, Mishe licensees, service providers, clients,
        researchers, and other third parties in accordance with applicable Law, agreements and the
        Privacy policy. Further, Mishe may also disclose such data if required to do so by Law or
        Mishe determines that such preservation or disclosure is reasonably necessary to (1) comply
        with legal process, (2) enforce this Agreement, (3) respond to claims that any such data
        violates the rights of others, or (4) protect the rights, property, or personal safety of
        Mishe, its employees, and users of the App. For further information regarding your privacy,
        please review our{' '}
        <Link type='internal' to='/privacy-policy'>
          Privacy Policy
        </Link>{' '}
        which is incorporated into this Agreement by reference. Notwithstanding the foregoing, the
        Privacy Policy may be changed from time to time without amending this Agreement. To the
        extent that there is a conflict between the Privacy Policy and this Agreement, this
        Agreement will control with respect to your use of the App; the Privacy Policy will control
        with respect to the collection, use, disclosure, and disposal of your information.
      </Text>
    </Block>

    <Block id='eulaC9'>
      <Title>9. Authorized Use.</Title>
      <Text>
        Except as expressly set forth in this Agreement, you will protect the confidentiality of the
        App, and will not distribute or otherwise make available the App, or any portion of the App,
        in any form to any third party. Any rights you may possess in the App expire upon expiration
        or termination of this Agreement. You will employ the security measures necessary to prevent
        unauthorized users from accessing the App and your user ID(s) and password(s) (
        <Bold>&quot;Login Information&quot;</Bold>). You are solely responsible for the maintenance
        and protection of your Login Information. You accept responsibility for, and will be liable
        for all access to the App in connection with your Login Information. Without the prior
        written consent of Mishe, you will not utilize the services of any third party to assist you
        in using the App. Further, you will be responsible for all activities that occur under or in
        connection with your account and your use of the App and you agree to notify Mishe in the
        event your password has been hacked or stolen.
      </Text>
    </Block>

    <Block id='eulaC10'>
      <Title>10. Export Law Assurances.</Title>
      <Text>
        You will not use or otherwise export or re-export this App except as authorized by United
        States law and the laws of the jurisdiction in which the App was obtained. In particular,
        but without limitation, the App will not be exported or re-exported (1) into (or to a
        national or resident of) any U.S. embargoed countries, or (2) to anyone on the U.S. Treasury
        Department&apos;s list of Specially Designated Nationals or the U.S. Department of Commerce
        Denied Persons&apos; List or Entity List. By using the App, you represent and warrant that
        you are not located in, under control of, or a national or resident of any such country or
        on any such list.
      </Text>
    </Block>

    <Block id='eulaC11'>
      <Title>11. Territory.</Title>
      <Text>
        You agree to abide by United States and other applicable export control laws and not to
        transfer, by electronic transmission or otherwise, any content or software subject to
        restrictions under such laws to a national destination or person prohibited under such laws.
      </Text>
    </Block>

    <Block id='eulaC12'>
      <Title>12. Trademarks.</Title>
      <Text>
        Trademarks, service marks, graphics and logos used in connection with the App are the
        trademarks of their respective owners. You are not granted any right or license with respect
        to any of the trademarks mentioned above and any use of such trademarks.
      </Text>
    </Block>

    <Block id='eulaC13'>
      <Title>13. Copyright Infringement.</Title>
      <Text>
        Mishe respects copyright and other laws. Mishe requires all App users to comply with
        copyright and other laws. Mishe does not, by the supply of the App, authorize you to
        infringe the copyright or other rights of third parties. As a condition to use the App, you
        agree that you must not use the App to infringe upon the intellectual property or other
        rights of others in any way. The unauthorized reproduction, distribution, modification,
        public display, communication to the public or public performance of copyrighted works is an
        infringement of copyright. You are entirely responsible for your conduct and for ensuring
        that it complies with all applicable copyright and data-protection laws. In the event you
        fail to comply with laws regarding copyrights or other intellectual property rights, data
        protection and privacy, you may be exposed to civil and criminal liability, including
        possible fines and jail time.
      </Text>
    </Block>

    <Block id='eulaC14'>
      <Title>14. Ownership and Title.</Title>
      <Text>
        All title to and the rights in the App, including ownership rights to patents
        (registrations, renewals, and pending applications), copyrights, trademarks, service marks,
        domain names, trade dress, trade secrets, Mishe&apos;s or third party other technology, any
        derivatives of and all goodwill associated with the foregoing is the exclusive property of
        Mishe and/or third parties.
      </Text>
    </Block>

    <Block id='eulaC15'>
      <Title>15. Representations & Warranties.</Title>
      <Text>
        In addition to the other representations and warranties contained in this Agreement, you
        further represent, warrant, and covenant to Mishe the following:
      </Text>
      <List type='a'>
        <Item>
          All information you provide to Mishe as part of the registration process or otherwise will
          be truthful, accurate and complete, irrespective of any independent verification or other
          determination made by Mishe;
        </Item>
        <Item>
          Your use of the App will comply with all applicable national, federal, state, and local
          laws, regulations, ordinances, and judicial decisions in courts and tribunals of competent
          jurisdiction within the United States (collectively, <Bold>&quot;Law&quot;</Bold>);
        </Item>
        <Item>
          This Agreement has been duly and validly authorized, accepted, agreed to, and delivered by
          you (or your authorized representative) and constitutes your legal, valid, and binding
          obligation, enforceable against you in accordance with this Agreement. You represent that
          you have full power, capacity and authority to enter into this Agreement; and
        </Item>
        <Item>
          The performance by you of this Agreement and your use of the App does not and will not
          conflict with or violate (1) any law, rule, regulation, order, judgment, decree,
          agreement, instrument, or obligation applicable to you, or (2) if you are an entity, any
          provision of your organizational or governing documents.
        </Item>
      </List>
    </Block>

    <Block id='eulaC16'>
      <Title>16. Services.</Title>
      <Text>
        You hereby agree to pay for the services, products and/or merchandise purchased via the App
        and further agree to refrain from submitting claims to, without limitation, any insurer,
        insurance company, insurance carrier, underwriter or similar entity.
      </Text>
    </Block>

    <Block id='eulaC17'>
      <Title>17. Disclaimer of Warranties.</Title>
      <Text>
        <CapsLock>
          The App, CONTENT, and any THIRD PARTY TECHNOLOGY are provided on an &quot;AS IS&quot; and
          &quot;AS AVAILABLE&quot; basis without warranties of any kind, either express or implied,
          including warranties of title, non-infringement, and implied warranties of merchantability
          or fitness for a particular purpose. Without limiting the generality of the foregoing,
          MISHE, its licensors, and suppliers make no warranty, representation, or guaranty:
        </CapsLock>
      </Text>
      <List type='1'>
        <Item>
          <CapsLock>
            as to the content, sequence, accuracy, timeliness, RELEVANCE, or completeness of any
            content;
          </CapsLock>
        </Item>
        <Item>
          <CapsLock>
            AS TO any CONTENT offered or provided within or through the App regarding, WITHOUT
            LIMITATION, treatment of medical conditions, actionS, DIAGNOSeS, procedures, application
            of medication, or other provision of healthcare services;
          </CapsLock>
        </Item>
        <Item>
          <CapsLock>
            as to the satisfaction of any government regulations OR LAW requiring disclosure of
            CONTENT on prescription drug products or the approval or compliance of any software
            tools with regard to the Content or app; OR
          </CapsLock>
        </Item>
        <Item>
          <CapsLock>
            that the APP OR content may be relied upon for any reason, that THE APP OR content will
            be uninterrupted or error free, or that any defects can or will be corrected;
          </CapsLock>
        </Item>
      </List>
      <Text>
        <CapsLock>
          further, YOUR USE OF THE APP, CONTENT, AND any THIRD PARTY TECHNOLOGY IS AT YOUR OWN RISK.
          MISHE DOES NOT WARRANT THAT THE APP, content, OR THIRD PARTY TECHNOLOGY WILL MEET YOUR
          SPECIFIC REQUIREMENTS. TO THE EXTENT THAT MISHE MAY NOT DISCLAIM ANY WARRANTY AS A MATTER
          OF APPLICABLE LAW, THE SCOPE AND DURATION OF SUCH WARRANTY WILL BE THE MINIMUM PERMITTED
          UNDER SUCH LAW.
        </CapsLock>
      </Text>
    </Block>

    <Block id='eulaC18'>
      <Title>18. Limitation of Liability.</Title>
      <Text>
        <CapsLock>
          1. EXCEPT WHERE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCES WILL MISHE BE LIABLE TO YOU OR
          ANY OTHER PERSON FOR DIRECT, CONSEQUENTIAL, SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR
          EXEMPLARY DAMAGES, COSTS, EXPENSES OR LOSSES OR LOST PROFITS IN CONNECTION WITH THE APP OR
          OTHERWISE RELATED TO THIS AGREEMENT. THE PROVISIONS OF THIS SECTION WILL APPLY REGARDLESS
          OF THE FORM OF ACTION, DAMAGE, CLAIM, LIABILITY, COST, EXPENSE, OR LOSS, WHETHER IN
          CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE), FAILURE OF ESSENTIAL PURPOSE OR OTHERWISE,
          AND EVEN IF ADVISED OF THE LIKELIHOOD OF SUCH DAMAGES. MISHE WILL NOT BE LIABLE FOR ANY
          FAILURE TO PERFORM ITS OBLIGATIONS UNDER THIS AGREEMENT BECAUSE OF CIRCUMSTANCES BEYOND
          ITS CONTROL, WHICH CIRCUMSTANCES INCLUDE NATURAL DISASTER, PANDEMIC, TERRORISM, THIRD
          PARTY LABOR DISPUTES, WAR, DECLARATIONS OF GOVERNMENTS, TRANSPORTATION DELAYS, FAILURE OF
          HARDWARE, EQUIPMENT, OR TELECOMMUNICATIONS FAILURE. MISHE WILL NOT BE LIABLE FOR ANY
          FAILURE TO PERFORM ITS OBLIGATIONS UNDER THIS AGREEMENT BECAUSE OF MISUSE OF THE APP BY
          YOU OR BY ANY OTHER PERSON. THIS SECTION WILL BE GIVEN FULL EFFECT EVEN IN THE EVENT THAT
          ANY EXCLUSIVE REMEDY PROVIDED HAS FAILED OF ITS ESSENTIAL PURPOSE. FURTHER, IN NO EVENT
          WILL MISHE BE LIABLE TO YOU OR ANYONE ELSE FOR ANY CLAIMS, LOSSES, OR DAMAGES ARISING OUT
          OF ANY USE OR MISUSE OF ANY THIRD PARTY TECHNOLOGY. THE PARTIES ACKNOWLEDGE THAT THE TERMS
          OF THIS SECTION REFLECT THE ALLOCATION OF RISK SET FORTH IN THIS AGREEMENT AND THAT THE
          PARTIES WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT THESE LIMITATIONS OF LIABILITY. IN NO
          EVENT SHALL THE LIABILITY OF MISHE FOR ANY LOSS RELATED TO USE OR INABILITY TO USE THE APP
          EXCEED $1.00 U.S.
        </CapsLock>
      </Text>
    </Block>

    <Block id='eulaC19'>
      <Title>19. Indemnification.</Title>
      <Text>
        <CapsLock>
          You will indemnify, defend, and hold Indemnitee (as defined below) harmless from and
          against any and all Claims and Losses ARISING from or attributable to (1) your breach of
          any of your representations, warranties, covenants, or other agreements made under this
          Agreement; (3) any Claims by or disputes related to your use of the App; (4) ANY CONTENT
          YOU PROVIDE TO the app OR OTHERWISE TRANSMIT USING THE APP; and (5) any breach of
          confidentiality related to your use of the app.
        </CapsLock>
      </Text>
      <List type='a'>
        <Item>
          <Bold>Procedures.</Bold> The Indemnitee will give you written notice of any Claim for
          which indemnification is sought. However, failure to provide such notice will not relieve
          you from your liability or obligations under this Agreement, except to the extent you are
          materially prejudiced as a direct result of such failure. The Indemnitee will cooperate
          with you at your expense in connection with the defense and settlement of the Claim. You
          may not settle any indemnified Claim in a manner that adversely affects the Indemnitee
          without its prior written consent. Further, the Indemnitee may participate in the defense
          of the Claim through counsel of its own choosing at its own cost and expense. If you fail
          to promptly assume the defense and employ counsel reasonably satisfactory to Indemnitee,
          or the Indemnitee has been advised by counsel that there exist actual or potential
          conflicting interests between you or your counsel and such Indemnitee, the Indemnitee may
          employ separate counsel, in addition to local counsel, to represent or defend such
          Indemnitee in such action or proceeding, and you agree to pay the fees and disbursements
          of such separate counsel as incurred. To the extent indemnification requires the payment
          of monies owed, such indemnification will occur as soon as reasonably possible after the
          determination of monies owed, and payment to the Indemnitee will be made within 30 days of
          a final determination of monies owed. Your obligations under this Section are in addition
          to any rights that any Indemnitee may have at common law or otherwise.
        </Item>
        <Item>
          <Bold>Defined Terms.</Bold> <Bold>&quot;Claim&quot;</Bold> means each and every claim,
          request, accusation, allegation, assertion, complaint, petition, demand, suit, action,
          proceeding, and cause of action of every kind and description.{' '}
          <Bold>&quot;Indemnitee&quot;</Bold> means Mishe, its affiliates, and its and their
          respective officers, directors, shareholders, managers, members, agents, employees,
          representatives, successors, and assigns. &quot;Loss&quot; means each and every liability,
          loss, damage, and injury (including injury or damage to any property right, and injury,
          damage, or death to any Person), wound, wrong, hurt, harm, expense, deficiency, diminution
          in value, obligation, expenditure and disbursement of any kind or nature (including all
          fees, costs, and expenses of investigation, travel expenses, and value of time expended by
          personnel), settlement, fine, fee, cost, cost of court, and all expenses of litigation
          (including reasonable attorneys&apos; fees) incident to any of the foregoing.
        </Item>
      </List>
    </Block>

    <Block id='eulaC20'>
      <Title>20. Release.</Title>
      <Text>
        <CapsLock>
          IN the event that you have a dispute with one or more users of the App for any reason, you
          release MISHE (and its officers, directors, managers, members, agents, subsidiaries,
          REPRESENTATIVES, and employees) from all claims, demands and damages (actual and
          consequential) of every kind and nature, known and unknown, suspected and unsuspected,
          disclosed and undisclosed, arising out of or in any way connected with such disputes. If
          you are a California resident, you waive California Civil Code §1542, which provides:
          &quot;A general release does not extend to claims which the creditor does not know or
          suspect to exist in his favor at the time of executing the release, which if known by him
          must have materially affected his settlement with the debtor.&quot;
        </CapsLock>
      </Text>
    </Block>

    <Block id='eulaC21'>
      <Title>21.Term.</Title>
      <Text>
        Unless otherwise terminated by Mishe as provided for in this Agreement, this Agreement will
        remain in effect for so long as Mishe licenses the use of the App to you.
      </Text>
    </Block>

    <Block id='eulaC22'>
      <Title>22. Termination.</Title>
      <Text>
        Mishe may, in its sole discretion and without prior notice, terminate your access to the App
        for violations of this Agreement or other agreements or guidelines, which may be associated
        with your use of the App, or if Mishe deems it necessary in its sole discretion. In any such
        event, you must destroy all copies of the App and all of its component parts. Failure to
        abide by this Agreement is a material breach of this Agreement for which Mishe may pursue
        all rights and remedies it has pursuant to this Agreement, and any other rights and remedies
        it may have at law or in equity. You also agree that any violation by you of this Agreement
        will constitute an unlawful and unfair business practice and will cause irreparable harm to
        Mishe, for which monetary damages would be inadequate, and you consent to Mishe obtaining
        any injunctive or equitable relief that Mishe deems necessary or appropriate in such
        circumstances without the need to post a bond or satisfy any similar requirements. These
        remedies are in addition to any other remedies Mishe may have at law or in equity.
      </Text>
    </Block>

    <Block id='eulaC23'>
      <Title>23. U. S. Government End Users.</Title>
      <Text>
        The App is a &quot;commercial item&quot; as that term is defined at FAR 2.101, consisting of
        &quot;commercial computer software&quot; and &quot;commercial computer software
        documentation&quot; as such terms are used in 48 C.F.R. 12.212 and is provided to the U.S.
        Government only as a commercial end item. Consistent with FAR 12.212 and DFARS 227.7202, all
        U.S. Government End Users acquire the App with only those rights expressly set forth in this
        Agreement.
      </Text>
    </Block>

    <Block id='eulaC24'>
      <Title>24. General Provisions.</Title>
      <List type='a'>
        <Item>
          <Bold>Entire Agreement.</Bold> This Agreement, the Privacy Policy, and any other terms of
          use, or other guidelines (collectively, <Bold>&quot;Other Terms&quot;</Bold>) provided by
          Mishe through or in connection with the App contain the entire understanding of the
          parties with respect to the subject matter of this Agreement and supersede all previous
          verbal and written agreements between the parties concerning the subject matter of this
          Agreement. To the extent that any Other Terms conflict with any provision of this
          Agreement, this Agreement will control. The App is the property of Mishe. Mishe reserves
          the right to change, add or remove portions of this Agreement or the App at any time and
          at its sole discretion. Your continued use of the App following the posting or delivery to
          you of any changes means that you accept and agree to such changes.
        </Item>
        <Item>
          <Bold>Assignment.</Bold> This Agreement, and any rights or obligations in this Agreement
          will not be assigned by you without the prior written consent of Mishe. Any attempt to
          assign or transfer this Agreement other than in accordance with this provision will be
          null and void. Subject to the forgoing, this Agreement and its terms and provisions inure
          to the benefit of and are binding upon the parties and their respective successors, heirs,
          personal representatives, and assigns.
        </Item>
        <Item>
          <Bold>Governing Law/Waiver of Trial by Jury.</Bold>
          <List type='i'>
            <Item>
              You agree that all matters relating to your access to or use of the App, including all
              disputes, will be governed by the laws of the United States and by the laws of the
              State of Delaware without regard to its conflicts of laws provisions. You agree to the
              personal jurisdiction by and venue in the state and federal courts in California, and
              waive any objection to such jurisdiction or venue. This Agreement will not be governed
              by the United Nations Convention on Contracts for the International Sale of Goods, the
              application of which is expressly excluded. If for any reason a court of competent
              jurisdiction finds any provision, or portion of any provision, to be unenforceable,
              the remainder of this Agreement will continue in full force and effect.
            </Item>
            <Item>
              <CapsLock>
                EXCEPT WHERE PROHIBITED BY LAW, THE PARTIES EXPRESSLY WAIVE TRIAL BY JURY IN ANY
                JUDICIAL PROCEEDING INVOLVING ANY DISPUTE, CONTROVERSY, OR CLAIM ARISING OUT OF OR
                RELATING TO THIS AGREEMENT.
              </CapsLock>
            </Item>
          </List>
        </Item>
        <Item>
          <Bold>Notices.</Bold> All notices, requests, or consents sent to Mishe that are required
          or permitted under this Agreement must be in writing (including electronic form) and must
          be delivered in a notice served in the manner provided for below. Each notice, request,
          consent, or other communication will be given and will be effective: (1) if delivered by
          hand, when so delivered; (2) if delivered by nationally recognized overnight courier
          service or sent by United States Express Mail, upon confirmation of delivery; (3) if
          delivered by certified or registered mail, on the third following day after deposit with
          the United States Postal App; or (4) if delivered by facsimile, upon confirmation of
          successful transmission, and if delivered by email, upon confirmation of receipt by the
          other party in writing by return email.
        </Item>
        <Item>
          <Bold>Severability.</Bold> The provisions of this Agreement are severable. The invalidity,
          in whole or in part, of any provision of this Agreement will not affect the validity or
          enforceability of any other of its provisions. If one or more provisions of this Agreement
          are declared invalid or unenforceable, the remaining provisions will remain in full force
          and effect and will be construed in the broadest possible manner to effectuate the
          purposes of this Agreement. The parties further agree to replace such void or
          unenforceable provisions of this Agreement with valid and enforceable provisions that will
          achieve, to the extent possible, the economic, business, and other purposes of the void or
          unenforceable provisions.
        </Item>
        <Item>
          <Bold>Captions.</Bold> The headings and captions of this Agreement are inserted for
          reference convenience and do not define, limit, or describe the scope or intent of this
          Agreement or any particular section, paragraph, or provision of this Agreement. Unless
          otherwise expressly provided, the words &quot;include(s),&quot; &quot;included,&quot; or
          &quot;including&quot; do not limit the preceding words or terms. Pronouns will refer to
          the masculine, feminine, neuter, singular, or plural as the context will require.
        </Item>
        <Item>
          <Bold>Waiver.</Bold> The failure or delay of Mishe to exercise or enforce any rights or
          provision of this Agreement does not constitute a waiver of such right or provision.
        </Item>
        <Item>
          <Bold>Survival.</Bold> All provisions which must survive in order to give effect to their
          meaning will survive any expiration or termination of this Agreement, including without
          limitation Sections 2(b), 16, 17, 18, and 19 and all of your representations, warranties
          and indemnification obligations, which will survive any expiration or termination of this
          Agreement indefinitely.
        </Item>
      </List>
    </Block>
  </div>
)

export default EULAConsumerContent
