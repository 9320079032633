import { useCallback, useState } from 'react'

const useSteps = () => {
  const [step, setStep] = useState(0)

  const nextStep = useCallback(() => {
    setStep(current => current + 1)
  }, [])

  const previousStep = useCallback(() => {
    setStep(current => current - 1)
  }, [])

  const nextStepWithoutPayment = useCallback(() => {
    setStep(current => current + 2)
  }, [])

  const previousStepWithoutPayment = useCallback(() => {
    setStep(current => current - 2)
  }, [])

  const changeStep = useCallback((_, newStep) => {
    setStep(newStep)
  }, [])

  return {
    step,
    nextStep,
    previousStep,
    nextStepWithoutPayment,
    previousStepWithoutPayment,
    changeStep,
  }
}

export default useSteps
