import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import MuiButton from '@mui/material/Button'

const StyledButton = styled(MuiButton)`
  text-transform: none;
  ${props =>
    props.align === 'left' &&
    css`
      justify-content: flex-start;
    `}
  ${props =>
    props.align === 'space-between' &&
    css`
      justify-content: space-between;
    `}
  ${props =>
    props.bgcolor === 'primary' &&
    css`
      background: ${props.theme.palette.primary.main};
      color: ${props.theme.palette.background.paper};
    `}
  ${props =>
    props.display === 'standard' &&
    css`
      text-transform: none;
      font-weight: 500;
    `}
  ${props =>
    props.display === 'primary' &&
    css`
      text-transform: inherit;
      color: ${props.theme.palette.primary.main};
      font-weight: 500;
    `}
  ${props =>
    props.text === 'inherit' &&
    css`
      text-transform: inherit;
    `}
  ${props =>
    props.family === 'Poppins' &&
    css`
      font-family: Poppins;
      font-weight: 400;
    `}
  ${props =>
    props.color === 'primary' &&
    css`
      color: ${props.theme.palette.primary.main};
    `}
    ${props =>
    props.width === 'full' &&
    css`
      width: 100%;
    `}
`

const NavLink = ({ children, to, align = '', bgcolor = '', external = false, ...props }) => {
  let component

  if (external) {
    return (
      <StyledButton
        color='inherit'
        component='a'
        href={to}
        rel='noopener noreferrer'
        align={align}
        bgcolor={bgcolor}
        size='large'
        {...props}
      >
        {children}
      </StyledButton>
    )
  }

  if (to) {
    component = Link
  }

  return (
    <StyledButton
      color='inherit'
      bgcolor={bgcolor}
      size='large'
      width=''
      align={align}
      to={to}
      component={component}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

NavLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  external: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  align: PropTypes.string,
  bgcolor: PropTypes.string,
  width: PropTypes.string,
}

export default NavLink
