import algoliasearch from 'algoliasearch'
import { zipcodeToCoordinates, getStateFromZipcode } from './zipcodes'
import { getStateFromCoords } from './geo'

const VITE_ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID
const VITE_ALGOLIA_SEARCH_KEY = import.meta.env.VITE_ALGOLIA_SEARCH_KEY

const DEFAULT_PARAMS = {
  aroundLatLngViaIP: true,
  getRankingInfo: true,
  aroundRadius: 'all',
  sortBy: 'by_distance',
}

const client = algoliasearch(VITE_ALGOLIA_APP_ID, VITE_ALGOLIA_SEARCH_KEY)

const locations = client.initIndex('locations')

const getParams = async (zipcode, coordinates, options) => {
  const params = { ...DEFAULT_PARAMS, ...options }
  if (coordinates) {
    params.state = await getStateFromCoords(coordinates.latitude, coordinates.longitude)
    params.aroundLatLngViaIP = false
    params.aroundLatLng = `${coordinates.latitude},${coordinates.longitude}`
  }

  if (zipcode) {
    params.state = getStateFromZipcode(zipcode)
    const coords = await zipcodeToCoordinates(zipcode)

    if (coords) {
      params.aroundLatLngViaIP = false
      params.aroundLatLng = coords
    }
  }

  if (options.typeFilters) {
    switch (options.typeFilters[0]) {
      case 'virtual':
        params.filters = params.state
          ? `virtual:true AND statesLicensed:${params.state}`
          : `virtual:true`
        break
      case 'mobile':
        params.filters = `mobile:true`
        break
      case 'inPerson':
        params.filters = `inPerson:true`
        break
      default:
        break
    }
  }
  delete params.typeFilters
  return params
}

export const searchForLocations = async (query, zipcode, coordinates = null, options = {}) => {
  const { sortBy, ...params } = await getParams(zipcode, coordinates, options)
  delete params.state
  return locations.search(query, params)
}

export default client
