import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import TextField from '../stories/TextField'
import Button from '../stories/Button'
import Icon from '../stories/Icons'

const SignInForm = ({ onSubmit, errors = {} }) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  return (
    <form title='sign-in-form' onSubmit={onSubmit}>
      <TextField
        required
        name='email'
        type='email'
        label='Email address'
        placeholder='Email address'
        display='standard'
        inputProps={{
          title: 'email',
        }}
        error={!!errors.email}
        helperText={errors.email}
      />
      <Box position='relative'>
        <TextField
          required
          name='password'
          type={passwordVisible ? 'text' : 'password'}
          label='Password'
          placeholder='Password'
          display='standard'
          inputProps={{
            title: 'password',
          }}
          error={!!errors.password}
          helperText={errors.password}
        />
        <Box position='absolute' right={0} top={0} bottom={0} mt={2} mb={2}>
          <Button variant='icon' onClick={() => setPasswordVisible(!passwordVisible)}>
            <Icon color='primary' name={passwordVisible ? 'visibility' : 'visibilityOff'} />
          </Button>
        </Box>
      </Box>
      <Box mt={2}>
        <Button type='submit' id='signInButton' fullWidth>
          Login
        </Button>
      </Box>
    </form>
  )
}

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
}

export default SignInForm
