import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Tooltip from '../stories/Tooltip'
import Icon from '../stories/Icons'

const VoucherList = ({ headers, children }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          {headers.map(each => (
            <TableCell key={each.label} align={each.align}>
              {each.type === 'text' && each.label}
              {each.type === 'icon' && each.user === 'consumer' && (
                <Tooltip title='scheduling instructions' placement='top'>
                  <span>
                    <Icon name={each.label} fontSize='small' />
                  </span>
                </Tooltip>
              )}
              {each.type === 'icon' && each.user === 'provider' && (
                <Tooltip title='more info' placement='top'>
                  <span>
                    <Icon name={each.label} fontSize='small' />
                  </span>
                </Tooltip>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  </TableContainer>
)

VoucherList.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      align: PropTypes.string,
    }),
  ).isRequired,
  children: PropTypes.node.isRequired,
}

export default VoucherList
