import { FC, PropsWithChildren } from 'react'
import { StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { ThemeProvider as StyledProvider } from 'styled-components'
import CssBaseline from '@mui/material/CssBaseline'
import theme from '@app/themes/MisheTheme'

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <StyledProvider theme={theme}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledProvider>
  </StyledEngineProvider>
)
