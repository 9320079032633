import { useRef, useState, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import {
  ResponsiveChartContainer,
  BarPlot,
  ChartsXAxis,
  ChartsYAxis,
  useDrawingArea,
  useYScale,
  ChartsTooltip,
} from '@mui/x-charts'

const StyledPath = styled('path')(({ theme }) => ({
  fill: 'none',
  stroke: theme.palette.text.primary,
  shapeRendering: 'crispEdges',
  strokeWidth: 1,
  pointerEvents: 'none',
  strokeDasharray: '5 2',
}))

const StyledText = styled('text')(({ theme }) => ({
  stroke: 'none',
  fill: theme.palette.text.primary,
  shapeRendering: 'crispEdges',
}))

function ValueHighlight(props) {
  const { svgRef } = props

  // Get the drawing area bounding box
  const { left, top, width, height } = useDrawingArea()

  // Get the two scale
  const leftAxisScale = useYScale('left_axis_id')
  const rightAxisScale = useYScale('right_axis_id')

  const [mouseY, setMouseY] = useState(null)

  useEffect(() => {
    const element = svgRef.current
    if (element === null) {
      return () => {}
    }

    const handleMouseOut = () => {
      setMouseY(null)
    }

    const handleMouseMove = event => {
      const x = event.offsetX
      const y = event.offsetY
      if (x < left || x > left + width) {
        setMouseY(null)
        return
      }
      if (y < top - 10 || y > top + height + 10) {
        // Allows some margin if slightly on top/bottom of the drawing area
        setMouseY(null)
        return
      }
      setMouseY(Math.max(Math.min(top + height, y), top)) // clamp to the drawing area
    }

    element.addEventListener('mouseout', handleMouseOut)
    element.addEventListener('mousemove', handleMouseMove)
    return () => {
      element.removeEventListener('mouseout', handleMouseOut)
      element.removeEventListener('mousemove', handleMouseMove)
    }
  }, [height, left, top, width, svgRef])

  if (mouseY === null) {
    return null
  }
  return (
    <Fragment>
      <StyledPath d={`M ${left} ${mouseY} l ${width} 0`} />
      <StyledText x={left + 5} y={mouseY} textAnchor='start' dominantBaseline='text-after-edge'>
        {leftAxisScale.invert(mouseY).toFixed(0)}
      </StyledText>

      <StyledText
        x={left + width - 5}
        y={mouseY}
        textAnchor='end'
        dominantBaseline='text-after-edge'
      >
        {rightAxisScale.invert(mouseY).toFixed(0)}
      </StyledText>
    </Fragment>
  )
}

const ResponsiveBarChart = ({ dataset, xAxis, yAxis, seriesData, chartSettings }) => {
  const svgRef = useRef(null)
  return (
    <ResponsiveChartContainer
      ref={svgRef}
      // Actual Data
      dataset={dataset}
      // Declare which data from the set to use
      series={seriesData}
      // Lables for the x-axis
      xAxis={xAxis}
      // Labels Scales and IDs for the y-axis
      yAxis={yAxis}
      {...chartSettings}
      margin={{ top: 20, right: 100, bottom: 50, left: 30 }}
    >
      <BarPlot />
      <ChartsXAxis />
      <ChartsYAxis position='left' axisId='left_axis_id' />
      <ChartsYAxis position='right' axisId='right_axis_id' />
      <ChartsTooltip />
      <ValueHighlight svgRef={svgRef} />
    </ResponsiveChartContainer>
  )
}

export default ResponsiveBarChart
