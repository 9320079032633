import { useEffect } from 'react'
import Container from '../../stories/Container'
import Section from '../../stories/Section'
import PrivacyPolicyContent from './PrivacyPolicyContent'

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Privacy Policy Lander',
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Container>
        <Section>
          <PrivacyPolicyContent />
        </Section>
      </Container>
    </>
  )
}

export default PrivacyPolicyPage
