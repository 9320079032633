import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Grid from '@mui/material/Grid'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import { distanceToMiles } from '../libs/geo'
import Icon from '../stories/Icons'
import SimpleMap from './GoogleMap'
import MapEffectsProvider from '../context/MapEffects'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

// Depricated
// const formatForMap = locations => {
//   return locations.map(location => {
//     return {
//       organizationId: location.organizationId,
//       organization: {
//         primaryLocationCoords: {
//           lat: location?._geoloc.lat, // eslint-disable-line
//           lng: location?._geoloc.lng // eslint-disable-line
//         }
//       },
//       text: location?.name || ''
//     }
//   })
// }

const headCells = [
  { id: 'name', numeric: false, label: 'Location' },
  { id: 'city', numeric: false, label: 'City' },
  { id: 'distance', numeric: false, label: 'Distance' },
  { id: 'phone', numeric: false, label: 'Phone' },
  { id: 'hours', numeric: false, label: 'Hours' },
]

const EnhancedTableHead = props => {
  // eslint-disable-next-line react/prop-types
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const OrganizationLocations = ({ locations = [] }) => {
  // const matches = useMediaQuery('(min-width:960px)')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [goodLocations, setGoodLocations] = useState()
  const [badLocations, setBadLocations] = useState()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const filterOutNullLocations = (locs, reversed) => {
    return locs.filter(location => {
      // eslint-disable-next-line no-underscore-dangle
      if (!location.geopoint && !location._geoloc) {
        return !!reversed
      }
      // check if lat or long are 0 or null
      if (
        location.geopoint &&
        // eslint-disable-next-line no-underscore-dangle
        (location.geopoint._lat === 0 || location.geopoint._long === 0)
      ) {
        return !!reversed
      }

      if (
        // eslint-disable-next-line no-underscore-dangle
        location._geoloc &&
        // eslint-disable-next-line no-underscore-dangle
        (location._geoloc.lat === 0 || location._geoloc.lng === 0)
      ) {
        return !!reversed
      }
      // eslint-disable-next-line no-underscore-dangle
      return !reversed
    })
  }

  useEffect(() => {
    if (!locations) return undefined
    const good = filterOutNullLocations(locations)
    const bad = filterOutNullLocations(locations, true)
    setGoodLocations(good)
    setBadLocations(bad)
  }, [locations])

  return (
    <MapEffectsProvider>
      <Section id='locations'>
        <Container>
          <Typography variant='h4' gutterBottom>
            Our Locations
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <TableContainer>
                <Table>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {goodLocations &&
                      stableSort(goodLocations, getComparator(order, orderBy)).map((row, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={row.name}>
                            <TableCell component='th' scope='row'>
                              <Box display='flex' alignItems='center'>
                                <Box mr={2}>
                                  <Icon name='location' color='primary' />
                                  <Typography variant='body2' align='center'>
                                    {index + 1}
                                  </Typography>
                                </Box>
                                <Typography variant='body2' component='span'>
                                  {row.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {row.city}, {row.state}, {row.zipcode}
                            </TableCell>
                            <TableCell>{distanceToMiles(row)} miles</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            <TableCell>
                              {row.description} {row.operatingHours}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    {badLocations && badLocations.length > 0 && (
                      <>
                        <TableRow hover tabIndex={-1}>
                          <TableCell component='th' scope='row' colSpan={5}>
                            <Box display='flex' justifyContent='center'>
                              <Typography variant='body2' align='center'>
                                The following locations could not be displayed.
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                        {stableSort(badLocations, getComparator(order, orderBy)).map(row => {
                          return (
                            <TableRow hover tabIndex={-1} key={row.name}>
                              <TableCell component='th' scope='row'>
                                <Box display='flex' alignItems='center'>
                                  <Box mr={2}>
                                    <Icon name='location' color='primary' />
                                  </Box>
                                  <Typography variant='body2' component='span'>
                                    {row.name}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                {row.city}, {row.state}, {row.zipcode}
                              </TableCell>
                              <TableCell>{distanceToMiles(row)} miles</TableCell>
                              <TableCell>{row.phone}</TableCell>
                              <TableCell>
                                {row.description} {row.operatingHours}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box
                mt={2}
                width='100%'
                style={{
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                  borderRadius: '5px',
                  padding: '15px',
                }}
              >
                {goodLocations && <SimpleMap clinics={[{ locations: goodLocations }]} />}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </MapEffectsProvider>
  )
}

OrganizationLocations.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      objectID: PropTypes.string.isRequired,
    }),
  ),
}

export default OrganizationLocations
