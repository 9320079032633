import { useCallback, useState } from 'react'

function useCheck(defaultValue = false) {
  const [checked, setChecked] = useState(defaultValue)

  const onChange = useCallback(event => {
    setChecked(event.target.checked)
  }, [])

  return [checked, onChange]
}

export default useCheck
