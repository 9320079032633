import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '../Button'
import FormattedText from '../FormatFromDB'
import Typography from '../Typography'
import Icon from '../Icons'

// collapse by word count instead of character count
const CollapsableFormattedText = ({ text = '' }) => {
  const [showMore, setShowMore] = useState(false)

  if (typeof text !== 'string') {
    return <></>
  }

  const firstPart = text?.split('|', 1)[0]
  const firstPartElipse = `${firstPart}..`
  const secondPart = text?.split('|')?.slice(1)?.join('|')

  return (
    <Box width='100%'>
      <Box
        width='100%'
        display='flex'
        justifyContent='space-between'
        sx={{
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
        }}
      >
        <Box>
          <Box
            width='100%'
            sx={{
              paddingRight: {
                xs: '0px',
                sm: '16px',
              },
            }}
          >
            <FormattedText text={showMore ? firstPart : firstPartElipse} mt={0.5} />
          </Box>
          <Box
            width='100%'
            display='grid'
            style={{
              gridTemplateRows: showMore ? '1fr' : '0fr',
              transition: 'all .1s',
            }}
            sx={{
              paddingRight: {
                xs: '0px',
                sm: '16px',
              },
            }}
          >
            <Box overflow='hidden'>
              <FormattedText
                text={secondPart}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          color='#7004c6'
          sx={{
            marginTop: {
              xs: '8px',
              sm: 0,
            },
            alignSelf: {
              xs: 'center',
              sm: showMore ? 'center' : 'flex-end',
            },
          }}
        >
          <Button
            size='small'
            color='inherit'
            variant='text'
            onClick={() => setShowMore(!showMore)}
          >
            <Icon name={showMore ? 'closeArrow' : 'openArrow'} />
            <Typography
              variant='body2'
              weight='medium'
              textalignment='justified'
              style={{ whiteSpace: 'nowrap' }}
            >
              {showMore ? 'Show Less' : 'Show More'}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

CollapsableFormattedText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default CollapsableFormattedText
