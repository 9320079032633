import PropTypes from 'prop-types'
import styled from 'styled-components'
import MuiTypography from '@mui/material/Typography'

const StyledTypography = styled(MuiTypography)`
  font-weight: 600;
`

const Bold = ({ children, ...props }) => (
  <StyledTypography component='span' weight='' {...props}>
    {children}
  </StyledTypography>
)

Bold.propTypes = {
  children: PropTypes.node.isRequired,
  weight: PropTypes.string,
}

export default Bold
