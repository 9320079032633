import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Hidden } from '@mui/material'
import Container from '../Container'
import Section from '../Section'
import Button from '../Button'
import Typography from '../Typography'
import LiveChatImage from '../../assets/images/live-chat.png'

export const LiveChatSection = styled(Section)`
  background: #ffffff;
  padding: 100px 0 60px 0;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`
export const LiveChatContainer = styled.div`
  background: #ffffff;
  border: 2px solid #662d91;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 60px 118px 55px 118px;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`

const LiveChat = ({ sectionId = 'live-chat', ...props }) => {
  return (
    <LiveChatSection id={sectionId} {...props}>
      <Container>
        <LiveChatContainer>
          <Container>
            <Grid container spacing={3} display='flex' alignItems='center'>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Hidden mdDown>
                  <Typography variant='caption' weight='semibold'>
                    <Box component='span' color='primary.main'>
                      DO YOU HAVE QUESTIONS?
                    </Box>
                  </Typography>
                  <Typography variant='h3' gutterBottom weight='medium'>
                    mishe’s{' '}
                    <Box component='span' color='primary.main'>
                      Concierge Team{' '}
                    </Box>
                    is ready with answers.
                  </Typography>
                  <Typography variant='body1' weight='light'>
                    We want to simplify healthcare and are here to serve you. Our team will help you
                    answer questions, navigate available options, and schedule appointments. Think
                    of us as your live virtual assistant – a bridge between you and your care. Reach
                    us by phone, text, email or chat!
                  </Typography>
                  <Box mt={4} mb={1}>
                    <span className='open-live-chat'>
                      <Button size='medium'>Live chat with concierge</Button>
                    </span>
                  </Box>
                  <Typography variant='body1' weight='light'>
                    or call/text{' '}
                    <Box component='span' style={{ fontWeight: '500' }}>
                      <a
                        style={{
                          textDecoration: 'none',
                          color: 'gray',
                        }}
                        href='tel:+1-855-647-4326'
                      >
                        855-647-4326 (855-MISHECO)
                      </a>
                    </Box>
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <Typography variant='body1' style={{ fontWeight: '600' }} align='center'>
                    <Box component='span' color='primary.main'>
                      DO YOU HAVE QUESTIONS?
                    </Box>
                  </Typography>
                  <Typography variant='h3' gutterBottom align='center'>
                    mishe’s{' '}
                    <Box component='span' color='primary.main'>
                      Concierge Team{' '}
                    </Box>
                    is ready with answers.
                  </Typography>
                  <Typography variant='body1' align='center'>
                    We want to simplify healthcare and are here to serve you. Our team will help you
                    answer questions, navigate available options, and schedule appointments. Think
                    of us as your live virtual assistant – a bridge between you and your care. Reach
                    us by phone, text, email or chat!
                  </Typography>
                  <Box mt={4} mb={1} textAlign='center'>
                    <span className='open-live-chat'>
                      <Button size='medium'>Live chat with concierge</Button>
                    </span>
                  </Box>
                  <Typography variant='body1' align='center'>
                    or call{' '}
                    <Box component='span' style={{ fontWeight: '500' }}>
                      <a
                        style={{
                          textDecoration: 'none',
                          color: 'gray',
                        }}
                        href='tel:+1-855-647-4326'
                      >
                        855-647-4326 (855-MISHECO)
                      </a>
                    </Box>
                  </Typography>
                </Hidden>
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} />
              <Grid item xs={12} sm={12} md={5} lg={5} display='flex'>
                <Box display='flex' justifyContent='flex-end'>
                  <img src={LiveChatImage} alt='LiveChatImage' width='100%' />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </LiveChatContainer>
      </Container>
    </LiveChatSection>
  )
}

LiveChat.propTypes = {
  sectionId: PropTypes.string,
}

export default LiveChat
