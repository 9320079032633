import firebase from './app'
import 'firebase/compat/functions'

export const call = (name, options = {}) => firebase.functions().httpsCallable(name)(options)

export const sendVerificationEmail = async info => {
  const response = await call('auth-resendVerificationEmail', info)
  return response.data
}

export const createOrderNetwork = async info => {
  const response = await call('networkPayments-createOrder', info)
  return response.data
}

export const createPaymentIntentNetwork = async info => {
  const response = await call('networkPayments-createPaymentIntent', info)
  return response.data
}

export const attachCustomer = async info => {
  const response = await call('networkPayments-attachCustomer', info)
  return response.data
}

export const createPaymentIntentMedication = async info => {
  const response = await call('networkPayments-createPaymentIntentMedication', info)
  return response.data
}

export const createMakoCard = async info => {
  const response = await call('makoRx-createCard', info)
  return response.data
}

export const createVideoChatRoom = async info => {
  const response = await call('videoChat-createVideoChatRoom', info)
  return response.data
}

export const createAccount = async info => {
  const response = await call('auth-createAccount', info)
  return response.data
}

export const createMedOrder = async info => {
  const response = await call('emrOrders-createMedicationOrder', info)
  return response.data
}

export const sendProviderEnrollmentNotification = async info => {
  const response = await call('providers-enrollmentSubmission', info)
  return response.data
}

export const getDiRxOrderStatus = async info => {
  const response = await call('emrOrders-getTrackingDetails', info)
  return response.data
}

export const createChatVoucher = async info => {
  const response = await call('chats-createChatVoucher', info)
  return response.data
}
