import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

const YoutubeEmbed = ({ embedID, ...props }) => {
  if (!embedID) {
    console.warn('  ** No embedId provided')
    return <></>
  }

  return (
    <Box
      sx={{
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0,
        overflow: 'hidden',
        maxWidth: '100%',
        '& iframe': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        },
      }}
      {...props}
    >
      <style>
        {`
          #ytPlayer {
            border: 0;
          }
        `}
      </style>
      <iframe
        id='ytPlayer'
        src={`https://www.youtube.com/embed/${embedID}`}
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerPolicy='strict-origin-when-cross-origin'
        allowFullScreen
      />
    </Box>
  )
}

YoutubeEmbed.propTypes = {
  embedID: PropTypes.string.isRequired,
}

export default YoutubeEmbed
