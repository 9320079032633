import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Highlight } from 'react-instantsearch'
import Typography from '../stories/Typography'
import Button from '../stories/Button'

const ClinicsMapSearchCard = ({ data, onClick }) => {
  return (
    <Button
      variant='blank'
      fullWidth
      onClick={() => {
        onClick(data)
      }}
    >
      <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
        <Box>
          <Typography variant='body1' weight='bold'>
            <Highlight attribute='name' hit={data} />
          </Typography>
        </Box>
      </Box>
    </Button>
  )
}

ClinicsMapSearchCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    relevantSpecialties: PropTypes.arrayOf(PropTypes.string),
    slug: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ClinicsMapSearchCard
