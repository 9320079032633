import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import Button from '../stories/Button'
import SuperBillTemplate from './SuperBillPDF'
import { collection } from '../firebase/firestore'

const GenerateSuperBillButton = ({ voucher, disabled = true }) => {
  const [loading, setLoading] = useState(false)
  const fetchOrgDocument = useCallback(async uid => {
    return collection('organizations').doc(uid).get()
  }, [])

  // TODO: Switch where query to service ID REQUIRES: service ID on voucher creation
  const fetchServiceDocumentByName = useCallback(async ({ organizationID, serviceName }) => {
    return collection('organizations')
      .doc(organizationID)
      .collection('services')
      .where('name', '==', serviceName)
      .limit(1)
      .get()
  }, [])

  const fetchServiceDocumentByID = useCallback(async ({ planID, serviceID }) => {
    return collection('networks').doc(planID).collection('services').doc(serviceID).get()
  }, [])

  const fetchCPTDocuments = useCallback(async ({ code }) => {
    return collection('procedureCodes').where('hcps', '==', code).get()
  }, [])

  const fetchUCRDocument = useCallback(async ({ uid, cpt }) => {
    return collection('organizations').doc(uid).collection('ucr').where('cpt', '==', cpt).get()
  }, [])

  const generatePdfDocument = useCallback(
    async voucherObj => {
      //
      // Checks
      //

      if (!voucherObj?.service?.objectID && !voucherObj?.service?.id) {
        console.warn('Service ID not found') // eslint-disable-line
        return false
      }

      if (!voucherObj?.plan?.id && !voucherObj?.network?.id) {
        console.warn('Plan ID not found') // eslint-disable-line
        return false
      }

      //
      // Fetch data
      //

      const organization = await fetchOrgDocument(voucherObj.organization.id)
        .then(response => {
          const orgResponse = response.data()
          if (orgResponse && orgResponse !== undefined) return orgResponse
          return false
        })
        .catch(error => {
          console.log(error) // eslint-disable-line
          console.debug('Error fetching organization document') // eslint-disable-line
          return false
        })

      const service = await fetchServiceDocumentByID({
        planID: voucherObj.plan.id || voucherObj.network.id,
        serviceID: voucherObj.service.objectID || voucherObj.service.id,
      })
        .then(response => {
          if (!response.exists) {
            console.error('Service document does not exist') // eslint-disable-line
            return false
          }

          return response.data()
        })
        .catch(error => {
          console.log(error) // eslint-disable-line
          console.log('Error fetching service document by ID') // eslint-disable-line
          return false
        })

      console.log('Service:', service) // eslint-disable-line

      if (!service || !organization) {
        console.warn('Service or organization not found')
        console.log('Service: ', service) // eslint-disable-line
        console.log('Organization: ', organization) // eslint-disable-line
        return false
      }

      const cpts = await fetchCPTDocuments({ code: service.cptCode })
        .then(response => {
          if (response.empty) {
            console.error('CPT documents not found') // eslint-disable-line
            return false
          }

          return response.map(doc => doc.data())
        })
        .catch(error => {
          console.error('  ** Error fetching CPT documents') // eslint-disable-line
          console.error(error) // eslint-disable-line
          return false
        })

      if (!cpts || cpts.length === 0) {
        console.warn('  ** CPT documents not found')
        return false
      }

      console.log('CPTs:', cpts) // eslint-disable-line

      const ucrs = []

      await Promise.all(
        cpts.map(async cptObj => {
          const ucrData = await fetchUCRDocument({
            uid: voucherObj.organization.id,
            cpt: cptObj.cpt,
          })
            .then(response => {
              if (response.docs.length === 0) return false
              const ucrResponse = response.docs[0].data()
              if (ucrResponse && ucrResponse !== undefined) return ucrResponse
              return false
            })
            .catch(error => {
              console.log(error) // eslint-disable-line
              console.log('Error fetching UCR document') // eslint-disable-line
              return false
            })
          // Only pushes data if it exists
          if (ucrData) ucrs.push(ucrData)
        }),
      )

      let totalUcrFee = 0
      ucrs.forEach(ucr => {
        totalUcrFee += ucr.fee
      })

      if (!organization || !service || ucrs.length === 0) return false

      const data = {
        voucher: voucherObj,
        organization,
        service,
        ucrs,
        totalUcrFee,
      }

      const blob = await pdf(<SuperBillTemplate data={data} />).toBlob()
      saveAs(blob, `superbill-${voucherObj.code}.pdf`)
      return true
    },
    [
      fetchServiceDocumentByID,
      fetchServiceDocumentByName,
      fetchOrgDocument,
      fetchCPTDocuments,
      fetchUCRDocument,
    ],
  )

  const onClick = useCallback(
    async event => {
      // eslint-disable-line
      if (!loading) {
        setLoading(true)
        try {
          await generatePdfDocument(voucher)
        } catch (error) {
          console.log(error) // eslint-disable-line
          setLoading(false)
        }
        setLoading(false)
      }
    },
    [generatePdfDocument, loading, voucher],
  )

  return (
    <Button
      size='small'
      variant='contained'
      color='primary'
      onClick={onClick}
      disabled={loading && !disabled}
    >
      Download PDF
    </Button>
  )
}

GenerateSuperBillButton.propTypes = {
  disabled: PropTypes.bool,
  voucher: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    service: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    customer: PropTypes.shape({
      lastname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default GenerateSuperBillButton
