import styled, { css } from 'styled-components'
import MuiIconButton from '@mui/material/IconButton'

const IconButton = styled(MuiIconButton)`
  ${props =>
    props.severity === 'destructive' &&
    css`
      cursor: pointer;
      transition: all 0.2s ${props.theme.transitions.easing.easeInOut};
      color: ${props.theme.palette.text.secondary};
      &:hover {
        color: ${props.theme.palette.error.main};
      }
    `}
`

export default IconButton
