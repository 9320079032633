import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import badge from '../../assets/images/cert_partner.png'

const StyledLogo = styled.img`
  height: ${props => props.theme.spacing(4)};
  ${props =>
    props.size === 'small' &&
    css`
      height: ${props.theme.spacing(2)};
      ${props.theme.breakpoints.up('lg')} {
        height: ${props.theme.spacing(2.5)};
      }
    `}
  ${props =>
    props.size === 'medium' &&
    css`
      height: ${props.theme.spacing(3)};
      ${props.theme.breakpoints.up('lg')} {
        height: ${props.theme.spacing(3.5)};
      }
    `}
  ${props =>
    props.size === 'mediumLarge' &&
    css`
      height: ${props.theme.spacing(6)};
      ${props.theme.breakpoints.up('lg')} {
        height: ${props.theme.spacing(4)};
      }
    `}
  ${props =>
    props.size === 'large' &&
    css`
      height: ${props.theme.spacing(4)};
      ${props.theme.breakpoints.up('lg')} {
        height: ${props.theme.spacing(6)};
      }
    `}
  ${props =>
    props.size === 'huge' &&
    css`
      height: ${props.theme.spacing(7)};
    `}
`

const Logo = ({ size = 'medium' }) => <StyledLogo src={badge} alt='mishe' size={size} />

Logo.propTypes = {
  size: PropTypes.string,
}

export default Logo
