import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import InstantSearchContainer from '../../containers/InstantSearchContainer'
import DiagnosisCard from '../DiagnosisCard'

const DiagnosisCodeSearch = ({ values, setters }) => {
  const { diagnosisCodeData } = values

  const { setDiagnosisCodeData } = setters

  return (
    <Box>
      {!diagnosisCodeData && (
        <InstantSearchContainer
          HitComponent={DiagnosisCard}
          hitClick={data => {
            if (window.Intercom) {
              window.Intercom('trackEvent', 'Diagnosis Selected', {
                diagnosisCode: data.code,
                diagnosisDescription: data.description,
              })
            }
            setDiagnosisCodeData(data)
          }}
          indexName='diagnosis_codes'
          configuration={{
            hitsPerPage: 5,
          }}
          searchBoxText='Search for a diagnosis code'
          noResultsMessage='No results for'
        />
      )}
      {diagnosisCodeData && (
        <DiagnosisCard
          data={diagnosisCodeData}
          buttonText='Clear Selection'
          onClick={() => {
            if (window.Intercom) {
              window.Intercom('trackEvent', 'Diagnosis Cleared', {})
            }
            setDiagnosisCodeData(null)
          }}
        />
      )}
    </Box>
  )
}

DiagnosisCodeSearch.propTypes = {
  values: PropTypes.shape({
    diagnosisCodeData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }).isRequired,
  setters: PropTypes.shape({
    setDiagnosisCodeData: PropTypes.func,
  }).isRequired,
}

export default DiagnosisCodeSearch
