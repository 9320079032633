import { useState, useCallback, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { createMedOrder } from '../firebase/functions'
import { formatPhoneNumberForProfile } from '../libs/formatters'
import { medOrderValidator } from '../libs/validators'

import useNotes from './useNotes'
import { uploadFile } from '../firebase/storage'

import { collection, collectionModular, subCollectionModular } from '../firebase/firestore'
import { query, where, getDocs, and, or, orderBy, limit } from 'firebase/firestore'

import DOMPurify from 'dompurify'
import { useCurrentProfile } from '@app/context/CurrentUser'
import { ADD_NOTE } from '@app/libs/constant'

const useEmrOrders = ({ voucher, patient, provider, service, medication }) => {
  const { addTextNote, addFileNote } = useNotes()

  // For UI
  const [orders, setOrders] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [error, setError] = useState('')

  const { organizationId } = useCurrentProfile()

  const [emrOrders, setEmrOrders] = useState([])
  const [displayEmrOrders, setDisplayEmrOrders] = useState([])
  const [emrOrdersLoading, setEmrOrdersLoading] = useState()
  const [ordersLimit, setOrdersLimit] = useState(30)
  const [ordersFilter, setOrdersFilter] = useState({
    startDate: '',
    endDate: '',
    lastname: '',
    medication: true,
    lab: true,
    referral: true,
    other: true,
    paid: true,
    unpaid: true,
    failed: true,
  })

  // Reference Voucher
  const [referenceVoucher, setReferenceVoucher] = useState(null)

  // Patient INFO
  const [patientUserID, setPatientUserID] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [dob, setDob] = useState('')
  const [gender, setGender] = useState('')
  const [street, setStreet] = useState('')
  const [street2, setStreet2] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [zip, setZip] = useState('')

  // Provider INFO
  const [providerID, setProviderID] = useState('')
  const [providerFirstname, setProviderFirstname] = useState('')
  const [providerLastname, setProviderLastname] = useState('')
  const [providerEmail, setProviderEmail] = useState('')
  const [providerPhone, setProviderPhone] = useState('')
  const [providerNPI, setProviderNPI] = useState('')
  const [clinicID, setClinicID] = useState('')
  const [clinicName, setClinicName] = useState('')

  // Services
  const [serviceSelected, setServiceSelected] = useState('')
  const [diagnosisCodeData, setDiagnosisCodeData] = useState('')

  // Medications
  const [medicationSelected, setMedicationSelected] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [SIG, setSIG] = useState('')
  const [drugAllergies, setDrugAllergies] = useState('')
  const [otherMedications, setOtherMedications] = useState('')
  const [medicalConditions, setMedicalConditions] = useState('')
  const [pregnant, setPregnant] = useState(false)
  const [nursing, setNursing] = useState(false)
  const [safetyCap, setSafetyCap] = useState(false)

  // Notes
  const [currentFile, setCurrentFile] = useState(null)
  const [noteContent, setNoteContent] = useState('')

  // Clear Order Info
  const clearAllOrderInfo = () => {
    // Patient Info
    setPatientUserID('')
    setFirstname('')
    setLastname('')
    setEmail('')
    setPhone('')
    setDob('')
    setGender('')
    setStreet('')
    setStreet2('')
    setState('')
    setCity('')
    setZip('')

    // Clinic Info
    setProviderID('')
    setProviderFirstname('')
    setProviderLastname('')
    setProviderEmail('')
    setProviderPhone('')
    setProviderNPI('')
    setClinicID('')
    setClinicName('')

    // General Order
    setReferenceVoucher(null)
    setDiagnosisCodeData(null)

    // Referrals
    setServiceSelected('')

    // Medications
    setMedicationSelected('')
    setQuantity(0)
    setSIG('')
    setDrugAllergies('')
    setOtherMedications('')
    setMedicalConditions('')
    setPregnant(false)
    setNursing(false)
    setSafetyCap(false)

    // Notes
    setCurrentFile(null)
    setNoteContent(null)

    // Functional
    setDisabled(false)
    setSubmitDisabled(true)
    setError('')

    return true
  }

  // clear just patient info
  const clearPatientInfo = () => {
    setPatientUserID('')
    setFirstname('')
    setLastname('')
    setEmail('')
    setPhone('')
    setDob('')
    setGender('')
    setStreet('')
    setStreet2('')
    setState('')
    setCity('')
    setZip('')

    return true
  }

  // Clear order type Info
  const clearGeneralOrderInfo = () => {
    setServiceSelected('')
    setDiagnosisCodeData('')

    setMedicationSelected('')
    setQuantity(0)
    setSIG('')
    setDrugAllergies('')
    setOtherMedications('')
    setMedicalConditions('')
    setPregnant(false)
    setNursing(false)
    setSafetyCap(false)

    setCurrentFile(null)
    setNoteContent(null)

    setDisabled(false)
    setSubmitDisabled(true)
    setError('')

    return true
  }

  useEffect(() => {
    if (patient) {
      console.log(' -- Setting patient info -- ')
      setPatientUserID(patient?.id || patient?.uid || '')
      setFirstname(patient?.firstname || '')
      setLastname(patient?.lastname || '')
      setEmail(patient?.email || '')
      const formattedPhone = formatPhoneNumberForProfile(patient.phone || '')
      setPhone(formattedPhone)
      setDob(patient?.dob || '')
      setGender(patient?.gender || '')
      setStreet(patient?.address?.street || '')
      setStreet2(patient?.address?.street2 || '')
      setState(patient?.address?.state || '')
      setCity(patient?.address?.city || '')
      setZip(patient?.address?.zip || '')
    }

    if (provider) {
      console.log(' -- Setting provider info -- ')
      setProviderID(provider?.id || '')
      setProviderFirstname(provider?.firstname || '')
      setProviderLastname(provider?.lastname || '')
      setProviderEmail(provider?.email || '')
      setProviderPhone(provider?.phone)
      setProviderNPI(provider?.npi || '')
      setClinicID(provider?.clinicID || '')
      setClinicName(provider?.clinicName || '')
    }

    if (service) {
      console.log(' -- Setting service info -- ')
      setServiceSelected(service || null)
    }

    if (medication) {
      console.log(' -- Setting medication info -- ')
      setMedicationSelected(medication || null)
    }

    if (voucher) {
      console.log(' -- Setting voucher info -- ')
      setReferenceVoucher(voucher || null)
    }
  }, [])

  useEffect(() => {
    console.log(' -- Checking for submit -- ')
    if (!medicationSelected && !serviceSelected) {
      setSubmitDisabled(true)
    }

    if (medicationSelected) {
      if (medicationSelected?.requiredQuantity && !quantity) {
        setQuantity(medicationSelected.requiredQuantity)
      }
      setSubmitDisabled(
        !(
          medicationSelected &&
          quantity > 0 &&
          diagnosisCodeData &&
          providerNPI &&
          providerFirstname
        ),
      )
    }

    if (serviceSelected) {
      console.log(' -- Checking for service submit -- ')
      console.log(!(diagnosisCodeData && providerNPI && providerFirstname))
      console.log(diagnosisCodeData)
      console.log(providerNPI)
      console.log(providerFirstname)
      setSubmitDisabled(!(diagnosisCodeData && providerNPI && providerFirstname))
    }
  }, [
    medicationSelected,
    quantity,
    diagnosisCodeData,
    providerNPI,
    providerFirstname,
    serviceSelected,
  ])

  const setFormattedPhone = phoneToFormat => {
    setPhone(formatPhoneNumberForProfile(phoneToFormat))
  }

  const updateOrders = useCallback(
    orderData => {
      setOrders([orderData, ...orders])
    },
    [orders],
  )

  // note
  const uploadNote = useCallback(
    async orderID => {
      const providerName = `${providerFirstname || ''} ${providerLastname || ''}`

      const { code: voucherCode, uid: voucherUID, id: voucherID, preReg } = referenceVoucher

      const note = {
        content: null,
        patient: {
          firstName: firstname,
          lastName: lastname,
          id: patientUserID,
        },
        voucher: preReg
          ? null
          : {
              code: voucherCode,
              id: voucherID || voucherUID || null,
            },
        organization: {
          id: clinicID || null,
          name: clinicName || null,
          provider: providerName,
        },
        orderID: orderID || null,
        noteStatus: ADD_NOTE.FINALIZE,
      }

      if (noteContent !== '') {
        // TODO: add validation but also add fallback for doctors who dont have a display name
        note.content = DOMPurify.sanitize(noteContent)
        await addTextNote(note)
      }

      if (currentFile !== null) {
        const url = await uploadFile(`/notes/${voucherCode}/${uuidv4()}`, currentFile, {
          ownerID: providerID,
        })
        if (url !== null) {
          note.content = url
          note.fileName = currentFile.name
          await addFileNote(note)
        }
        if (url.error) {
          if (url.error.code === 403) alert('File with that name already exists!') // eslint-disable-line no-alert
        }
      }

      return true
    },
    [
      providerFirstname,
      providerLastname,
      firstname,
      lastname,
      patientUserID,
      referenceVoucher,
      clinicID,
      clinicName,
      noteContent,
      currentFile,
      addTextNote,
      providerID,
      addFileNote,
    ],
  )

  const handleMedOrder = useCallback(async () => {
    const { uid: voucherUID, id: voucherID, preReg } = referenceVoucher

    const newOrder = {
      sourceVoucherId: preReg ? 'pre-reg' : voucherID || voucherUID,
      orderType: 'medication',
      status: 'unpaid',
      patient: {
        id: patientUserID || null,
        name: `${firstname || ''} ${lastname || ''}`.trim() || null,
        firstname: firstname?.toLowerCase()?.trim() || null,
        lastname: lastname?.toLowerCase()?.trim() || null,
        email: email || null,
        phone: phone || null,
        dob: dob || null,
        gender: gender || null,
        pregnant: pregnant || false,
        nursing: nursing || false,
        drugAllergies: drugAllergies || null,
        otherMedications: otherMedications || null,
        medicalConditions: medicalConditions || null,
        safetyCap: safetyCap || false,
      },
      referring: {
        provider: {
          name: `${providerFirstname || ''} ${providerLastname || ''}`.trim() || null,
          npi: providerNPI || null,
          id: providerID || null,
          email: providerEmail || false,
          phone: providerPhone || false,
        },
        clinic: {
          id: clinicID || null,
          name: clinicName || null,
        },
      },
      referringProvider: {
        id: providerID,
        name: `${providerFirstname} ${providerLastname}` || null,
        email: providerEmail || false,
        phone: providerPhone || false,
      },
      referringClinic: {
        id: clinicID,
        name: clinicName || null,
        provider: `${providerFirstname} ${providerLastname}` || null,
      },
      details: {
        misheCode: medicationSelected?.misheCode || null,
        medId: medicationSelected?.objectID,
        medicationName: medicationSelected?.medName || null,
        controlledSubstance: medicationSelected?.controlledSubstance || false,
        pharmaType: 'mail',
        pharmacy: {
          name: 'dirx',
          pharmacyType: 'mail',
        },
        deliveryMethod: 'mail',
        strength: medicationSelected?.strength || null,
        count: medicationSelected?.requiredQuantity || quantity,
        refills: {
          allowed: 0,
          filled: 0,
        },
        SIG: SIG || null,
        // Need to update for special pricing
        price: medicationSelected.price * quantity,
      },
    }

    const response = await createMedOrder(newOrder)

    // send to intercom
    window.Intercom('trackEvent', 'Order Created', {
      typeOfOrder: 'Medication',
      sourceVoucherId: preReg ? 'pre-reg' : voucherID || voucherUID,
      status: 'unpaid',
      patientName: `${firstname} ${lastname}`,
      patientFirstname: firstname,
      patientLastname: lastname,
      patientEmail: email,
      patientPhone: phone,
      patientDOB: dob,
      patientGender: gender,
      referringProviderID: providerID,
      referringProviderName: `${providerFirstname} ${providerLastname}` || null,
      referringProviderEmail: providerEmail || false,
      referringProviderPhone: providerPhone || false,
      referringClinicID: clinicID,
      referringClinicName: clinicName,
      referringClinicProvider: `${providerFirstname} ${providerLastname}` || null,
      misheCode: medicationSelected?.misheCode,
      medID: medicationSelected?.objectID,
      medicationName: medicationSelected?.medName,
      controlledSubstance: medicationSelected?.controlledSubstance || false,
      strength: medicationSelected?.strength,
      count: medicationSelected?.requiredQuantity || quantity,
      SIG,
      price: medicationSelected.price * quantity,
      noteContent: noteContent || null,
    })

    console.log('  -- Response Status', response?.success)
    if (response?.success || response?.status === 'success') {
      console.log('  -- Order successfully created!')
      updateOrders(newOrder)
      return response.orderID
    }

    console.error(' ** Error creating order')
    return null
  }, [
    SIG,
    clinicID,
    clinicName,
    dob,
    email,
    firstname,
    gender,
    lastname,
    noteContent,
    phone,
    providerEmail,
    providerFirstname,
    providerID,
    providerLastname,
    providerNPI,
    providerPhone,
    drugAllergies,
    medicationSelected,
    medicalConditions,
    nursing,
    otherMedications,
    pregnant,
    quantity,
    referenceVoucher,
    safetyCap,
    updateOrders,
  ])

  useEffect(() => {
    console.log('Patient ID: ', patientUserID)
    console.log('First Name: ', firstname)
  }, [patientUserID, firstname])

  const handleReferralOrder = useCallback(async () => {
    const newOrderRef = collection('emrOrders').doc()

    const { uid: voucherUID, id: voucherID, preReg } = referenceVoucher

    let patientName = ''

    if (firstname && lastname) {
      patientName = `${firstname} ${lastname}`
    } else if (firstname) {
      patientName = firstname
    } else if (lastname) {
      patientName = lastname
    }

    const newOrder = {
      id: newOrderRef.id,
      timestamp: new Date(),
      sourceVoucherId: preReg ? 'pre-reg' : voucherID || voucherUID,
      orderType: 'referral',
      status: 'unpaid',
      patient: {
        id: patientUserID || null,
        name: patientName || null,
        firstname: firstname?.toLowerCase()?.trim() || null,
        lastname: lastname?.toLowerCase()?.trim() || null,
        email,
        phone,
        dob,
        gender,
        address: {
          street,
          street2,
          city,
          state,
          zip,
        },
      },
      // TODO : add referring provider info
      referring: {
        provider: {
          name: `${providerFirstname} ${providerLastname}` || null,
          npi: providerNPI || null,
          id: providerID || null,
          email: providerEmail || false,
          phone: providerPhone || false,
        },
        clinic: {
          id: clinicID || null,
          name: clinicName || 'Profile did not have an organization name',
        },
      },
      // Depricating
      referringProvider: {
        name: `${providerFirstname} ${providerLastname}` || null,
        npi: providerNPI || null,
        id: providerID || null,
        email: providerEmail || false,
        phone: providerPhone || false,
      },
      // Depricating
      referringClinic: {
        id: clinicID || null,
        name: clinicName || 'Profile did not have an organization name',
      },
      referred: {
        clinic: {
          id:
            serviceSelected.clinicID ||
            serviceSelected.organizationId ||
            serviceSelected.clinicIDs ||
            null,
          name:
            serviceSelected?.clinicName ||
            serviceSelected?.organizationName ||
            serviceSelected.clinicNames ||
            null,
        },
        provider: {
          id: serviceSelected?.providerID || serviceSelected?.providerId || null,
          name: serviceSelected?.providerName || null,
        },
        // Depricated
        organizationId:
          serviceSelected.organizationId ||
          serviceSelected.clinicID ||
          serviceSelected.clinicIDs ||
          null,
        // Depricated
        organizationName: serviceSelected?.organizationName || serviceSelected.clinicNames || null,
      },
      referredClinic: {
        clinicId:
          serviceSelected.organizationId ||
          serviceSelected.clinicID ||
          serviceSelected.clinicIDs ||
          null,
        clinicName: serviceSelected?.organizationName || serviceSelected.clinicNames || null,
        // Depricated
        organizationId:
          serviceSelected.organizationId ||
          serviceSelected.clinicID ||
          serviceSelected.clinicIDs ||
          null,
        // Depricated
        organizationName: serviceSelected?.organizationName || serviceSelected.clinicNames || null,
      },
      plan: {
        id: serviceSelected?.networkId || null,
        name: serviceSelected?.network?.name || null,
        data: serviceSelected?.network || null,
      },
      details: {
        serviceName: serviceSelected?.name || null,
        serviceId: serviceSelected.objectID,
        diagnosisCode: diagnosisCodeData?.code || null,
        diagnosisCodeDescription: diagnosisCodeData?.longDescription || null,
        serviceData: serviceSelected,
        networkData: serviceSelected?.network || null,
        networkId: serviceSelected?.networkId || null,
        networkName: serviceSelected?.network?.name || null,
        planData: serviceSelected?.network || null,
        planId: serviceSelected?.networkId || null,
        planName: serviceSelected?.network?.name || null,
        price: serviceSelected?.referralPrice || serviceSelected?.price || 0,
        bundle: serviceSelected?.bundle || false,
      },
      note: noteContent || null,
    }

    console.log('  -- Creating order: ', newOrder)

    // send to intercom
    window.Intercom('trackEvent', 'Order Created', {
      typeOfOrder: 'Referral',
      orderID: newOrderRef.id,
      sourceVoucherId: preReg ? 'pre-reg' : voucherID || voucherUID,
      orderType: 'referral',
      status: 'unpaid',
      patientName: patientName || null,
      patientFirstname: firstname || false,
      patientEmail: email || false,
      patientPhone: phone || false,
      patientDOB: dob || false,
      patientGender: gender || false,
      patientAddress: street || false,
      patientAddress2: street2 || false,
      patientCity: city || false,
      patientState: state || false,
      patientZip: zip || false,
      // TODO : add referring provider info
      referringProviderID: providerID,
      referringProviderName: `${providerFirstname} ${providerLastname}` || null,
      referringProviderNPI: providerNPI || null,
      referringUserEmail: providerEmail || false,
      referringUserPhone: providerPhone || false,
      referringClinicID: clinicID,
      referringClinicName: clinicName,
      referringClinicProvider: `${providerFirstname} ${providerLastname}` || null,
      referredOrganizationId: serviceSelected.clinicID || serviceSelected.organizationId,
      referredOrganizationName:
        serviceSelected?.clinicName || serviceSelected?.organizationName || null,
      serviceName: serviceSelected.name,
      serviceID: serviceSelected.objectID,
      diagnosisCode: diagnosisCodeData?.code || false,
      diagnosisCodeDescription: diagnosisCodeData?.longDescription || null,
      networkID: serviceSelected.networkId,
      networkName: serviceSelected?.network?.name,
      price: serviceSelected?.referralPrice || serviceSelected?.price || 0,
      note: noteContent || null,
    })

    return newOrderRef
      .set(newOrder)
      .then(() => {
        console.log('  -- Order successfully created!')
        updateOrders(newOrder)
        return newOrder.id
      })
      .catch(err => {
        console.error('  ** Error creating order: ', err)
        return false
      })
  }, [
    city,
    clinicID,
    clinicName,
    diagnosisCodeData,
    dob,
    email,
    firstname,
    gender,
    lastname,
    noteContent,
    phone,
    providerEmail,
    providerFirstname,
    providerID,
    providerLastname,
    providerNPI,
    providerPhone,
    referenceVoucher,
    serviceSelected,
    state,
    street,
    street2,
    zip,
    updateOrders,
  ])

  const createNewOrder = async callback => {
    setDisabled(true)
    setSubmitDisabled(true)

    if (medicationSelected) {
      const errorStatus = medOrderValidator({
        quantity,
        SIG,
        firstname,
        lastname,
        email,
        phone,
        dateOfBirth: dob,
        gender,
      })

      if (errorStatus.error) {
        setDisabled(false)
        setSubmitDisabled(false)
        setError(errorStatus.messages)
        return
      }

      const orderCreationStatus = await handleMedOrder()

      if (!orderCreationStatus) {
        console.log('Error creating order')
        setError(orderCreationStatus?.message)
      }

      const notesStatus = await uploadNote(orderCreationStatus) // eslint-disable-line no-unused-vars

      if (!notesStatus) {
        console.log('Error uploading notes')
      }

      // onUpload()

      if (callback) {
        console.log('Callback')
        callback()
      } else {
        clearAllOrderInfo()
      }
    }

    if (serviceSelected) {
      if (!diagnosisCodeData) {
        setError('Please select a diagnosis code')
        setDisabled(false)
        return
      }

      const orderCreationStatus = await handleReferralOrder()

      if (!orderCreationStatus) {
        console.log('Error creating order')
        setError('Error creating order')
      }

      const notesStatus = await uploadNote(orderCreationStatus) // eslint-disable-line no-unused-vars

      if (!notesStatus) {
        console.log('Error uploading notes')
      }

      // clearAllOrderInfo()
      // onUpload()

      if (callback) {
        console.log('Callback')
        callback()
      } else {
        clearAllOrderInfo()
      }
    }

    setDisabled(false)
    setSubmitDisabled(false)
  }

  const fetchEmrOrders = useCallback(async () => {
    try {
      if (organizationId) {
        const constructSentQuery = [where('referring.clinic.id', '==', organizationId)]
        let constructQueryOrder = [orderBy('createdAt', 'desc'), limit(ordersLimit)]

        const statusSentQuery = []
        const receivedSentQuery = []

        const orderTypeSentQuery = []
        const orderTypeRevievedQuery = []

        const constructReceivedQuery = [where('referred.clinic.id', '==', organizationId)]
        const { startDate, endDate, unpaid, paid, failed, medication, referral, lastname } =
          ordersFilter
        if (startDate) {
          constructSentQuery.push(where('createdAt', '>=', new Date(startDate)))
          constructReceivedQuery.push(where('createdAt', '>=', new Date(startDate)))
        }
        if (endDate) {
          constructSentQuery.push(where('createdAt', '<=', new Date(endDate)))
          constructReceivedQuery.push(where('createdAt', '<=', new Date(endDate)))
        }
        if (unpaid) {
          statusSentQuery.push(where('status', '==', 'unpaid'))
          receivedSentQuery.push(where('status', '==', 'unpaid'))
        }
        if (paid) {
          statusSentQuery.push(where('status', '==', 'paid'))
          receivedSentQuery.push(where('status', '==', 'paid'))
        }
        if (failed) {
          statusSentQuery.push(where('status', '==', 'failed'))
          receivedSentQuery.push(where('status', '==', 'failed'))
        }
        if (medication) {
          orderTypeSentQuery.push(where('orderType', '==', 'medication'))
          orderTypeRevievedQuery.push(where('orderType', '==', 'medication'))
        }
        if (referral) {
          orderTypeSentQuery.push(where('orderType', '==', 'referral'))
          orderTypeRevievedQuery.push(where('orderType', '==', 'referral'))
        }
        if (lastname) {
          constructSentQuery.push(where('patient.lastname', '==', lastname))
          constructReceivedQuery.push(where('patient.lastname', '==', lastname))
        }

        setEmrOrders([])
        const emrOrdersQuery = query(
          collectionModular('emrOrders'),
          or(
            and(...constructSentQuery, or(...statusSentQuery), or(...orderTypeSentQuery)),
            and(...constructReceivedQuery, or(...receivedSentQuery), or(...orderTypeSentQuery)),
          ),
          ...constructQueryOrder,
        )

        let emrOrdersDataFilter = []
        let countLoop = 0
        setEmrOrdersLoading(true)
        getDocs(emrOrdersQuery).then(async function (voucherSnapshot) {
          let totalDocs = voucherSnapshot.docs.length
          console.log('Orders-Docs', totalDocs)
          if (voucherSnapshot.empty) {
            setEmrOrders([])
            setEmrOrdersLoading(false)

            return
          }

          for await (let doc of voucherSnapshot.docs) {
            const { details, status } = doc.data()
            // console.log('doc.data()', doc.data())
            const emrOrderList = {
              ...doc.data(),
              id: doc.id,
              uid: doc.id,
              display: true,
            }

            countLoop++

            if (details.bundle) {
              const subOrdersQuery = query(
                subCollectionModular({
                  docName: 'emrOrders',
                  docId: doc.id,
                  subDocName: 'subOrders',
                }),
                or(
                  and(...constructSentQuery, or(...orderTypeSentQuery)),
                  and(...constructReceivedQuery, or(...orderTypeSentQuery)),
                ),
                ...constructQueryOrder,
              )
              const querySnapshotSubOrderDoc = await getDocs(subOrdersQuery)

              let subOrderLoop = 0
              const subOrderData = []
              const totalSubVoucher = querySnapshotSubOrderDoc.docs.length
              totalDocs = totalDocs + totalSubVoucher
              querySnapshotSubOrderDoc.forEach(subOrderDoc => {
                subOrderLoop++
                countLoop++

                const subOrder = {
                  status,
                  ...subOrderDoc.data(),
                  id: subOrderDoc.id,
                  uid: subOrderDoc.id,
                }

                subOrderData.push(subOrder)

                if (subOrderLoop === totalSubVoucher) {
                  emrOrdersDataFilter.push({
                    ...emrOrderList,
                    subOrders: subOrderData,
                  })
                }
              })

              if (countLoop === totalDocs) {
                setEmrOrdersLoading(false)
                setEmrOrders(emrOrdersDataFilter)
              }
            } else {
              emrOrdersDataFilter.push({
                ...emrOrderList,
                subOrders: [],
              })

              if (countLoop === totalDocs) {
                setEmrOrdersLoading(false)
                setEmrOrders(emrOrdersDataFilter)
              }
            }
          }
        })
      }
    } catch (error) {
      console.log('error fetching vouchers by service Data') // eslint-disable-line
      console.log(error) // eslint-disable-line
      return false
    }
  }, [ordersLimit, organizationId, ordersFilter])

  useEffect(() => {
    if (emrOrders && emrOrders.length > 0) {
      const sentOrder = emrOrders.filter(data => typeof data.referred != 'undefined' && data)
      const receivedOrder = emrOrders.filter(data => typeof data.referring != 'undefined' && data)

      setDisplayEmrOrders({
        sent: sentOrder,
        received: receivedOrder,
      })
    } else {
      setDisplayEmrOrders({
        sent: [],
        received: [],
      })
    }
  }, [emrOrders])

  console.log('emrOrderssss', emrOrders)
  console.log('ordersFilter', ordersFilter)

  return {
    orders,
    setOrders,
    updateOrders,
    handleMedOrder,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    email,
    setEmail,
    phone,
    setPhone,
    setFormattedPhone,
    dob,
    setDob,
    gender,
    setGender,
    street,
    setStreet,
    street2,
    setStreet2,
    state,
    setState,
    city,
    setCity,
    zip,
    setZip,
    patientUserID,
    setPatientUserID,
    providerFirstname,
    setProviderFirstname,
    providerLastname,
    setProviderLastname,
    providerEmail,
    setProviderEmail,
    providerPhone,
    setProviderPhone,
    providerNPI,
    setProviderNPI,
    serviceSelected,
    setServiceSelected,
    diagnosisCodeData,
    setDiagnosisCodeData,
    medicationSelected,
    setMedicationSelected,
    quantity,
    setQuantity,
    SIG,
    setSIG,
    drugAllergies,
    setDrugAllergies,
    otherMedications,
    setOtherMedications,
    medicalConditions,
    setMedicalConditions,
    pregnant,
    setPregnant,
    nursing,
    setNursing,
    safetyCap,
    setSafetyCap,
    referenceVoucher,
    setReferenceVoucher,
    currentFile,
    setCurrentFile,
    noteContent,
    setNoteContent,
    createNewOrder,
    clearAllOrderInfo,
    clearPatientInfo,
    clearGeneralOrderInfo,
    disabled,
    submitDisabled,
    error,
    fetchEmrOrders,
    emrOrders,
    displayEmrOrders,
    emrOrdersLoading,
    setOrdersLimit,
    ordersLimit,
    setOrdersFilter,
    ordersFilter,
  }
}

export default useEmrOrders
