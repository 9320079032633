import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Card, CardTitle, CardText } from '../stories/Card'
import Avatar from '../stories/Avatar'
import Typography from '../stories/Typography'

const ProviderCard = ({ image = '', degree, name, specialities = [] }) => (
  <Card>
    <Box display='flex'>
      <Avatar size='tiny' position='left' src={image} alt='Kenneth Ortiz'>
        {name?.[0]}
      </Avatar>
      <Box>
        <CardTitle>
          {name}
          <Typography component='span' variant='h4' color='primary'>
            {' '}
            {degree}
          </Typography>
        </CardTitle>
        <CardText>
          <Typography variant='body2' weight='light'>
            {specialities?.join(', ')}
          </Typography>
        </CardText>
      </Box>
    </Box>
  </Card>
)

ProviderCard.propTypes = {
  image: PropTypes.string,
  degree: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  specialities: PropTypes.arrayOf(PropTypes.string),
}

export default ProviderCard
