import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Collapse from '@mui/material/Collapse'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import { useCurrentProfile, useCurrentClinic } from '../context/CurrentUser'
import Icon from '../stories/Icons'
import SelectField from '../stories/Select'
import Hidden from '@mui/material/Hidden'

// import BarChart from './Charts/BarChart'
import ResponsiveBarChart from './Charts/ResponsiveChart'
import { moneyFormatter } from '../libs/formatters'

import { collection } from '../firebase/firestore'
import useReports from '../hooks/useReports'

const ReportsContent = () => {
  const [reportType, setReportType] = useState('')
  const [reportTypeSpecific, setReportTypeSpecific] = useState('')
  const [openMenu, setOpenMenu] = useState('')
  const { isProvider, organizationId } = useCurrentProfile()

  const {
    fetchRevenueByReferringProvider,
    fetchRevenueByProvider,
    fetchRevenueByPlan,
    fetchRevenueByService,
    revenueRefeeringProvider,
    revenueProvider,
    revenueByPlan,
    revenueByService,
  } = useReports({ organizationId })

  const [loading, setLoading] = useState(false)

  const [graphData, setGraphData] = useState(null)

  const [noDataMessage, setNoDataMessage] = useState('')

  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const [selectedPlan, setSelectedPlan] = useState('All Plans')

  const { plansAssociated = {} } = useCurrentClinic()

  const [plans, setPlans] = useState([])

  useEffect(() => {
    if (plansAssociated) {
      const planList = Object.values(plansAssociated).map(plan => ({
        value: plan.slug,
        label: plan.name,
      }))
      if (
        !planList.some(
          plan => plan?.value === 'Cost Plus Health Plan' || plan?.value === 'Mishe Health Plan',
        )
      ) {
        planList.unshift({
          value: 'Mishe Health Plan',
          label: 'Mishe Health Plan',
        })
      }
      planList.unshift({ value: 'All Plans', label: 'All Plans' })
      setPlans(planList)
    }
    console.log('plansAssociated changed', plansAssociated)
  }, [plansAssociated])

  useEffect(() => {
    if (reportTypeSpecific === 'byService') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Service', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Revenue', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'byReferringProvider') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Referring Provider', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Revenue', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'byProvider') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Provider', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Revenue', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'byPlan') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Plan', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Revenue', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'outstandingOrders') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Service', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Pending Revenue', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'vouchersNotRedeemed') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Service', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Pending Payout', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'vouchersRedeemedNotPaid') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Service', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Pending Payout', width: 200 },
      ])
    }
  }, [reportTypeSpecific])

  useEffect(() => {
    if (graphData) {
      const updatedRows = graphData.dataset.map((data, index) => ({
        id: index + 1,
        ...data,
      }))
      setRows(updatedRows)
    }
  }, [graphData])

  useEffect(() => {
    const fetchData = async () => {
      // fetch data

      if (reportTypeSpecific === 'byService') {
        fetchRevenueByService()

        setLoading(false)
      }

      if (reportTypeSpecific === 'byReferringProvider') {
        fetchRevenueByReferringProvider()
        setLoading(false)
      }

      if (reportTypeSpecific === 'byProvider') {
        fetchRevenueByProvider()
        setLoading(false)
      }

      if (reportTypeSpecific === 'byPlan') {
        fetchRevenueByPlan()
        setLoading(false)
      }

      if (reportTypeSpecific === 'outstandingOrders') {
        const data = await collection('emrOrders')
          .where('referred.clinic.id', '==', organizationId)
          .where('status', '==', 'unpaid')
          .get()

        if (data.empty) {
          console.log('No matching documents.')
          setLoading(false)
          setNoDataMessage('There are is currently no matching data.')
          return undefined
        }

        const counts = {}

        data.forEach(doc => {
          const order = doc.data()
          if (counts[order?.details?.serviceName]) {
            counts[order?.details?.serviceName].count += 1
            counts[order?.details?.serviceName].revenue += parseFloat(order?.details?.price) || 0
          } else {
            counts[order?.details?.serviceName] = {
              name: order?.details?.serviceName,
              count: 1,
              revenue: parseFloat(order?.details?.price) || 0,
            }
          }
        })

        const dataset = Object.values(counts)

        const xAxis = [
          {
            scaleType: 'band',
            dataKey: 'name',
            tickPlacement: 'middle',
            valueFormatter: value => {
              // cut text if too long
              if (value?.length > 20) {
                return value.substring(0, 20) + '...'
              }
              return value || 'N/A'
            },
          },
        ]
        const yAxis = [
          { id: 'left_axis_id' },
          { id: 'right_axis_id', valueFormatter: moneyFormatter },
        ]

        const seriesData = [
          {
            type: 'bar',
            dataKey: 'count',
            label: 'Count',
            yAxisKey: 'left_axis_id',
            color: '#652d92',
          },
          {
            type: 'bar',
            dataKey: 'revenue',
            label: 'Pending Revenue',
            yAxisKey: 'right_axis_id',
            color: '#652d9233',
            valueFormatter: moneyFormatter,
          },
        ]

        setGraphData({
          title: 'Outstanding Orders by Service',
          dataset,
          xAxis,
          yAxis,
          seriesData,
          chartSettings: {
            height: 400,
            tooltip: true,
          },
        })
        setLoading(false)
      }

      if (reportTypeSpecific === 'vouchersNotRedeemed') {
        const data = await collection('vouchers')
          .where('organization.id', '==', organizationId)
          .where('status', '==', 'purchased')
          .get()

        if (data.empty) {
          console.log('No matching documents.')
          setLoading(false)
          setNoDataMessage('There are is currently no matching data.')
          return undefined
        }

        const counts = {}

        data.forEach(doc => {
          const voucher = doc.data()
          if (counts[voucher?.service?.name]) {
            counts[voucher?.service?.name].count += 1
            counts[voucher?.service?.name].revenue +=
              parseFloat(voucher?.service?.pricePaidToDoc) || 0
          } else {
            counts[voucher?.service?.name] = {
              name: voucher?.service?.name,
              count: 1,
              revenue: parseFloat(voucher?.service?.price) || 0,
            }
          }
        })

        const dataset = Object.values(counts)

        const xAxis = [
          {
            scaleType: 'band',
            dataKey: 'name',
            tickPlacement: 'middle',
            valueFormatter: value => {
              // cut text if too long
              if (value?.length > 20) {
                return value.substring(0, 20) + '...'
              }
              return value || 'N/A'
            },
          },
        ]
        const yAxis = [
          { id: 'left_axis_id' },
          { id: 'right_axis_id', valueFormatter: moneyFormatter },
        ]

        const seriesData = [
          {
            type: 'bar',
            dataKey: 'count',
            label: 'Count',
            yAxisKey: 'left_axis_id',
            color: '#652d92',
          },
          {
            type: 'bar',
            dataKey: 'revenue',
            label: 'Pending Payout',
            yAxisKey: 'right_axis_id',
            color: '#652d9233',
            valueFormatter: moneyFormatter,
          },
        ]

        setGraphData({
          title: 'Vouchers Not Redeemed by Service',
          dataset,
          xAxis,
          yAxis,
          seriesData,
          chartSettings: {
            height: 400,
            tooltip: true,
          },
        })
        setLoading(false)
      }

      if (reportTypeSpecific === 'vouchersRedeemedNotPaid') {
        const data = await collection('vouchers')
          .where('organization.id', '==', organizationId)
          .where('status', '==', 'redeemed')
          .where('paid', '==', false)
          .get()
          .catch(err => {
            console.error('Error getting documents', err)
            return {
              empty: true,
            }
          })

        if (data.empty) {
          console.log('No matching documents.')
          setLoading(false)
          setNoDataMessage('There are is currently no matching data.')
          return undefined
        }

        const counts = {}

        data.forEach(doc => {
          const voucher = doc.data()
          if (counts[voucher?.service?.name]) {
            counts[voucher?.service?.name].count += 1
            counts[voucher?.service?.name].revenue +=
              parseFloat(voucher?.service?.pricePaidToDoc) || 0
          } else {
            counts[voucher?.service?.name] = {
              name: voucher?.service?.name,
              count: 1,
              revenue: parseFloat(voucher?.service?.price) || 0,
            }
          }
        })

        const dataset = Object.values(counts)

        const xAxis = [
          {
            scaleType: 'band',
            dataKey: 'name',
            tickPlacement: 'middle',
            valueFormatter: value => {
              // cut text if too long
              if (value?.length > 20) {
                return value.substring(0, 20) + '...'
              }
              return value || 'N/A'
            },
          },
        ]
        const yAxis = [
          { id: 'left_axis_id' },
          { id: 'right_axis_id', valueFormatter: moneyFormatter },
        ]

        const seriesData = [
          {
            type: 'bar',
            dataKey: 'count',
            label: 'Count',
            yAxisKey: 'left_axis_id',
            color: '#652d92',
          },
          {
            type: 'bar',
            dataKey: 'revenue',
            label: 'Pending Payout',
            yAxisKey: 'right_axis_id',
            color: '#652d9233',
            valueFormatter: moneyFormatter,
          },
        ]

        setGraphData({
          title: 'Vouchers Redeemed but not Paid by Service',
          dataset,
          xAxis,
          yAxis,
          seriesData,
          chartSettings: {
            height: 400,
            tooltip: true,
          },
        })
        setLoading(false)
      }

      if (reportTypeSpecific === 'totalSavings') {
        // const data = await collection('vouchers')
        //   .where('organization.id', '==', organizationId)
        //   .get()
        //   .catch(err => {
        //     console.error('Error getting documents', err)
        //     return {
        //       empty: true
        //     }
        //   })

        // if (data.empty) {
        //   console.log('No matching documents.')
        //   setLoading(false)
        //   setNoDataMessage('There are is currently no matching data.')
        //   return undefined
        // }

        // const counts = {}

        // data.forEach(doc => {
        //   const voucher = doc.data()

        //   if (voucher.plan.id) {
        //     if (counts[voucher.plan.id]) {
        //       counts[voucher.plan.id][voucher?.service?.name].count += 1
        //       counts[voucher.plan.id][voucher?.service?.name].savings += parseFloat(voucher.service.price) - parseFloat(voucher?.service?.msrp || 0) || 0
        //       counts[voucher.plan.id][voucher?.service?.name].msrp += parseFloat(voucher?.service?.msrp) || 0
        //       counts[voucher.plan.id][voucher?.service?.name].price += parseFloat(voucher.service.price) || 0

        //     } else {
        //       counts[voucher.plan.id] = {
        //         name: voucher.plan.name,
        //         count: 1,
        //         revenue: parseFloat(voucher.service.price) || 0
        //       }
        //     }
        //   }

        //   if (counts[voucher?.service?.name]) {
        //     counts[voucher?.service?.name].count += 1
        //     counts[voucher?.service?.name].revenue += parseFloat(voucher?.service?.pricePaidToDoc) || 0
        //   } else {
        //     counts[voucher?.service?.name] = {
        //       name: voucher?.service?.name,
        //       count: 1,
        //       revenue: parseFloat(voucher?.service?.price) || 0
        //     }
        //   }
        // })

        // const dataset = Object.values(counts)

        console.log('=======here!!!!')

        const dataset = [
          {
            name: 'Office Consult',
            msrp: 90,
            price: 0,
            savings: 1260,
            count: 14,
          },
          {
            name: 'Video Visit',
            msrp: 80,
            price: 0,
            savings: 560,
            count: 7,
          },
          {
            name: 'Text Visit',
            msrp: 50,
            price: 0,
            savings: 950,
            count: 19,
          },
          {
            name: 'Medication Refill',
            msrp: 35,
            price: 0,
            savings: 105,
            count: 3,
          },
          {
            name: 'Chronic Disease Follow Up',
            msrp: 75,
            price: 0,
            savings: 1125,
            count: 15,
          },
          {
            name: 'Acute Sick Visit',
            msrp: 90,
            price: 0,
            savings: 1800,
            count: 20,
          },
          {
            name: 'Annual Well Visit',
            msrp: 100,
            price: 0,
            savings: 200,
            count: 2,
          },
          {
            name: 'Home Visit',
            msrp: 125,
            price: 0,
            savings: 2250,
            count: 18,
          },
          {
            name: 'Specialist Visit',
            msrp: 300,
            price: 150,
            savings: 1650,
            count: 11,
          },
        ]

        const xAxis = [
          {
            scaleType: 'band',
            dataKey: 'name',
            tickPlacement: 'middle',
            valueFormatter: value => {
              // cut text if too long
              if (value?.length > 20) {
                return value.substring(0, 20) + '...'
              }
              return value || 'N/A'
            },
          },
        ]
        const yAxis = [
          { id: 'left_axis_id' },
          { id: 'right_axis_id', valueFormatter: moneyFormatter },
        ]

        const seriesData = [
          {
            type: 'bar',
            dataKey: 'price',
            label: 'Price',
            yAxisKey: 'left_axis_id',
            color: '#652d92',
          },
          {
            type: 'bar',
            dataKey: 'msrp',
            label: 'MSRP',
            yAxisKey: 'right_axis_id',
            color: '#652d9233',
          },
        ]

        setGraphData({
          title: 'Total Savings by Service',
          dataset,
          xAxis,
          yAxis,
          seriesData,
          chartSettings: {
            height: 400,
            tooltip: true,
          },
        })
        setLoading(false)
      }

      if (reportTypeSpecific === 'itemizedSavings') {
        // const data = await collection('vouchers')
        //   .where('organization.id', '==', organizationId)
        //   .get()
        //   .catch(err => {
        //     console.error('Error getting documents', err)
        //     return {
        //       empty: true
        //     }
        //   })

        // if (data.empty) {
        //   console.log('No matching documents.')
        //   setLoading(false)
        //   setNoDataMessage('There are is currently no matching data.')
        //   return undefined
        // }

        // const counts = {}

        // data.forEach(doc => {
        //   const voucher = doc.data()

        //   if (voucher.plan.id) {
        //     if (counts[voucher.plan.id]) {
        //       counts[voucher.plan.id][voucher?.service?.name].count += 1
        //       counts[voucher.plan.id][voucher?.service?.name].savings += parseFloat(voucher.service.price) - parseFloat(voucher?.service?.msrp || 0) || 0
        //       counts[voucher.plan.id][voucher?.service?.name].msrp += parseFloat(voucher?.service?.msrp) || 0
        //       counts[voucher.plan.id][voucher?.service?.name].price += parseFloat(voucher.service.price) || 0

        //     } else {
        //       counts[voucher.plan.id] = {
        //         name: voucher.plan.name,
        //         count: 1,
        //         revenue: parseFloat(voucher.service.price) || 0
        //       }
        //     }
        //   }

        //   if (counts[voucher?.service?.name]) {
        //     counts[voucher?.service?.name].count += 1
        //     counts[voucher?.service?.name].revenue += parseFloat(voucher?.service?.pricePaidToDoc) || 0
        //   } else {
        //     counts[voucher?.service?.name] = {
        //       name: voucher?.service?.name,
        //       count: 1,
        //       revenue: parseFloat(voucher?.service?.price) || 0
        //     }
        //   }
        // })

        // const dataset = Object.values(counts)

        console.log('=======here!!!!')

        const dataset = [
          {
            name: 'Office Consult',
            msrp: 90,
            price: 0,
            savings: 1260,
            count: 14,
          },
          {
            name: 'Video Visit',
            msrp: 80,
            price: 0,
            savings: 560,
            count: 7,
          },
          {
            name: 'Text Visit',
            msrp: 50,
            price: 0,
            savings: 950,
            count: 19,
          },
          {
            name: 'Medication Refill',
            msrp: 35,
            price: 0,
            savings: 105,
            count: 3,
          },
          {
            name: 'Chronic Disease Follow Up',
            msrp: 75,
            price: 0,
            savings: 1125,
            count: 15,
          },
          {
            name: 'Acute Sick Visit',
            msrp: 90,
            price: 0,
            savings: 1800,
            count: 20,
          },
          {
            name: 'Annual Well Visit',
            msrp: 100,
            price: 0,
            savings: 200,
            count: 2,
          },
          {
            name: 'Home Visit',
            msrp: 125,
            price: 0,
            savings: 2250,
            count: 18,
          },
          {
            name: 'Specialist Visit',
            msrp: 300,
            price: 150,
            savings: 1650,
            count: 11,
          },
        ]

        const xAxis = [
          {
            scaleType: 'band',
            dataKey: 'name',
            tickPlacement: 'middle',
            valueFormatter: value => {
              // cut text if too long
              if (value?.length > 20) {
                return value.substring(0, 20) + '...'
              }
              return value || 'N/A'
            },
          },
        ]
        const yAxis = [
          { id: 'left_axis_id' },
          { id: 'right_axis_id', valueFormatter: moneyFormatter },
        ]

        const seriesData = [
          {
            type: 'bar',
            dataKey: 'price',
            label: 'Price',
            yAxisKey: 'left_axis_id',
            color: '#652d92',
          },
          {
            type: 'bar',
            dataKey: 'msrp',
            label: 'MSRP',
            yAxisKey: 'right_axis_id',
            color: '#652d9233',
          },
        ]

        setGraphData({
          title: 'Itemized Savings by Service',
          dataset,
          xAxis,
          yAxis,
          seriesData,
          chartSettings: {
            height: 400,
            tooltip: true,
          },
        })
        setLoading(false)
      }
    }

    if (!reportTypeSpecific) {
      return undefined
    }
    setNoDataMessage('')
    setLoading(true)
    fetchData()
  }, [reportTypeSpecific])

  const graphConfig = ({
    xAxisScaleType,
    xAxisDataKey,
    xAxisTickPlacement,
    yAxisLeftId,
    yAxisRightId,
    seriesData,
    dataset,
    title,
  }) => {
    const xAxis = [
      {
        scaleType: xAxisScaleType,
        dataKey: xAxisDataKey,
        tickPlacement: xAxisTickPlacement,
        valueFormatter: value => {
          if (value?.length > 20) {
            return value.substring(0, 20) + '...'
          }
          return value || 'N/A'
        },
      },
    ]
    const yAxis = [{ id: yAxisLeftId }, { id: yAxisRightId, valueFormatter: moneyFormatter }]

    setGraphData({
      title,
      dataset,
      xAxis,
      yAxis,
      seriesData,
      chartSettings: {
        height: 400,
        tooltip: true,
      },
    })
  }

  useEffect(() => {
    const dataset = []

    revenueRefeeringProvider &&
      revenueRefeeringProvider.forEach(({ voucher, emrOrder }) => {
        const revenue = parseFloat(voucher?.service?.price) || 0

        const referringProvider = emrOrder.referringProvider.id
        const findExistingreferringProvider = dataset.find(
          ({ referringProviderId }) => referringProviderId === referringProvider,
        )
        if (findExistingreferringProvider) {
          const { count, revenue: prevRevenue } = findExistingreferringProvider

          const foundIndex = dataset.findIndex(
            ({ referringProviderId }) => referringProviderId == referringProvider,
          )
          dataset[foundIndex] = {
            name: emrOrder.referringProvider.name,
            referringProviderId: referringProvider,
            count: count + 1,
            revenue: revenue + prevRevenue,
          }
        } else {
          dataset.push({
            name: emrOrder.referringProvider.name,
            referringProviderId: referringProvider,
            count: 1,
            revenue,
          })
        }
      })
    graphConfig({
      xAxisScaleType: 'band',
      xAxisDataKey: 'name',
      xAxisTickPlacement: 'middle',
      yAxisLeftId: 'left_axis_id',
      yAxisRightId: 'right_axis_id',
      seriesData: [
        {
          type: 'bar',
          dataKey: 'count',
          label: 'Count',
          yAxisKey: 'left_axis_id',
          color: '#652d92',
        },
        {
          type: 'bar',
          dataKey: 'revenue',
          label: 'Revenue',
          yAxisKey: 'right_axis_id',
          color: '#652d9233',
          valueFormatter: moneyFormatter,
        },
      ],
      dataset,
      title: 'Revenue and Encounter Count by Referring Provider',
    })
  }, [revenueRefeeringProvider])

  useEffect(() => {
    const datasetRevenueProvider = []
    revenueProvider.forEach(({ voucher, emrOrder }) => {
      const referredOrganizationId = emrOrder.referred.organizationId
      const name = emrOrder.referred.organizationName
      const revenue = parseFloat(voucher?.service?.price) || 0

      const findExistingreferringProvider = datasetRevenueProvider.find(
        ({ referredId }) => referredId === referredOrganizationId,
      )
      if (findExistingreferringProvider) {
        const { count, revenue: prevRevenue } = findExistingreferringProvider

        const foundIndex = datasetRevenueProvider.findIndex(
          ({ referredId }) => referredId == referredOrganizationId,
        )
        datasetRevenueProvider[foundIndex] = {
          name,
          referredId: referredOrganizationId,
          count: count + 1,
          revenue: revenue + prevRevenue,
        }
      } else {
        datasetRevenueProvider.push({
          name,
          referredId: referredOrganizationId,
          count: 1,
          revenue,
        })
      }
    })

    graphConfig({
      xAxisScaleType: 'band',
      xAxisDataKey: 'name',
      xAxisTickPlacement: 'middle',
      yAxisLeftId: 'left_axis_id',
      yAxisRightId: 'right_axis_id',
      seriesData: [
        {
          type: 'bar',
          dataKey: 'count',
          label: 'Count',
          yAxisKey: 'left_axis_id',
          color: '#652d92',
        },
        {
          type: 'bar',
          dataKey: 'revenue',
          label: 'Revenue',
          yAxisKey: 'right_axis_id',
          color: '#652d9233',
          valueFormatter: moneyFormatter,
        },
      ],
      dataset: datasetRevenueProvider,
      title: 'Revenue and Encounter Count by Provider',
    })
  }, [revenueProvider])

  useEffect(() => {
    const counts = {}
    revenueByPlan.forEach(({ network, service }) => {
      const networkName = network?.name
      const networkId = network?.id
      if (counts.networkId === networkId) {
        counts[networkName].count += 1
        counts[networkName].revenue += parseFloat(service?.price) || 0
      } else {
        if (networkName && networkId) {
          counts[networkName] = {
            name: networkName,
            networkId,
            count: 1,
            revenue: parseFloat(service?.price) || 0,
          }
        }
      }
    })

    const dataset = Object.values(counts)

    graphConfig({
      xAxisScaleType: 'band',
      xAxisDataKey: 'name',
      xAxisTickPlacement: 'middle',
      yAxisLeftId: 'left_axis_id',
      yAxisRightId: 'right_axis_id',
      seriesData: [
        {
          type: 'bar',
          dataKey: 'count',
          label: 'Count',
          yAxisKey: 'left_axis_id',
          color: '#652d92',
        },
        {
          type: 'bar',
          dataKey: 'revenue',
          label: 'Revenue',
          yAxisKey: 'right_axis_id',
          color: '#652d9233',
          valueFormatter: moneyFormatter,
        },
      ],
      dataset,
      title: 'Revenue and Encounter Count by Provider',
    })
  }, [revenueByPlan])

  useEffect(() => {
    const revenueByServiceData = {}
    revenueByService.forEach(({ voucher }) => {
      if (revenueByServiceData[voucher?.service?.name]) {
        revenueByServiceData[voucher?.service?.name].count += 1
        revenueByServiceData[voucher?.service?.name].revenue +=
          parseFloat(voucher?.service?.price) || 0
      } else {
        revenueByServiceData[voucher?.service?.name] = {
          name: voucher?.service?.name,
          count: 1,
          revenue: parseFloat(voucher?.service?.price) || 0,
        }
      }
    })
    const dataset = Object.values(revenueByServiceData)

    graphConfig({
      xAxisScaleType: 'band',
      xAxisDataKey: 'name',
      xAxisTickPlacement: 'middle',
      yAxisLeftId: 'left_axis_id',
      yAxisRightId: 'right_axis_id',
      seriesData: [
        {
          type: 'bar',
          dataKey: 'count',
          label: 'Count',
          yAxisKey: 'left_axis_id',
          color: '#652d92',
        },
        {
          type: 'bar',
          dataKey: 'revenue',
          label: 'Revenue',
          yAxisKey: 'right_axis_id',
          color: '#652d9233',
          valueFormatter: moneyFormatter,
        },
      ],
      dataset,
      title: 'Revenue and Encounter Count by Service',
    })
  }, [revenueByService])

  useEffect(() => {
    setOpenMenu('')
  }, [reportType])

  useEffect(() => {
    if (isProvider) {
      setReportType('Provider Revenue')
    } else {
      setReportType('Patient Spending')
    }
  }, [isProvider])

  return (
    <Box>
      <Box
        display='flex'
        width='100%'
        maxHeight='150px'
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          overflow: {
            xs: '',
            md: 'hidden',
          },
          maxHeight: {
            xs: 'auto',
            md: '150px',
          },
          boxShadow: {
            xs: 'none',
            md: '0px 0px 1px rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            width: {
              xs: '100%',
              md: '33.3%',
            },
            overflow: {
              xs: '',
              md: 'hidden',
            },
          }}
        >
          {isProvider && (
            <>
              <Hidden mdUp>
                <SelectField
                  label='Report Type'
                  value={reportType}
                  onChange={e => setReportType(e.target.value)}
                  options={[
                    { value: 'Provider Revenue', label: 'Provider Revenue' },
                    { value: 'Plan Spending', label: 'Plan Spending' },
                  ]}
                />
              </Hidden>
              <Hidden mdDown>
                <Button
                  width='100%'
                  variant='text'
                  onClick={() => setReportType('Provider Revenue')}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: reportType === 'Provider Revenue' ? '#652d92' : 'transparent',
                    borderRadius: '0',
                    margin: '0',
                    height: '50px',
                  }}
                >
                  <Typography
                    variant='h5'
                    weight='light'
                    family='poppins'
                    margin='0'
                    color={reportType === 'Provider Revenue' ? 'white' : 'primary'}
                  >
                    Provider Revenue
                  </Typography>
                  {reportType === 'Provider Revenue' && (
                    <Box color='white'>
                      <Icon name='chevronRight' />
                    </Box>
                  )}
                </Button>
                <Button
                  width='100%'
                  variant='text'
                  onClick={() => setReportType('Plan Spending')}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: '0',
                    backgroundColor: reportType === 'Plan Spending' ? '#652d92' : 'transparent',
                    margin: '0',
                    height: '50px',
                  }}
                >
                  <Typography
                    variant='h5'
                    weight='light'
                    family='poppins'
                    margin='0'
                    color={reportType === 'Plan Spending' ? 'white' : 'primary'}
                  >
                    Plan Spending
                  </Typography>
                  {reportType === 'Plan Spending' && (
                    <Box color='white'>
                      <Icon name='chevronRight' />
                    </Box>
                  )}
                </Button>
                <Button
                  width='100%'
                  variant='text'
                  onClick={() => setReportType('Plan Savings')}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: '0',
                    backgroundColor: reportType === 'Plan Savings' ? '#652d92' : 'transparent',
                    margin: '0',
                    height: '50px',
                  }}
                >
                  <Typography
                    variant='h5'
                    weight='light'
                    family='poppins'
                    margin='0'
                    color={reportType === 'Plan Savings' ? 'white' : 'primary'}
                  >
                    Plan Savings
                  </Typography>
                  {reportType === 'Plan Savings' && (
                    <Box color='white'>
                      <Icon name='chevronRight' />
                    </Box>
                  )}
                </Button>
              </Hidden>
            </>
          )}
          {!isProvider && (
            <Button
              width='100%'
              variant='text'
              onClick={() => setReportType('Patient Spending')}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: reportType === 'Patient Spending' ? '#652d92' : 'transparent',
                borderRadius: '0',
                margin: '0',
                height: '50px',
              }}
            >
              <Typography
                variant='h5'
                weight='light'
                family='poppins'
                margin='0'
                color={reportType === 'Patient Spending' ? 'white' : 'primary'}
              >
                Patient Spending
              </Typography>
              {reportType === 'Patient Spending' && (
                <Box color='white'>
                  <Icon name='chevronRight' />
                </Box>
              )}
            </Button>
          )}
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            width: {
              xs: '100%',
              md: '33.3%',
            },
            overflow: {
              xs: '',
              md: 'hidden',
            },
          }}
        >
          <Hidden mdUp>
            <Collapse in={reportType === 'Provider Revenue'}>
              <SelectField
                label='Report Type'
                value={openMenu}
                onChange={e => setOpenMenu(e.target.value)}
                options={[
                  {
                    value: 'Revenue and Encounter Count',
                    label: 'Revenue and Encounter Count',
                  },
                  {
                    value: 'Dollar Total and Count',
                    label: 'Dollar Total and Count',
                  },
                ]}
              />
            </Collapse>
            <Collapse in={reportType === 'Plan Spending'}>
              <SelectField
                label='Report Type'
                value={openMenu}
                onChange={e => setOpenMenu(e.target.value)}
                options={[
                  {
                    value: 'Revenue and Encounter Count',
                    label: 'Revenue and Encounter Count',
                  },
                  {
                    value: 'Dollar Total and Count',
                    label: 'Dollar Total and Count',
                  },
                ]}
              />
            </Collapse>
          </Hidden>
          <Hidden mdDown>
            <Collapse in={reportType === 'Provider Revenue'}>
              <Button
                width='100%'
                variant='text'
                onClick={() =>
                  setOpenMenu(prevState =>
                    prevState === 'Revenue and Encounter Count'
                      ? ''
                      : 'Revenue and Encounter Count',
                  )
                }
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor:
                    openMenu === 'Revenue and Encounter Count' ? '#652d9244' : 'transparent',
                  borderRadius: '0',
                  margin: '0',
                  height: '50px',
                }}
              >
                <Typography
                  variant='h5'
                  weight='light'
                  family='poppins'
                  margin='0'
                  color={openMenu === 'Revenue and Encounter Count' ? 'white' : 'primary'}
                >
                  Revenue and Encounter Count
                </Typography>
                {openMenu === 'Revenue and Encounter Count' && (
                  <Box color='white'>
                    <Icon name='chevronRight' />
                  </Box>
                )}
              </Button>
              <Button
                width='100%'
                variant='text'
                onClick={() =>
                  setOpenMenu(prevState =>
                    prevState === 'Dollar Total and Count' ? '' : 'Dollar Total and Count',
                  )
                }
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: '0',
                  backgroundColor:
                    openMenu === 'Dollar Total and Count' ? '#652d9244' : 'transparent',
                  margin: '0',
                  height: '50px',
                }}
              >
                <Typography
                  variant='h5'
                  weight='light'
                  family='poppins'
                  margin='0'
                  color={openMenu === 'Dollar Total and Count' ? 'white' : 'primary'}
                >
                  Dollar Total and Count
                </Typography>
                {openMenu === 'Dollar Total and Count' && (
                  <Box color='white'>
                    <Icon name='chevronRight' />
                  </Box>
                )}
              </Button>
            </Collapse>
            <Collapse in={reportType === 'Plan Spending'}>
              <Button
                width='100%'
                variant='text'
                onClick={() =>
                  setOpenMenu(prevState =>
                    prevState === 'Revenue and Encounter Count'
                      ? ''
                      : 'Revenue and Encounter Count',
                  )
                }
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor:
                    openMenu === 'Revenue and Encounter Count' ? '#652d9244' : 'transparent',
                  borderRadius: '0',
                  margin: '0',
                  height: '50px',
                }}
              >
                <Typography
                  variant='h5'
                  weight='light'
                  family='poppins'
                  margin='0'
                  color={openMenu === 'Revenue and Encounter Count' ? 'white' : 'primary'}
                >
                  Revenue and Encounter Count
                </Typography>
                {openMenu === 'Revenue and Encounter Count' && (
                  <Box color='white'>
                    <Icon name='chevronRight' />
                  </Box>
                )}
              </Button>
              <Button
                width='100%'
                variant='text'
                onClick={() =>
                  setOpenMenu(prevState =>
                    prevState === 'Dollar Total and Count' ? '' : 'Dollar Total and Count',
                  )
                }
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: '0',
                  backgroundColor:
                    openMenu === 'Dollar Total and Count' ? '#652d9244' : 'transparent',
                  margin: '0',
                  height: '50px',
                }}
              >
                <Typography
                  variant='h5'
                  weight='light'
                  family='poppins'
                  margin='0'
                  color={openMenu === 'Dollar Total and Count' ? 'white' : 'primary'}
                >
                  Dollar Total and Count
                </Typography>
                {openMenu === 'Dollar Total and Count' && (
                  <Box color='white'>
                    <Icon name='chevronRight' />
                  </Box>
                )}
              </Button>
            </Collapse>
            <Collapse in={reportType === 'Plan Savings'}>
              <Button
                width='100%'
                variant='text'
                onClick={() =>
                  setOpenMenu(prevState =>
                    prevState === 'Encounter Savings' ? '' : 'Encounter Savings',
                  )
                }
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: openMenu === 'Encounter Savings' ? '#652d9244' : 'transparent',
                  borderRadius: '0',
                  margin: '0',
                  height: '50px',
                }}
              >
                <Typography
                  variant='h5'
                  weight='light'
                  family='poppins'
                  margin='0'
                  color={openMenu === 'Encounter Savings' ? 'white' : 'primary'}
                >
                  Encounter Savings
                </Typography>
                {openMenu === 'Encounter Savings' && (
                  <Box color='white'>
                    <Icon name='chevronRight' />
                  </Box>
                )}
              </Button>
              {/* <Button
                width='100%'
                variant='text'
                onClick={() => setOpenMenu(prevState => prevState === 'Procedure Savings' ? '' : 'Procedure Savings')}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: openMenu === 'Procedure Savings' ? '#652d9244' : 'transparent',
                  borderRadius: '0',
                  margin: '0',
                  height: '50px'
                }}
              >
                <Typography
                  variant='h5'
                  weight='light'
                  family='poppins'
                  margin='0'
                  color={openMenu === 'Procedure Savings' ? 'white' : 'primary'}
                >
                  Procedure Savings
                </Typography>
                {openMenu === 'Procedure Savings' && (
                  <Box
                    color='white'
                  >
                    <Icon name='chevronRight' />
                  </Box>
                )}
              </Button>
              <Button
                width='100%'
                variant='text'
                onClick={() => setOpenMenu(prevState => prevState === 'Medication Savings' ? '' : 'Medication Savings')}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: openMenu === 'Medication Savings' ? '#652d9244' : 'transparent',
                  borderRadius: '0',
                  margin: '0',
                  height: '50px'
                }}
              >
                <Typography
                  variant='h5'
                  weight='light'
                  family='poppins'
                  margin='0'
                  color={openMenu === 'Medication Savings' ? 'white' : 'primary'}
                >
                  Medication Savings
                </Typography>
                {openMenu === 'Medication Savings' && (
                  <Box
                    color='white'
                  >
                    <Icon name='chevronRight' />
                  </Box>
                )}
              </Button> */}
            </Collapse>
          </Hidden>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            width: {
              xs: '100%',
              md: '33.4%',
            },
            overflow: {
              xs: '',
              md: 'hidden',
            },
          }}
        >
          <Hidden mdUp>
            <Collapse in={openMenu === 'Revenue and Encounter Count'}>
              <SelectField
                label='Report Type'
                value={reportTypeSpecific}
                onChange={e => setReportTypeSpecific(e.target.value)}
                options={[
                  { value: 'byService', label: 'By Service' },
                  {
                    value: 'byReferringProvider',
                    label: 'By Referring Provider',
                  },
                  { value: 'byProvider', label: 'By Provider' },
                  { value: 'byPlan', label: 'By Plan' },
                ]}
              />
            </Collapse>
            <Collapse in={openMenu === 'Dollar Total and Count'}>
              <SelectField
                label='Report Type'
                value={reportTypeSpecific}
                onChange={e => setReportTypeSpecific(e.target.value)}
                options={[
                  { value: 'outstandingOrders', label: 'Outstanding Orders' },
                  {
                    value: 'vouchersNotRedeemed',
                    label: 'Vouchers not Redeemed',
                  },
                  {
                    value: 'vouchersRedeemedNotPaid',
                    label: 'Vouchers Redeemed but not Paid',
                  },
                ]}
              />
            </Collapse>
          </Hidden>
          <Hidden mdDown>
            <Collapse in={openMenu === 'Revenue and Encounter Count'}>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'byService' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  color='primary'
                  onClick={() => setReportTypeSpecific('byService')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    By Service
                  </Typography>
                </Button>
              </Box>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'byReferringProvider' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  color='primary'
                  onClick={() => setReportTypeSpecific('byReferringProvider')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    By Referring Provider
                  </Typography>
                </Button>
              </Box>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'byProvider' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  color='primary'
                  onClick={() => setReportTypeSpecific('byProvider')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    By Provider
                  </Typography>
                </Button>
              </Box>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'byPlan' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  color='primary'
                  onClick={() => setReportTypeSpecific('byPlan')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    By Plan
                  </Typography>
                </Button>
              </Box>
            </Collapse>
            <Collapse in={openMenu === 'Dollar Total and Count'}>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'outstandingOrders' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  onClick={() => setReportTypeSpecific('outstandingOrders')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    Outstanding Orders
                  </Typography>
                </Button>
              </Box>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'vouchersNotRedeemed' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  color='primary'
                  onClick={() => setReportTypeSpecific('vouchersNotRedeemed')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    Vouchers not Redeemed
                  </Typography>
                </Button>
              </Box>
              <Box
                width='100%'
                bgcolor={
                  reportTypeSpecific === 'vouchersRedeemedNotPaid' ? '#652d9211' : 'transparent'
                }
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  color='primary'
                  onClick={() => setReportTypeSpecific('vouchersRedeemedNotPaid')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    Vouchers Redeemed but not Paid
                  </Typography>
                </Button>
              </Box>
            </Collapse>
            <Collapse in={openMenu === 'Encounter Savings'}>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'totalSavings' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  onClick={() => setReportTypeSpecific('totalSavings')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    Total Savings
                  </Typography>
                </Button>
              </Box>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'itemizedSavings' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  color='primary'
                  onClick={() => setReportTypeSpecific('itemizedSavings')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    Itemized Savings
                  </Typography>
                </Button>
              </Box>
            </Collapse>
            <Collapse in={openMenu === 'Procedure Savings'}>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'outstandingOrders' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  onClick={() => setReportTypeSpecific('outstandingOrders')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    Total Savings
                  </Typography>
                </Button>
              </Box>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'vouchersNotRedeemed' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  color='primary'
                  onClick={() => setReportTypeSpecific('vouchersNotRedeemed')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    Itemized Savings
                  </Typography>
                </Button>
              </Box>
            </Collapse>
            <Collapse in={openMenu === 'Medication Savings'}>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'outstandingOrders' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  onClick={() => setReportTypeSpecific('outstandingOrders')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    Total Savings
                  </Typography>
                </Button>
              </Box>
              <Box
                width='100%'
                bgcolor={reportTypeSpecific === 'vouchersNotRedeemed' ? '#652d9211' : 'transparent'}
              >
                <Button
                  width='100%'
                  variant='text'
                  size='medium'
                  color='primary'
                  onClick={() => setReportTypeSpecific('vouchersNotRedeemed')}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '30px',
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    Itemized Savings
                  </Typography>
                </Button>
              </Box>
            </Collapse>
          </Hidden>
        </Box>
      </Box>
      {/* Plan Selection Area */}
      <Collapse in={reportType === 'Plan Savings' || reportType === 'Plan Spending'}>
        <Box
          display='flex'
          width='100%'
          marginTop='24px'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box width='80%'>
            <SelectField
              label='Select Plan'
              value={selectedPlan}
              onChange={e => setSelectedPlan(e.target.value)}
              options={plans}
            />
          </Box>
          <Box width='20%' paddingLeft='16px'>
            <Button
              variant='contained'
              color='primary'
              disabled={true}
              style={{
                width: '100%',
                marginTop: '8px',
              }}
            >
              Advanced Filters
            </Button>
          </Box>
        </Box>
      </Collapse>
      {/* Graph Area */}
      <Box display='flex' width='100%'>
        <Box
          id='graphs'
          display={reportTypeSpecific ? 'block' : 'none'}
          width='100%'
          marginTop='24px'
        >
          {/* {loading && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='100%'
            >
              <Typography variant='h4' weight='light' family='poppins'>
                Loading...
              </Typography>
            </Box>
          )} */}
          {graphData && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              width='100%'
            >
              <Typography variant='h4' weight='light' family='poppins'>
                {graphData.title}
                {/* {loading ? '' : graphData.title} */}
              </Typography>
              <ResponsiveBarChart
                dataset={graphData.dataset}
                xAxis={graphData.xAxis}
                yAxis={graphData.yAxis}
                seriesData={graphData.seriesData}
                chartSettings={graphData.chartSettings}
              />
            </Box>
          )}
          {noDataMessage && !loading && (
            <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
              <Typography variant='h4' weight='light' family='poppins'>
                {noDataMessage}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {/* Data Area */}
      {graphData && reportTypeSpecific && (
        <Box width='100%'>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: true,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            sx={{
              minHeight: '200px',
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default ReportsContent
