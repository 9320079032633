import TableCell from '@mui/material/TableCell'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'
import Link from '../stories/Link'

const TableCellToggle = ({ open = false, handleClick, handleClose, row = [] }) => {
  return (
    <>
      <TableCell align='center'>
        <IconButton
          aria-label={open ? 'close row' : 'expand row'}
          size='small'
          onClick={open ? handleClose : handleClick}
        >
          {open ? <Icon name='closeArrow' /> : <Icon name='openArrow' />}
        </IconButton>
      </TableCell>
      <TableCell>
        {row?.organization?.name && (
          <Link
            to={row?.organization?.slug ? `/clinics/${row?.organization?.slug}` : ''}
            display='inline'
            variant='unstyled'
            size='small'
          >
            {row.organization.name || ''}
          </Link>
        )}
      </TableCell>
    </>
  )
}

export default TableCellToggle
