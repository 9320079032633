import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import firebase from 'firebase/compat/app'
import Collapse from '@mui/material/Collapse'
import Typography from '../stories/Typography'
import Divider from '../stories/Divider'
import OrderChatView from './OrderChatView'
import Button from '../stories/Button'
import { useCurrentProfile } from '../context/CurrentUser'
import OrderNoteView from './OrderNoteView'
import Icon from '../stories/Icons'
import { getDiRxOrderStatus } from '../firebase/functions'
import { formatDateForUI } from '@app/libs/formatters'

const PaymentLinkAndChat = ({ order }) => {
  const { id: orderID, status } = order
  const { isProvider } = useCurrentProfile()

  return (
    <>
      {status === 'paid' && (
        <Box>
          <Divider
            style={{
              margin: '10px 0',
              backgroundColor: `${status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          />
          <OrderChatView chatID={orderID} order={order} />
        </Box>
      )}
      {(status === 'unpaid' || status === 'failed') && (
        <>
          {!isProvider && order?.checkoutLink && (
            <>
              <Divider
                style={{
                  margin: '10px 0',
                  backgroundColor: `${status === 'paid' ? '#009124' : '#d32f2f'}`,
                }}
              />
              <Typography variant='body1' gutterBottom style={{ color: '#d32f2f' }}>
                Price: {order?.details?.price && `$${parseFloat(order?.details?.price).toFixed(2)}`}
              </Typography>
              <Button
                onClick={() => {
                  window.open(order.checkoutLink, '_blank')
                }}
                variant='outlined'
                severity='danger'
                size='small'
                style={{
                  color: '#fff',
                  backgroundColor: `${status === 'paid' ? '#009124' : '#d32f2f'}`,
                  border: `${status === 'paid' ? 'solid 1px #009124' : 'solid 1px #d32f2f'}`,
                }}
              >
                <Typography variant='body1' family='poppins' align='center'>
                  Pay Now
                </Typography>
              </Button>
            </>
          )}
        </>
      )}
    </>
  )
}

PaymentLinkAndChat.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    details: PropTypes.shape({
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    checkoutLink: PropTypes.string,
  }).isRequired,
}

const SubOrderModal = ({ order, subOrder = '', open, setOpen }) => {
  useEffect(() => {
    if (open) {
      // Disable scrolling when the modal is open
      document.body.style.overflow = 'hidden'
    } else {
      // Enable scrolling when the modal is closed
      document.body.style.overflow = 'auto'
    }
    // Clean up the effect
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [open])

  return (
    <Box
      position='fixed'
      top='0'
      left='0'
      right='0'
      bottom='0'
      display={open ? 'flex' : 'none'}
      justifyContent='center'
      alignItems='center'
      padding='20px'
      zIndex='10000'
      bgcolor='rgba(0, 0, 0, 0.25)'
    >
      <Box
        position='relative'
        display='flex'
        flexDirection='column'
        width='90vw'
        height='90vh'
        padding='20px'
        bgcolor='#fff'
        borderRadius='4px'
        boxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)'
      >
        <Button
          variant='text'
          size='small'
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            color: '#000',
            minWidth: '0px',
            minHeight: '0px',
            borderRadius: '50%',
            padding: '8px',
          }}
          onClick={() => setOpen(false)}
        >
          <Icon name='close' />
        </Button>
        <Typography variant='h5' family='poppins'>
          {subOrder}
        </Typography>
      </Box>
    </Box>
  )
}

SubOrderModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  subOrder: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

const ShippingInfo = ({ orderStatus, trackingInfo, errorMessage }) => {
  console.log(trackingInfo, 'trackingInfo')
  console.log(orderStatus, 'orderStatus')
  console.log(errorMessage, 'errorMessage')

  if (errorMessage) {
    return <Typography variant='body1'>{errorMessage}</Typography>
  }

  const { trackingNumber, carrier, status, message } = trackingInfo || {}
  let statusMsg = status

  if (message && message === 'Order Not Found') {
    return (
      <Typography variant='body1'>
        Please contact your case manager for more information.
      </Typography>
    )
  }

  if (status === 'pending') {
    statusMsg = 'Pharmacy packaging order.'
  }
  return (
    <>
      <Typography
        variant='body1'
        gutterBottom
        style={{
          color: `${orderStatus === 'paid' ? '#009124' : '#d32f2f'}`,
        }}
      >
        Tracking Number: {trackingNumber || '---'}
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        style={{
          color: `${orderStatus === 'paid' ? '#009124' : '#d32f2f'}`,
        }}
      >
        Carrier: {carrier || '---'}
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        style={{
          color: `${orderStatus === 'paid' ? '#009124' : '#d32f2f'}`,
        }}
      >
        Status: {statusMsg || '---'}
      </Typography>
    </>
  )
}

ShippingInfo.propTypes = {
  orderStatus: PropTypes.string.isRequired,
  trackingInfo: PropTypes.shape({
    trackingNumber: PropTypes.string,
    carrier: PropTypes.string,
    status: PropTypes.string,
  }),
}

const MedCard = ({ order, gridVariants, styleVariants }) => {
  const { status, intercom } = order

  const [checkingForShipping, setCheckingForShipping] = useState(false)
  const [shippingInfo, setShippingInfo] = useState(null)
  const [fetchingShippingStatusError, setFetchingShippingStatusError] = useState(null)

  const dirxOrder = order?.details?.pharmacy?.name === 'dirx'

  const handleCheckShipping = async orderID => {
    setCheckingForShipping(true)
    setShippingInfo(false)
    setFetchingShippingStatusError(false)
    // Check for shipping info
    try {
      const shippingInfo = await getDiRxOrderStatus(orderID)
      console.log(shippingInfo, 'shippingInfo')

      if (!shippingInfo) {
        console.warn('No shipping info found')
        setFetchingShippingStatusError('Shipping pending.')
      }

      // if there was an expected error we can check otherwise it should be good to go
      if (shippingInfo?.error) {
        console.warn('Error checking for shipping info')
        console.warn(shippingInfo.error)
        setFetchingShippingStatusError('Please message your case manager for more information.')
      } else if (shippingInfo?.MESSAGE === 'Order Not Found') {
        console.warn('** ENF')
        setFetchingShippingStatusError('Please message your case manager for more information.')
      } else {
        setShippingInfo(shippingInfo)
      }
    } catch (error) {
      console.warn('Error checking for shipping info')
      console.warn(error)
      setFetchingShippingStatusError(
        `Whoops! We're having trouble completing your request. Please contact to support if this issue persists.`,
      )
    }
    setCheckingForShipping(false)
  }

  return (
    <Grid item {...gridVariants} key={order.id} {...styleVariants}>
      <Box
        style={{
          position: 'relative',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '4px',
          padding: '20px',
          height: '100%',
          width: '100%',
          marginBottom: '16px',
          border: `${order?.status === 'paid' ? 'solid 1px #009124' : 'solid 1px #d32f2f'}`,
          backgroundColor: `${order?.status === 'paid' ? '#e3ffea' : '#ffe8e3'}`,
        }}
      >
        <Box>
          <Typography
            variant='h5'
            gutterBottom
            style={{
              color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          >
            <b>Case Management Info</b>
          </Typography>
          {intercom?.ticket?.attributes?.title || intercom?.ticket?.attributes?.status ? (
            <>
              {intercom?.ticket?.attributes?.title && (
                <Typography
                  variant='body1'
                  gutterBottom
                  style={{
                    color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                  }}
                >
                  Title: {intercom?.ticket?.attributes?.title}
                </Typography>
              )}
              {intercom?.ticket?.attributes?.status && (
                <Typography
                  variant='body1'
                  gutterBottom
                  style={{
                    color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                  }}
                >
                  Status: {intercom?.ticket?.attributes?.status}
                </Typography>
              )}
            </>
          ) : (
            <Typography
              variant='body1'
              gutterBottom
              style={{
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            >
              Info pending...
            </Typography>
          )}
          <Divider
            style={{
              margin: '10px 0',
              backgroundColor: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          />
          <Typography
            variant='h5'
            gutterBottom
            style={{
              color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          >
            <b>Created on: </b>
            {formatDateForUI(order.timestamp, true) || '---'}
          </Typography>
          <Divider
            style={{
              margin: '10px 0',
              backgroundColor: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          />
          {(order?.patient?.firstname || order?.patient?.lastname) && (
            <Typography
              style={{
                textTransform: 'capitalize',
                marginBottom: '0px',
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
              variant='h4'
              gutterBottom
            >
              {order?.patient?.firstname} {order?.patient?.lastname}
            </Typography>
          )}
          {!(order?.patient?.firstname || order?.patient?.lastname) &&
            (order?.patient?.email || order?.patient?.phone) && (
              <Typography
                style={{
                  textTransform: 'capitalize',
                  marginBottom: '0px',
                  color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                }}
                variant='h4'
                gutterBottom
              >
                {order?.patient?.email || order?.patient?.phone}
              </Typography>
            )}
          <Typography
            variant='body1'
            gutterBottom
            style={{
              paddingLeft: '10px',
              color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          >
            {formatDateForUI(order?.patient?.dob || order?.patient?.birthday) || '---'}
          </Typography>
          <Divider
            style={{
              margin: '10px 0',
              backgroundColor: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          />
        </Box>
        <Box
          style={{
            position: 'relative',
          }}
        >
          <Typography
            style={{
              textTransform: 'capitalize',
              marginBottom: '20px',
              color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
            variant='h4'
            gutterBottom
          >
            {order?.orderType}
          </Typography>
          <Box
            style={{
              position: 'absolute',
              top: '0px',
              right: '0px',
            }}
          >
            <Typography
              variant='h4'
              gutterBottom
              style={{
                textTransform: 'capitalize',
                color: `${status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            >
              {status}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            paddingLeft: '10px',
          }}
        >
          <Typography
            variant='body1'
            gutterBottom
            style={{
              color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          >
            {order?.details?.medicationName} | {order?.details?.strength} | {order?.details?.count}{' '}
            Units
          </Typography>
          <Typography
            variant='body1'
            gutterBottom
            style={{
              color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          >
            Refills Allowed: {order?.details?.refills?.allowed || '0'}
          </Typography>
          <Typography
            variant='body1'
            gutterBottom
            style={{
              color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          >
            Refills Filled: {order?.details?.refills?.filled || '0'}
          </Typography>
        </Box>
        {/* There will not be tracking info unless the order has been paid for */}
        {/* Shipping / Tracking Info */}
        {order?.status === 'paid' && dirxOrder && (
          <>
            <Divider
              style={{
                margin: '10px 0',
                backgroundColor: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            />
            <Typography
              variant='h5'
              gutterBottom
              style={{
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            >
              Tracking Info
              <Button
                variant='text'
                size='small'
                disabled={checkingForShipping}
                onClick={() => {
                  handleCheckShipping(order.id)
                }}
              >
                <Icon
                  name='sync'
                  sx={
                    checkingForShipping && {
                      animation: 'spin 2s linear infinite',
                      '@keyframes spin': {
                        '0%': {
                          transform: 'rotate(360deg)',
                        },
                        '100%': {
                          transform: 'rotate(0deg)',
                        },
                      },
                    }
                  }
                />
              </Button>
            </Typography>
            <Box
              style={{
                paddingLeft: '10px',
              }}
            >
              <ShippingInfo
                orderStatus={order?.status}
                trackingInfo={shippingInfo || order.trackingInfo}
                errorMessage={fetchingShippingStatusError}
              />
            </Box>
          </>
        )}
        {/* End Shipping / Tracking Info */}
        <PaymentLinkAndChat order={order} />
        <Divider
          style={{
            margin: '10px 0',
            backgroundColor: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
          }}
        />
        <OrderNoteView order={order} orderID={order.id} status={order?.status} />
        {order?.details?.bundle && (
          <Box
            width='100%'
            position='relative'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Button
              variant='text'
              size='small'
              style={{
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                minWidth: '0px',
                borderRadius: '50%',
                padding: '5px',
              }}
            >
              <Icon name='openArrow' />
            </Button>
          </Box>
        )}
      </Box>
    </Grid>
  )
}

MedCard.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    orderType: PropTypes.string.isRequired,
    patient: PropTypes.shape({
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      dob: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(firebase.firestore.Timestamp),
      ]),
      email: PropTypes.string,
      phone: PropTypes.string,
    }).isRequired,
    details: PropTypes.shape({
      medicationName: PropTypes.string.isRequired,
      strength: PropTypes.string.isRequired,
      count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      refills: PropTypes.shape({
        allowed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        filled: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      bundle: PropTypes.bool,
    }).isRequired,
    trackingInfo: PropTypes.shape({
      estimatedDelivery: PropTypes.string,
      status: PropTypes.string,
      trackingNumber: PropTypes.string,
      carrier: PropTypes.string,
    }),
    timestamp: PropTypes.instanceOf(firebase.firestore.Timestamp).isRequired,
    intercom: PropTypes.shape({
      ticket: PropTypes.shape({
        attributes: PropTypes.shape({
          status: PropTypes.string.isRequired,
          title: PropTypes.string,
        }).isRequired,
      }).isRequired,
    }),
  }).isRequired,
  gridVariants: PropTypes.shape({
    xs: PropTypes.number.isRequired,
    sm: PropTypes.number.isRequired,
    md: PropTypes.number.isRequired,
    lg: PropTypes.number.isRequired,
    xl: PropTypes.number.isRequired,
  }).isRequired,
  styleVariants: PropTypes.shape({
    style: PropTypes.shape({
      marginBottom: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

const RefCard = ({ order, gridVariants, styleVariants }) => {
  const { status, intercom } = order

  const [open, setOpen] = useState(false)
  // const [subOrderDetails, setSubOrderDetails] = useState(null)
  // const [openSubOrder, setOpenSubOrder] = useState(false)

  return (
    <Grid item {...gridVariants} {...styleVariants} key={order.id}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'
        style={{
          position: 'relative',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '4px',
          padding: '20px',
          height: '100%',
          width: '100%',
          marginBottom: '16px',
          border: `${order?.status === 'paid' ? 'solid 1px #009124' : 'solid 1px #d32f2f'}`,
          backgroundColor: `${order?.status === 'paid' ? '#e3ffea' : '#ffe8e3'}`,
        }}
      >
        <Box marginBottom='auto'>
          <Box>
            <Typography
              variant='h5'
              gutterBottom
              style={{
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            >
              <b>Case Management Info</b>
            </Typography>
            {intercom?.ticket?.attributes?.title || intercom?.ticket?.attributes?.status ? (
              <>
                {intercom?.ticket?.attributes?.title && (
                  <Typography
                    variant='body1'
                    gutterBottom
                    style={{
                      color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                    }}
                  >
                    Title: {intercom?.ticket?.attributes?.title}
                  </Typography>
                )}
                {intercom?.ticket?.attributes?.status && (
                  <Typography
                    variant='body1'
                    gutterBottom
                    style={{
                      color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                    }}
                  >
                    Status: {intercom?.ticket?.attributes?.status}
                  </Typography>
                )}
              </>
            ) : (
              <Typography
                variant='body1'
                gutterBottom
                style={{
                  color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                }}
              >
                Info pending...
              </Typography>
            )}
            <Divider
              style={{
                margin: '10px 0',
                backgroundColor: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            />
            <Typography
              variant='h5'
              gutterBottom
              style={{
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            >
              <b>Created on: </b>
              {formatDateForUI(order.timestamp, true) || '---'}
            </Typography>
            <Divider
              style={{
                margin: '10px 0',
                backgroundColor: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            />{' '}
            {(order?.patient?.firstname || order?.patient?.lastname) && (
              <Typography
                style={{
                  textTransform: 'capitalize',
                  marginBottom: '0px',
                  color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                }}
                variant='h4'
                gutterBottom
              >
                {order?.patient?.firstname} {order?.patient?.lastname}
              </Typography>
            )}
            {!(order?.patient?.firstname || order?.patient?.lastname) &&
              (order?.patient?.email || order?.patient?.phone) && (
                <Typography
                  style={{
                    textTransform: 'capitalize',
                    marginBottom: '0px',
                    overflowWrap: 'break-word',
                    color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                  }}
                  variant='body1'
                  gutterBottom
                >
                  {order?.patient?.email || order?.patient?.phone}
                </Typography>
              )}
            <Typography
              variant='body1'
              gutterBottom
              style={{
                paddingLeft: '10px',
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            >
              {formatDateForUI(order?.patient?.dob || order?.patient?.birthday) || '---'}
            </Typography>
            <Divider
              style={{
                margin: '10px 0',
                backgroundColor: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            />
          </Box>
          <Box
            style={{
              position: 'relative',
            }}
          >
            <Typography
              style={{
                textTransform: 'capitalize',
                marginBottom: '20px',
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
              variant='h4'
              gutterBottom
            >
              {order?.orderType}
            </Typography>
            <Box
              style={{
                position: 'absolute',
                top: '0px',
                right: '0px',
              }}
            >
              <Typography
                variant='h4'
                gutterBottom
                style={{
                  textTransform: 'capitalize',
                  color: `${status === 'paid' ? '#009124' : '#d32f2f'}`,
                }}
              >
                {status}
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              paddingLeft: '10px',
            }}
          >
            <Typography
              variant='body1'
              gutterBottom
              style={{
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            >
              Service: <b>{order?.details?.serviceName}</b>
            </Typography>
            <Typography
              variant='body1'
              gutterBottom
              style={{
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
              }}
            >
              Clinic: <b>{order?.referred?.organizationName}</b>
            </Typography>
          </Box>
          <PaymentLinkAndChat order={order} />
          <Divider
            style={{
              margin: '10px 0',
              backgroundColor: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
            }}
          />
          <OrderNoteView order={order} orderID={order.id} status={order?.status} />
        </Box>
        {order?.details?.bundle && (
          <Box
            width='100%'
            position='relative'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <Collapse in={open} timeout='auto' unmountOnExit style={{ width: '100%' }}>
              <Box width='100%' display='flex' flexDirection='column' justifyContent='center'>
                <Divider
                  style={{
                    margin: '4px 0',
                    backgroundColor: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                  }}
                />

                <>
                  {order?.subOrders &&
                    Array.isArray(order?.subOrders) &&
                    order?.subOrders?.map((eachSubOrder, index) => {
                      return (
                        <>
                          <Typography
                            variant='body1'
                            weight='bold'
                            style={{
                              color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                              fontSize: '.85rem',
                              marginBottom: '-4px',
                              marginLeft: '-4px',
                            }}
                          >
                            {eachSubOrder?.details?.serviceData?.organizationName}
                          </Typography>
                          <Typography
                            key={eachSubOrder?.id}
                            variant='body1'
                            style={{
                              color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                              fontSize: '1.2rem',
                            }}
                          >
                            {eachSubOrder?.details?.serviceName}
                          </Typography>
                          <Typography
                            variant='body1'
                            gutterBottom
                            style={{
                              color: `${eachSubOrder?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                            }}
                          >
                            Status: {eachSubOrder?.intercom?.ticket?.attributes?.status}
                          </Typography>
                          <Divider
                            style={{
                              margin: '4px 0 8px 0',
                              backgroundColor: `${eachSubOrder?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                            }}
                          />
                          <OrderChatView chatID={eachSubOrder?.id} order={eachSubOrder} />
                          <Box height='8px' />
                          <OrderNoteView
                            order={eachSubOrder}
                            orderID={eachSubOrder?.id}
                            status={eachSubOrder?.status}
                            noDownload
                          />
                          <Divider
                            style={{
                              margin: '8px 0 4px 0',
                              backgroundColor: `${eachSubOrder?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                            }}
                          />
                        </>
                      )
                    })}
                </>
              </Box>
            </Collapse>
            <Button
              variant='text'
              size='small'
              style={{
                color: `${order?.status === 'paid' ? '#009124' : '#d32f2f'}`,
                padding: '5px',
              }}
              onClick={() => setOpen(!open)}
            >
              <Typography variant='body1' family='poppins' align='center'>
                {open ? 'Hide' : 'Show'} Bundle
              </Typography>
              <Icon name={open ? 'openArrow' : 'closeArrow'} />
            </Button>
          </Box>
        )}
        {/* <SubOrderModal
          order={order}
          subOrder={subOrderDetails}
          open={openSubOrder}
          setOpen={setOpenSubOrder}
        /> */}
      </Box>
    </Grid>
  )
}

RefCard.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    orderType: PropTypes.string.isRequired,
    patient: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      dob: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(firebase.firestore.Timestamp),
      ]),
      email: PropTypes.string,
      phone: PropTypes.string,
    }).isRequired,
    referred: PropTypes.shape({
      organizationName: PropTypes.string,
    }),
    details: PropTypes.shape({
      serviceName: PropTypes.string.isRequired,
      serviceData: PropTypes.shape({
        bundledServiceNames: PropTypes.arrayOf(PropTypes.string),
        bundleComponentIDs: PropTypes.arrayOf(PropTypes.string),
        clinicNames: PropTypes.arrayOf(PropTypes.string),
      }),
      bundle: PropTypes.bool,
    }).isRequired,
    timestamp: PropTypes.instanceOf(firebase.firestore.Timestamp).isRequired,
    intercom: PropTypes.shape({
      ticket: PropTypes.shape({
        attributes: PropTypes.shape({
          title: PropTypes.string,
          status: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  }).isRequired,
  gridVariants: PropTypes.shape({
    xs: PropTypes.number.isRequired,
    sm: PropTypes.number.isRequired,
    md: PropTypes.number.isRequired,
    lg: PropTypes.number.isRequired,
    xl: PropTypes.number.isRequired,
  }).isRequired,
  styleVariants: PropTypes.shape({
    style: PropTypes.shape({
      marginBottom: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

const OrderDetailsCard = ({ order, variant }) => {
  const gridVariants = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    ...(variant === 'grid' && {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 4,
      xl: 4,
    }),
  }

  const styleVariants = {
    style: {
      marginBottom: `${variant === 'grid' ? '0px' : '16px'}`,
    },
  }

  const CompToRender = () => {
    switch (order?.orderType) {
      case 'medication':
        return <MedCard order={order} gridVariants={gridVariants} styleVariants={styleVariants} />
      case 'referral':
        return <RefCard order={order} gridVariants={gridVariants} styleVariants={styleVariants} />
      default:
        return <></>
    }
  }

  return <CompToRender key={order.id} />
}

OrderDetailsCard.propTypes = {
  order: PropTypes.shape({
    orderType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  variant: PropTypes.string,
}

export default OrderDetailsCard
