import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Heading from '../Heading'
import Avatar from '../Avatar'

const spinning = keyframes`
  0% {
    transform:  rotate(0deg) translateX(6px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(6px) rotate(-360deg);
  }
`
const ellipsis = keyframes`
  0% {
    width: 0px; 
  }
  100% {
    width: 16px; 
  }
`
const LaoderWrapper = styled(Box)`
  text-align: center;
`

const SvgWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  text-align: center;
  .spinner {
    animation: ${spinning} 3s linear infinite;
  }
`
const LoaderText = styled(Heading)`
  position: relative;
  display: inline;
  &:after {
    content: '...';
    position: absolute;
    left: 100%;
    overflow: hidden;
    display: inline-block;
    text-align: left;
    vertical-align: bottom;
    animation: ${ellipsis} steps(4, end) 1800ms infinite;
    width: 0px;
  }
`

const Loader = ({ text = 'Fetching your services.', variant = 'searching' }) => (
  <LaoderWrapper>
    <Avatar size='medium' position='top' src='' alt='searching'>
      <SvgWrapper>
        {variant === 'searching' ? (
          <svg viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M64 127.998c35.345 0 63.998-28.653 63.998-63.999C127.998 28.653 99.345 0 63.999 0 28.653 0 0 28.653 0 64c0 35.345 28.653 63.998 64 63.998z'
              fill='#7F52A3'
            />
            <path
              d='M26.283 64c0-30.841 21.825-56.588 50.867-62.65A64.338 64.338 0 0064 .003C28.653.002 0 28.655 0 64 0 99.347 28.653 128 64 128c4.504 0 8.915-.469 13.156-1.354C48.114 120.584 26.283 94.841 26.283 64z'
              fill='#652D92'
            />
            <g className='spinner'>
              <path
                d='M59.913 73.802l-4.632 5.366-1.113.261-4.087-3.528.072-1.16 4.632-5.366c2.785.966 3.88 1.87 5.128 4.427z'
                fill='#F5F8F9'
              />
              <path
                d='M57.212 71.748c11.331 9.781 28.447 8.525 38.229-2.807s8.525-28.448-2.807-38.23c-11.332-9.781-28.448-8.525-38.23 2.807-9.781 11.332-8.525 28.448 2.807 38.23z'
                fill='#95C8D2'
              />
              <path
                d='M60.945 67.422c8.943 7.72 22.45 6.727 30.17-2.216 7.72-8.943 6.727-22.45-2.216-30.17-8.942-7.719-22.45-6.727-30.17 2.216-7.718 8.943-6.727 22.45 2.216 30.17z'
                fill='#F5F8F9'
              />
              <path
                d='M65.588 70.478a21.352 21.352 0 01-4.642-3.056c-8.928-7.707-9.923-21.241-2.216-30.17 7.706-8.928 21.242-9.922 30.17-2.215a21.35 21.35 0 013.701 4.145c-8.505-4.122-19.066-2.214-25.528 5.272-6.462 7.486-6.806 18.213-1.485 26.024z'
                fill='#E8EDF2'
              />
              <path
                d='M32.52 100.354a6.678 6.678 0 009.418-.692l15.356-17.79a1.152 1.152 0 00-.119-1.626l-8.365-7.22a1.152 1.152 0 00-1.626.118L31.827 90.935a6.678 6.678 0 00.692 9.419z'
                fill='#2F3676'
              />
              <path
                d='M35.578 101.849a6.678 6.678 0 01-3.75-10.914l15.357-17.79a1.152 1.152 0 011.625-.12l4.113 3.55-16.11 18.663a6.674 6.674 0 00-1.235 6.611z'
                fill='#191847'
              />
            </g>
          </svg>
        ) : (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={400}
            height={400}
            style={{
              width: '100%',
              height: '100%',
              transform: 'translate3d(0,0,0)',
              contentVisibility: 'visible',
            }}
          >
            <defs>
              <linearGradient
                id='b'
                x1={0}
                x2={100}
                y1={0}
                y2={0}
                gradientUnits='userSpaceOnUse'
                spreadMethod='pad'
              >
                <stop offset='0%' stopColor='#3F61AC' />
                <stop offset='39%' stopColor='#80469A' />
                <stop offset='100%' stopColor='#C12B87' />
              </linearGradient>
              <clipPath id='a'>
                <path d='M0 0h400v400H0z' />
              </clipPath>
            </defs>
            <g clipPath='url(#a)'>
              <path
                fill='none'
                stroke='url(#b)'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={38}
                d='M0-87c48.015 0 87 38.985 87 87S48.015 87 0 87-87 48.015-87 0c0-4.95.414-9.804 1.21-14.529'
                style={{
                  display: 'block',
                }}
                transform='rotate(105.99 24.631 175.369) scale(1.46)'
              />
            </g>
          </svg>
        )}
      </SvgWrapper>
    </Avatar>
    <br />
    <LoaderText variant='h4' component='p' align='center'>
      {text}
    </LoaderText>
  </LaoderWrapper>
)

Loader.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
}

export default Loader
