import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiAvatar from '@mui/material/Avatar'

const StyledAvatar = styled(MuiAvatar)`
  color: ${props => props.theme.palette.background.paper};
  background: ${props => props.theme.palette.primary.dark};
  box-shadow: ${props => props.theme.shadows[2]};
  width: ${props => props.theme.spacing(12)};
  height: ${props => props.theme.spacing(12)};
  font-weight: 500;
  img,
  svg {
    transition: all 0.25s ease;
  }
  ${props =>
    props.coloring === 'negative' &&
    css`
      color: ${props.theme.palette.primary.dark};
      background: ${props.theme.palette.background.paper};
    `}
  ${props =>
    props.size === 'tiny' &&
    css`
      width: ${props.theme.spacing(7)};
      height: ${props.theme.spacing(7)};
    `}
  ${props =>
    props.size === 'small' &&
    css`
      width: ${props.theme.spacing(10)};
      height: ${props.theme.spacing(10)};
    `}
  ${props =>
    props.size === 'medium' &&
    css`
      width: ${props.theme.spacing(12)};
      height: ${props.theme.spacing(12)};
    `}

  ${props =>
    props.size === 'mediumlarge' &&
    css`
      width: ${props.theme.spacing(14)};
      height: ${props.theme.spacing(14)};
    `}
  ${props =>
    props.size === 'large' &&
    css`
      width: ${props.theme.spacing(14)};
      height: ${props.theme.spacing(14)};
      ${props.theme.breakpoints.up('md')} {
        width: ${props.theme.spacing(20)};
        height: ${props.theme.spacing(20)};
      }
    `}
  ${props =>
    props.size === 'huge' &&
    css`
      width: ${props.theme.spacing(24)};
      height: ${props.theme.spacing(24)};
      font-size: 3rem;
      font-weight: 600;
      ${props.theme.breakpoints.up('md')} {
        width: ${props.theme.spacing(32)};
        height: ${props.theme.spacing(32)};
      }
    `}
  ${props =>
    props.position === 'top' &&
    css`
      margin: 0px auto ${props.theme.spacing(2)};
    `}
  ${props =>
    props.position === 'left' &&
    css`
      margin-right: ${props.theme.spacing(2)};
    `}
`

const Avatar = ({ children, size = 'medium', coloring = '', ...props }) => (
  <StyledAvatar variant='circular' size={size} coloring={coloring} position='' {...props}>
    {children}
  </StyledAvatar>
)

Avatar.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  position: PropTypes.string,
  coloring: PropTypes.string,
}

export default Avatar
