export const dateOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

export const handleDownload = async (imageUrl, fileName) => {
  try {
    const response = await fetch(imageUrl)
    const blob = await response.blob()
    const url = window.URL.createObjectURL(new Blob([blob]))
    const anchorElement = document.createElement('a')
    anchorElement.href = url
    anchorElement.setAttribute('download', fileName || 'attachment')
    anchorElement.target = '_blank'
    document.body.appendChild(anchorElement)

    anchorElement.click()
    anchorElement?.parentNode?.removeChild(anchorElement)
  } catch (error) {
    console.error(error)
  }
  // const link = document.createElement('a')
  // link.href = imageUrl
  // link.download = 'file'
  // link.target = '_blank'
  // link.click()
}
