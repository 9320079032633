import TextField from '../TextField'

const DateField = props => (
  <TextField
    type='date'
    display='standard'
    InputLabelProps={{
      shrink: true,
    }}
    {...props}
  />
)

export default DateField
