import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Heading from '../Heading'
import Button from '../Button'
import Divider from '../Divider'
import Link from '../Link'

const CheckoutStatus = ({ status, orderId }) => {
  return (
    <div id='checkoutStatusBox'>
      {status === 'succeeded' ? (
        <Box>
          <Box mb={4}>
            <Heading variant='h2' component='h2'>
              Thanks for your order!
            </Heading>
            {orderId && (
              <Typography variant='body1' display='inline'>
                Order #: {orderId}
              </Typography>
            )}
            <Divider spacing='vertical' />
          </Box>
          <Box mb={4}>
            <Typography variant='body1'>
              We notified the provider organization and they are looking forward to caring for you!
              <br />
              You will receive an order confirmation email with purchase information.
              <br />
              <br />
              Access your dashboard to view service information, provider organization and
              scheduling instructions. To proceed, follow the scheduling/walk-in instructions listed
              in your{' '}
              <Link to='/dashboard' display='inline'>
                <i>dashboard</i>
              </Link>{' '}
              and be sure to present your voucher(s) to the organization to redeem your care.
            </Typography>
          </Box>
          <Box display='flex' justifyContent='flex-end' mt={4}>
            <Button to='/dashboard'>dashboard</Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box mb={4}>
            <Heading variant='h2' component='h2'>
              Your payment is processing!
            </Heading>
            {orderId && (
              <Typography variant='body1' display='inline'>
                Order #: {orderId}
              </Typography>
            )}
            <Divider spacing='vertical' />
          </Box>
          <Box mb={4}>
            <Typography variant='body1'>
              We will notify the provider organization.
              <br />
              You will receive an order confirmation email with purchase information once confirmed.
              <br />
              <br />
              Access your dashboard after your payment processes to view service information,
              provider organization and scheduling instructions. To proceed, follow the
              scheduling/walk-in instructions listed in your{' '}
              <Link to='/dashboard' display='inline'>
                <i>dashboard</i>
              </Link>{' '}
              and be sure to present your voucher(s) to the organization to redeem your care.
            </Typography>
          </Box>
          <Box display='flex' justifyContent='flex-end' mt={4}>
            <Button to='/dashboard'>dashboard</Button>
          </Box>
        </Box>
      )}
    </div>
  )
}

CheckoutStatus.propTypes = {
  status: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
}

export default CheckoutStatus
