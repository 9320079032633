import { useRef, forwardRef, useImperativeHandle } from 'react'
import MaskedInput from 'react-text-mask'
import TextField from '../TextField'

const MASK = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

const MyInputComponent = forwardRef((props, ref) => {
  const inputRef = useRef(null)

  // implement `InputElement` interface
  useImperativeHandle(ref, () => ({
    focus: () => {
      // logic to focus the rendered component from 3rd party belongs here
      inputRef.current.focus()
    },
    // hiding the value e.g. react-stripe-elements
  }))

  // `Component` will be your `SomeThirdPartyComponent` from below
  return <MaskedInput ref={inputRef} mask={MASK} {...props} />
})

const PhoneInput = props => (
  <TextField
    {...props}
    InputProps={{
      inputComponent: MyInputComponent,
    }}
    InputLabelProps={{ shrink: true }}
  />
)

export default PhoneInput
