import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

const NotificationText = ({ message = undefined, severity = 'success', ...props }) => (
  <Box color={`${severity}.dark`} role='alert' fontSize='small' {...props}>
    {message}
  </Box>
)

NotificationText.propTypes = {
  severity: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  message: PropTypes.string,
}

export default NotificationText
