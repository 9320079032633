import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useVouchers } from '../context/Vouchers'
import useInput from '../hooks/useInput'
import Form from '../components/ProviderVouchersForm'
import useVouchersFilter from '@app/hooks/useVouchersFilter'

const ProviderVouchersForm = ({ uid }) => {
  const [date, setDate] = useInput()
  const [query, setQuery] = useInput()
  const [status, setStatus] = useInput('purchased')
  const { changeVouchers } = useVouchers()
  const { fetchVouchersFilter } = useVouchersFilter()

  useEffect(() => {
    if (uid) {
      fetchVouchersFilter({ userInputQuery: query, date, status })
    }
  }, [uid, query, date, changeVouchers, status])

  return (
    <Form
      onQueryChange={setQuery}
      onDateChange={setDate}
      onStatusChange={setStatus}
      currentStatus={status}
    />
  )
}

ProviderVouchersForm.propTypes = {
  uid: PropTypes.string.isRequired,
}

export default ProviderVouchersForm
