import { createContext, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from '../firebase/auth'
import useUser from '../hooks/useUser'
import { collection } from '../firebase/firestore'

const CurrentUser = createContext()
const CurrentProfile = createContext()
const CurrentClinic = createContext()

export const useCurrentUser = () => useContext(CurrentUser)
export const useCurrentProfile = () => useContext(CurrentProfile)
export const useCurrentClinic = () => useContext(CurrentClinic)

export const CurrentUserProvider = ({ children }) => {
  const [user, profile, login, logout, refresh, refreshProfile, clinic] = useUser()

  // when a user is changed or on mount, add a profile monitor using onsnapshot to call refreshProfile
  useEffect(() => {
    if (user?.uid) {
      const profileRef = collection('profiles').doc(user.uid)

      // using onSnapshot to listens for real-time changes to the firestore profile collection. subscribe is only for auth
      const unsubscribeProfile = profileRef.onSnapshot(
        snapshot => {
          if (snapshot.exists) {
            console.log('******** Profile doc changed ********') // eslint-disable-line
            refreshProfile() // refresh profile when there is a change in Firestore
          } else {
            console.log('there is no profile not found, log in?')
          }
        },
        error => {
          console.error('!!!error subscribing to profile changes:', error)
        },
      )

      // cleanup the when its unmountde
      return () => unsubscribeProfile()
    }
  }, [user?.uid, refreshProfile])

  // @TODO outdated copies of user are accessed and not monitoed, refresh is being called twice
  // the subscribe is not being cleaned up on mount, no need to refersh profile on log in/out
  useEffect(() => {
    // this is only good for auth subscriptions.
    subscribe(value => {
      if (value) {
        login(value)
          .then(() => {
            console.log('******** User logged in ********') // eslint-disable-line
            refresh()
            refreshProfile().then(() => {
              console.log('******** Profile refreshed ********') // eslint-disable-line
              if (window.Intercom) {
                window.Intercom('trackEvent', 'Login', {
                  email: user?.email || value?.email || '',
                  phone: user?.phoneNumber || value?.phoneNumber || '',
                  uid: user?.uid || value?.uid || '',
                })
              }
            })
          })
          .catch(error => {
            console.log('******** There was an error ********') // eslint-disable-line
            console.log(error.message) // eslint-disable-line
          })
      } else {
        logout()
      }
    })
  }, [login, logout])

  return (
    <CurrentUser.Provider value={{ refresh, refreshProfile, ...user }}>
      <CurrentProfile.Provider value={profile}>
        <CurrentClinic.Provider value={clinic}>{children}</CurrentClinic.Provider>
      </CurrentProfile.Provider>
    </CurrentUser.Provider>
  )
}

CurrentUserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CurrentUser
