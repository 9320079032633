import { useCallback, useState } from 'react'

function useNotification() {
  const [value, setValue] = useState(null)

  const reset = useCallback(() => {
    setValue({})
  }, [])

  const info = useCallback(message => {
    console.log('infoNotification')
    console.log(message)
    setValue({ severity: 'info', message })
  }, [])

  const error = useCallback(message => {
    setValue({ severity: 'error', message })
  }, [])

  const success = useCallback(message => {
    setValue({ severity: 'success', message })
  }, [])

  const changed = value && value.severity === undefined

  return { changed, value, info, error, success, reset }
}

export default useNotification
