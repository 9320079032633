import { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ReactStars from 'react-rating-stars-component'
import TextTruncate from 'react-text-truncate'
import { Hidden } from '@mui/material'
import Container from '../Container'
import Typography from '../Typography'
import Avatar from '../../components/OrganizationAvatar'
import PriceTag from '../PriceTag'
import Button from '../Button'
import Icon from '../Icons'
import { Collapse } from '@mui/material'
import { Divider } from '@mui/material'

const ServiceCardContainer = styled.div`
  background: #f6eeff;
  box-shadow: 4px 4px 10px rgba(68, 26, 100, 0.2);
  border-radius: 10px;
  padding: 42px 0;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`
const MuiTypography = styled(Typography)`
  margin-bottom: 0;
  font-size: 1.12rem;
  color: #652d92;
  line-height: 1;
  font-weight: 500;
  margin-right: 5px;
  font-family: Poppins;
  ${props =>
    props.transform === 'lowercase' &&
    css`
      text-transform: lowercase;
    `}
`
const MoreButton = styled.button`
  cursor: pointer;
  color: #662d91;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 166%;
  background: transparent;
  border: none;
`

const ServiceCard = ({ serviceCardData, variant = 'service' }) => {
  const [textLimit, setTextLimit] = useState(2)
  const [open, setOpen] = useState(false)
  console.log('serviceCardData', serviceCardData) // eslint-disable-line

  const {
    service,
    bundleComponents = [],
    clinic,
    // clinics,
    readablePrice,
  } = serviceCardData

  if (!serviceCardData) {
    console.error('  ** ServiceCard: No serviceCardData provided')
    return <></>
  }

  console.log(bundleComponents) // eslint-disable-line

  const combinedBundleComponents = bundleComponents
    .reduce((acc, component) => {
      const existingComponent = acc.find(
        c =>
          c.cptCode === component.cptCode &&
          c.clinicID === component.clinicID &&
          c.rendering === component.rendering &&
          c.serviceID === component.serviceID,
      )
      if (existingComponent) {
        existingComponent.count += 1
        existingComponent.calculatedPrice +=
          component.customPrice || component.referencePatientPrice || 0
      } else {
        acc.push({
          ...component,
          count: 1,
          calculatedPrice: component.customPrice || component.referencePatientPrice || 0,
        })
      }
      return acc
    }, [])
    .sort((a, b) => b.calculatedPrice - a.calculatedPrice)

  console.log('combinedBundleComponents', combinedBundleComponents) // eslint-disable-line

  return (
    <ServiceCardContainer>
      {variant === 'bundle' && (
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant='body1' color='primary' gutterBottom family='poppins'>
                Bundle
              </Typography>
              <Typography variant='h1' gutterBottom family='poppins' weight='medium'>
                {service?.name || ''}
              </Typography>
              <Box mb={2}>
                <Typography variant='body2' weight='light' gutterBottom>
                  {textLimit === 2 && (
                    <TextTruncate
                      line={textLimit}
                      element='span'
                      truncateText='…'
                      text={service?.description}
                      textTruncateChild={
                        <MoreButton
                          onClick={e => {
                            e.stopPropagation()
                            setTextLimit(0)
                          }}
                        >
                          show more
                        </MoreButton>
                      }
                    />
                  )}
                  {textLimit === 0 && (
                    <>
                      {service?.description || ''}
                      <MoreButton
                        onClick={e => {
                          e.stopPropagation()
                          setTextLimit(2)
                        }}
                      >
                        show less
                      </MoreButton>
                    </>
                  )}
                </Typography>
              </Box>
              <Button variant='text' onClick={() => setOpen(!open)} size='small'>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  color='#7004c6dd'
                >
                  <Typography variant='body1' style={{ color: '#7004c6dd' }}>
                    Bundled Services
                  </Typography>
                  <Box
                    style={{
                      transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s',
                    }}
                  >
                    <Icon name='chevronRight' />
                  </Box>
                </Box>
              </Button>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box paddingLeft='12px'>
                  {combinedBundleComponents.map((component, index) => (
                    <Box>
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        key={index} // eslint-disable-line react/no-array-index-key
                        sx={{
                          flexDirection: {
                            xs: 'column',
                            md: 'row',
                          },
                          alignItems: {
                            xs: 'flex-start',
                            md: 'center',
                          },
                        }}
                      >
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box display='flex' color='#652d92'>
                            <Typography variant='body1' style={{ fontSize: '14px' }}>
                              {component.name && component.name}
                            </Typography>
                            <Typography variant='body1' style={{ fontSize: '14px' }}>
                              &nbsp;
                              {component.count > 1 ? `(x${component.count})` : ''}
                            </Typography>
                          </Box>
                          {component.cptCode && (
                            <Box width='100%' display='flex' justifyContent='space-between'>
                              <Typography
                                variant='body1'
                                weight='light'
                                style={{ fontSize: '14px' }}
                              >
                                Procedure Code:
                              </Typography>
                              <Typography
                                variant='body1'
                                weight='light'
                                style={{ fontSize: '14px' }}
                              >
                                {component.cptCode}
                              </Typography>
                            </Box>
                          )}
                          <Box width='100%' display='flex' justifyContent='space-between'>
                            {component.rendering && (
                              <Typography
                                variant='body1'
                                weight='light'
                                style={{
                                  fontSize: '14px',
                                }}
                              >
                                {component.rendering}:
                              </Typography>
                            )}
                            <Typography
                              variant='body1'
                              weight='light'
                              style={{
                                fontSize: '14px',
                              }}
                            >
                              {component.clinicName}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {index < combinedBundleComponents.length - 1 && (
                        <Divider
                          style={{
                            margin: '2px 0',
                          }}
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              </Collapse>
              <Box margin='15px 0px 0px 0px'>
                {readablePrice && (
                  <Typography variant='h5' family='poppins' style={{ margin: '10px 0px 0px 0px' }}>
                    ${readablePrice}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
      {variant === 'service' && (
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              {Array.isArray(clinic?.image) && clinic?.image?.length !== 0 && (
                <Avatar
                  position='top'
                  size='small'
                  src={clinic.image[0]?.downloadURL}
                  alt='Logo of Clinic'
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Hidden mdDown>
                <Typography variant='h3' gutterBottom>
                  {service.name || ''}
                </Typography>
                <Typography variant='h5' gutterBottom>
                  {clinic.name || ''}
                </Typography>
                <Box mb={2}>
                  <Typography variant='body2' weight='light' gutterBottom>
                    {textLimit === 2 && (
                      <TextTruncate
                        line={textLimit}
                        element='span'
                        truncateText='…'
                        text={service.description}
                        textTruncateChild={
                          <MoreButton
                            onClick={e => {
                              e.stopPropagation()
                              setTextLimit(0)
                            }}
                          >
                            show more
                          </MoreButton>
                        }
                      />
                    )}
                    {textLimit === 0 && (
                      <>
                        {service.description || ''}
                        <MoreButton
                          onClick={e => {
                            e.stopPropagation()
                            setTextLimit(2)
                          }}
                        >
                          show less
                        </MoreButton>
                      </>
                    )}
                  </Typography>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Typography variant='h3' gutterBottom>
                    {service.name || ''}
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Typography variant='h5' gutterBottom>
                    {clinic.name || ''}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant='body2' gutterBottom align='center' weight='light'>
                    {textLimit === 2 && (
                      <TextTruncate
                        line={textLimit}
                        element='span'
                        truncateText='…'
                        text={service.description}
                        textTruncateChild={
                          <MoreButton
                            onClick={e => {
                              e.stopPropagation()
                              setTextLimit(0)
                            }}
                          >
                            show more
                          </MoreButton>
                        }
                      />
                    )}
                    {textLimit === 0 && (
                      <>
                        {service.description || ''}
                        <MoreButton
                          onClick={e => {
                            e.stopPropagation()
                            setTextLimit(2)
                          }}
                        >
                          show less
                        </MoreButton>
                      </>
                    )}
                  </Typography>
                </Box>
              </Hidden>
              <Grid container>
                <Hidden mdDown>
                  {clinic.virtual && (
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Typography variant='body2' weight='light'>
                        Virtual
                      </Typography>
                    </Grid>
                  )}
                  {clinic?.review?.count && (
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <Box display='flex' alignItems='center'>
                        <ReactStars
                          value={clinic.review.count}
                          size={20}
                          edit={false}
                          activeColor='#652D92'
                          isHalf
                        />
                      </Box>
                    </Grid>
                  )}
                </Hidden>
                <Hidden mdUp>
                  {clinic.virtual && (
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Box display='flex' alignItems='center' justifyContent='center'>
                        <Typography
                          mb={1}
                          variant='body2'
                          gutterBottom
                          align='center'
                          weight='light'
                        >
                          Virtual
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {clinic?.review?.count && (
                    <Grid item xs={6} sm={6} md={5} lg={5}>
                      <Box display='flex' alignItems='center' justifyContent='center'>
                        <ReactStars
                          value={clinic.review.count}
                          size={20}
                          edit={false}
                          activeColor='#652D92'
                          isHalf
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={clinic?.review?.count ? 6 : 12}
                    sm={clinic?.review?.count ? 6 : 12}
                    md={2}
                    lg={2}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      textAlign='center'
                    >
                      <PriceTag size='small'>
                        {service?.price && service.price.toLocaleString('en-US')}
                      </PriceTag>
                    </Box>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Box mb={1} mr={3} textAlign='center'>
                  <PriceTag size='small'>
                    {service?.price && service.price.toLocaleString('en-US')}
                  </PriceTag>
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      )}
    </ServiceCardContainer>
  )
}

ServiceCard.propTypes = {
  serviceCardData: PropTypes.shape({
    service: PropTypes.object,
    bundleComponents: PropTypes.array,
    clinic: PropTypes.object,
    clinics: PropTypes.array,
    readablePrice: PropTypes.string,
  }).isRequired,
  variant: PropTypes.string,
}

export default ServiceCard
