import { useEffect, useCallback, useState } from 'react'
import { collection } from '../firebase/firestore'

const LIMIT = 9

const fetchDocuments = uid =>
  collection(`organizations/${uid}/providers`).orderBy('name').limit(LIMIT)

const listDocuments = snapshot =>
  snapshot.docs.map(doc => {
    const { image, ...data } = doc.data()
    return {
      ...data,
      doc,
      image: image?.[0]?.downloadURL,
    }
  })

function useOrganizationCollection(uid) {
  const [hasMore, setHasMore] = useState(false)
  const [documents, setDocuments] = useState([])

  const loadMore = useCallback(() => {
    if (uid) {
      const last = documents[documents.length - 1]
      fetchDocuments(uid)
        .startAfter(last.doc)
        .get()
        .then(snapshot => {
          setHasMore(snapshot.size === LIMIT)
          const list = listDocuments(snapshot)
          setDocuments(current => current.concat(list))
        })
    }
  }, [uid, documents])

  useEffect(() => {
    if (uid) {
      fetchDocuments(uid)
        .get()
        .then(snapshot => {
          setHasMore(snapshot.size === LIMIT)
          setDocuments(listDocuments(snapshot))
        })
    }
  }, [uid])

  return [documents, hasMore, loadMore]
}

export default useOrganizationCollection
