import { FC } from 'react'
import {
  Navigate,
  NavigateProps,
  generatePath,
  useParams,
  useRoutes,
  RouteObject,
} from 'react-router-dom'
import Landing from '@app/pages/LandingPage'
import Dashboard from '@app/pages/DashboardPage'
import SignIn from '@app/pages/SignInPage'
import SignUp from '@app/pages/SignUpPage'
import ResetPassword from '@app/pages/ResetPasswordPage'
import ConfirmPasswordPage from '@app/pages/ConfirmPasswordPage'
import OrganizationPage from '@app/pages/OrganizationPage'
import BlogPage from '@app/pages/BlogPage'
import Questions from '@app/pages/QuestionsPage'
import PrivacyPolicy from '@app/pages/terms/PrivacyPolicyPage'
import TermsOfUse from '@app/pages/terms/TermsOfUsePage'
import HIPAA from '@app/pages/terms/HIPAAPage'
import BAA from '@app/pages/terms/BAAPage'
import EULA from '@app/pages/terms/EULAPage'
import EULAConsumer from '@app/pages/terms/EULAConsumerPage'
import EULAProvider from '@app/pages/terms/EULAProviderPage'
import AuthAction from '@app/pages/AuthAction'
import IntroToMishe from '@app/pages/IntroToMishe'
import Support from '@app/pages/SupportPage'
import WhyMishe from '@app/pages/WhyMishePage'
import ForClinicians from '@app/pages/ForClinicians'
import Enrollment from '@app/pages/Enrollment'
import ClaimProfile from '@app/pages/ClaimProfile'
import NetworkLander from '@app/pages/NetworkLander'
import NetworkServiceCheckout from '@app/pages/NetworkServiceCheckout'
import NetworkServiceLander from '@app/pages/NetworkServiceLander'
import PlanMedicationLanderPage from '@app/pages/PlanMedicationLanderPage'
import EmrCheckout from '@app/pages/EmrCheckout'
import NationalMapPage from '@app/pages/NationalMapPage'
import BlogDirectoryPage from '@app/pages/BlogDirectoryPage'
import VideoChatContainer from '@app/containers/VideoChatContainer'
import HipaaAuthPDF from '@app/containers/HipaaAuthPDF'
import OrderOverviewPage from '@app/pages/OrderOverviewPage'
import JoinMishe from '@app/pages/JoinMishe'
import PhysiciansPage from '@app/pages/PhysiciansPage'
import HealthPlansPage from '@app/pages/HealthPlansPage'
import AffiliatesPage from '@app/pages/AffiliatesPage'
import MembersPage from '@app/pages/MembersPage'
import StripeSetupResult from '@app/pages/StripeSetupResult'
import PageNotFound from '@app/pages/PageNotFound'
import Layout from '@app/components/Layout'
import AnonymousRoute from './AnonymousRoute'
import PrivateRoute from './PrivateRoute'
import PlanRoute from './PlanRoute'

const Redirect: FC<NavigateProps> = ({ to, ...props }) => {
  const params = useParams()
  return <Navigate {...props} to={typeof to === 'string' ? generatePath(to, params) : to} replace />
}

const routes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Landing /> },
      { path: 'joinmishe', element: <JoinMishe /> },
      { path: 'providers', element: <PhysiciansPage /> },
      { path: 'payers', element: <HealthPlansPage /> },
      { path: 'patients', element: <MembersPage /> },
      { path: 'affiliates', element: <AffiliatesPage /> },
      { path: 'hipaaauthpdf', element: <HipaaAuthPDF /> },
      { path: 'videochat', element: <VideoChatContainer /> },
      { path: 'orders/:typeOfOrder/checkout', element: <EmrCheckout /> },
      { path: 'orders/checkout', element: <EmrCheckout /> },
      { path: 'orders/overview', element: <OrderOverviewPage /> },
      { path: 'map', element: <NationalMapPage /> },
      { path: 'support', element: <Support /> },
      { path: 'why-mishe', element: <WhyMishe /> },
      { path: 'enrollment', element: <Enrollment /> },
      { path: 'claimprofile', element: <ClaimProfile /> },
      { path: 'forclinicians', element: <ForClinicians /> },
      { path: 'questions', element: <Questions /> },
      { path: 'clinics/:slug', element: <OrganizationPage /> },
      { path: 'blog/almostbankrupt', element: <IntroToMishe /> },
      { path: 'blog/:slug', element: <BlogPage /> },
      { path: 'blog-directory', element: <BlogDirectoryPage /> },
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
      { path: 'terms', element: <TermsOfUse /> },
      { path: 'hipaa', element: <HIPAA /> },
      { path: 'baa', element: <BAA /> },
      { path: 'eula', element: <EULA /> },
      { path: 'eula-consumer', element: <EULAConsumer /> },
      { path: 'eula-provider', element: <EULAProvider /> },
      { path: '__/auth/action', element: <AuthAction /> },
      // Stripe setup success / cancel pages
      { path: 'setup', element: <StripeSetupResult /> },
      {
        element: <PlanRoute />,
        children: [
          { path: 'plans/:network', element: <NetworkLander /> },
          {
            path: 'plans/:network/service/:misheCode',
            element: <NetworkServiceLander />,
          },
          {
            path: 'plans/:network/service/:networkServiceId/checkout',
            element: <NetworkServiceCheckout />,
          },
          {
            path: 'medication/:misheCode',
            element: <PlanMedicationLanderPage />,
          },
        ],
      },
      {
        element: <AnonymousRoute />,
        children: [
          { path: 'signin', element: <SignIn /> },
          { path: 'signup', element: <SignUp /> },
          { path: 'reset-password', element: <ResetPassword /> },
          {
            path: 'reset-password/auth/action',
            element: <ConfirmPasswordPage />,
          },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [{ path: 'dashboard', element: <Dashboard /> }],
      },
      // Page not found
      { path: '*', element: <PageNotFound /> },
    ],
  },

  /**
   * Redirects for old urls
   */
  {
    path: 'networks/:network',
    element: <Redirect to='/plans/:network' />,
  },
  {
    path: 'networks/:network/service/:misheCode',
    element: <Redirect to='/plans/:network/service/:misheCode' />,
  },
  {
    path: 'networks/:network/service/:networkServiceId/checkout',
    element: <Redirect to='/plans/:network/service/:networkServiceId/checkout' />,
  },
  {
    path: 'forproviders',
    element: <Redirect to='/forclinicians' />,
  },
  {
    path: 'organizations/:slug',
    element: <Redirect to='/clinics/:slug' />,
  },
  {
    path: 'blogs/:slug',
    element: <Redirect to='/blog/:slug' />,
  },
]

const Routes: FC = () => {
  return useRoutes(routes)
}

export default Routes
