import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'

const PhoneImagesShifter = ({
  children,
  cycleLength = 5000,
  buttonColor = '',
  testDisplay,
  handleChange,
}) => {
  const [fadeIn, setFadeIn] = useState(true)
  const [childShift, setChildShift] = useState(0)
  const [buttonShift, setButtonShift] = useState(0)
  const [debouncedClick, setDebouncedClick] = useState(false)
  const [autoShift, setAutoShift] = useState(true)

  const childrenArray = Object.keys(children)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedClick(false)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [debouncedClick])

  useEffect(() => {
    if (autoShift) {
      const interval = setInterval(() => {
        setFadeIn(false)
        setTimeout(() => {
          setChildShift(prevShift => (prevShift + 1) % childrenArray.length)
          handleChange(childShift + 2 > childrenArray.length ? 1 : childShift + 2)
          setButtonShift(prevShift => (prevShift + 1) % childrenArray.length)
          setFadeIn(true)
        }, 500)
      }, cycleLength)

      return () => clearInterval(interval)
    }
    return () => {}
  }, [autoShift, buttonShift, children, childrenArray.length, cycleLength])

  const handleButtonClick = shift => {
    setAutoShift(false)
    if (!debouncedClick) {
      setFadeIn(false)
      setDebouncedClick(true)

      if (buttonShift === shift) {
        setButtonShift(0)
      } else {
        setButtonShift(shift)
      }

      setTimeout(() => {
        if (childShift === shift) {
          setChildShift(0)
        } else {
          handleChange(shift + 1)
          setChildShift(shift)
        }
        setFadeIn(true)
      }, 500)
    }
  }

  return (
    <Box
      position='relative'
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='center'
      width='100%'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        height='100%'
      >
        <Box height='100%'>
          <IconButton
            size='small'
            onClick={() =>
              handleButtonClick(buttonShift === 0 ? childrenArray.length - 1 : buttonShift - 1)
            }
            style={{
              marginTop: '20px',
              height: '100%',
              borderRadius: '0 50% 50% 0',
              color: buttonColor,
              padding: '0px 24px',
            }}
            sx={{
              width: {
                xs: '40px',
                md: '100px',
              },
            }}
          >
            <Icon name='chevronLeft' />
          </IconButton>
        </Box>
        <Fade in={fadeIn} timeout={{ enter: 1000, exit: 500 }}>
          <Box marginTop='40px' display='flex' justifyContent='center'>
            {children[childShift]}
          </Box>
        </Fade>
        <Box height='100%'>
          <IconButton
            size='small'
            onClick={() =>
              handleButtonClick(buttonShift === childrenArray.length - 1 ? 0 : buttonShift + 1)
            }
            style={{
              marginTop: '20px',
              height: '100%',
              borderRadius: '50% 0 0 50%',
              color: buttonColor,
              padding: '0px 24px',
            }}
            sx={{
              width: {
                xs: '40px',
                md: '100px',
              },
            }}
          >
            <Icon name='chevronRight' />
          </IconButton>
        </Box>
      </Box>
      <Box
        position='absolute'
        top='0px'
        display='flex'
        justifyContent='center'
        alignItems='center'
        width='100%'
      >
        {childrenArray.map((child, index) => (
          <IconButton
            key={index} // eslint-disable-line
            size='small'
            onClick={() => {
              setAutoShift(false)
              setFadeIn(false)
              setButtonShift(index)
              setTimeout(() => {
                setChildShift(index)
                handleChange(index + 1)
                setFadeIn(true)
              }, 500)
            }}
            style={{
              color: buttonColor,
            }}
          >
            <Icon
              name={buttonShift === index ? 'fiberManualRecord' : 'fiberManualRecordOutlined'}
              size='small'
            />
          </IconButton>
        ))}
      </Box>
    </Box>
  )
}

PhoneImagesShifter.propTypes = {
  children: PropTypes.array.isRequired,
  cycleLength: PropTypes.number,
  buttonColor: PropTypes.string,
}

export default PhoneImagesShifter
