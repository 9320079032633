import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiTextField from '@mui/material/TextField'

const StyledTextField = styled(MuiTextField)`
  label {
    text-transform: lowercase;
    ${props =>
      props.display === 'standard' &&
      css`
        text-transform: capitalize;
      `}
  }
  input {
    background: ${props => props.theme.palette.background.paper};
    border-radius: ${props => props.theme.shape.borderRadius}px;
  }
`

const TextField = ({ value = undefined, children, size = 'medium', helperText = '', ...props }) => {
  const inputLabelProps = {}
  if (value) {
    inputLabelProps.shrink = value.length > 0
  }
  return (
    <StyledTextField
      variant='outlined'
      bgcolor='background.paper'
      margin='normal'
      fullWidth
      size={size}
      value={value}
      helperText={helperText}
      error={!!helperText}
      InputLabelProps={inputLabelProps}
      {...props}
    />
  )
}

TextField.propTypes = {
  children: PropTypes.node,
  helperText: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
}

export default TextField
