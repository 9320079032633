import PropTypes from 'prop-types'
import MuiContainer from '@mui/material/Container'

const Container = ({ children, ...props }) => (
  <MuiContainer {...props} maxWidth='xl'>
    {children}
  </MuiContainer>
)

Container.propTypes = {
  children: PropTypes.node,
}

export default Container
