import { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  useHits,
  Pagination,
  useSearchBox,
  InstantSearch,
  useInstantSearch,
  useConfigure,
  useRefinementList,
  SortBy,
} from 'react-instantsearch'

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Collapse } from '@mui/material'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import TextField from '../stories/TextField'

import searchClient from '../libs/algolia'
import { getCoordsFromAddress } from '../libs/geo'
import { Hidden } from '@mui/material'
import Grid from '@mui/material/Grid'
import CollapsableListItem from '../components/CollapsableListItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import SimpleMap from '../components/GoogleMap'
import MapEffectsProvider, { useMapEffects } from '../context/MapEffects'
import SelectField from '@app/stories/Select'

const CustomConfigure = ({ configuration }) => {
  const { refine } = useConfigure(configuration)

  useEffect(() => {
    refine(configuration)
  }, [configuration, refine])

  return null
}

CustomConfigure.propTypes = {
  configuration: PropTypes.shape({
    hitsPerPage: PropTypes.number,
  }).isRequired,
}

const NoResults = ({ noResultsMessage, uiState, noResultsColor }) => {
  return (
    <Box color={noResultsColor}>
      <Typography variant='body1' weight='bold' align='center' style={{ marginTop: '8px' }}>
        {noResultsMessage} {uiState.query}
      </Typography>
    </Box>
  )
}

const HomeScreenTextField = styled(TextField)({
  '& label.Mui-focused': {
    backgroundColor: '#652d92',
    color: '#fff',
  },
  '& .MuiFormLabel-root': {
    color: '#fff',
  },
  '& .MuiInput-underline:after': {
    backgroundColor: '#fff',
    borderRadius: '28px',
    borderBottomColor: '#fff',
    color: '#fff',
  },
  '& .MuiInputBase-input': {
    backgroundColor: '#652d92',
    color: '#fff',
    fontSize: 16,
    borderRadius: '28px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      background: '#transparent',
      borderRadius: '28px',
      borderColor: '#fff',
      color: '#fff',
    },
    '&:hover fieldset': {
      background: '#transparent',
      borderRadius: '28px',
      borderColor: '#fff',
      color: '#fff',
    },
    '&.Mui-focused fieldset': {
      background: '#transparent',
      borderRadius: '28px',
      borderColor: '#fff',
      color: '#fff',
    },
  },
})

const CustomSearchBox = ({
  searchBoxText,
  displayHits,
  showInitialHits = false,
  showZipcode,
  showZipcodeMobile,
  smallZipcode = false,
  zipcode = '',
  setZipcode,
  clearSearchButton = false,
  homePageSearch,
  searchOnMap,
  ...props
}) => {
  const { query, refine } = useSearchBox(props)
  const { status } = useInstantSearch()
  const { searchMapCordinates } = useMapEffects()
  const [inputValue, setInputValue] = useState(query)
  const [zipcodeValue, setZipcodeValue] = useState(zipcode)
  const [isClicked, setIsClicked] = useState(false)
  const [zipIsClicked, setZipIsClicked] = useState(false)

  const isSearchStalled = status === 'stalled'
  const searchDelay = 500
  const searchTimeout = useRef(null)
  const zipcodeTimeout = useRef(null)

  const handleFocusClick = target => {
    setIsClicked(false)
    setZipIsClicked(false)
    if (target === 'main') {
      setIsClicked(true)
    }
    if (target === 'zip') {
      setZipIsClicked(true)
    }
  }

  const handleZipcode = useCallback(
    value => {
      if (zipcodeTimeout.current) {
        clearTimeout(zipcodeTimeout.current)
      }

      setZipcodeValue(value)
      zipcodeTimeout.current = setTimeout(() => {
        setZipcode(value)
      }, 500)
    },
    [setZipcode],
  )

  const handleQuery = useCallback(
    ({ queryValue = '' }) => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current)
      }

      // The order of these events matter!
      // We always want to update the input value first, this is what the user is typing, its a controlled form element
      // Then we want to display the hits components before updating the algolia search, if we don't do this, the hits will throw a warning
      // because the component is unmounted and the useState hook is trying to update the state
      // Then we refine the query, this is what algolia is searching on / and what generates hits
      setInputValue(queryValue)
      if (queryValue?.length === 0 && !showInitialHits) {
        displayHits(false)
        return
      }

      if (queryValue?.length > 0 || showInitialHits) {
        displayHits(true)
      }

      searchTimeout.current = setTimeout(() => {
        refine(queryValue)
      }, searchDelay)
    },
    [showInitialHits, displayHits, refine],
  )

  useEffect(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }
    if (zipcode?.length > 4) {
      displayHits(true)
    }
    searchTimeout.current = setTimeout(() => {
      refine(inputValue)
    }, searchDelay)
  }, [zipcode])

  useEffect(() => {
    searchOnMap(searchMapCordinates)
  }, [searchMapCordinates])

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <form
        action=''
        role='search'
        noValidate
        onSubmit={event => {
          event.preventDefault()
          event.stopPropagation()
        }}
        onReset={event => {
          event.preventDefault()
          event.stopPropagation()
          handleQuery('')
        }}
      >
        {homePageSearch ? (
          <Box
            style={{
              position: 'relative',
            }}
          >
            {showZipcodeMobile && (
              <HomeScreenTextField
                onClick={() => handleFocusClick('zip')}
                autoFocus={zipIsClicked}
                style={{
                  marginTop: '1rem',
                  marginBottom: '0px',
                  zIndex: '5',
                  width: '100%',
                }}
                display='standard'
                autoComplete='off'
                autoCorrect='off'
                autoCapitalize='off'
                spellCheck={false}
                maxLength={10}
                type='search'
                value={zipcodeValue}
                onChange={event => {
                  handleZipcode(event.currentTarget.value)
                }}
                id='zip'
                label={smallZipcode ? 'Zip' : 'Zip Code'}
                variant='outlined'
              />
            )}
            <HomeScreenTextField
              onClick={() => handleFocusClick('main')}
              autoFocus={isClicked}
              style={{
                marginTop: '1rem',
                marginBottom: '0px',
                zIndex: '5',
                width: showZipcode ? '75%' : '100%',
                color: '#fff',
              }}
              display='standard'
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              maxLength={512}
              type='search'
              value={inputValue}
              onChange={event => {
                handleQuery({
                  queryValue: event.currentTarget.value,
                })
              }}
              id='medication'
              label={searchBoxText}
              variant='outlined'
            />
            {showZipcode && (
              <HomeScreenTextField
                onClick={() => handleFocusClick('zip')}
                autoFocus={zipIsClicked}
                style={{
                  marginTop: '1rem',
                  marginBottom: '0px',
                  zIndex: '5',
                  marginLeft: '1rem',
                  width: 'calc(25% - 1rem)',
                }}
                display='standard'
                autoComplete='off'
                autoCorrect='off'
                autoCapitalize='off'
                spellCheck={false}
                maxLength={10}
                type='search'
                value={zipcodeValue}
                onChange={event => {
                  handleZipcode(event.currentTarget.value)
                }}
                id='zip'
                label={smallZipcode ? 'Zip' : 'Zip Code'}
                variant='outlined'
              />
            )}
            {clearSearchButton && (
              <Button
                style={{
                  position: 'absolute',
                  bottom: '2px',
                  right: showZipcode ? 'calc(25% + 2px)' : '2px',
                  zIndex: '6',
                  border: 'none',
                  borderRadius: '0px 28px 28px 0px',
                  background: '#652d92',
                  boxShadow: 'none',
                  borderLeft: '1px solid #e0e0e0',
                  height: '52px',
                  color: '#fff',
                }}
                onClick={() => {
                  handleQuery({ queryValue: '' })
                  handleZipcode('')
                }}
              >
                <Typography variant='body1' align='center' color='inherit'>
                  Clear
                </Typography>
              </Button>
            )}
            <Box position='absolute' bottom='-24px' left='16px' zIndex='5' color='#fff'>
              <span hidden={!isSearchStalled}>Searching…</span>
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              position: 'relative',
            }}
          >
            <TextField
              style={{
                marginTop: '1rem',
                marginBottom: '0px',
                zIndex: '5',
                width: showZipcode ? '75%' : '100%',
              }}
              color='primary'
              display='standard'
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              maxLength={512}
              type='search'
              value={inputValue}
              onChange={event => {
                handleQuery({
                  queryValue: event.currentTarget.value,
                })
              }}
              autoFocus
              id='medication'
              label={searchBoxText}
              variant='outlined'
            />
            {showZipcode && (
              <TextField
                style={{
                  marginTop: '1rem',
                  marginBottom: '0px',
                  zIndex: '5',
                  marginLeft: '1rem',
                  width: 'calc(25% - 1rem)',
                }}
                display='standard'
                autoComplete='off'
                autoCorrect='off'
                autoCapitalize='off'
                spellCheck={false}
                maxLength={10}
                type='search'
                value={zipcodeValue}
                onChange={event => {
                  handleZipcode(event.currentTarget.value)
                }}
                autoFocus
                id='zip'
                label={smallZipcode ? 'Zip' : 'Zip Code'}
                variant='outlined'
              />
            )}
            {clearSearchButton && (
              <Button
                style={{
                  position: 'absolute',
                  bottom: '2px',
                  right: showZipcode ? 'calc(25% + 2px)' : '2px',
                  zIndex: '6',
                  border: 'none',
                  borderRadius: '0px',
                  background: '#fff',
                  boxShadow: 'none',
                  borderLeft: '1px solid #e0e0e0',
                  height: '52px',
                }}
                onClick={() => {
                  handleQuery({ queryValue: '' })
                  handleZipcode('')
                }}
              >
                <Typography variant='body1' align='center' color='primary'>
                  Clear
                </Typography>
              </Button>
            )}
            <Box position='absolute' bottom='-24px' left='16px' zIndex='5'>
              <span hidden={!isSearchStalled}>Searching…</span>
            </Box>
          </Box>
        )}
      </form>
    </div>
  )
}

CustomSearchBox.propTypes = {
  searchBoxText: PropTypes.string.isRequired,
  zipcode: PropTypes.string,
  setZipcode: PropTypes.func.isRequired,
  displayHits: PropTypes.func.isRequired,
  showInitialHits: PropTypes.bool,
  showZipcode: PropTypes.bool.isRequired,
  smallZipcode: PropTypes.bool,
  clearSearchButton: PropTypes.bool,
}

const ExportHits = ({ exportFunction }) => {
  const { hits } = useHits()

  useEffect(() => {
    if (exportFunction) {
      exportFunction(hits)
    }
  }, [hits])

  return <></>
}

ExportHits.propTypes = {
  exportFunction: PropTypes.func.isRequired,
}

const CustomHits = ({
  HitContainer = null,
  HitComponent,
  hitClick = null,
  variant = '',
  noResultsMessage = 'No results for',
  // homePageSearch,
  noResultsColor,
}) => {
  const { hits } = useHits()
  console.log('hits =============', hits)
  const { indexUiState } = useInstantSearch()

  if (hits.length === 0) {
    return (
      <NoResults
        noResultsMessage={noResultsMessage}
        uiState={indexUiState}
        noResultsColor={noResultsColor}
      />
    )
  }

  if (HitContainer) {
    return (
      <HitContainer>
        {hits.map(hit => (
          <HitComponent
            key={hit.objectID}
            data={hit}
            onClick={hitClick}
            variant={variant}
            exactMatch={
              indexUiState?.query?.toString() === hit?.cptCode?.toString() && indexUiState?.query
            }
          />
        ))}
      </HitContainer>
    )
  }

  return (
    <Box
      style={{
        backgroundColor: 'white',
        boxShadow: '0 0 0 1px rgba(35,38,59,.05), 0 1px 3px 0 rgba(35,38,59,.15)',
        borderRadius: '5px',
        maxHeight: '35rem',
        overflow: 'auto',
      }}
    >
      {hits.map(hit => (
        <HitComponent
          key={hit.objectID}
          data={hit}
          onClick={hitClick}
          variant={variant}
          exactMatch={
            indexUiState?.query?.toString() === hit?.cptCode?.toString() && indexUiState?.query
          }
        />
      ))}
    </Box>
  )
}

CustomHits.propTypes = {
  HitContainer: PropTypes.func,
  HitComponent: PropTypes.func.isRequired,
  hitClick: PropTypes.func,
  variant: PropTypes.string,
  noResultsMessage: PropTypes.string,
}

const HitMap = ({ noResultsMessage, displayHeight, noResultsColor }) => {
  const { hits } = useHits()
  const { indexUiState } = useInstantSearch()

  if (hits.length === 0) {
    return (
      <NoResults
        noResultsMessage={noResultsMessage}
        uiState={indexUiState}
        noResultsColor={noResultsColor}
      />
    )
  }
  const uniqueClinicsBundle = hits
    .map(hit => {
      if (hit?.bundle) {
        return Object.values(hit.uniqueClinicData)
      }
      return null
    })
    .filter(hit => hit)
    .flat()
    .filter(Boolean)
  const setBundleMarker = uniqueClinicsBundle.map(e => {
    return { ...e, fromBundle: true }
  })
  const placeholderfornow = [...setBundleMarker, ...hits]

  const uniqueClinics = placeholderfornow.reduce((acc, hit) => {
    if (!hit?.bundle) {
      if (!acc[hit.clinicID || hit.id]) {
        acc[hit.clinicID || hit.id] = hit
      }
    }
    return acc
  }, {})
  const uniqueClinicsArray = Object.values(uniqueClinics)

  const goodLocations = uniqueClinicsArray
    .map(hit => {
      return hit._geoloc.map(location => {
        return {
          _geoloc: {
            lat: location.lat,
            lng: location.lng,
          },
          name: hit.clinicName || hit.name || '',
          id: `${hit.clinicID || hit.id}`,
          fromBundle: hit.fromBundle,
        }
      })
    })
    .flat()

  console.log('goodLocations', goodLocations)

  return (
    <Box
      border='solid 2px #652d92'
      borderRadius='4px'
      sx={{
        marginTop: {
          xs: '8px',
          md: '0px',
        },
      }}
    >
      <SimpleMap
        key={Math.random()}
        clinics={[{ locations: goodLocations }]}
        displayHeight={displayHeight}
        handleSelectLocation={data => {
          console.log(data)
        }}
      />
    </Box>
  )
}

const InstantSearchContainerCustom = ({
  HitContainer = null,
  HitComponent,
  variant = '',
  hitClick = null,
  indexName,
  configuration = { hitsPerPage: 5 },
  searchBoxText,
  noResultsMessage,
  showInitialHits = false,
  usePagination = false,
  clearSearchButton = false,
  backgroundCollapse = false,
  exportFunction = null,
  showZipcode = false,
  showZipcodeMobile,
  smallZipcode = false,
  geoSearch = false,
  address = '',
  customZIndex = '2000',
  homePageSearch,
  networkLanderSearch,
  noResultsColor = '#fff',
  setSearchLimit,
  searchLimit,
}) => {
  const [displayHits, setDisplayHits] = useState(showInitialHits)
  const [zipcode, setZipcode] = useState('')
  const [coordinates, setCoordinates] = useState(null)

  const [config, setConfig] = useState({
    analytics: false,
    getRankingInfo: true,
    ...configuration,
  })

  const [currentIndex, setCurrentIndex] = useState('')
  // const { searchMapCordinates } = useMapEffects()

  const onChangeCollapse = e => {
    setCurrentIndex(e)
  }

  function CustomRefinementList(props) {
    const { items, refine } = useRefinementList(props)

    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {items.map(item => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.isRefined}
                  onChange={() => refine(item.value)}
                  color='primary'
                />
              }
              label={item.label}
              key={item.label}
              style={{
                width: '100%',
                borderBottom: '1px solid rgba(35,38,59,.05)',
              }}
            />
          ))}
        </Grid>
      </>
    )
  }

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (!zipcode && zipcode?.length < 4) {
        setCoordinates(null)
        return undefined
      }

      const coords = await getCoordsFromAddress(address || zipcode)
      console.log('coords--------', coords)
      if (coords) {
        setCoordinates(coords)
      }
    }

    fetchCoordinates()
  }, [zipcode, address])

  useEffect(() => {
    const configBuilder = {
      analytics: false,
      getRankingInfo: true,
      ...configuration,
    }

    if (coordinates) {
      console.log('coordinates', coordinates)
      configBuilder.aroundLatLng = `${coordinates.lat}, ${coordinates.lng}`
      configBuilder.aroundRadius = 'all'
    }

    if (geoSearch && !coordinates) {
      configBuilder.aroundLatLngViaIP = true
      configBuilder.aroundRadius = 'all'
    }
    console.log('configBuilder--------', configBuilder, geoSearch)

    setConfig(configBuilder)
  }, [coordinates, geoSearch]) // eslint-disable-line react-hooks/exhaustive-deps

  const searchOnMap = searchMapCordinates => {
    if (searchMapCordinates) {
      const configBuilder = {
        analytics: false,
        getRankingInfo: true,
        ...configuration,
      }

      console.log('searchMapCordinates----', searchMapCordinates)
      configBuilder.aroundLatLng = `${searchMapCordinates.lat}, ${searchMapCordinates.lng}`
      configBuilder.aroundRadius = 'all'

      console.log('configBuilder--searchOnMap------', configBuilder)
      setConfig(configBuilder)
    }
  }

  if (!config) {
    return null
  }

  return (
    <MapEffectsProvider>
      <InstantSearch
        style={{
          width: '100%',
        }}
        searchClient={searchClient}
        indexName={indexName}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        initialUiState={{
          [indexName]: {
            query: '',
          },
        }}
      >
        <CustomConfigure configuration={config} />
        <Box
          display='flex'
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            },
          }}
        >
          {networkLanderSearch && (
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '20%',
                },
              }}
            >
              <Hidden smUp>
                <Grid item xs={12} sm={12}>
                  <CollapsableListItem
                    key='filter_collapsable'
                    value='1'
                    icon='filter'
                    currentIndex={currentIndex}
                    onChangeCollapse={onChangeCollapse}
                    title={<span>Refine your search</span>}
                  >
                    <TextField
                      id='zipCode'
                      variant='outlined'
                      style={{
                        marginTop: '0px',
                        marginBottom: '30px',
                      }}
                      display='standard'
                      autoComplete='off'
                      autoCorrect='off'
                      autoCapitalize='off'
                      spellCheck={false}
                      maxLength={10}
                      type='search'
                      value={zipcode}
                      onChange={event => {
                        setZipcode(event.currentTarget.value)
                      }}
                      autoFocus
                      label={smallZipcode ? 'Zip' : 'Zip Code'}
                    />
                    <Typography
                      family='poppins'
                      variant='h5'
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      Clinic Name
                    </Typography>
                    <CustomRefinementList attribute='clinicName' />
                    <Typography
                      family='poppins'
                      variant='h5'
                      style={{
                        marginTop: '20px',
                      }}
                    >
                      Sort By
                    </Typography>
                    <SortBy
                      items={[
                        {
                          label: 'Most Relevant',
                          value: 'network_services',
                        },
                        {
                          label: 'Price: Low to High',
                          value: 'network_services_price_asc',
                        },
                        {
                          label: 'Price: High to Low',
                          value: 'network_services_price_desc',
                        },
                      ]}
                      style={{
                        marginTop: '20px',
                      }}
                    />
                  </CollapsableListItem>
                </Grid>
              </Hidden>
              <Hidden lgDown>
                <Grid item md={3} lg={3} mt={2} marginRight={2}>
                  <TextField
                    id='zipCode'
                    variant='outlined'
                    style={{
                      marginTop: '0px',
                      marginBottom: '30px',
                    }}
                    display='standard'
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='off'
                    spellCheck={false}
                    maxLength={10}
                    type='search'
                    value={zipcode}
                    onChange={event => {
                      setZipcode(event.currentTarget.value)
                    }}
                    autoFocus
                    label={smallZipcode ? 'Zip' : 'Zip Code'}
                  />
                  <Typography family='poppins' variant='h4' style={{ marginBottom: '10px' }}>
                    Filters
                  </Typography>
                  <Typography
                    family='poppins'
                    variant='h5'
                    style={{
                      marginTop: '30px',
                    }}
                  >
                    Clinic Name
                  </Typography>
                  <CustomRefinementList attribute='clinicName' />
                  <Typography
                    family='poppins'
                    variant='h5'
                    style={{
                      marginTop: '30px',
                    }}
                  >
                    Sort By
                  </Typography>
                  <SortBy
                    items={[
                      {
                        label: 'Most Relevant',
                        value: 'network_services',
                      },
                      {
                        label: 'Price: Low to High',
                        value: 'network_services_price_asc',
                      },
                      {
                        label: 'Price: High to Low',
                        value: 'network_services_price_desc',
                      },
                    ]}
                    style={{
                      marginTop: '10px',
                    }}
                  />
                  <Box>
                    <Typography
                      family='poppins'
                      variant='h5'
                      style={{
                        marginTop: '30px',
                      }}
                    >
                      Limit
                    </Typography>
                    <SelectField
                      label='Limit'
                      value={searchLimit}
                      onChange={e => setSearchLimit(e.target.value)}
                      options={[
                        { value: '5', label: '5' },
                        { value: '10', label: '10' },
                        { value: '25', label: '25' },
                        { value: '50', label: '50' },
                      ]}
                    />
                  </Box>
                </Grid>
              </Hidden>
            </Box>
          )}
          {networkLanderSearch && (
            <Box
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                },
              }}
            >
              <HitMap noResultsMessage={noResultsMessage} noResultsColor={noResultsColor} />
            </Box>
          )}
          <Box
            position='relative'
            height='min-content'
            sx={{
              width: {
                xs: '100%',
                md: networkLanderSearch ? '40%' : '100%',
              },
            }}
          >
            <CustomSearchBox
              searchBoxText={searchBoxText}
              displayHits={setDisplayHits}
              showInitialHits={showInitialHits}
              showZipcode={showZipcode}
              showZipcodeMobile={showZipcodeMobile}
              zipcode={zipcode}
              setZipcode={setZipcode}
              smallZipcode={smallZipcode}
              clearSearchButton={clearSearchButton}
              homePageSearch={homePageSearch}
              searchOnMap={searchOnMap}
            />
            {backgroundCollapse && (
              <Collapse in={displayHits} timeout={1000} unmountOnExit>
                <Box bgcolor='background.smoke' height='550px' width='100%' />
              </Collapse>
            )}
            {displayHits && (
              <>
                {exportFunction && <ExportHits exportFunction={exportFunction} />}
                {!exportFunction && (
                  <Box
                    mb={3}
                    width='100%'
                    display='block'
                    style={{
                      position: 'absolute',
                      height: '42rem',
                      top: backgroundCollapse ? '88px' : 'calc(100% + 1rem)',
                      zIndex: customZIndex || '2000',
                      width: '100%'
                    }}
                  >
                    {usePagination && (
                      <Box
                        mb={1}
                        width='100%'
                        display='flex'
                        justifyContent='center'
                        style={{
                          backgroundColor: 'white',
                          padding: '5px',
                          borderRadius: '5px',
                          boxShadow: '0 0 0 1px rgba(35,38,59,.05), 0 1px 3px 0 rgba(35,38,59,.15)',
                          overflow: 'auto',
                        }}
                      >
                        <Pagination
                          showFirst
                          showLast
                          showPrevious={false}
                          showNext={false}
                          style={{
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.05)',
                            borderRadius: '5px',
                          }}
                        />
                      </Box>
                    )}
                    <CustomHits
                      HitContainer={HitContainer}
                      HitComponent={HitComponent}
                      hitClick={hitClick}
                      variant={variant}
                      noResultsMessage={noResultsMessage}
                      homePageSearch={homePageSearch}
                      noResultsColor={noResultsColor}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '40%',
              },
              padding: {
                xs: '0px',
                md: '16px 0px 16px 16px',
              },
              display: {
                xs: 'none',
                md: networkLanderSearch ? 'block' : 'none',
              },
            }}
          >
            {networkLanderSearch && (
              <HitMap
                noResultsMessage={noResultsMessage}
                displayHeight='75vh'
                noResultsColor={noResultsColor}
              />
            )}
          </Box>
        </Box>
      </InstantSearch>
    </MapEffectsProvider>
  )
}

InstantSearchContainerCustom.propTypes = {
  HitContainer: PropTypes.func,
  HitComponent: PropTypes.func.isRequired,
  hitClick: PropTypes.func,
  indexName: PropTypes.string.isRequired,
  searchBoxText: PropTypes.string.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  variant: PropTypes.string,
  showInitialHits: PropTypes.bool,
  usePagination: PropTypes.bool,
  showZipcode: PropTypes.bool,
  smallZipcode: PropTypes.bool,
  clearSearchButton: PropTypes.bool,
  backgroundCollapse: PropTypes.bool,
  exportFunction: PropTypes.func,
  geoSearch: PropTypes.bool,
  address: PropTypes.string,
  configuration: PropTypes.shape({
    hitsPerPage: PropTypes.number,
  }),
  customZIndex: PropTypes.string,
  noResultsColor: PropTypes.string,
}

export default InstantSearchContainerCustom
