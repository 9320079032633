import { useState } from 'react'
import Form from '../components/ChangePasswordForm'
import { changePassword } from '../firebase/auth'
import { isPassword } from '../libs/validators'
import useNotification from '../hooks/useNotification'
import { EMPTY_ERROR_MESSAGE, INVALID_PASSWORD_MESSAGE } from '../hooks/useAuthErrors'

const INFO_MESSAGE = 'Changing password...'
const SUCCESS_MESSAGE = 'Password changed successfuly'

const validate = event => {
  const errors = {}
  const { elements } = event.target
  const password = elements.currentPassword.value
  const newPassword = elements.newPassword.value
  if (!password) {
    errors.currentPassword = EMPTY_ERROR_MESSAGE
  }
  if (!newPassword) {
    errors.newPassword = EMPTY_ERROR_MESSAGE
  }
  if (!isPassword(newPassword)) {
    errors.newPassword = INVALID_PASSWORD_MESSAGE
  }
  return [password, newPassword, errors]
}

const ChangePasswordForm = () => {
  const notification = useNotification()
  const [errors, setErrors] = useState({})

  const onSubmit = async event => {
    event.preventDefault()
    if (!notification.changed) {
      return
    }
    const [password, newPassword, newErrors] = validate(event)
    setErrors(newErrors)
    if (Object.keys(newErrors).length) {
      return
    }
    notification.info(INFO_MESSAGE)
    try {
      await changePassword(password, newPassword)
      notification.success(SUCCESS_MESSAGE)
    } catch (error) {
      notification.error(error.message)
    }
  }

  return (
    <Form
      errors={errors}
      notification={notification.value}
      onChange={notification.reset}
      onSubmit={onSubmit}
    />
  )
}

export default ChangePasswordForm
