import { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '../../stories/Typography'

const LastnameFilter = ({ filterFunction }) => {
  const [lastnameFilter, setLastnameFilter] = useState('')

  return (
    <>
      <Typography variant='h5' gutterBottom>
        Filter by Last Name.
      </Typography>
      <Box
        display='flex'
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          margin: { xs: '0px', sm: '-16px 0px -8px 0px' },
        }}
      >
        <Box padding='24px 8px'>
          <TextField
            id='last-name'
            value={lastnameFilter}
            onChange={e => {
              filterFunction('lastname', e.target.value?.toLowerCase()?.trim())
              setLastnameFilter(e.target.value?.toLowerCase()?.trim())
            }}
            label='Last Name'
            variant='outlined'
            fullWidth
            style={{
              backgroundColor: '#fff',
            }}
          />
        </Box>
      </Box>
    </>
  )
}

LastnameFilter.propTypes = {
  filterFunction: PropTypes.func.isRequired,
}

export default LastnameFilter
