import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import ProviderVouchersForm from '../containers/ProviderVouchersForm'
import RedeemVoucherModalContainer from '../containers/RedeemVoucherModalContainer'
import ProviderVouchersRows from '../containers/ProviderVouchersRows'
import VoucherList from './VoucherList'
import Heading from '../stories/Heading'
import ClaimVoucherModal from '../containers/ClaimVoucherModal'

const HEADERS = [
  { label: 'info', align: 'center', type: 'icon', user: 'provider' },
  { label: 'consumer', align: 'left', type: 'text' },
  { label: 'service', align: 'left', type: 'text' },
  { label: 'Appt Date', align: 'left', type: 'text' },
  { label: 'Purchased On', align: 'left', type: 'text' },
  { label: 'voucher ID', align: 'left', type: 'text' },
  { label: 'status', align: 'center', type: 'text' },
  { label: 'redeem', align: 'center', type: 'text' },
]

const ProviderVouchers = ({ uid }) => (
  <Grid container spacing={2} mt={2}>
    <Grid item xs={12}>
      <Heading variant='body1'>
        Enter order #, voucher # or consumer last name and DOB to search for voucher
      </Heading>
    </Grid>
    <ProviderVouchersForm uid={uid} />
    <Grid item xs={12} sm={12} md={12}>
      <VoucherList headers={HEADERS}>
        <RedeemVoucherModalContainer />
        <ClaimVoucherModal uid={uid} />
        <ProviderVouchersRows />
      </VoucherList>
    </Grid>
  </Grid>
)

ProviderVouchers.propTypes = {
  uid: PropTypes.string.isRequired,
}

export default ProviderVouchers
