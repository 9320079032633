import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'

const StripeSetupResult = () => {
  // get 'result' from query params
  const { search } = useLocation()

  const params = new URLSearchParams(search)

  const result = params.get('result')

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Stripe Setup Result',
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>Thank you!</title>
        <meta property='og:title' content='Thank you!' />
      </Helmet>
      <Container maxWidth='lg'>
        <Section>
          {result == null && (
            <Box>
              <Typography variant='h1' align='center'>
                Something went wrong
              </Typography>
              <Typography variant='body1' align='center'>
                Please try again later.
              </Typography>
            </Box>
          )}
          {result === 'success' && (
            <Box>
              <Typography variant='h1' align='center'>
                Success!
              </Typography>
              <Typography variant='body1' align='center'>
                You have successfully linked your Bank Account to your account.
              </Typography>
            </Box>
          )}
          {result === 'canceled' && (
            <Box>
              <Typography variant='h1' align='center'>
                Canceled
              </Typography>
              <Typography variant='body1' align='center'>
                You have canceled the linking of your Bank Account.
              </Typography>
            </Box>
          )}
        </Section>
      </Container>
    </>
  )
}

export default StripeSetupResult
