import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiBox from '@mui/material/Box'

const ArrowFoot = '80px'
const ArrowPeak = '56px'

const ArrowWrapper = styled(MuiBox)`
  position: relative;
  margin: ${ArrowPeak} 0px;
  width: 20%;
  z-index: 2;
  ${props =>
    props.orientation.xs === 'top' &&
    css`
      width: 40%;
      height: ${ArrowPeak};
      margin: 0px;
    `}
  ${props =>
    props.orientation.xs === 'down' &&
    css`
      width: 40%;
      height: ${ArrowPeak};
      margin: 0px;
    `}
  ${props => props.theme.breakpoints.up('sm')} {
    ${props =>
      props.orientation.sm === 'right' &&
      css`
        width: auto;
        height: auto;
        flex-grow: 1;
        margin: ${ArrowPeak} 0px 0px;
      `}
  }
`

const ArrowTail = styled(MuiBox)`
  display: inline-block;
  position: absolute;
  width: 100%;
  height: calc(${ArrowFoot} / 2);
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  background: ${props => props.theme.palette.auxLight.dark};
  ${props =>
    props.orientation.xs === 'top' &&
    css`
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(${ArrowFoot} / 2);
      height: 100%;
    `}
  ${props =>
    props.orientation.xs === 'down' &&
    css`
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(${ArrowFoot} / 2);
      height: 100%;
    `}
  ${props => props.theme.breakpoints.up('sm')} {
    ${props =>
      props.orientation.sm === 'right' &&
      css`
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        width: 100%;
        height: calc(${ArrowFoot} / 2);
      `}
  }
`

const ArrowTip = styled(MuiBox)`
  width: ${ArrowPeak};
  height: ${ArrowFoot};
  position: absolute;
  overflow: hidden;
  top: 50%;
  right: -${ArrowPeak};
  transform: translateY(-50%);
  &:after {
    content: '';
    position: absolute;
    width: ${ArrowPeak};
    height: ${ArrowPeak};
    background: ${props => props.theme.palette.auxLight.dark};
    left: calc(-${ArrowPeak} / 2);
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
  ${props =>
    props.orientation.xs === 'left' &&
    css`
      width: ${ArrowPeak};
      height: ${ArrowFoot};
      left: -${ArrowPeak};
      right: auto;
      &:after {
        left: calc(${ArrowPeak} / 2);
      }
    `}
  ${props =>
    props.orientation.xs === 'right' &&
    css`
      width: ${ArrowPeak};
      height: ${ArrowFoot};
      right: -${ArrowPeak};
      &:after {
        left: calc(-${ArrowPeak} / 2);
      }
    `}
  ${props =>
    props.orientation.xs === 'top' &&
    css`
      width: ${ArrowFoot};
      height: ${ArrowPeak};
      top: -${ArrowPeak};
      right: 50%;
      transform: translateX(50%);
      &:after {
        left: 50%;
        top: calc(${ArrowPeak} / 2);
        transform: translateX(-50%) rotate(-45deg);
      }
    `}
  ${props =>
    props.orientation.xs === 'down' &&
    css`
      width: ${ArrowFoot};
      height: ${ArrowPeak};
      top: auto;
      bottom: -${ArrowPeak};
      right: 50%;
      transform: translateX(50%);
      &:after {
        left: 50%;
        top: calc(-${ArrowPeak} / 2);
        transform: translateX(-50%) rotate(-45deg);
      }
    `}
  ${props => props.theme.breakpoints.up('sm')} {
    ${props =>
      props.orientation.sm === 'right' &&
      css`
        width: ${ArrowPeak};
        height: ${ArrowFoot};
        left: auto;
        right: -${ArrowPeak};
        top: 50%;
        transform: translateY(-50%);
        &:after {
          left: calc(-${ArrowPeak} / 2);
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }
      `}
  }
`

const Arrow = ({ orientation = { xs: 'right', sm: 'right' }, ...props }) => (
  <ArrowWrapper orientation={orientation} {...props}>
    <ArrowTail orientation={orientation} />
    <ArrowTip orientation={orientation} />
  </ArrowWrapper>
)

Arrow.propTypes = {
  orientation: PropTypes.shape({
    xs: PropTypes.string,
    sm: PropTypes.string,
  }),
}

export default Arrow
