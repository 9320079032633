import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'

const ComponentShifterFade = ({ children, length = 2000 }) => {
  const [fadeIn, setFadeIn] = useState(true)
  const [childShift, setChildShift] = useState(0)
  const timeoutRef = useRef(null)

  const childrenArray = Object.keys(children)

  useEffect(() => {
    // Start the timer
    timeoutRef.current = setInterval(() => {
      setFadeIn(false)
      setTimeout(() => {
        setChildShift(prevShift => (prevShift + 1) % childrenArray.length)
        setFadeIn(true)
      }, 1500)
    }, length)

    // Clean up the timer when the component unmounts
    return () => {
      clearInterval(timeoutRef.current)
    }
  }, [childrenArray.length, length])

  return (
    <Fade in={fadeIn} timeout={{ enter: 1500, exit: 1500 }} style={{ height: '100%' }}>
      <Box
        display='flex'
        padding='0 2rem'
        width='100%'
        height='100%'
        justifyContent='center'
        alignItems='center'
      >
        {children[childShift]}
      </Box>
    </Fade>
  )
}

ComponentShifterFade.propTypes = {
  children: PropTypes.array.isRequired,
  length: PropTypes.number,
}

export default ComponentShifterFade
