import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
// import useToggleOpen from '../hooks/useToggleOpen'

const VoucherModal = createContext()

export const useVoucherModal = () => useContext(VoucherModal)

const VoucherModalProvider = ({ children }) => {
  const [voucher, setVoucher] = useState(null)
  const [subVoucher, setSubVoucher] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = () => {
    setAnchorEl({}) // Set to an empty object to make open become true
  }

  const value = {
    open,
    voucher,
    subVoucher,
    handleClick,
    handleClose,
    setVoucher,
    setSubVoucher,
    handleOpen,
  }
  return <VoucherModal.Provider value={value}>{children}</VoucherModal.Provider>
}

VoucherModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default VoucherModalProvider
