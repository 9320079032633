import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link as ReactLink } from 'react-router-dom'
import Box from '@mui/material/Box'

const LinkBase = css`
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ${props => props.theme.transitions.easing.easeInOut};
  text-decoration: underline;
  line-height: 1.5;
  color: ${props => props.theme.palette.text.primary};
  margin: 0px ${props => props.theme.spacing(2)};
  &:hover {
    color: ${props => props.theme.palette.primary.main};
  }
  ${props =>
    props.display === 'standard' &&
    css`
      margin: 0px;
      color: ${props.theme.palette.primary.main};
      font-weight: 500;
      line-height: 24px;
      &:hover {
        color: ${props.theme.palette.text.primary};
      }
    `}
  ${props =>
    props.display === 'inline' &&
    css`
      margin: 0px;
      color: inherit;
    `}
  ${props =>
    props.display === 'standalone' &&
    css`
      display: block;
      margin: 0px;
    `}
  ${props =>
    props.size === 'xsmall' &&
    css`
      font-size: 0.65rem;
    `}
  ${props =>
    props.size === 'small' &&
    css`
      font-size: 0.875rem;
    `}
  ${props =>
    props.size === 'large' &&
    css`
      font-size: 1.25rem;
    `}
  ${props =>
    props.variant === 'unstyled' &&
    css`
      margin: 0px;
      text-decoration: none;
    `}
  ${props =>
    props.flex === 'flex' &&
    css`
      display: flex;
    `}
`

const ButtonBase = css`
  font-size: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ${props => props.theme.transitions.easing.easeInOut};
  text-decoration: underline;
  line-height: 1.5;
  color: ${props => props.theme.palette.text.primary};
  margin: 0px ${props => props.theme.spacing(2)};
  &:hover {
    color: ${props => props.theme.palette.primary.main};
  }
  ${props =>
    props.display === 'standard' &&
    css`
      margin: 0px;
      color: ${props.theme.palette.primary.main};
      font-weight: 500;
      line-height: 24px;
      &:hover {
        color: ${props.theme.palette.text.primary};
      }
    `}
  ${props =>
    props.display === 'inline' &&
    css`
      margin: 0px;
      color: inherit;
    `}
  ${props =>
    props.display === 'standalone' &&
    css`
      display: block;
      margin: 0px;
    `}
  ${props =>
    props.size === 'xsmall' &&
    css`
      font-size: 0.65rem;
    `}
  ${props =>
    props.size === 'small' &&
    css`
      font-size: 0.875rem;
    `}
  ${props =>
    props.size === 'large' &&
    css`
      font-size: 1.25rem;
    `}
  ${props =>
    props.variant === 'unstyled' &&
    css`
      margin: 0px;
      text-decoration: none;
    `}
  ${props =>
    props.flex === 'flex' &&
    css`
      display: flex;
    `}
`

const InternalLink = styled(ReactLink)`
  ${LinkBase}
`

const ExternalLink = styled.a`
  ${LinkBase}
`

const BlankLink = styled.button`
  ${ButtonBase}
`

const Link = ({
  children,
  display = 'block',
  align = 'left',
  type = 'internal',
  to = '',
  ...props
}) => (
  <Box display={display} textAlign={align} component='span'>
    {type === 'internal' && (
      <InternalLink display={display} to={to} size='' variant='' {...props}>
        {children}
      </InternalLink>
    )}
    {type === 'external' && (
      <ExternalLink display={display} href={to} size='' variant='' {...props}>
        {children}
      </ExternalLink>
    )}
    {type === 'blank' && (
      <BlankLink display={display} size='' variant='' {...props}>
        {children}
      </BlankLink>
    )}
  </Box>
)

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  display: PropTypes.string,
  align: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
}

export default Link
