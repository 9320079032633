import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { TermsList as List, TermsListItem as Item } from '../Terms'

const BookingPolicy = () => {
  // Make the box containing the terms collapsible
  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(!open)

  return (
    <Box display='block' alignItems='center' mb={3} mt={3}>
      <Typography variant='h3'>Mishe Booking Policy</Typography>
      <Typography variant='body2'>
        Thank you for choosing Mishe for your healthcare needs. To ensure a seamless experience for
        all our patients and healthcare providers, we have established the following booking policy.
        Please take a moment to familiarize yourself with these terms:
      </Typography>
      {open ? (
        <Box width='100%'>
          <Box mt={2}>
            <Typography variant='body1' weight='bold'>
              1. Reservation Fee and Cancellation:
            </Typography>
            <List>
              <Item>
                Upon scheduling your appointment through Mishe, a portion of the visit cost will be
                reserved as a non-refundable fee. This fee helps cover the administrative and
                operational costs associated with securing your appointment.
              </Item>
              <Item>
                Should you need to cancel or reschedule your appointment, please be advised that
                this reservation fee will not be refunded. We recommend reaching out to our support
                team for any queries or assistance related to your booking.
              </Item>
            </List>
          </Box>
          <Box mt={2}>
            <Typography variant='body1' weight='bold'>
              2. Post-Visit Refund Policy::
            </Typography>
            <List>
              <Item>
                Once your visit has been successfully completed, the full cost of the service
                becomes non-refundable. This policy ensures that our network of dedicated healthcare
                professionals is appropriately compensated for their expertise and the quality care
                they provide.
              </Item>
              <Item>
                If you have concerns or feedback about your visit, we encourage you to contact our
                patient support team. We are committed to ensuring your satisfaction and addressing
                any concerns you may have.
              </Item>
            </List>
          </Box>
          <Box mt={2}>
            <Typography variant='body1' weight='bold'>
              3. Communication and Updates:
            </Typography>
            <List>
              <Item>
                Mishe may send you reminders or updates related to your appointment via email, SMS,
                or other communication channels. Please ensure your contact details are up-to-date
                in our system.
              </Item>
              <Item>
                If there are any changes or updates to your scheduled appointment, our team will
                make every effort to notify you promptly.
              </Item>
            </List>
          </Box>
          <Typography
            variant='body2'
            onClick={handleClick}
            align='center'
            style={{
              color: '#662D91',
              fontSize: '14px',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Hide Terms
          </Typography>
        </Box>
      ) : (
        <Box mt={2}>
          <Typography
            variant='body2'
            onClick={handleClick}
            align='center'
            style={{
              color: '#662D91',
              fontSize: '14px',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            View Terms
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default BookingPolicy
