import { useMediaQuery } from 'react-responsive'

export const isDesktopView = () => useMediaQuery({ query: '(min-width: 1280px)' })

export const isMobileView = () => useMediaQuery({ query: '(max-width: 1279px)' })

export const getScreenType = () => {
  const queries = {
    mobile: useMediaQuery({ maxWidth: 767 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
  }

  if (queries.mobile) {
    return 'mobile'
  }

  if (queries.tablet) {
    return 'tablet'
  }

  return 'desktop'
}

const AutoBreakpoint = ({ DesktopChildren, MobileChildren }) => {
  if (isMobileView()) {
    return MobileChildren
  }

  return DesktopChildren
}

export default AutoBreakpoint
