import Providers from './providers'
import Routes from './routes'

const App = () => (
  <Providers>
    <Routes />
  </Providers>
)

export default App
