import Section from '../stories/Section'
import Container from '../stories/Container'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import NavLink from '../stories/NavLink'

const PageNotFound = () => {
  return (
    <Section>
      <Container>
        <Typography
          variant='h1'
          align='center'
          gutterBottom
          color='primary'
          style={{ marginTop: '100px' }}
          family='poppins'
        >
          Page Not Found
        </Typography>
        <Typography variant='body1' align='center' gutterBottom family='poppins'>
          Oops! It seems like you&apos;ve stumbled upon a page that doesn&apos;t exist.
          <br />
          Just click the button below to go back home and start fresh!
        </Typography>
        <Typography variant='body1' align='center' gutterBottom family='poppins'>
          <NavLink to='/'>
            <Button variant='contained' color='primary'>
              Go to Home
            </Button>
          </NavLink>
        </Typography>
      </Container>
    </Section>
  )
}

export default PageNotFound
