import Container from '../stories/Container'
import Backdrop from '@mui/material/Backdrop'
import SignUpForm from '../containers/SignUpForm'
import Typography from '../stories/Typography'
import Link from '../stories/Link'
import Section from '../stories/Section'
import Modal from '../stories/Modal'

const SignUpModal = ({ open, handleClose, callback }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Container
        maxWidth='xs'
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Section width='100%'>
          <Typography variant='h2' gutterBottom>
            Create account
          </Typography>
          <Typography variant='body2' weight='light' color='inherit'>
            Already have an account?{' '}
            <Link to='/signin' display='standard'>
              Log in here.
            </Link>
          </Typography>
          <SignUpForm callback={callback} />
          <br />
        </Section>
      </Container>
    </Modal>
  )
}

export default SignUpModal
