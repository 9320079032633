import { memo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '../stories/Typography'
import Modal from '../stories/Modal'
import Divider from '../stories/Divider'
import Button from '../stories/Button'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'

import { formatDateForUI } from '@app/libs/formatters'

const RedeemVoucherModal = ({
  open = false,
  handleClose,
  voucherCode = '*Voucher code not found, please contact support*',
  subVoucherCode,
  customerFirstName = '*Customer name not found, please contact support*',
  customerLastName = '*Customer name not found, please contact support*',
  customerDOB = '*Customer DOB not found, please contact support*',
  service = '*Service name not found, please contact support*',
  subVoucherService,
  errors,
  onRedeem,
}) => (
  <Modal maxWidth='sm' open={open} onClose={handleClose}>
    {!errors.redeem && (
      <>
        <form onSubmit={onRedeem}>
          <Box>
            <Typography variant='h3' weight='medium' align='center' family='poppins'>
              Redeem voucher
            </Typography>
            <Divider spacing='vertical' />
            <Typography variant='body1' family='poppins' style={{ textTransform: 'capitalize' }}>
              Customer: {customerFirstName} {customerLastName}
            </Typography>
            <Typography variant='body1' family='poppins' style={{ textTransform: 'capitalize' }}>
              Date of Birth: {formatDateForUI(customerDOB)}
            </Typography>
            <Divider spacing='vertical' />
            <Typography variant='body1' family='poppins' style={{ textTransform: 'capitalize' }}>
              Service: {subVoucherService || service}
            </Typography>
            <Typography variant='body1' family='poppins' style={{ textTransform: 'capitalize' }}>
              Voucher Code: {subVoucherCode || voucherCode}
            </Typography>
            <Divider spacing='vertical' />
          </Box>
          <Box display='flex' justifyContent='space-around' mt={4}>
            <Button size='medium' variant='outlined' onClick={handleClose} fullWidth>
              cancel
            </Button>
            <Button type='submit' size='medium' variant='contained' fullWidth>
              redeem
            </Button>
          </Box>
        </form>
      </>
    )}
    {errors.redeem && (
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='body1' family='poppins' style={{ textTransform: 'capitalize' }}>
          {errors.redeem}
        </Typography>
        <Box padding='0px 0px 0px 8px'>
          <IconButton onClick={handleClose} size='large'>
            <Icon name='close' />
          </IconButton>
        </Box>
      </Box>
    )}
  </Modal>
)

RedeemVoucherModal.propTypes = {
  open: PropTypes.bool,
  errors: PropTypes.shape({
    redeem: PropTypes.string,
  }).isRequired,
  voucherCode: PropTypes.string,
  customerFirstName: PropTypes.string,
  customerLastName: PropTypes.string,
  customerDOB: PropTypes.string,
  service: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onRedeem: PropTypes.func.isRequired,
}

export default memo(RedeemVoucherModal)
