import PropTypes from 'prop-types'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { outlook, office365, yahoo } from 'calendar-link'
import queryString from 'query-string'
import { add } from 'date-fns'
import Icon from '../stories/Icons'
import googleCalendar from '../assets/images/calenders/googleCalender.svg'
import outlookCalendar from '../assets/images/calenders/outlookCalender.svg'
import officeCalender from '../assets/images/calenders/office365.svg'
import yahooCalender from '../assets/images/calenders/yahoo.svg'
import icsCalender from '../assets/images/calenders/icsCalender.svg'
import { createDownloadICSFile } from '../libs/icsFile'

const AddToCalender = ({ onClose, open, calenderEvent }) => {
  const calenderConfig = {
    ...calenderEvent,
    start: calenderEvent.appointmentDate.toDate().toLocaleString(),
  }

  const googleCalenderEventLink = () => {
    const { title, description, appointmentDate } = calenderEvent
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const startTime = appointmentDate.toDate()
    const endTime = add(startTime, { hours: 1 })

    const formattedStartTime = startTime.toISOString().replace(/-|:|\.\d\d\d/g, '')
    const formattedEndTime = endTime.toISOString().replace(/-|:|\.\d\d\d/g, '')

    const details = {
      action: 'TEMPLATE',
      text: title,
      details: description,
      dates: `${formattedStartTime}/${formattedEndTime}`,
      ctz: timeZone,
    }

    return `https://calendar.google.com/calendar/render?${queryString.stringify(details)}`
  }

  const handleListItemClick = value => {
    switch (value) {
      case 'google':
        window.open(googleCalenderEventLink(calenderConfig), '_blank')
        break
      case 'outlook':
        window.open(outlook(calenderConfig), '_blank')
        break
      case 'office365':
        window.open(office365(calenderConfig), '_blank')
        break
      case 'yahoo':
        window.open(yahoo(calenderConfig), '_blank')
        break
      case 'ics':
        icsFile(calenderConfig)
        break
      default:
        break
    }
  }

  const icsFile = () => {
    const { title, description, appointmentDate } = calenderEvent

    const endTime = add(appointmentDate.toDate(), { hours: 1 })

    const startTime = new Date(appointmentDate.toDate())

    createDownloadICSFile({
      title,
      description,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startTime: startTime,
      endTime,
    })
  }

  const calenderList = [
    { name: 'Google', icon: googleCalendar, id: 'google' },
    { name: 'Outlook', icon: outlookCalendar, id: 'outlook' },
    { name: 'Office365', icon: officeCalender, id: 'office365' },
    { name: 'Yahoo', icon: yahooCalender, id: 'yahoo' },
    { name: 'Download ICS', icon: icsCalender, id: 'ics' },
  ]

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Add To Calender</DialogTitle>
      <List sx={{ pt: 0 }}>
        {calenderList.map(({ name, icon, id }) => (
          <ListItem disableGutters key={id}>
            <ListItemButton onClick={() => handleListItemClick(id)}>
              <ListItemAvatar>
                <Avatar src={icon} alt='calendar' size='mediumlarge'>
                  <Icon name='calendar' />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

AddToCalender.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default AddToCalender
