import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Container from '../stories/Container'
import Section from '../stories/Section'
import NavLink from '../stories/NavLink'
import LiveChat from '../stories/LiveChat'
import Typography from '../stories/Typography'
import { scrollToHash } from '../libs/scroll'

import WhyMisheHero from '../assets/images/why-mishe-hero.png'
import HealthCareCostGraphic from '../assets/images/heatlthcare-cost-image.svg'
import PaientChat from '../assets/images/paient-chat.png'
import HealthCareVision from '../assets/images/healthcare-vision.png'
import HealthCareSecurity from '../assets/images/healthcare-security.png'

import MisheFlow from '../components/MisheFlow'

const WhyMishePage = () => {
  const { hash, search } = useLocation()
  const ref = new URLSearchParams(search).get('ref')
  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'WhyMishePage',
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeout
    if (hash) {
      timeout = setTimeout(() => {
        // wait 0.3s before scrolling
        scrollToHash(hash)
      }, 200)
    }
    return () => {
      // clear timeout on re-render
      clearTimeout(timeout)
    }
  })

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Why Mishe? Transparency, Equity and Access for All.</title>
        <meta property='og:title' content='Why Mishe? Transparency, Equity and Access for All.' />
        <meta
          name='description'
          property='og:description'
          content='This is not our parents’ healthcare system. Mishe is simple to use, puts you in control, and provides everyone with access to affordable care.'
        />
        <meta property='og:url' content='https://mishe.co/why-mishe' />
        <link rel='canonical' href='https://mishe.co/why-mishe' />
      </Helmet>
      <Section>
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Typography variant='h1' gutterBottom>
                <Box component='span' color='primary.main'>
                  Why{' '}
                </Box>
                choose mishe?
              </Typography>
              <Typography variant='body1' weight='light' style={{ maxWidth: '430px' }}>
                mishe is simple to use, it provides access to affordable healthcare for everyone,
                and gives you control. This is not your parents’ healthcare system. We are shifting
                the paradigm – putting power back in the hands of consumers and providers – and
                we’re proud of it.
              </Typography>
              <Box mt={3}>
                <NavLink
                  to='#howitworks'
                  rel='nofollow'
                  display='standard'
                  color='primary'
                  family='poppins'
                >
                  How it works{' '}
                  <ArrowDownwardIcon
                    style={{
                      color: '#662D91',
                      fontSize: '18px',
                      marginLeft: 10,
                    }}
                  />
                </NavLink>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} />
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img src={WhyMisheHero} alt='WhyMisheHero' width='100%' />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <MisheFlow />
      <Section bgcolor='background.smoke'>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box mb={1}>
                <Typography variant='caption' weight='semibold'>
                  <Box component='span' color='primary.main'>
                    DIRECT, EFFICIENT, AFFORDABLE
                  </Box>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='h3' gutterBottom>
                  This{' '}
                  <Box component='span' color='primary.main'>
                    is not your parents’{' '}
                  </Box>
                  healthcare system.
                </Typography>
              </Box>
              <Typography variant='body1' weight='light' style={{ maxWidth: '480px' }}>
                Today’s healthcare system makes us jump through hoops. Unnecessary red tape serves
                the middlemen, but delays care and increase costs for consumers and providers. mishe
                eliminates the waste and streamlines care at the most competitive prices by
                connecting you directly with providers. That means faster access to care with no
                added restrictions, requirements or costs. Everything is covered. <br />
                We support an ever-growing number of heavily trained and licensed medical providers.
                Eliminating middle-man interference allows for better care while passing savings on
                to you. There’s no catch – this is the system everyone deserves. This is how we
                heal.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} />
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <img src={HealthCareCostGraphic} alt='CostGraphic' width='100%' />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box mb={1}>
                <Typography variant='caption' weight='semibold'>
                  <Box component='span' color='primary.main'>
                    TRANSPARENT, INFORMATIVE, EMPOWERING
                  </Box>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='h3' gutterBottom>
                  This is you taking your care.{' '}
                  <Box component='span' color='primary.main'>
                    into your own hands
                  </Box>
                </Typography>
              </Box>
              <Typography variant='body1' weight='light' style={{ maxWidth: '480px' }}>
                At mishe, we’re just like you. We want to know what options are available, what’s
                included and what it’s going to cost. We take price transparency and consumerism
                very seriously. <br /> <br />
                Information is power and we’re putting it back in your hands. We work directly with
                providers to give you the actionable information needed to make educated decisions.
                The prices you see are real – not estimates, bids, or averages – and they come
                directly from the source. <br /> <br />
                Say hello to your provider and goodbye to surprises. No need to delay or avoid care
                because with mishe, you call the shots.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} />
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img src={PaientChat} alt='PaientChat' width='100%' />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section bgcolor='background.smoke'>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box mb={1}>
                <Typography variant='caption' weight='semibold'>
                  <Box component='span' color='primary.main'>
                    INCLUSIVE, COMPREHENSIVE, EQUITABLE
                  </Box>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='h3' gutterBottom>
                  This is our vision of{' '}
                  <Box component='span' color='primary.main'>
                    healthcare for all.
                  </Box>
                </Typography>
              </Box>
              <Typography variant='body1' weight='light' style={{ maxWidth: '480px' }}>
                Access to healthcare is a fundamental right. It should not depend on insurance
                coverage, employment status, medical history, geographic location, or income level.{' '}
                <br /> <br />
                Our journey began by focusing on those with the most need who have been marginalized
                without traditional access to healthcare.
                <br /> <br />
                Together we are building a network that fosters health literacy and guarantees
                access so everyone is covered. <br /> <br />
                mishe strives to bridge the knowledge gap to provide you with the care you value.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} />
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <img src={HealthCareVision} alt='HealthCareVision' width='100%' />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box mb={1}>
                <Typography variant='caption' weight='semibold'>
                  <Box component='span' color='primary.main'>
                    PRIVATE, SECURE, COMPLIANT
                  </Box>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='h3' gutterBottom>
                  This is your data and we{' '}
                  <Box component='span' color='primary.main'>
                    protect it like it’s our own.
                  </Box>
                </Typography>
              </Box>
              <Typography variant='body1' weight='light' style={{ maxWidth: '480px' }}>
                We know your health information is sacred and protecting it has been our
                foundational principle from day one. <br /> <br />
                Our multi-layered safeguards shield your data throughout our encrypted
                infrastructure to ensure HIPAA compliance, maximum data privacy, and greater peace
                of mind. <br /> <br />
                During their decades in the industry, mishe’s leadership amassed extensive
                experience managing sensitive health data for millions of consumers nationwide.
                Their thorough understanding of HIPAA compliance requirements laid the foundation
                for our fortified security apparatus that keeps your protected health information
                safe.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} />
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <img src={HealthCareSecurity} alt='HealthCareSecurity' width='100%' />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <LiveChat />
    </>
  )
}

export default WhyMishePage
