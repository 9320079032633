import { useCallback } from 'react'
import { useVoucherDetails } from '../context/VoucherDetails'
import { useOrderModal } from '../context/AddOrderModal'
import Modal from '../components/AddOrderModal'

const AddNoteModal = () => {
  const { setCurrentFile } = useVoucherDetails() // eslint-disable-line no-unused-vars
  const { open, handleClose, orderType, setOrderType } = useOrderModal() // eslint-disable-line no-unused-vars

  const onUpload = useCallback(async () => {
    setCurrentFile(null)
    setOrderType(null)
    handleClose()
  }, [handleClose, setCurrentFile, setOrderType])

  const closed = useCallback(() => {
    setOrderType(null)
    handleClose()
  }, [handleClose, setOrderType])

  return <Modal open={open} orderType={orderType} handleClose={closed} onUpload={onUpload} />
}

export default AddNoteModal
