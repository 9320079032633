import React, { useState, useEffect } from 'react'
import { convertFirestoreToDate } from '../libs/formatters'
import { Box, Grid, Typography, Paper } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import EventIcon from '@mui/icons-material/Event'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import InfoIcon from '@mui/icons-material/Info'
import { getSubVouchers } from '../utils/voucherUtils'
import VoucherDetailsEntry from '../containers/voucherDetailsEntry'

function getRecentMostVoucher(rows) {
  return rows.reduce((nearest, current) => {
    if (
      current.organization?.name && // if it has clinic name
      current.appointmentDate && // if it has a date
      convertFirestoreToDate(current.appointmentDate) >= new Date() &&
      (!nearest ||
        convertFirestoreToDate(current.appointmentDate) <
          convertFirestoreToDate(nearest.appointmentDate)) // if the current date is most recent
    ) {
      return current //set that to the most recent service
    }
    return nearest
  }, null)
}

const VoucherUpcomingAppointment = ({ rows = [] }) => {
  const [allVouchers, setAllVouchers] = useState([])
  const [mostRecentBundle, setMostRecentBundle] = useState(null)

  useEffect(() => {
    const subVouchers = rows
      .filter(row => row?.bundle)
      .map(row => getSubVouchers(row.uid, row.user.id))

    if (subVouchers.length) {
      Promise.all(subVouchers).then(subVouchersArrays => {
        const subVouchersFlat = subVouchersArrays.flat()
        setAllVouchers([...rows, ...subVouchersFlat])
      })
    } else {
      setAllVouchers(rows)
    }
  }, [rows])

  useEffect(() => {
    const recentApptVoucher = getRecentMostVoucher(allVouchers)
    setMostRecentBundle(recentApptVoucher)
  }, [allVouchers])

  if (!mostRecentBundle) {
    // doing this so it wont render anything until we have a most recent bundle
    return (
      <Paper
        elevation={2}
        sx={{
          padding: '16px',
          textAlign: 'center',
          backgroundColor: '#f5f5f5',
          cursor: 'pointer',
          transition: 'transform 0.2s, box-shadow 0.2s',
          '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        No Upcoming Appointments
      </Paper>
    )
  }

  // getting date of the most recent bundle for the UI of this component
  const dateOfAppt = convertFirestoreToDate(mostRecentBundle.appointmentDate)

  const appointmentDetails = {
    month: dateOfAppt.toLocaleString('default', { month: 'short' }),
    year: dateOfAppt.getFullYear(),
    day: dateOfAppt.getDate(),
    hours: dateOfAppt.getHours(),
    minutes: dateOfAppt.getMinutes().toString().padStart(2, '0'),
  }

  const hours = appointmentDetails.hours % 12 || 12 // Convert 0 to 12 for midnight
  const period = appointmentDetails.hours >= 12 ? 'PM' : 'AM'

  const time = `${hours}:${appointmentDetails.minutes} ${period}`
  const service = mostRecentBundle.service?.name || '---'
  const clinic = mostRecentBundle.organization?.name || '---'

  return (
    <VoucherDetailsEntry voucher={mostRecentBundle}>
      <Paper
        elevation={2}
        sx={{
          padding: '16px',
          marginBottom: '16px',
          backgroundColor: '#f5f5f5',
          cursor: 'pointer',
          transition: 'transform 0.2s, box-shadow 0.2s',
          '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={8} md={3}>
            <Box display='flex' alignItems='center'>
              <InfoIcon color='info' />
              <Typography variant='subtitle1' color='textSecondary' ml={1}>
                Upcoming Appointment
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Box display='flex' alignItems='center'>
              <EventIcon color='primary' />
              <Typography variant='h6' ml={1}>
                {`${appointmentDetails.month} ${appointmentDetails.day}, ${appointmentDetails.year}`}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box display='flex' alignItems='center'>
              <AccessTimeIcon color='primary' />
              <Typography variant='h6' ml={1}>
                {time}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Box display='flex' alignItems='center'>
              <LocalHospitalIcon color='primary' />
              <Typography variant='h6' ml={1}>
                {service}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box display='flex' alignItems='center'>
              <LocationOnIcon color='primary' />
              <Typography variant='h6' ml={1}>
                {clinic}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </VoucherDetailsEntry>
  )
}

export default VoucherUpcomingAppointment
