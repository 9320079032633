import PropTypes from 'prop-types'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

const FilterContainer = ({ open, leftComponents, rightComponents, actionComponents }) => {
  return (
    <Collapse
      in={open}
      unmountOnExit
      mountOnEnter
      timeout={{ enter: 250, exit: 250 }}
      style={{ width: '100%' }}
    >
      <Fade in={open} timeout={{ enter: 500, exit: 250 }} style={{ width: '100%' }}>
        <Grid item xs={12} style={{ width: '100%', padding: '14px' }}>
          <Grid
            container
            style={{
              width: '100%',
              boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.2)',
              borderRadius: '4px',
              padding: '16px',
            }}
          >
            <Grid item xs={12} md={5} mb={3}>
              {leftComponents}
            </Grid>
            <Grid item xs={12} md={7} mb={3}>
              <Box
                display='flex'
                flexDirection='column'
                sx={{
                  width: '100%',
                  alignItems: {
                    xs: 'flex-start',
                    sm: 'flex-end',
                  },
                }}
              >
                {rightComponents}
              </Box>
            </Grid>
            <Grid item xs={12} style={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                  width: '100%',
                }}
              >
                {actionComponents}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </Collapse>
  )
}

FilterContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  leftComponents: PropTypes.element.isRequired,
  rightComponents: PropTypes.element.isRequired,
  actionComponents: PropTypes.element.isRequired,
}

export default FilterContainer
