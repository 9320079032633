import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import WarningIcon from '@mui/icons-material/Warning'
import { green, red, yellow } from '@mui/material/colors'
import Typography from '@mui/material/Typography'
import { useVouchers } from '../context/Vouchers'
import Modal from '../stories/Modal'
import Heading from '../stories/Heading'
import Divider from '../stories/Divider'
import Button from '../stories/Button'

// NOTE: Made voucher id not required because it is null until triggered.
const ClaimVoucherModal = ({ voucherId = null, vouchers = null, flag = false }) => {
  const { redeemVoucher } = useVouchers()
  const [status, setStatus] = useState('')
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(true)
  const [iconText, setIconText] = useState('')

  useEffect(() => {
    async function redeem() {
      if (voucherId && vouchers && vouchers.length > 0) {
        if (vouchers[0].status === 'purchased') {
          await redeemVoucher(vouchers[0].uid)
          setStatus(`Voucher ${voucherId} was successfully redeemed.`)
          setIconText('purchased')
          setOpen(true)
        }
        if (!open) {
          if (vouchers[0].status === 'redeemed') {
            setStatus(`Voucher ${voucherId} is already redeemed.`)
            setIconText('redeemed')
          }
        }
      } else if (flag) {
        setIconText('empty')
        setStatus(
          'To redeem your care, please provide the voucher QR code when you present for care',
        )
      }
    }
    redeem()
  }, [flag, open, redeemVoucher, voucherId, vouchers])

  return (
    <Modal maxWidth='sm' open={openModal && flag}>
      <Box>
        {iconText === 'purchased' && (
          <>
            <CheckCircleOutlineIcon fontSize='large' style={{ color: green[500] }} />
            <Heading variant='h3' weight='bold' align='center'>
              Success
            </Heading>
          </>
        )}
        {iconText === 'redeemed' && (
          <>
            <WarningIcon fontSize='large' style={{ color: yellow[900] }} />
            <Heading variant='h3' weight='bold' align='center'>
              Something happened
            </Heading>
          </>
        )}
        {iconText === 'empty' && (
          <>
            <ErrorOutlineIcon fontSize='large' style={{ color: red[500] }} />
            <Heading variant='h3' weight='bold' align='center'>
              Voucher Status
            </Heading>
          </>
        )}
        <Divider spacing='vertical' />
        <Typography>{status}</Typography>
      </Box>
      <Box display='flex' justifyContent='space-around' mt={4}>
        <Button
          type='button'
          size='medium'
          variant='contained'
          fullWidth
          onClick={() => setOpenModal(false)}
        >
          Okay
        </Button>
      </Box>
    </Modal>
  )
}

ClaimVoucherModal.propTypes = {
  voucherId: PropTypes.string,
  flag: PropTypes.bool,
  vouchers: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
}

export default ClaimVoucherModal
