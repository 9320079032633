import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '../Typography'

const LoadingBar = ({ speed = 10, customWording = '' }) => {
  const [progress, setProgress] = useState(0)
  const [buffer, setBuffer] = useState(10)
  const [progressInterval, setProgressInterval] = useState(null)

  const startProgressBar = useCallback(() => {
    setProgress(0)
    setBuffer(10)
    clearInterval(progressInterval)
    setProgressInterval(
      setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * speed
          return Math.min(oldProgress + diff, 100)
        })
        setBuffer(oldProgress => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * speed
          return Math.min(oldProgress + diff, 100)
        })
      }, 100),
    )
  }, [progressInterval, speed])

  useEffect(() => {
    startProgressBar()
    return () => {
      setProgress(100)
      setBuffer(100)
      clearInterval(progressInterval)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid mt={2} spacing={3} display='flex' justifyContent='center' container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {customWording ? (
          <Typography variant='h4' align='center'>
            {customWording}
          </Typography>
        ) : (
          <Typography variant='h4' align='center'>
            Just a moment while we load your options...
          </Typography>
        )}
      </Grid>
      <Grid lg={6} md={6} sm={9} xs={9} item>
        <LinearProgress variant='buffer' value={progress} valueBuffer={buffer} />
      </Grid>
    </Grid>
  )
}

LoadingBar.propTypes = {
  speed: PropTypes.number,
  customWording: PropTypes.string,
}

export default LoadingBar
