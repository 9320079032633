import { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { Grid, Box } from '@mui/material'
import Button from '../stories/Button'
import { useCurrentClinic, useCurrentProfile } from '../context/CurrentUser'
import { collection } from '../firebase/firestore'
import InstantSearchContainer from '../containers/InstantSearchContainer'
import FeeScheduleCard from './FeeScheduleCard'

const PlansDashboardContent = () => {
  const { networksAssociated } = useCurrentClinic()
  const { organizationId } = useCurrentProfile()

  const [plans, setPlans] = useState([])
  const [filter, setFilter] = useState('')
  const [selectedPlanID, setSelectedPlanID] = useState(null)

  useEffect(() => {
    // get all plans
    const getPlans = async () => {
      // for each network id in the array of networks associated with the clinic get the data from firebase and add it to the plans array
      const promises = networksAssociated.map(async network => {
        const networkRef = await collection('networks').doc(network).get()

        const { name, slug } = networkRef.data()
        return {
          name,
          slug,
          id: networkRef.id,
        }
      })
      const results = await Promise.all(promises)
      setPlans(results)
    }
    if (networksAssociated?.length > 0) {
      getPlans()
    }
  }, [networksAssociated])

  const PlanNameCard = ({ plan }) => {
    return (
      <Box
        style={{
          width: '100%',
          marginBottom: '16px',
        }}
      >
        <Button
          id={plan.id}
          // conditionally add the variant prop using this selectedPlanID === plan.id
          variant={selectedPlanID === plan.id ? 'contained' : 'outlined'}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={async () => {
            setSelectedPlanID(plan.id)
            // set the filter to the plan id
            setFilter(`organizationId:${organizationId} AND network.slug:${plan.slug}`)
          }}
        >
          <Typography variant='h5' style={{ marginBottom: '0px' }}>
            {plan.name}
          </Typography>
        </Button>
      </Box>
    )
  }

  PlanNameCard.propTypes = {
    plan: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        style={{
          padding: '8px',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'scroll',
          }}
          sx={{
            maxHeight: {
              xs: '200px',
              sm: '200px',
              md: '572px',
            },
          }}
        >
          {plans.length === 0 && (
            <Typography variant='h6'>You have not added any plans yet.</Typography>
          )}
          {plans.length > 0 && plans.map(plan => <PlanNameCard key={plan.id} plan={plan} />)}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        style={{
          padding: '8px',
        }}
      >
        {filter && (
          <Box marginTop='-16px'>
            <InstantSearchContainer
              HitComponent={FeeScheduleCard}
              indexName='network_services_by_cpt'
              configuration={{
                filters: filter,
                hitsPerPage: 5,
              }}
              searchBoxText='Search by name or CPT'
              noResultsMessage='No results for'
            />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default PlansDashboardContent
