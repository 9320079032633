import Box from '@mui/material/Box'
import MedicalImg from '../assets/images/medical.svg'
import CalendarImg from '../assets/images/calendar.svg'
import CardImg from '../assets/images/card.svg'
import PricesImg from '../assets/images/prices.svg'
import Icon from '../stories/Icons'
import FeatureBox, { FeatureBoxText, FeatureBoxImg } from '../stories/FeatureBox'
import Avatar from '../stories/Avatar'
import Arrow from '../stories/Arrow'

const HowItWorksMini = () => (
  <Box
    position='relative'
    display='flex'
    justifyContent='space-between'
    flexWrap={{ xs: 'wrap', sm: 'wrap' }}
  >
    <Box order={1}>
      <FeatureBox>
        <FeatureBoxText>
          <strong>1</strong>
        </FeatureBoxText>
        <FeatureBoxImg rowpos='top' arrowed={{ xs: 'right', sm: 'right' }}>
          <Avatar src={PricesImg} alt='price tags' size='mediumlarge'>
            <Icon name='price' fontSize='large' />
          </Avatar>
        </FeatureBoxImg>
      </FeatureBox>
    </Box>

    <Arrow order={2} orientation={{ xs: 'right', sm: 'right' }} />

    <Box order={3}>
      <FeatureBox>
        <FeatureBoxText>
          <strong>2</strong>
        </FeatureBoxText>
        <FeatureBoxImg rowpos='top' arrowed={{ xs: 'bottom' }}>
          <Avatar src={CardImg} alt='credit card' size='mediumlarge'>
            <Icon name='card' fontSize='large' />
          </Avatar>
        </FeatureBoxImg>
      </FeatureBox>
    </Box>

    <Arrow order={4} orientation={{ xs: 'top' }} />

    <Arrow order={4} orientation={{ xs: 'down' }} />

    <Box order={5}>
      <FeatureBox>
        <Box order={2}>
          <FeatureBoxText>
            <strong>4</strong>
          </FeatureBoxText>
        </Box>
        <FeatureBoxImg rowpos='bottom' order={1} arrowed={{ xs: 'top' }}>
          <Avatar src={MedicalImg} alt='medical bag' size='mediumlarge'>
            <Icon name='cross' fontSize='large' />
          </Avatar>
        </FeatureBoxImg>
      </FeatureBox>
    </Box>

    <Arrow order={6} orientation={{ xs: 'left' }} />

    <Box order={7}>
      <FeatureBox>
        <Box order={2}>
          <FeatureBoxText>
            <strong>3</strong>
          </FeatureBoxText>
        </Box>
        <FeatureBoxImg rowpos='bottom' order={1} arrowed={{ xs: 'left' }}>
          <Avatar src={CalendarImg} alt='calendar' size='mediumlarge'>
            <Icon name='calendar' fontSize='large' />
          </Avatar>
        </FeatureBoxImg>
      </FeatureBox>
    </Box>
  </Box>
)

export default HowItWorksMini
