import { useCallback, useState, useEffect } from 'react'
import { useVouchers } from '../context/Vouchers'
import { useVoucherModal } from '../context/VoucherModal'
import RedeemVoucherModal from '../components/RedeemVoucherModal'
import { formatDateForDatePicker, convertFirestoreToDate } from '../libs/formatters'

const RedeemVoucherModalContainer = () => {
  const { redeemVoucher } = useVouchers()
  const { open, voucher, subVoucher, handleClose } = useVoucherModal()
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (open) {
      setErrors({})
    }
  }, [open])

  const onRedeem = useCallback(
    async (voucherInfo, subVoucherInfo) => {
      const redeemVoucherResult = await redeemVoucher(voucherInfo?.uid, subVoucherInfo?.id)
      if (redeemVoucherResult) {
        handleClose()
      } else {
        setErrors({ redeem: 'There was an error redeeming the voucher' })
      }
    },
    [redeemVoucher, handleClose],
  )

  return (
    <RedeemVoucherModal
      open={open}
      voucherCode={voucher?.code}
      subVoucherCode={subVoucher?.code}
      errors={errors}
      customerFirstName={voucher?.customer?.firstname}
      customerLastName={voucher?.customer?.lastname}
      customerDOB={
        formatDateForDatePicker(voucher?.customer?.dob) ||
        formatDateForDatePicker(voucher?.customer?.birthday)
      }
      service={voucher?.service?.name}
      subVoucherService={subVoucher?.service?.name}
      handleClose={handleClose}
      onRedeem={e => {
        e.preventDefault()
        onRedeem(voucher, subVoucher)
      }}
    />
  )
}

export default RedeemVoucherModalContainer
