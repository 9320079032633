import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Highlight } from 'react-instantsearch'
import Typography from '../stories/Typography'
import Button from '../stories/Button'

const ClinicSearchCard = ({ data, onClick = null, variant = null }) => {
  const { relevantSpecialties, slug, address, city, state, zipcode, _rankingInfo } = data
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      padding='16px'
    >
      <Box
        style={{
          minWidth: '50%',
        }}
      >
        <Typography variant='body1' weight='bold'>
          <Highlight attribute='name' hit={data} />
        </Typography>
        {/* Address */}
        <Typography variant='body1'>
          {`${address}, ` || ''}
          {`${city}, ` || ''}
          {`${state}, ` || ''}
          {`${zipcode}, ` || ''}
        </Typography>
        {variant === 'homepage' && _rankingInfo?.geoDistance && (
          // distance converted from meters to miles, add commas for readability
          <Typography variant='body1' weight='light'>
            Distance: {parseInt(_rankingInfo.geoDistance / 1609.344, 10).toLocaleString()} miles
          </Typography>
        )}
        <Typography variant='body1' style={{ textTransform: 'capitalize' }}>
          {relevantSpecialties[0]?.replace('_', ' ') || 'No Specialties Listed'}
        </Typography>
      </Box>
      {!variant && onClick && (
        <Box>
          <Button
            variant='outlined'
            onClick={() => {
              onClick(data)
            }}
          >
            Select
          </Button>
        </Box>
      )}
      {variant === 'homepage' && slug && (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-end'
          alignItems='flex-end'
          sx={{
            width: {
              xs: '175px',
              sm: '175px',
              md: '100%',
              lg: '100%',
            },
          }}
        >
          <Box
            style={{
              width: '100%',
              maxWidth: '150px',
              marginBottom: '8px',
            }}
          >
            <a href={`/clinics/${slug}`} style={{ margin: '0px 8px' }}>
              <Button variant='outlined' size='small' fullWidth>
                <Typography variant='body1'>Learn More</Typography>
              </Button>
            </a>
          </Box>
          <Box
            className='get-started-button-provider'
            style={{
              width: '100%',
              maxWidth: '150px',
            }}
          >
            <a style={{ margin: '0px 8px' }}>
              <Button variant='outlined' size='small' fullWidth>
                <Typography variant='body1'>Get Started</Typography>
              </Button>
            </a>
          </Box>
        </Box>
      )}
    </Box>
  )
}

ClinicSearchCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    relevantSpecialties: PropTypes.arrayOf(PropTypes.string),
    slug: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    _rankingInfo: PropTypes.shape({
      geoDistance: PropTypes.number,
    }),
  }).isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
}

export default ClinicSearchCard
