import { useVoucherDetails } from '../context/VoucherDetails'
import VoucherDetailsControlBar from '../components/VoucherDetailsControlBar'

const VoucherDetailsContainer = () => {
  const { voucher, notes, orders, originalOrder, handleClose } = useVoucherDetails()

  return (
    <VoucherDetailsControlBar
      voucher={voucher}
      notes={notes}
      orders={orders}
      originalOrder={originalOrder}
      handleClose={handleClose}
    />
  )
}

export default VoucherDetailsContainer
