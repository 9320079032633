import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import { Box } from '@mui/material'

import useSignInUpModals from '../hooks/useSignInUpModals'
import SignInModal from './SignInModal'
import Button from '../stories/Button'

const SignInForm = ({ allowNewAccount = false }) => {
  useEffect(() => {
    handleOpen('signIn')
  }, [])

  const { signInOpen, handleOpen } = useSignInUpModals()

  return (
    <Container maxWidth='xs'>
      <Section>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <Typography variant='h1' align='center'>
            Please sign in to continue
          </Typography>
          <Button onClick={() => handleOpen('signIn')}>Sign In</Button>
        </Box>
        <SignInModal
          open={signInOpen}
          handleClose={() => handleOpen('signIn')}
          callback={() => handleOpen('signIn')}
          preventRegister={true}
        />
      </Section>
    </Container>
  )
}

SignInForm.propTypes = {
  allowNewAccount: PropTypes.bool,
}

export default SignInForm
