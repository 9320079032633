import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'
import Typography from '../stories/Typography'
import Section from '../stories/Section'
import Logo from '../stories/Logo'

const OrderOverview = () => {
  const clinicDoctorName = 'Dr. Sandeep Palakodeti, MD MPH'
  const clinicName = 'Rebel Health Medical Group'
  const clinicAddress = '203 East Broadway St, Granville, OH 43023'
  const clinicNPI = '1568738649'
  const clinicPhoneNumber = '(202) 540-0265'
  const clinicEmail = 'amber@rebelhealthalliance.io'

  const patientName = 'John Doe'
  // const patientPhoneNumber = '555-555-5555'
  const patientDOB = '01/01/1970'
  const patientGender = 'Male'

  const OrderRow = ({ date = '', orders = '', diagnosis = '', notes = '' }) => {
    return (
      <Box width='100%'>
        <Grid container>
          <Grid
            item
            xs={2}
            style={{
              border: '1px solid black',
              padding: '8px',
            }}
          >
            <Typography variant='body1'>{date}</Typography>
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              border: '1px solid black',
              padding: '8px',
            }}
          >
            <Typography variant='body1'>{orders}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              border: '1px solid black',
              padding: '8px',
            }}
          >
            <Typography variant='body1'>{diagnosis}</Typography>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              border: '1px solid black',
              padding: '8px',
            }}
          >
            <Typography variant='body1'>{notes}</Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }

  OrderRow.propTypes = {
    date: PropTypes.string,
    orders: PropTypes.string,
    diagnosis: PropTypes.string,
    notes: PropTypes.string,
  }

  return (
    <Section>
      <Box margin='0px auto' maxWidth='900px'>
        <Box display='flex' justifyContent='center' alignItems='center' marginBottom='50px'>
          <Logo size='large' />
        </Box>
        <Box marginBottom='50px'>
          <Typography variant='body1' weight='bold'>
            {clinicDoctorName}
            <br />
            {clinicName}
            <br />
            {clinicAddress}
            <br />
            NPI: {clinicNPI}
            <br />
            Phone Number: {clinicPhoneNumber}
            <br />
            Email: {clinicEmail}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Typography family='poppins' variant='h1'>
            Physician Order Form
          </Typography>
        </Box>
        <Box marginBottom='20px'>
          <Typography variant='body1'>
            <b>Patient Name:</b> {patientName}
            <br />
            <b>Phone Number:</b> (601) 647-4326
            <br />
            <b>Date of Birth:</b> {patientDOB}
            <br />
            <b>Gender:</b> {patientGender}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column' border='1px solid black'>
          <OrderRow date='Date/Time' orders='Orders' diagnosis='Diagnosis' notes='Notes' />
          <OrderRow
            date='12/26/23 6:23 P.M.'
            orders='Referral for a Multi-phase liver MRI to assess liver lesions (CPT: 74183)'
            diagnosis='K76.89'
          />
        </Box>
        <Box margin='50px 0px'>
          <Typography
            variant='body1'
            family='poppins'
            weight='bold'
            style={{
              borderBottom: 'solid black 2px',
              width: '600px',
              marginBottom: '10px',
            }}
          >
            Physicians Signature:
          </Typography>
          <Typography
            variant='body1'
            family='poppins'
            weight='bold'
            style={{
              borderBottom: 'solid black 2px',
              width: '600px',
              marginBottom: '50px',
            }}
          >
            Date:
          </Typography>
          <Typography
            variant='body1'
            family='poppins'
            weight='bold'
            style={{
              borderBottom: 'solid black 2px',
              width: '600px',
              marginBottom: '10px',
            }}
          >
            Provider Signature:
          </Typography>
          <Typography
            variant='body1'
            family='poppins'
            weight='bold'
            style={{
              borderBottom: 'solid black 2px',
              width: '600px',
              marginBottom: '10px',
            }}
          >
            Provider Print:
          </Typography>
          <Typography
            variant='body1'
            family='poppins'
            weight='bold'
            style={{
              borderBottom: 'solid black 2px',
              width: '600px',
              marginBottom: '10px',
            }}
          >
            Date:
          </Typography>
        </Box>
        <Box />
      </Box>
    </Section>
  )
}

export default OrderOverview
