import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useCurrentProfile } from '../context/CurrentUser'
import { useVoucherModal } from '../context/VoucherModal'
import Button from '../stories/Button'
import { Typography } from '@mui/material'

const RedeemVoucherButton = ({ voucher, subVoucher }) => {
  const { acceptedTerms } = useCurrentProfile()
  const { handleClick, setVoucher, setSubVoucher } = useVoucherModal()

  const onClick = useCallback(
    event => {
      if (acceptedTerms) {
        setVoucher(voucher)
        setSubVoucher(subVoucher)
        handleClick(event)
      }
    },
    [voucher, subVoucher, acceptedTerms, setVoucher, handleClick],
  )

  return (
    <Button size='small' variant='outlined' onClick={onClick} disabled={!acceptedTerms}>
      <Typography variant='body1' sx={{ fontSize: '12px' }}>
        redeem
      </Typography>
    </Button>
  )
}

RedeemVoucherButton.propTypes = {
  voucher: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    service: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    customer: PropTypes.shape({
      lastname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default RedeemVoucherButton
