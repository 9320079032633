import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'

const StyledTabs = styled(MuiTabs)`
  background: ${props => props.theme.palette.background.smoke};
  width: 100%;
`

export const Tab = styled(MuiTab)`
  text-transform: lowercase;
  background: ${props => props.theme.palette.background.smoke};
  ${props =>
    props.size === 'grow' &&
    css`
      flex-grow: 1;
    `}
  ${props =>
    props.size === 'growXS' &&
    css`
      flex-grow: 1;
      ${props.theme.breakpoints.up('sm')} {
        flex-grow: 0;
      }
    `}
`

const Tabs = ({ children, position = '', ...props }) => (
  <StyledTabs indicatorColor='primary' textColor='primary' position={position} {...props}>
    {children}
  </StyledTabs>
)

Tabs.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string,
}

export default Tabs
