import { useState, useEffect, useCallback } from 'react'
import { Box, Grid } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import DobPicker from '../components/DobPicker'
import firebase from 'firebase/compat/app'
import { useCurrentProfile, useCurrentUser } from '../context/CurrentUser'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import TextField from '../stories/TextField'
import SelectField from '../stories/Select'
import PhoneField from '../stories/PhoneInput'
import Link from '../stories/Link'
import { collection } from '../firebase/firestore' // eslint-disable-line no-unused-vars
import { GENDER_OPTIONS, profileValidator } from '../libs/validators'
// import { sendCurrentUserVerification } from '../firebase/auth'
import StateSelectInput from '../components/StateSelectInput'
import { isDate } from '../libs/validators'
import { formatDateForDatePicker } from '@app/libs/formatters'

// TODO
// -- Patient --
// address (street, city, state, zip)

// -- Provider --
// address (street, city, state, zip)

const checkForFields = profile => {
  const missingFields = []
  if (!profile?.firstname) missingFields.push('firstname')
  if (!profile?.lastname) missingFields.push('lastname')
  if (!profile?.phone) missingFields.push('phone')
  if (!profile?.email) missingFields.push('email')
  if (!profile?.dob) missingFields.push('dob')
  if (!profile?.gender) missingFields.push('gender')
  if (!profile?.terms) missingFields.push('terms')
  if (!profile?.hipaa) missingFields.push('hipaa')
  if (!profile?.smsOptIn) missingFields.push('smsOptIn')
  if (!profile?.address?.street) missingFields.push('street')
  if (!profile?.address?.city) missingFields.push('city')
  if (!profile?.address?.state || profile?.address?.state === 'N/A') missingFields.push('state')
  if (!profile?.address?.zip) missingFields.push('zip')

  return missingFields
}

const MissingProfileInfoContainer = () => {
  const profile = useCurrentProfile()
  const { uid, refreshProfile } = useCurrentUser()

  const [missingFields, setMissingFields] = useState([])
  const [selectedDateDOB, setSelectedDateDOB] = useState(null)
  const [open, setOpen] = useState(false)
  const [inputsDisabled, setInputsDisabled] = useState(false)

  const [stateSelected, setStateSelected] = useState('')

  const [errors, setErrors] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!uid) {
      setMissingFields([])
      return undefined
    }
    setMissingFields(checkForFields(profile))
  }, [uid, profile])

  const handleOpen = useCallback(() => {
    if (missingFields?.length > 0 && Object.keys(profile)?.length > 0) {
      setOpen(true)
      return undefined
    }
    setOpen(false)
  }, [missingFields, profile])

  useEffect(() => {
    handleOpen()
  }, [missingFields]) // eslint-disable-line react-hooks/exhaustive-deps

  console.log('formatDateForDatePicker(selectedDateDOB)', formatDateForDatePicker(selectedDateDOB))
  const updateProfile = useCallback(
    async e => {
      e.preventDefault()
      setErrors({})
      setInputsDisabled(true)

      console.log('missingFields', missingFields)
      const updatedProfile = await missingFields.reduce((acc, field) => {
        if (field === 'gender') {
          acc[field] = e.target[field].value
          return acc
        }

        if (
          field === 'street' ||
          field === 'city' ||
          field === 'state' ||
          field === 'zip' ||
          field === 'street2'
        ) {
          acc.address = acc.address || {}
          acc.address[field] = e.target[field].value
          return acc
        }

        if (field === 'dob') {
          acc[field] = formatDateForDatePicker(selectedDateDOB)
          return acc
        }

        acc[field] = e.target[field].value?.toLowerCase() || e.target[field].checked

        return acc
      }, {})

      console.log(updatedProfile)

      if (updatedProfile.email) {
        updatedProfile.password = e.target.password.value
        updatedProfile.passwordConfirmation = e.target.passwordConfirmation.value
      }

      const errorResult = profileValidator(updatedProfile)

      if (errorResult.error) {
        console.log(errorResult.messages)
        setErrors(errorResult.messages)
        return
      }

      if (missingFields.includes('email')) {
        const credential = firebase.auth.EmailAuthProvider.credential(
          updatedProfile.email,
          updatedProfile.password,
        )

        try {
          const user = firebase.auth().currentUser
          await user.linkWithCredential(credential)
        } catch (error) {
          console.error('Error linking email to account', error)
        }
      }

      const strippedProfile = missingFields.reduce((acc, field) => {
        if (field === 'passwordConfirmation' || field === 'password') return acc

        if (
          field === 'street' ||
          field === 'city' ||
          field === 'state' ||
          field === 'zip' ||
          field === 'street2'
        ) {
          acc.address = acc.address || {}
          acc.address[field] = e.target[field].value
          return acc
        }
        if (field === 'dob') {
          acc[field] = formatDateForDatePicker(selectedDateDOB)
          return acc
        }

        acc[field] = updatedProfile[field]
        return acc
      }, {})

      console.log(strippedProfile)

      let closeTimeout = null

      console.log(uid)

      const profileRef = await collection('profiles').doc(uid).get()

      profileRef.ref
        .set(strippedProfile, { merge: true })
        .then(() => {
          console.log('refreshing profile')
          refreshProfile().then(() => {
            console.log('profile refreshed')
            // sendCurrentUserVerification()
            if (window.Intercom) {
              window.Intercom('trackEvent', 'profile-updated', {
                ...strippedProfile,
              })
            }
            setStateSelected('')
            setOpen(false)
          })
        })
        .catch(err => {
          console.log('error updating profile')
          console.log(err)
          // send intercom message
          if (window.Intercom) {
            window.Intercom('trackEvent', 'profile-update-error', {
              errorMessage: err?.message,
              error: err?.toString(),
            })
            window.Intercom(
              'showNewMessage',
              'Hello, my profile has had an issue updating. Please help.',
            )
          }
          setErrorMessage(
            'There was an error updating your missing info. Plesae contact support for assistance',
          )
          clearTimeout(closeTimeout)
          closeTimeout = setTimeout(() => {
            setOpen(false)
            setStateSelected('')
          }, 5000)
        })
    },
    [missingFields, selectedDateDOB, refreshProfile, uid],
  )

  useEffect(() => {
    if (open) {
      // prevent scroll on body
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [open])

  return (
    <>
      {open && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          height='100%'
          bgcolor='rgba(0,0,0,0.5)'
          position='fixed'
          zIndex='1101'
          top='0'
          left='0'
          padding={2}
        >
          <Box
            display='flex'
            position='relative'
            bgcolor='white'
            width='100%'
            maxWidth='500px'
            height='fit-content'
            maxHeight='90vh'
            borderRadius='5px'
            overflow='scroll'
            p={3}
          >
            <Box display='block'>
              <Box>
                <Typography variant='h2' gutterBottom>
                  Missing Profile Informations
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant='body2' weight='light'>
                  You are missing the following fields:
                </Typography>
              </Box>
              <Box>
                <form title='sign-up-form' onSubmit={updateProfile}>
                  {missingFields.includes('firstname') && (
                    <TextField
                      required
                      name='firstname'
                      label='First name'
                      display='standard'
                      inputProps={{
                        title: 'firstname',
                      }}
                      helperText={errors.firstname}
                      disabled={inputsDisabled}
                    />
                  )}
                  {missingFields.includes('lastname') && (
                    <TextField
                      required
                      name='lastname'
                      label='Last name'
                      display='standard'
                      inputProps={{
                        title: 'lastname',
                      }}
                      helperText={errors.lastname}
                      disabled={inputsDisabled}
                    />
                  )}
                  {missingFields.includes('email') && (
                    <>
                      <TextField
                        required
                        name='email'
                        type='email'
                        label='Email address'
                        display='standard'
                        inputProps={{
                          title: 'email',
                        }}
                        helperText={errors.email}
                        disabled={inputsDisabled}
                      />
                      <TextField
                        required
                        name='password'
                        type='password'
                        label='Password'
                        display='standard'
                        inputProps={{
                          title: 'password',
                        }}
                        helperText={errors.password}
                        disabled={inputsDisabled}
                      />
                      <TextField
                        required
                        name='passwordConfirmation'
                        type='password'
                        label='Password Confirmation'
                        display='standard'
                        inputProps={{
                          title: 'password-confirmation',
                        }}
                        helperText={errors.passwordConfirmation}
                        disabled={inputsDisabled}
                      />
                    </>
                  )}
                  {missingFields.includes('phone') && (
                    <PhoneField
                      required
                      name='phone'
                      label='Phone number'
                      display='standard'
                      inputProps={{
                        title: 'phone',
                      }}
                      helperText={errors.phone}
                      disabled={inputsDisabled}
                    />
                  )}
                  <Grid container justifyContent='space-between' spacing={2}>
                    {missingFields.includes('dob') && (
                      <Grid item xs={12} sm={6} mt={2}>
                        <DobPicker dob={selectedDateDOB} setDob={setSelectedDateDOB} />
                      </Grid>
                    )}
                    {missingFields.includes('gender') && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          paddingLeft: {
                            xs: 0,
                            sm: 1,
                          },
                        }}
                      >
                        <SelectField
                          required
                          label='Gender'
                          name='gender'
                          options={GENDER_OPTIONS}
                          defaultValue=''
                          helperText={errors.gender}
                          disabled={inputsDisabled}
                        />
                      </Grid>
                    )}
                    {missingFields.includes('street') && (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            required
                            id='street'
                            label='Street Address'
                            variant='outlined'
                            fullWidth
                            style={{
                              backgroundColor: '#fff',
                              margin: '0px',
                            }}
                            disabled={inputsDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id='street2'
                            label='Apt, Suite, etc. (Optional)'
                            variant='outlined'
                            fullWidth
                            style={{
                              backgroundColor: '#fff',
                              margin: '0px',
                            }}
                            disabled={inputsDisabled}
                          />
                        </Grid>
                      </>
                    )}
                    {missingFields.includes('city') && (
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          required
                          id='city'
                          label='City'
                          variant='outlined'
                          fullWidth
                          style={{
                            backgroundColor: '#fff',
                            margin: '0px',
                          }}
                          disabled={inputsDisabled}
                        />
                      </Grid>
                    )}
                    {missingFields.includes('state') && (
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box>
                          <StateSelectInput
                            fullWidth
                            id='state'
                            required
                            label='State'
                            variant='outlined'
                            onChange={setStateSelected}
                            state={stateSelected}
                            style={{
                              backgroundColor: '#fff',
                              margin: '0px',
                            }}
                            disabled={inputsDisabled}
                          />
                        </Box>
                      </Grid>
                    )}
                    {missingFields.includes('zip') && (
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          required
                          id='zip'
                          label='Zip Code'
                          variant='outlined'
                          fullWidth
                          style={{
                            backgroundColor: '#fff',
                            margin: '0px',
                          }}
                          disabled={inputsDisabled}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {(missingFields.includes('terms') ||
                    missingFields.includes('hipaa') ||
                    missingFields.includes('smsOptIn')) && (
                    <Box mt={2}>
                      <Typography variant='body2'>
                        To use this account please review and agree to the following:
                      </Typography>
                    </Box>
                  )}
                  {missingFields.includes('terms') && (
                    <Box display='flex' alignItems='center'>
                      <Checkbox
                        name='terms'
                        color='primary'
                        inputProps={{ 'aria-label': 'terms' }}
                        disabled={inputsDisabled}
                      />
                      <Typography variant='body2' color={errors.terms ? 'error' : 'initial'}>
                        <Link
                          display='standard'
                          size='small'
                          target='_blank'
                          to='/privacy-policy'
                          style={{ fontWeight: '300' }}
                        >
                          Privacy Policy
                        </Link>{' '}
                        ,{' '}
                        <Link
                          display='standard'
                          size='small'
                          target='_blank'
                          to='/terms'
                          style={{ fontWeight: '300' }}
                        >
                          Terms of Use
                        </Link>{' '}
                        and{' '}
                        <Link
                          display='standard'
                          size='small'
                          target='_blank'
                          to='/eula-consumer'
                          style={{ fontWeight: '300' }}
                        >
                          EULA
                        </Link>
                        .
                      </Typography>
                    </Box>
                  )}
                  {missingFields.includes('hipaa') && (
                    <Box display='flex' alignItems='center'>
                      <Checkbox
                        name='hipaa'
                        color='primary'
                        inputProps={{ 'aria-label': 'hipaa' }}
                        disabled={inputsDisabled}
                      />
                      <Typography variant='body2' color={errors.hipaa ? 'error' : 'initial'}>
                        <Link
                          display='standard'
                          size='small'
                          target='_blank'
                          to='/hipaa'
                          style={{ fontWeight: '300' }}
                        >
                          HIPAA Authorization
                        </Link>
                      </Typography>
                      .
                    </Box>
                  )}
                  {missingFields.includes('smsOptIn') && (
                    <Box display='flex' alignItems='center'>
                      <Checkbox
                        name='smsOptIn'
                        color='primary'
                        inputProps={{ 'aria-label': 'Text Opt In' }}
                        disabled={inputsDisabled}
                      />
                      <Typography variant='body2' color='initial'>
                        Agree to receive SMS about your account and orders. Message and data rates
                        may apply.
                      </Typography>
                    </Box>
                  )}
                  {errorMessage && (
                    <Box mt={2}>
                      <Typography variant='body2' color='error'>
                        {errorMessage}
                      </Typography>
                    </Box>
                  )}
                  <Box mt={2}>
                    <Button type='submit' fullWidth>
                      Update Profile
                    </Button>
                  </Box>
                </form>
                <Box height='24px' />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default MissingProfileInfoContainer
