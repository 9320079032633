import { FC, ReactNode } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useCurrentUser } from '@app/context/CurrentUser'

const AnonymousRoute: FC = () => {
  const { state } = useLocation()
  const { isAnonymous } = useCurrentUser()

  let node: ReactNode = 'Loading'
  if (isAnonymous === true) {
    node = <Outlet />
  } else if (isAnonymous === false) {
    node = <Navigate to={state || '/dashboard'} replace />
  }

  return node
}

export default AnonymousRoute
