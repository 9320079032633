import PropTypes from 'prop-types'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  body: {
    paddingTop: 10,
    paddingBottom: 20,
    paddingHorizontal: 35,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  orgSection: {
    margin: 10,
    fontSize: 20,
  },
  orgDetailsSection: {
    margin: 10,
    fontSize: 14,
  },
  orgLogoSection: {
    margin: 12,
  },
  orgLogo: {
    height: 50,
    width: 'auto',
    marginRight: 10,
  },
  patientSection: {
    border: '1px solid black',
    boxShadow: '0 0 5px 0 #00000080',
    marginTop: 12,
    fontSize: 14,
    marginBottom: 10,
  },
  procedureSection: {
    border: '1px solid black',
    boxShadow: '0 0 5px 0 #00000080',
    marginTop: 10,
    fontSize: 14,
  },
  grid: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 20,
  },
  gridRow: {
    margin: 'auto',
    width: '100%',
    minHeight: 30,
    flexDirection: 'row',
    border: '1px solid black',
  },
  dateCol: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 10,
    textAlign: 'center',
  },
  cptCol: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 10,
    textAlign: 'center',
  },
  feeCol: {
    width: '34%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 10,
    textAlign: 'center',
  },
  paidCol: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 10,
    textAlign: 'center',
  },
  subtotalCol: {
    width: '34 %',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 10,
    textAlign: 'center',
    float: 'right',
  },
  dueCol: {
    width: '34%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 10,
    textAlign: 'center',
    float: 'right',
  },
  diagnosisCol: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 10,
    textAlign: 'center',
  },
  gridCell: {
    margin: 5,
    fontSize: 10,
  },
  dateHeader: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#bfbfbf',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
  },
  cptHeader: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#bfbfbf',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
  },
  feeHeader: {
    width: '34%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#bfbfbf',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
  },
  paidHeader: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#bfbfbf',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
  },
  subtotalHeader: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#bfbfbf',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
    float: 'right',
  },
  dueHeader: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#bfbfbf',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
    float: 'right',
  },
  diagnosisHeader: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#bfbfbf',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
  },
  gridHalfHeader: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#bfbfbf',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
  },
  gridHalfCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 10,
    textAlign: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'left',
    marginRight: 'auto',
  },
  author: {
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 12,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    textAlign: 'left',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
  },
})

const PDFTemplate = ({
  data = {
    voucher: {
      redeemedAt: new Date(),
    },
    organization: {
      website: '',
    },
  },
}) => {
  const { voucher, organization, service, ucrs, totalUcrFee } = data // eslint-disable-line
  return (
    <Document>
      <Page size='A4' style={styles.body}>
        <View style={styles.orgSection}>
          <Text style={styles.title}>{organization.name}</Text>
          <Text style={styles.subtitle}>Superbill</Text>
        </View>
        <View style={styles.orgDetailsSection}>
          <Text style={styles.header}>Provider Information</Text>
          <Text>{organization.primaryLocation}</Text>
          <Text>
            Phone: {organization.phone} | Email: {organization.email} | Web: {organization.website}
          </Text>
        </View>
        <View style={styles.patientSection}>
          <Text style={styles.header}>Patient Information</Text>
          <Text>
            Name: {voucher.customer.firstname} {voucher.customer.lastname}
          </Text>
          <Text>
            Phone: {voucher.customer.phone} | Email: {voucher.customer.email}
          </Text>
          <Text>
            DOB: {voucher.customer.dob} | Gender: {voucher.customer.gender}
          </Text>
        </View>
        <View style={styles.grid}>
          <Text style={styles.header}>Treatment Information</Text>
          <View style={styles.gridRow}>
            <View style={styles.dateHeader}>
              <Text>Date of Service</Text>
            </View>
            <View style={styles.cptHeader}>
              <Text>Procedure/CPT Code</Text>
            </View>
            <View style={styles.feeHeader}>
              <Text>Fee</Text>
            </View>
          </View>
          {/* CPT row generator */}
          <>
            {ucrs.map(ucr => {
              return (
                <View key={ucr.cpt} style={styles.gridRow}>
                  <Text style={styles.dateCol}>{voucher.redeemedAt.toLocaleDateString()}</Text>
                  <Text style={styles.cptCol}>{ucr.cpt}</Text>
                  <Text style={styles.feeCol}>{ucr.fee}</Text>
                </View>
              )
            })}
          </>
          {/* END CPT row generator */}
          {/* Subtotal and total */}
          <View style={styles.gridRow}>
            <Text style={styles.subtotalCol} />
            <Text style={styles.subtotalHeader}>Amount Paid</Text>
            <Text style={styles.subtotalCol}>{voucher.service.price}</Text>
          </View>
          <View style={styles.gridRow}>
            <Text style={styles.dueCol} />
            <Text style={styles.dueHeader}>Total Due</Text>
            <Text style={styles.dueCol}>{totalUcrFee - voucher.service.price}</Text>
          </View>
        </View>
        {/* End Subtotal and total */}
        {/* Patient diagnosis */}
        <View style={styles.lastSection}>
          <View style={styles.grid}>
            <View style={styles.gridRow}>
              <Text style={styles.diagnosisHeader}>Patient Diagnosis</Text>
            </View>
            <View style={styles.gridRow} />
            <View style={styles.gridRow} />
          </View>
        </View>
        {/* End patient diagnosis */}
        {/* Provider signature */}
        <View style={styles.lastSection}>
          <View style={styles.grid}>
            <View style={styles.gridRow}>
              <Text style={styles.gridHalfHeader}>Provider Name</Text>
              <Text style={styles.gridHalfHeader}>Provider Signature</Text>
            </View>
            <View style={styles.gridRow}>
              <Text style={styles.gridHalfCol} />
              <Text style={styles.gridHalfCol} />
            </View>
          </View>
        </View>
        {/* End provider signature */}
        {/* Tax ID  */}
        <View style={styles.lastSection}>
          <View style={styles.grid}>
            <View style={styles.gridRow}>
              <Text style={styles.gridHalfHeader}>Tax ID</Text>
              <Text style={styles.gridHalfHeader}>Provider NPI</Text>
            </View>
            <View style={styles.gridRow}>
              <Text style={styles.gridHalfCol} />
              <Text style={styles.gridHalfCol} />
            </View>
          </View>
        </View>
        {/* End tax ID */}
      </Page>
    </Document>
  )
}

PDFTemplate.propTypes = {
  data: PropTypes.shape({
    voucher: PropTypes.shape({
      redeemedAt: PropTypes.instanceOf(Date),
      customer: PropTypes.shape({
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        dob: PropTypes.string.isRequired,
        gender: PropTypes.string.isRequired,
      }),
      service: PropTypes.shape({
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }),
    }),
    organization: PropTypes.shape({
      name: PropTypes.string.isRequired,
      primaryLocation: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      website: PropTypes.string,
    }),
    service: PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }),
    ucrs: PropTypes.array,
    totalUcrFee: PropTypes.number.isRequired,
  }),
}

export default PDFTemplate
