import { FC, useEffect } from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import Main from '@app/stories/Main'
import MissingProfileInfoContainer from '@app/containers/MissingProfileInfoContainer'
import CookiesNotice from '@app/stories/CookiesNotice'
import { scrollToTop } from '@app/libs/scroll'
import TopBar from './TopBar'
import Footer from './Footer'

const Layout: FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    scrollToTop({ behavior: 'instant' })
  }, [pathname])

  return (
    <>
      <TopBar />
      <MissingProfileInfoContainer />
      <Main>
        <Outlet />
      </Main>
      <CookiesNotice />
      <Footer />
    </>
  )
}

export default Layout
