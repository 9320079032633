import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Divider } from '@mui/material'
import Fade from '@mui/material/Fade'
import Icon from '../stories/Icons'
import Button from '../stories/Button'
import Typography from '../stories/Typography'

const MedicationPriceCalculator = ({
  onClick = () => {},
  medicationOptions = { options: [] },
  medicationInput = {},
  medicationInputHandler = {},
  setMedicationRequested = () => {},
}) => {
  const [formSelected, setFormSelected] = useState(false)
  const [dosageOptions, setDosageOptions] = useState([])
  const [quantityOptions, setQuantityOptions] = useState([])
  const [dosageSelected, setDosageSelected] = useState(false)
  const [medicationID, setMedicationID] = useState(null)
  const [quantitySelected, setQuantitySelected] = useState(null)
  const [priceSelected, setPriceSelected] = useState(false)

  useEffect(() => {
    if (!priceSelected) {
      if (medicationInput?.dosage && quantitySelected) {
        const medObj = dosageOptions?.find(option => option.text === medicationInput?.dosage)

        if (!medObj?.price) {
          return undefined
        }

        const totalPrice = (medObj?.price * quantitySelected).toFixed(2)
        if (!medicationInput.useDelivery) {
          const adminFee = 4.5
          medicationInputHandler.setTotalPrice((parseFloat(totalPrice) + adminFee).toFixed(2))
        } else {
          medicationInputHandler.setTotalPrice(totalPrice)
        }
        return undefined
      }
      return undefined
    }

    const totalPrice = (priceSelected * quantitySelected).toFixed(2)
    if (!medicationInput.useDelivery) {
      const adminFee = 4.5
      medicationInputHandler.setTotalPrice((parseFloat(totalPrice) + adminFee).toFixed(2))
    } else {
      medicationInputHandler.setTotalPrice(totalPrice)
    }
  }, [medicationInputHandler, priceSelected, quantitySelected, medicationInput, dosageOptions])

  useEffect(() => {
    // set medication info
    if (formSelected && dosageSelected && quantitySelected && medicationID) {
      setMedicationRequested({
        form: formSelected,
        dosage: dosageSelected,
        quantity: quantitySelected,
        id: medicationID,
      })
    }
  }, [formSelected, dosageSelected, quantitySelected, medicationID, setMedicationRequested])

  const FormButton = ({ FormData = '' }) => {
    return (
      <Button
        onClick={() => {
          medicationInputHandler.setForm(FormData)
          setFormSelected(FormData)
        }}
        variant={medicationInput?.form === FormData ? 'contained' : 'outlined'}
        size='medium'
        style={{
          backgroundColor: medicationInput?.form === FormData ? '#7004c6' : '#7004c600',
          borderRadius: '18px',
          margin: '4px 8px',
        }}
      >
        <Typography variant='body1' family='poppins' style={{ textTransform: 'capitalize' }}>
          {FormData}
        </Typography>
      </Button>
    )
  }

  FormButton.propTypes = {
    FormData: PropTypes.string,
  }

  useEffect(() => {
    console.log('medicationOptions', medicationOptions)
  }, [medicationOptions])

  useEffect(() => {
    if (formSelected) {
      const dosageOptionsResult = medicationOptions?.options?.find(
        option => option.label === medicationInput?.form,
      )
      setDosageOptions(dosageOptionsResult?.dosages)
    }
  }, [formSelected, medicationOptions, medicationInput])

  useEffect(() => {
    if (dosageSelected) {
      const dosagePriceResults = dosageOptions?.find(option => option.text === dosageSelected)
      console.log('Setting medication ID')
      setMedicationID(dosagePriceResults?.id)

      if (dosagePriceResults?.requiredQuantity) {
        setQuantityOptions([{ text: dosagePriceResults.requiredQuantity }])
      } else if (dosagePriceResults?.suggestedQuantity) {
        const suggestedQuantityResults = []
        Object.keys(dosagePriceResults.suggestedQuantity).forEach(key => {
          suggestedQuantityResults.push({
            text: key,
            price: dosagePriceResults.suggestedQuantity[key],
          })
        })
        setQuantityOptions(suggestedQuantityResults)
      } else if (dosagePriceResults?.specialPriceRules) {
        const specialPriceRulesResults = []
        Object.keys(dosagePriceResults.specialPriceRules).forEach(key => {
          specialPriceRulesResults.push({
            text: key,
            price: dosagePriceResults.specialPriceRules[key],
          })
        })
        setQuantityOptions(specialPriceRulesResults)
      }
    }
  }, [dosageSelected, dosageOptions, medicationInput])

  const DosageButton = ({ DosageData = '' }) => {
    return (
      <Button
        onClick={() => {
          medicationInputHandler.setDosage(DosageData)
          setDosageSelected(DosageData)
          // set price
          const dosagePrice = dosageOptions?.find(option => option.text === DosageData)
          setPriceSelected(dosagePrice?.price)
          setQuantitySelected('')
          medicationInputHandler.setQuantity('')
        }}
        variant={medicationInput?.dosage === DosageData ? 'contained' : 'outlined'}
        size='medium'
        style={{
          backgroundColor: medicationInput?.dosage === DosageData ? '#7004c6' : '#7004c600',
          borderRadius: '18px',
          margin: '4px 8px',
        }}
      >
        <Typography variant='body1' family='poppins'>
          {DosageData}
        </Typography>
      </Button>
    )
  }

  DosageButton.propTypes = {
    DosageData: PropTypes.string,
  }

  const QuantityButton = ({ QuantityData = '', QuantityPrice = 0 }) => {
    return (
      <Button
        onClick={() => {
          medicationInputHandler.setQuantity(QuantityData)
          setQuantitySelected(parseInt(QuantityData, 10))
          if (QuantityPrice !== 0) {
            setPriceSelected(QuantityPrice)
          }
        }}
        variant={medicationInput?.quantity === QuantityData ? 'contained' : 'outlined'}
        size='medium'
        style={{
          backgroundColor: medicationInput?.quantity === QuantityData ? '#7004c6' : '#7004c600',
          borderRadius: '18px',
          margin: '4px 8px',
        }}
      >
        <Typography variant='body1' family='poppins'>
          {QuantityData}
        </Typography>
      </Button>
    )
  }

  QuantityButton.propTypes = {
    QuantityData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    QuantityPrice: PropTypes.number,
  }

  return (
    <Box height='100%'>
      <Box
        display='flex'
        height='100%'
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Box
          color='#7004c6'
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          padding='0px 8px'
          sx={{
            width: { xs: '100%', sm: '18%' },
            alignItems: { xs: 'center', sm: 'flex-start' },
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            sx={{
              alignItems: { xs: 'center', sm: 'flex-start' },
            }}
          >
            <Typography variant='h3' family='poppins' color='inherit' style={{ margin: '0px' }}>
              ${medicationInput?.totalPrice}
            </Typography>
            <Box color='#888'>
              <Typography variant='body1' weight='light' family='poppins'>
                {medicationInput?.quantity} - {medicationInput?.dosage} {medicationInput?.form}s
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            <Button
              variant='text'
              size='small'
              style={{
                color: '#7004c695',
              }}
              onClick={() => {
                medicationInputHandler.setAlreadyHavePrescription(
                  !medicationInput?.alreadyHavePrescription,
                )
                if (!medicationInput?.alreadyHavePrescription && medicationInput?.useDelivery) {
                  medicationInputHandler.setCurrentFlow('deliveryWithPrescription')
                } else if (
                  !medicationInput?.alreadyHavePrescription &&
                  !medicationInput?.useDelivery
                ) {
                  medicationInputHandler.setCurrentFlow('pickupWithPrescription')
                } else if (
                  medicationInput?.alreadyHavePrescription &&
                  medicationInput?.useDelivery
                ) {
                  medicationInputHandler.setCurrentFlow('deliveryWithoutPrescription')
                } else {
                  medicationInputHandler.setCurrentFlow('pickupWithoutPrescription')
                }
              }}
            >
              {medicationInput?.alreadyHavePrescription ? (
                <Typography variant='body1' family='poppins' weight='light'>
                  I need a prescription
                </Typography>
              ) : (
                <Typography variant='body1' family='poppins' weight='light'>
                  Already have a prescription?
                </Typography>
              )}
            </Button>
          </Box>
        </Box>
        <Divider orientation='vertical' flexItem />
        <Box
          display='flex'
          flexDirection='column'
          padding='0px 8px'
          sx={{
            width: { xs: '100%', sm: '22%' },
          }}
        >
          <Typography variant='h5' family='poppins' style={{ margin: '8px 0px' }}>
            Form
          </Typography>
          <Box display='flex' flexDirection='row' flexWrap='wrap'>
            {medicationOptions?.options?.map(option => (
              <FormButton FormData={option.label} key={option.label} />
            )) || '---'}
          </Box>
        </Box>
        <Divider orientation='vertical' flexItem />
        <Fade in={formSelected && true} timeout={500}>
          <Box
            display='flex'
            flexDirection='column'
            padding='0px 8px'
            sx={{
              width: { xs: '100%', sm: '22%' },
            }}
          >
            <Typography variant='h5' family='poppins' style={{ margin: '8px 0px' }}>
              Dosage
            </Typography>
            <Box display='flex' flexDirection='row' flexWrap='wrap'>
              {dosageOptions?.map(option => (
                <DosageButton DosageData={option.text} key={option.id} />
              )) || '---'}
            </Box>
          </Box>
        </Fade>
        <Divider orientation='vertical' flexItem />
        <Fade in={dosageSelected && true} timeout={500}>
          <Box
            display='flex'
            flexDirection='column'
            padding='0px 8px'
            sx={{
              width: { xs: '100%', sm: '22%' },
            }}
          >
            <Typography variant='h5' family='poppins' style={{ margin: '8px 0px' }}>
              Quantity
            </Typography>
            <Box display='flex' flexDirection='row' flexWrap='wrap'>
              {quantityOptions?.map(option => (
                <QuantityButton
                  QuantityData={option.text}
                  key={option.text}
                  QuantityPrice={option.price}
                />
              )) || '---'}
            </Box>
          </Box>
        </Fade>
        <Divider orientation='vertical' flexItem />
        <Box
          display='flex'
          padding='0px'
          alignItems='flex-end'
          sx={{
            width: { xs: '100%', sm: '15%' },
            marginTop: { xs: '16px', sm: '0px' },
            flexDirection: { xs: 'row', sm: 'column' },
            justifyContent: { xs: 'space-between', sm: 'center' },
          }}
        >
          <Button
            variant='contained'
            size='medium'
            style={{
              color: '#fff',
              backgroundColor: quantitySelected ? '#7004c6' : '#00000022',
              borderRadius: '34px',
              pointerEvents: quantitySelected ? 'auto' : 'none',
            }}
            onClick={() => {
              onClick(medicationInput.activeStep + 1)
              medicationInputHandler.setSelectedMedication(true)
            }}
            disabled={
              !medicationInput?.form || !medicationInput?.dosage || !medicationInput?.quantity
            }
          >
            <Box padding='4px'>
              {medicationInput?.useDelivery ? (
                <>
                  {medicationInput?.alreadyHavePrescription ? (
                    <Box display='flex' alignItems='center'>
                      <Box paddingRight='8px'>
                        <Icon name='cart' />
                      </Box>
                      <Typography variant='body1' family='poppins' weight='light'>
                        Checkout
                      </Typography>
                    </Box>
                  ) : (
                    <Box display='flex' alignItems='center'>
                      <Box paddingRight='8px'>
                        <Icon name='assignmentOutlined' />
                      </Box>
                      <Box display='flex' flexDirection='column'>
                        <Typography variant='body1' family='poppins' weight='light'>
                          Confirm
                        </Typography>
                        <Typography variant='body1' family='poppins' weight='light'>
                          Consult
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <Box display='flex' alignItems='center'>
                  <Box paddingRight='8px'>
                    <Icon name='search' />
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    <Typography variant='body1' family='poppins' weight='light'>
                      Choose a
                    </Typography>
                    <Typography variant='body1' family='poppins' weight='light'>
                      Pharmacy
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

MedicationPriceCalculator.propTypes = {
  data: PropTypes.shape({
    forms: PropTypes.arrayOf(PropTypes.string),
    dosages: PropTypes.arrayOf(PropTypes.string),
  }),
  onClick: PropTypes.func,
  medicationInput: PropTypes.object,
  medicationInputHandler: PropTypes.object,
  medicationOptions: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        dosages: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            price: PropTypes.number,
          }),
        ),
        quantity: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }),
  setMedicationRequested: PropTypes.func,
}

export default MedicationPriceCalculator
