import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const VITE_STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY

const promise = loadStripe(VITE_STRIPE_KEY)

const StripeElementsProvider = ({ children }) => {
  return <Elements stripe={promise}>{children}</Elements>
}

StripeElementsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StripeElementsProvider
