import PropTypes from 'prop-types'
import { Highlight } from 'react-instantsearch'
import { Box, Typography } from '@mui/material'

const FeeScheduleCard = ({ data }) => {
  const { price, pricePaidToDoc } = data
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        // backgroundColor: 'rgba(101, 45, 146, 0.05)',
        // border: '1px solid #652d92',
        borderRadius: '4px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '8px',
      }}
      sx={{
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
        sx={{
          width: {
            xs: '100%',
            md: '50%',
          },
        }}
      >
        <Typography variant='h6'>
          <Highlight attribute='name' hit={data} />
        </Typography>
        <Typography variant='h6'>
          <Highlight attribute='cptCode' hit={data} />
        </Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        sx={{
          width: {
            xs: '100%',
            md: '50%',
          },
          alignItems: {
            xs: 'flex-start',
            md: 'flex-end',
          },
        }}
      >
        {price && <Typography variant='h6'>Market: {price}</Typography>}
        {pricePaidToDoc && <Typography variant='h6'>Contract: {pricePaidToDoc}</Typography>}
      </Box>
    </Box>
  )
}

FeeScheduleCard.propTypes = {
  data: PropTypes.shape({
    price: PropTypes.number,
    pricePaidToDoc: PropTypes.number,
  }).isRequired,
}

export default FeeScheduleCard
