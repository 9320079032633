import firebase from 'firebase/compat/app'

export const convertFirestoreToDate = firestoreTimestamp => {
  if (!firestoreTimestamp || !firestoreTimestamp.toDate) {
    return false
  }

  // Convert Firestore.timestamp to javascript.Date in UTC
  const date = firestoreTimestamp.toDate()

  // Return the UTC date with only year, month, and day like 00:00:00 UTC
  return date
}

// todo im not sure the purpose of this method. i dont think its correct.
// when you manually adjust the time using getTimezoneOffset() your  shifting the dates time and that can
// cause it to fall on the next day if its close to midnight
// so this offset can affect the date. why do we need it for birthday input for example. the user inputs their birthday
// we dont need to adjust their birthday to match a timezone
const convertOffset = date => {
  const tzoffset = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() + tzoffset)
}

// TODO DELETE ONCE WE REMOVE BIRTHDAY STRING FROM VALUES
export const formatDateForDatePicker = date => {
  if (!date) {
    return ''
  }

  // Handle
  if (date instanceof firebase.firestore.Timestamp) {
    console.log('date instanceof firebase.firestore.Timestamp', date)
    return date.toDate()
  }

  if (date?._seconds || date?._nanoseconds) {
    console.log('date?._seconds && date?._nanoseconds', date)
    // convert back to firebase timestamp
    const timestamp = new firebase.firestore.Timestamp(date._seconds, date._nanoseconds)
    return convertOffset(timestamp.toDate())
  }

  if (date instanceof Date) {
    console.log('date instanceof Date', date)
    return date
  }

  const BIRTHDAY_REGEX_DMY = /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/
  const BIRTHDAY_REGEX_UNIVERSAL = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/

  if (BIRTHDAY_REGEX_DMY.test(date)) {
    console.log('BIRTHDAY_REGEX_DMY.test(date)', date)
    const [month, day, year] = date.split('-')
    return convertOffset(new Date(`${year}-${month}-${day}T00:10:00`))
  }

  if (BIRTHDAY_REGEX_UNIVERSAL.test(date)) {
    console.log('BIRTHDAY_REGEX_UNIVERSAL.test(date)', date)
    return convertOffset(new Date(`${date}T00:10:00`))
  }

  console.log('returning empty string')
  return ''
}

export const formatDateForUI = (date, displayTime = false) => {
  if (!date) {
    return ''
  }

  const dateFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }

  if (displayTime) {
    dateFormat.hour = '2-digit'
    dateFormat.minute = '2-digit'
  }

  if (date instanceof firebase.firestore.Timestamp) {
    return date.toDate().toLocaleDateString('en-US', dateFormat)
  }

  if (date?._seconds || date?._nanoseconds) {
    console.log('date?._seconds && date?._nanoseconds', date)
    // convert back to firebase timestamp
    return new firebase.firestore.Timestamp(date._seconds, date._nanoseconds)
      .toDate()
      .toLocaleDateString('en-US', dateFormat)
  }

  if (date instanceof Date) {
    return date.toLocaleDateString('en-US', dateFormat)
  }

  const BIRTHDAY_REGEX = /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/
  const BIRTHDAY_REGEX_UNIVERSAL = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/

  if (BIRTHDAY_REGEX.test(date)) {
    const [month, day, year] = date.split('-')
    const dateString = `${year}-${month}-${day}T00:10:00`
    return new Date(dateString).toLocaleDateString('en-US', dateFormat)
  }

  if (BIRTHDAY_REGEX_UNIVERSAL.test(date)) {
    const dateString = `${date}T00:10:00`
    return new Date(dateString).toLocaleDateString('en-US', dateFormat)
  }

  return ''
}

export const formatDOBForIntercom = dob => {
  // need to return birthday as a unix
  if (!dob) {
    return null
  }

  if (dob instanceof firebase.firestore.Timestamp) {
    // get seconds not milliseconds
    return dob.seconds
  }

  if (dob?._seconds || dob?._nanoseconds) {
    // get seconds not milliseconds
    return dob._seconds
  }

  if (dob instanceof Date) {
    return dob.getTime()
  }

  const BIRTHDAY_REGEX_DMY = /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/
  const BIRTHDAY_REGEX_UNIVERSAL = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/

  if (BIRTHDAY_REGEX_DMY.test(dob)) {
    const [month, day, year] = dob.split('-')
    // add time to date
    const dateString = `${year}-${month}-${day}T00:10:00`
    return new Date(dateString).getTime()
  }

  if (BIRTHDAY_REGEX_UNIVERSAL.test(dob)) {
    const [year, month, day] = dob.split('-')
    // add time to date
    const dateString = `${year}-${month}-${day}T00:10:00`
    return new Date(dateString).getTime()
  }
  return null
}

export const formatPhoneNumberForAuth = number => {
  // convert phone into e.164 standard format assuming all are US removing any special characters

  // Remove any non-numeric characters except +
  const normalizedPhone = number.replace(/[^0-9+]/g, '')

  // check if number is already formatted for auth
  if (normalizedPhone.match(/^\+1\d{10}$/)) {
    return normalizedPhone
  }

  // check if number is exactly 10 digits and format for auth
  if (normalizedPhone.match(/^\d{10}$/)) {
    return `+1${normalizedPhone}`
  }

  return null
}

export const formatPhoneNumberForSMS = number => {
  // convert phone into e.164 standard format assuming all are US removing any special characters
  // check if number is already formatted for auth but remove any non-numeric characters except +
  if (number.match(/^\+1\d{10}$/)) {
    return `+${number.replace(/\D/g, '')}`
  }
  return `+1${number.replace(/\D/g, '')}`
}

export const formatPhoneNumberForProfile = number => {
  const cleaned = `${number}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`
  }
  return number
}

export const formatPhoneNumberForDiRx = number => {
  const cleaned = `${number}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `${match[2]}-${match[3]}-${match[4]}`
  }
  return null
}

export const moneyFormatter = amount => {
  if (typeof amount !== 'number') {
    return amount
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)
}

export const retrieveFiretableImageURL = imageArr => {
  if (!imageArr || !Array.isArray(imageArr)) {
    return null
  }

  if (imageArr.length === 0) {
    return null
  }

  return imageArr[0]?.downloadURL
}

export const formatMarkdown = markdownText => {
  return markdownText?.replaceAll('&#x20;', ' ') || markdownText
}
