import { createContext, useContext } from 'react'
import { useTrackVisibility } from 'react-intersection-observer-hook'
import PropTypes from 'prop-types'

const OnScreen = createContext()

export const useOnScreen = () => useContext(OnScreen)

export const OnScreenProvider = ({ children }) => {
  const [ref, { isVisible }] = useTrackVisibility()
  return <OnScreen.Provider value={{ ref, isVisible }}>{children}</OnScreen.Provider>
}

OnScreenProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OnScreen
