import { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Chip from './VoucherChip'
import { Toggle } from '../hooks/useToggleOpen'
import { collection } from '../firebase/firestore'
import { formatDateForUI } from '../libs/formatters'
import PatientVouchersBundle from './PatientVouchersBundle'
import PatientVouchersIndividual from './PatientVouchersIndividual'
import TableCellToggle from './TableCellToggle'

const PatientVouchersRow = ({ headers = [], row = [] }) => {
  const headersById = headers.reduce((acc, header) => {
    acc[header.id] = header
    return acc
  }, {})

  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Toggle>
      {({ open, handleClick, handleClose }) => (
        <Fragment>
          <TableRow>
            <TableCellToggle
              handleClose={handleClose}
              open={open}
              row={row}
              handleClick={handleClick}
            />

            {headersById.service && <TableCell>{row.service.name}</TableCell>}
            {headersById.purchasedDate && (
              <TableCell>{formatDateForUI(row?.createdAt || row?.date, true) || '---'}</TableCell>
            )}
            {headersById.code && <TableCell>{row.code}</TableCell>}
            {headersById.orgPhone && <TableCell>{row?.organization?.phone || '---'}</TableCell>}
            {headersById.appDate && (
              <TableCell>{formatDateForUI(row?.appointmentDate, true) || '---'}</TableCell>
            )}
            {headersById.status && (
              <TableCell align='center'>
                {row.bundle ? <Chip label='bundle' /> : <Chip label={row.status} />}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            {row.bundle ? (
              <PatientVouchersBundle open={open} row={row} />
            ) : (
              <PatientVouchersIndividual headerCount={headers.length} open={open} row={row} />
            )}
          </TableRow>
        </Fragment>
      )}
    </Toggle>
  )
}

PatientVouchersRow.propTypes = {
  row: PropTypes.shape({
    uid: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    status: PropTypes.string,
    orderId: PropTypes.string,
    code: PropTypes.string,
    cancelledAt: PropTypes.instanceOf(Date),
    redeemedAt: PropTypes.instanceOf(Date),
    bundle: PropTypes.bool,
    customer: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    service: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    organization: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      phone: PropTypes.string,
      instructions: PropTypes.string,
      telehealthLink: PropTypes.string,
    }),
    // string or date
    appointmentDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(firebase.firestore.Timestamp),
    ]),
    telehealthLink: PropTypes.string,
    bookingLink: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}

export default PatientVouchersRow
