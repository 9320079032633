import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import misheLogoWhite from '../../assets/images/LogoTextWhite.svg'

const StyledLogo = styled.img`
  height: ${props => props.theme.spacing(4)};
  ${props =>
    props.size === 'small' &&
    css`
      height: ${props.theme.spacing(2)};
      ${props.theme.breakpoints.up('lg')} {
        height: ${props.theme.spacing(2.5)};
      }
    `}
  ${props =>
    props.size === 'medium' &&
    css`
      height: ${props.theme.spacing(3)};
      ${props.theme.breakpoints.up('lg')} {
        height: ${props.theme.spacing(3.5)};
      }
    `}
  ${props =>
    props.size === 'mediumLarge' &&
    css`
      height: ${props.theme.spacing(6)};
      ${props.theme.breakpoints.up('lg')} {
        height: ${props.theme.spacing(4)};
      }
    `}
    ${props =>
    props.size === 'semiLarge' &&
    css`
      height: ${props.theme.spacing(4.5)};
      ${props.theme.breakpoints.up('lg')} {
        height: ${props.theme.spacing(4.5)};
      }
    `}
  ${props =>
    props.size === 'large' &&
    css`
      height: ${props.theme.spacing(4)};
      ${props.theme.breakpoints.up('lg')} {
        height: ${props.theme.spacing(6)};
      }
    `}
`

const Logo = ({ size = 'medium', source = '' }) => (
  <StyledLogo src={source || misheLogoWhite} alt='mishe' size={size} />
)

Logo.propTypes = {
  size: PropTypes.string,
  source: PropTypes.string,
}

export default Logo
