import { memo } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden'
import styled from 'styled-components'
import Typography from '../stories/Typography'
import Section from '../stories/Section'
import Container from '../stories/Container'
import WhyMisheSection1 from '../assets/images/why-mishe-section-1.png'
import WhyMisheSection2 from '../assets/images/why-mishe-section-2.png'
import WhyMisheSection3 from '../assets/images/why-mishe-section-3.png'

export const Number = styled.div`
  width: 50px;
  height: 50px;
  color: white;
  background: #662d91;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 14px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`
const NumberBorder = styled.div`
  border-right: 2px solid #bcbcbc;
  transform: rotate(180deg);
  height: 100%;
  margin-left: 23px;
  margin-top: -25px;
  position: relative;
  z-index: -1;
`
const MisheFlow = () => {
  return (
    <Section id='howitworks'>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mb={1} textAlign='center'>
              <Typography variant='caption' weight='semibold'>
                <Box component='span' color='primary.main'>
                  BASIC, EASY, FRICTIONLESS
                </Box>
              </Typography>
            </Box>
            <Box mb={5}>
              <Typography variant='h2' gutterBottom align='center'>
                This is healthcare,{' '}
                <Box component='span' color='primary.main'>
                  simplified
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid id='exp_serv_sched' container spacing={2}>
          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Box>
              <Number>1</Number>
            </Box>
            <Hidden mdDown>
              <NumberBorder />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box mb={1}>
              <Typography variant='caption' weight='semibold'>
                <Box component='span' color='primary.main'>
                  EXPLORE SERVICES
                </Box>
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant='h3' gutterBottom>
                Search and{' '}
                <Box component='span' color='primary.main'>
                  buy the services{' '}
                </Box>
                you need.
              </Typography>
            </Box>
            <Typography variant='body1' weight='light' style={{ maxWidth: '460px' }}>
              Shop care from licensed and vetted healthcare providers near you, based on their
              transparent cash prices and other factors important to you. Finding care is quick and
              easy with our AI-powered search and filter capabilities. Purchase care using credit,
              debit, HSA/FSA, buy now pay later (BNPL) installment payments, and soon
              cryptocurrency.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} />
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box mb={5}>
              <img src={WhyMisheSection1} alt='WhyMisheSection1' width='100%' />
            </Box>
          </Grid>
        </Grid>
        <Grid id='book_apt_visit' container spacing={2}>
          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Box>
              <Number>2</Number>
            </Box>
            <Hidden mdDown>
              <NumberBorder />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box mb={1}>
              <Typography variant='caption' weight='semibold'>
                <Box component='span' color='primary.main'>
                  BOOK APPOINTMENT
                </Box>
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant='h3' gutterBottom>
                <Box component='span' color='primary.main'>
                  Schedule appointments{' '}
                </Box>
                directly with your provider.
              </Typography>
            </Box>
            <Typography variant='body1' weight='light' style={{ maxWidth: '460px' }}>
              Your provider’s scheduling or walk-in instructions are available in your dashboard.
              You may cancel care at any time prior to voucher redemption. Call, text, email or chat
              mishe’s Concierge Team for help scheduling an appointment or with any questions.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} />
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box mb={5}>
              <img src={WhyMisheSection2} alt='WhyMisheSection2' width='100%' />
            </Box>
          </Grid>
        </Grid>
        <Grid id='vist_provider' container spacing={2}>
          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Box>
              <Number>3</Number>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box mb={1}>
              <Typography variant='caption' weight='semibold'>
                <Box component='span' color='primary.main'>
                  VISIT PROVIDER
                </Box>
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant='h3' gutterBottom>
                <Box component='span' color='primary.main'>
                  Visit your provider{' '}
                </Box>
                virtually or in-person and take control of your care.
              </Typography>
            </Box>
            <Typography variant='body1' weight='light' style={{ maxWidth: '460px' }}>
              Present your mishe care voucher to your provider for processing and follow any
              instructions received during the scheduling process. You will receive a confirmation
              email when your care voucher is processed.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} />
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box mb={5}>
              <img src={WhyMisheSection3} alt='WhyMisheSection3' width='100%' />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

export default memo(MisheFlow)
