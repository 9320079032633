import { useCallback } from 'react'
import Box from '@mui/material/Box'
import { signOut } from '../firebase/auth'
import NavLink from '../stories/NavLink'
import Typography from '../stories/Typography'

const SignOutButton = ({ color }) => {
  const onClick = useCallback(() => {
    signOut()
  }, [])

  return (
    <Box color={color || '#fff'} width='100%'>
      <NavLink
        type='button'
        onClick={onClick}
        display='standard'
        style={{
          borderRadius: '21.125px',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Typography variant='body1' weight='medium'>
          Log out
        </Typography>
      </NavLink>
    </Box>
  )
}

export default SignOutButton
