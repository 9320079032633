import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const MapEffects = createContext()

export const useMapEffects = () => useContext(MapEffects)

const MapEffectsProvider = ({ children }) => {
  const [hovered, setHovered] = useState(null)
  const [clicked, setClicked] = useState(null)
  const [hoveredData, setHoveredData] = useState(null)
  const [searchMapCordinates, setSearchMapCordinates] = useState(null)

  const handleHover = (open, locationIDs, data) => {
    if (!clicked && open) {
      setHovered(locationIDs)
      setHoveredData(data)
    }
    if (!clicked && !open) {
      setHovered(null)
      setHoveredData(null)
    }
  }

  const handleClick = (open, locationID) => {
    if (open) {
      setClicked(locationID)
    }
    if (!open) {
      setClicked(null)
    }
  }
  const value = {
    hovered,
    setHovered,
    handleHover,
    clicked,
    setClicked,
    handleClick,
    hoveredData,
    setSearchMapCordinates,
    searchMapCordinates,
  }

  return <MapEffects.Provider value={value}>{children}</MapEffects.Provider>
}

MapEffectsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MapEffectsProvider
