import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Collapse from '@mui/material/Collapse'
import { QRCodeCanvas } from 'qrcode.react'
import Icon from '../stories/Icons'
import Button from '../stories/Button'
import Link from '../stories/Link'
import VoucherDetailsEntry from '../containers/voucherDetailsEntry'
import Typography from '../stories/Typography'
import { formatDateForUI } from '../libs/formatters'
import { checkIfDate } from '../libs/validators'
import { useState } from 'react'
import AddToCalender from './AddToCalender'

const PatientVouchersIndividual = ({ open = false, row = [], headerCount = 5 }) => {
  const [addToCalenderOpen, setAddToCalenderOpen] = useState(false)

  const onClickAddToCalender = () => {
    setAddToCalenderOpen(!addToCalenderOpen)
  }

  return (
    <>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
      <TableCell style={{ padding: '0px' }} colSpan={headerCount - 2}>
        {!row.bundle && (
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box display='flex' alignItems='center' my={2}>
              <Icon name='price' fontSize='small' color='primary' />
              <Box fontWeight={500} ml={2}>
                Order:
              </Box>
              <Box ml={1} mr={2}>
                {row.orderId}
              </Box>
              <span>|</span>
              <Box fontWeight={500} ml={2}>
                Price:
              </Box>
              <Box ml={1} mr={2}>
                ${row.service?.price}
              </Box>
            </Box>
            <Box display='flex' alignItems='center' my={2}>
              <Icon name='calendar' fontSize='small' color='primary' />
              <Box fontWeight={500} ml={2}>
                Scheduling Instructions:
              </Box>
              <Box ml={1} mr={2}>
                {row?.organization?.instructions || '---'}
              </Box>
            </Box>
            {(row?.telehealthLink || row?.organization?.telehealthLink) && (
              <Box display='flex' alignItems='center' my={2}>
                <Box fontWeight={500} ml={2}>
                  Video Call Room:
                </Box>
                <Box ml={1} mr={2}>
                  <Link
                    to={row?.telehealthLink || row?.organization?.telehealthLink || ''}
                    type='external'
                    display='inline'
                    variant='unstyled'
                    size='small'
                    target='_blank'
                    style={{
                      padding: '5px 10px',
                      borderRadius: '5px',
                      backgroundColor: '#662D91',
                      color: '#fff',
                      boxShadow: '1px 1px 5px 0px #662D91',
                    }}
                  >
                    Join External Call
                  </Link>
                </Box>
              </Box>
            )}
            <Box display='flex' alignItems='center' my={2}>
              <Box fontWeight={500} ml={2}>
                Video Call Room:
              </Box>
              <Box ml={1} mr={2}>
                <Link
                  to={`/videochat?id=${row.uid}`}
                  type='internal'
                  display='inline'
                  variant='unstyled'
                  size='small'
                  target='_blank'
                  style={{
                    padding: '5px 10px',
                    borderRadius: '5px',
                    backgroundColor: '#662D91',
                    color: '#fff',
                    boxShadow: '1px 1px 5px 0px #662D91',
                  }}
                >
                  Join Mishe Video Call
                </Link>
              </Box>
            </Box>
            {row.bookingLink && (
              <Box display='flex' alignItems='center' my={2}>
                <Box fontWeight={500} ml={2}>
                  Booking Link:
                </Box>
                <Box ml={1} mr={2}>
                  <Link
                    to={row.bookingLink}
                    type='external'
                    display='inline'
                    variant='unstyled'
                    size='small'
                    target='_blank'
                    style={{
                      padding: '5px 10px',
                      borderRadius: '5px',
                      backgroundColor: '#662D91',
                      color: '#fff',
                      boxShadow: '1px 1px 5px 0px #662D91',
                    }}
                  >
                    Book here
                  </Link>
                </Box>
              </Box>
            )}
            <Box display='flex' alignItems='center' my={2}>
              <Icon name='notes' fontSize='small' color='primary' />
              <Box fontWeight={500} ml={2}>
                Notes
              </Box>
              <Box ml={2}>
                <VoucherDetailsEntry voucher={row}>
                  <Button
                    size='small'
                    variant='outlined'
                    style={{
                      display: 'inline-block',
                    }}
                  >
                    <Typography variant='body1' family='poppins'>
                      Details
                    </Typography>
                  </Button>
                </VoucherDetailsEntry>
              </Box>
              {checkIfDate(row?.appointmentDate) && (
                <>
                  {console.log(row.appointmentDate)}
                  <Box ml={3}>
                    <Button size='small' variant='outlined' onClick={onClickAddToCalender}>
                      <Typography variant='body1' family='poppins'>
                        Add To Calender
                      </Typography>
                    </Button>
                  </Box>
                  {/* We should make a formatter to handle the to ISO string similar to our formatDateForUI helper function */}
                  <AddToCalender
                    calenderEvent={{
                      title: row.service.name,
                      description: `Voucher Link:  ${window.location.origin}/dashboard?vc=${row.code}`,
                      start: row?.appointmentDate?.toDate()?.toISOString(),
                      appointmentDate: row.appointmentDate,
                      duration: [1, 'hour'],
                    }}
                    open={addToCalenderOpen}
                    onClose={onClickAddToCalender}
                  />
                </>
              )}
            </Box>
          </Collapse>
        )}
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} align='center'>
        <Collapse in={open} timeout='auto' unmountOnExit>
          {row.status === 'purchased' && (
            <>
              <Box style={{ marginTop: 10 }}>
                <QRCodeCanvas value={`${window.location.origin}/dashboard?claim=${row.code}`} />
              </Box>
            </>
          )}
          {row.status === 'cancelled' && (
            <>
              <Box fontWeight={500} mb={2}>
                Cancelled at:
              </Box>
              <Box>{formatDateForUI(row?.cancelledAt, true)}</Box>
            </>
          )}

          {row.status === 'redeemed' && (
            <>
              <Box>Redeemed at:</Box>
              <Box>{formatDateForUI(row?.redeemedAt, true) || '---'}</Box>
            </>
          )}
        </Collapse>
      </TableCell>
    </>
  )
}

export default PatientVouchersIndividual
