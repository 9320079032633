const EMAIL_REGEX = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/ // eslint-disable-line
const PHONE_REGEX = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/
const UNFORMATTED_PHONE_REGEX = /^\d{10}$/
// dash needs to be at the end of the regex to avoid being interpreted as a range
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_+?=-]).{8,}$/
const BIRTHDAY_REGEX = /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/
const BIRTHDAY_REGEX_UNIVERSAL = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/
const ZIPCODE_REGEX = /^\d{5}$/

import firebase from 'firebase/compat/app'

export const checkForEmptyObject = obj => {
  if (typeof obj !== 'object' || obj === null) {
    // this should technically return false
    return true
  }
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true
  }
  return false
}

export const GENDER_OPTIONS = [
  { value: 'F', label: 'Female' },
  { value: 'M', label: 'Male' },
  { value: 'FTM', label: 'Transgender Male / FTM' },
  { value: 'MTF', label: 'Transgender Female / MTF' },
  { value: 'GQ', label: 'Gender Queer' },
  { value: 'O', label: 'Other' },
  { value: 'NA', label: 'Decline to Answer' },
  { value: 'undefined', label: 'Not Selected' },
]

export const convertGender = value => {
  for (let i = 0; i < GENDER_OPTIONS.length; i++) {
    if (GENDER_OPTIONS[i].value === value) {
      return GENDER_OPTIONS[i].label
    }
  }
  return 'Not Selected'
}

export const isEmail = value => EMAIL_REGEX.test(value)

export const isPhone = value => PHONE_REGEX.test(value)

export const isUnformattedPhone = value => UNFORMATTED_PHONE_REGEX.test(value)

export const isZipcode = value => ZIPCODE_REGEX.test(value)

export const isPassword = value => PASSWORD_REGEX.test(value)

export const isDate = value => {
  if (BIRTHDAY_REGEX.test(value)) {
    const [month, day, year] = value.split('-')
    const date = new Date(year, month, day)
    return !!date.getFullYear()
  }
  if (BIRTHDAY_REGEX_UNIVERSAL.test(value)) {
    const [year, month, day] = value.split('-')
    const date = new Date(year, month, day)
    return !!date.getFullYear()
  }
  return false
}

export const checkIfDate = date =>
  date instanceof Date || date instanceof firebase.firestore.Timestamp

export const isGender = value => GENDER_OPTIONS.find(each => each.value === value)

export const isText = value => value?.length > 1

export const medOrderValidator = values => {
  const result = {
    error: false,
    messages: [],
  }

  if (!values?.quantity || values.quantity === 0) {
    result.error = true
    result.messages.push('Please enter a quantity')
  }

  if (!values.SIG) {
    result.error = true
    result.messages.push('Please enter a SIG')
  }

  if (!values.firstname) {
    result.error = true
    result.messages.push('Please enter a first name')
  }

  if (!values.lastname) {
    result.error = true
    result.messages.push('Please enter a last name')
  }
  if (!isEmail(values?.email)) {
    if (!values.email) {
      result.error = true
      result.messages.push('Please enter an email')
    } else {
      result.error = true
      result.messages.push('Please enter a valid email')
    }
  }
  if (!values.phone) {
    result.error = true
    result.messages.push('Please enter a phone number')
  }

  if (!values.dateOfBirth) {
    result.error = true
    result.messages.push('Please enter a date of birth')
  }

  if (!values.gender) {
    result.error = true
    result.messages.push('Please enter a gender')
  }

  return result
}

export const profileValidator = values => {
  const result = {
    error: false,
    messages: {},
  }

  if (values.firstName && !isText(values.firstName)) {
    result.error = true
    result.messages.firstname = 'Invalid first name'
  }

  if (values.lastName && !isText(values.lastName)) {
    result.error = true
    result.messages.lastname = 'Invalid last name'
  }

  if (values.email && !isEmail(values.email)) {
    result.error = true
    result.messages.email = 'Invalid email'
  }

  if (values.password && !isPassword(values.password)) {
    result.error = true
    // dash needs to be at the end of the regex to avoid being interpreted as a range
    result.messages.password =
      'Password must be at least 8 characters and contain at least one number, one uppercase letter, one lowercase letter, and one special character from !@#$%^&*_+?=-'
  }

  if (
    values.password &&
    values.passwordConfirmation &&
    values.password !== values.passwordConfirmation
  ) {
    result.error = true
    result.messages.passwordConfirmation = 'Passwords do not match'
  }

  if (values.phone && !isPhone(values.phone)) {
    result.error = true
    result.messages.phone = 'Invalid phone number'
  }

  if (values.gender && !isGender(values.gender)) {
    result.error = true
    result.messages.gender = 'Invalid gender'
  }

  if (values.birthday && !isDate(values.birthday)) {
    result.error = true
    result.messages.birthday = 'Invalid birthday'
  }

  if (values.zipcode && !isZipcode(values.zipcode)) {
    result.error = true
    result.messages.zipcode = 'Invalid zipcode'
  }

  return result
}
