import MuiTooltip from '@mui/material/Tooltip'

// Use sx prop to style MuiTooltip
const Tooltip = ({ children, ...props }) => {
  return (
    <MuiTooltip
      sx={{
        color: '#652D92',
        backgroundColor: '#E8EDF2',
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  )
}

export default Tooltip
