import { Fragment, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import NavLink from '../stories/NavLink'
import Logo from '../stories/Logo'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'
import SignOutButton from '../containers/SignOutButton'
import { Toggle } from '../hooks/useToggleOpen'
import { useCurrentUser } from '../context/CurrentUser'
import Button from '../stories/Button'
import Collapse from '@mui/material/Collapse'
import MenuIcon from '@mui/icons-material/Menu'

import useSignInUpModals from '../hooks/useSignInUpModals'
import SignInModal from './SignInModal'

import { useWhiteLabelingContext } from '../context/WhiteLabelingContext'

const SideMenu = ({ plans }) => {
  const [open, setOpen] = useState(false)
  const user = useCurrentUser()

  const { logoSource } = useWhiteLabelingContext()

  const [supportItemsOpen, setSupportItemsOpen] = useState(false)
  const [myPlansOpen, setMyPlansOpen] = useState(false)

  const { signInOpen, handleOpen } = useSignInUpModals()

  if (user === null) {
    return 'trying to authenticate...'
  }

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen)
  }

  return (
    <Toggle>
      {() => {
        // Memo the sign in modal
        const signInModal = useMemo(() => {
          return (
            <SignInModal
              open={signInOpen}
              handleClose={() => {
                handleOpen('signIn')
                toggleDrawer(false)()
              }}
              callback={() => {
                handleOpen('signIn')
                toggleDrawer(false)()
              }}
            />
          )
        }, [signInOpen, handleOpen])

        return (
          <>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={toggleDrawer(true)}
              size='large'
            >
              <MenuIcon />
            </IconButton>
            <MuiDrawer open={open} onClose={toggleDrawer(false)}>
              {signInModal}
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                height='100%'
                width='100%'
                style={{
                  position: 'relative',
                }}
              >
                <List>
                  <ListItem>
                    <NavLink color='inherit' align='left' to='/' onClick={toggleDrawer(false)}>
                      <Logo size='medium' source={logoSource} />
                    </NavLink>
                  </ListItem>
                  <Box
                    style={{
                      position: 'absolute',
                      top: '16px',
                      right: '16px',
                    }}
                  >
                    <Button
                      size='small'
                      variant='text'
                      onClick={toggleDrawer(false)}
                      style={{
                        width: '40px',
                        height: '40px',
                        minWidth: '40px',
                        borderRadius: '20px',
                      }}
                    >
                      <Icon name='exitToApp' />
                    </Button>
                  </Box>
                  <Divider />
                  <ListItem>
                    <NavLink
                      to='/map'
                      align='left'
                      fullWidth
                      onClick={toggleDrawer(false)}
                      display='standard'
                      family='poppins'
                    >
                      Clinics Map
                    </NavLink>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <NavLink
                      variant='text'
                      onClick={() => setSupportItemsOpen(!supportItemsOpen)}
                      align='left'
                      style={{
                        width: '100%',
                      }}
                    >
                      24/7 Support
                      <Icon
                        name={supportItemsOpen ? 'openArrow' : 'closeArrow'}
                        style={{
                          marginLeft: '5px',
                        }}
                      />
                    </NavLink>
                  </ListItem>
                  <Collapse in={supportItemsOpen} timeout='auto' unmountOnExit>
                    <Divider />
                    <ListItem>
                      <NavLink
                        to='tel:601-647-4326'
                        align='left'
                        width='full'
                        external
                        onClick={toggleDrawer(false)}
                      >
                        Call
                      </NavLink>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <NavLink
                        align='left'
                        width='full'
                        onClick={() => {
                          window?.Intercom('show')
                          toggleDrawer(false)()
                        }}
                      >
                        Chat
                      </NavLink>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <NavLink
                        to='sms:855-647-4326'
                        align='left'
                        width='full'
                        external
                        onClick={() => {
                          toggleDrawer(false)()
                        }}
                      >
                        SMS
                      </NavLink>
                    </ListItem>
                  </Collapse>
                  <Divider />
                  <ListItem>
                    <NavLink
                      variant='text'
                      onClick={() => setMyPlansOpen(prev => !prev)}
                      align='left'
                      style={{
                        width: '100%',
                      }}
                    >
                      {user.uid ? 'My Plans' : 'Plans'}
                      <Icon
                        name={myPlansOpen ? 'openArrow' : 'closeArrow'}
                        style={{
                          marginLeft: '5px',
                        }}
                      />
                    </NavLink>
                  </ListItem>
                  <Collapse in={myPlansOpen} timeout='auto' unmountOnExit>
                    <Divider />
                    <ListItem>
                      <NavLink
                        to='/plans/mishehealthplan'
                        align='left'
                        width='full'
                        onClick={toggleDrawer(false)}
                      >
                        Mishe Health Plan
                      </NavLink>
                    </ListItem>
                    {plans.map(plan => (
                      <Fragment key={plan.id}>
                        <Divider />
                        <ListItem>
                          <NavLink
                            to={`/plans/${plan.slug}`}
                            key={plan.id}
                            align='left'
                            width='full'
                            onClick={toggleDrawer(false)}
                          >
                            {plan.name}
                          </NavLink>
                        </ListItem>
                      </Fragment>
                    ))}
                  </Collapse>
                  {user.uid ? (
                    <>
                      <Divider />
                      <ListItem>
                        <NavLink
                          to='/dashboard'
                          align='left'
                          fullWidth
                          display='standard'
                          family='poppins'
                          onClick={toggleDrawer(false)}
                        >
                          Dashboard
                        </NavLink>
                      </ListItem>
                      <Divider />
                      <ListItem onClick={toggleDrawer(false)}>
                        <SignOutButton color='#000' />
                      </ListItem>
                    </>
                  ) : (
                    <>
                      <Divider />
                      <ListItem>
                        <Box color='#000' width='100%'>
                          <NavLink
                            align='left'
                            fullWidth
                            display='standard'
                            family='poppins'
                            onClick={() => {
                              handleOpen('signIn')
                            }}
                          >
                            Login
                          </NavLink>
                        </Box>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <Box color='#000' width='100%'>
                          <NavLink
                            to='/signup'
                            align='left'
                            fullWidth
                            display='standard'
                            family='poppins'
                            onClick={toggleDrawer(false)}
                          >
                            Sign Up
                          </NavLink>
                        </Box>
                      </ListItem>
                    </>
                  )}
                  <Divider />
                </List>
                <List>
                  <Divider />
                  <ListItem>
                    <NavLink
                      to='/privacy-policy'
                      align='left'
                      fullWidth
                      display='standard'
                      onClick={toggleDrawer(false)}
                      family='poppins'
                    >
                      privacy policy
                    </NavLink>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <NavLink
                      to='/terms'
                      align='left'
                      display='standard'
                      fullWidth
                      onClick={toggleDrawer(false)}
                      family='poppins'
                    >
                      terms of use
                    </NavLink>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Box display='flex' justifyContent='space-between' width='100%'>
                      <IconButton
                        target='_blank'
                        href='https://twitter.com/mishehealth'
                        size='large'
                      >
                        <Icon name='twitter' color='primary' />
                      </IconButton>
                      <IconButton
                        target='_blank'
                        href='https://www.instagram.com/mishe.health/'
                        size='large'
                      >
                        <Icon name='instagram' color='primary' />
                      </IconButton>
                      <IconButton
                        target='_blank'
                        href='https://www.facebook.com/mishe.care'
                        size='large'
                      >
                        <Icon name='facebook' color='primary' />
                      </IconButton>
                      <IconButton
                        target='_blank'
                        href='https://www.youtube.com/channel/UCQOg7eSM8FqkMPnzFbC0VdA'
                        size='large'
                      >
                        <Icon name='youtube' color='primary' />
                      </IconButton>
                    </Box>
                  </ListItem>
                </List>
              </Box>
            </MuiDrawer>
          </>
        )
      }}
    </Toggle>
  )
}

export default SideMenu
