import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useParams } from 'react-router-dom'
import { Box, Grid, Typography, LinearProgress, Button } from '@mui/material'
import LiveChat from '../stories/LiveChat'
import QuickCheckoutContainer from '../containers/QuickCheckoutContainer'
import QuickCheckoutNetwork from '../components/QuickCheckoutNetwork'
import { createPaymentIntentNetwork } from '../firebase/functions'
import Section from '../stories/Section'
import parseQueryString from '../libs/parseQueryStrings'
import { useCurrentNetwork } from '../context/CurrentNetwork'

const NetworkServiceCheckout = () => {
  const [clientSecret, setClientSecret] = useState(false)
  const [paymentIntentId, setPaymentIntentId] = useState(false)
  const [price, setPrice] = useState(0)
  const [ref, setRef] = useState('')
  const [progress, setProgress] = useState(0)
  const [buffer, setBuffer] = useState(10)

  const { search } = useLocation()
  const { network, networkServiceId } = useParams()

  const { loadNetworkBySlug, networkId, networkData, allUTMs } = useCurrentNetwork()

  const [errorTimer, setErrorTimer] = useState(null)
  const [loadError, setLoadError] = useState(null)
  const [fatalErrorMsg, setFatalErrorMsg] = useState(null)

  useEffect(() => {
    if (loadError) {
      clearTimeout(errorTimer)
    }
    setErrorTimer(
      setTimeout(() => {
        setLoadError(true)
      }, 10000),
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // {* BEGIN Tracking Calls *}
  useEffect(() => {
    const { ref } = parseQueryString(search) // eslint-disable-line
    if (ref) {
      localStorage.setItem('refvalue', ref)
    }
    setRef(ref)
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Network Checkout',
      network,
      RefCode: ref || '',
      ...allUTMs,
    })
  }, [allUTMs]) // eslint-disable-line react-hooks/exhaustive-deps

  // {* END Tracking Calls *}

  // {* BEGIN Calls to fetch lander data *}
  useEffect(() => {
    async function GetNetwork() {
      await loadNetworkBySlug(network)
    }
    if (network && !networkData && networkData?.slug !== network) {
      GetNetwork()
    }
  }, [network, networkData]) // eslint-disable-line react-hooks/exhaustive-deps
  // {* END Calls to fetch lander data *}

  useEffect(() => {
    async function getClientSecret() {
      const response = await createPaymentIntentNetwork({
        networkId,
        serviceId: networkServiceId,
      }).catch(error => {
        console.log('******** There was an error ********') // eslint-disable-line
        return error
      })

      if (response instanceof Error) {
        console.log('response.messsage', response.message) // eslint-disable-line
        console.log('response.code', response.code) // eslint-disable-line
        setLoadError(true)
        return
      }

      if (response.code === 'functions/failed-precondition') {
        console.log('******** Checking error ********') // eslint-disable-line
        setFatalErrorMsg(
          <>
            <Typography variant='h3' align='center'>
              Whoops! Something went wrong.
            </Typography>
            <Typography variant='body1' align='center'>
              We are unable to process this request right now.
              <br />
              Please try again later, or
              <Button onClick={() => window?.Intercom('show')} variant='text' color='primary'>
                contact support.
              </Button>
            </Typography>
          </>,
        )
        return
      }
      setPaymentIntentId(response.paymentIntentId)
      setClientSecret(response.secret)
      setPrice(response.amount)
    }
    if (networkId && networkServiceId) {
      console.log('networkId', networkId) // eslint-disable-line
      console.log('networkServiceId', networkServiceId) // eslint-disable-line
      getClientSecret()
    }
  }, [networkId, networkServiceId])

  useEffect(() => {
    let percentFinished = 0
    if (networkId) {
      percentFinished += 25
    }
    if (clientSecret) {
      percentFinished += 25
    }
    if (paymentIntentId) {
      percentFinished += 25
    }
    if (networkData) {
      percentFinished += 25
    }
    setProgress(percentFinished)
  }, [networkId, clientSecret, paymentIntentId, networkData])

  useEffect(() => {
    setBuffer(progress + 15)
  }, [progress])

  return (
    <>
      <Helmet>
        {networkData?.metaTitle && <title>Mishe | {networkData?.metaTitle || ''}</title>}
        {networkData?.metaTitle && (
          <meta property='og:title' content={`Mishe | ${networkData?.metaTitle || ''}`} />
        )}
        {!networkData?.metaTitle && <title>Mishe | {network}</title>}
        {!networkData?.metaTitle && <meta property='og:title' content={`Mishe | ${network}`} />}
        <meta
          name='description'
          property='og:description'
          content='Flexible treatment options, transparent pricing, modern payment tools, and your own personal care concierge team. Mishe is bringing healing back to healthcare'
        />
      </Helmet>
      {/* <NetworkServiceHero /> */}
      {networkId && clientSecret && paymentIntentId ? (
        <Section>
          <QuickCheckoutContainer clientSecret={clientSecret} paymentIntent={paymentIntentId}>
            <QuickCheckoutNetwork networkId={networkId} price={price} />
          </QuickCheckoutContainer>
        </Section>
      ) : (
        <Section>
          <Box display='flex' justifyContent='center' mt={2}>
            <Grid container display='flex' justifyContent='center'>
              <Grid item xs={12}>
                <Grid mt={2} spacing={3} display='flex' justifyContent='center' container>
                  {!fatalErrorMsg && (
                    <>
                      <Grid lg={12} md={12} sm={12} xs={12} item>
                        <Typography variant='h4' align='center'>
                          Building your booking experience...
                        </Typography>
                      </Grid>
                      <Grid lg={6} md={6} sm={9} xs={9} item>
                        <LinearProgress variant='buffer' value={progress} valueBuffer={buffer} />
                      </Grid>
                    </>
                  )}
                  {(loadError || fatalErrorMsg) && (
                    <Grid lg={12} md={12} sm={12} xs={12} item>
                      {loadError && !fatalErrorMsg && (
                        <Typography variant='h4' align='center'>
                          We apologize for the delay this is taking longer than usual.
                        </Typography>
                      )}
                      {fatalErrorMsg && (
                        <Typography variant='h4' align='center'>
                          {fatalErrorMsg}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Section>
      )}
      <LiveChat />
    </>
  )
}

export default NetworkServiceCheckout
