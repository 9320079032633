import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Box, Typography } from '@mui/material'
import GoogleMapReact from 'google-map-react'
import LocationMarker from './LocationMarker'

const VITE_GEOLOCATION = 'https://geolocation-db.com/json/'

const getDefaultCenter = async () => {
  const res = await axios.get(VITE_GEOLOCATION).catch(err => {
    console.log(err)
    return null
  })

  if (!res) {
    return null
  }

  const { latitude, longitude } = res?.data
  if (!latitude || !longitude) {
    return null
  }

  return {
    lat: latitude,
    lng: longitude,
  }
}

const NationalMap = ({ locations = [], hitClick = () => {}, newCenter = null }) => {
  // eslint-disable-next-line no-unused-vars
  const [markers, setMarkers] = useState(null)
  const [allCoords, setAllCoords] = useState(null)
  const [center, setCenter] = useState(null)
  const [updatedCenter, setUpdatedCenter] = useState(null)
  const [updatedZoom, setUpdatedZoom] = useState(4.3)

  useEffect(() => {
    if (newCenter) {
      setUpdatedCenter(center)
      setUpdatedZoom(14.9)
      setTimeout(() => {
        setUpdatedCenter(newCenter?.[0])
        setUpdatedZoom(15)
      }, 100)
    }
  }, [center, newCenter])

  const getMiddle = (prop, coords) => {
    let values = coords.map(m => m[prop])
    let min = Math.min(...values)
    let max = Math.max(...values)
    if (prop === 'lng' && max - min > 180) {
      values = values.map(val => (val < max - 180 ? val + 360 : val))
      min = Math.min(...values)
      max = Math.max(...values)
    }
    let result = (min + max) / 2
    if (prop === 'lng' && result > 180) {
      result -= 360
    }
    return result
  }

  const findCenter = coords => {
    return {
      lat: getMiddle('lat', coords),
      lng: getMiddle('lng', coords),
    }
  }

  const filterOutNullLocations = locs => {
    return locs.filter(location => {
      if (!location?.geopoint) {
        return false
      }
      // eslint-disable-next-line no-underscore-dangle
      if (!location?.geopoint[0]?.lat || !location?.geopoint[0]?.lng) {
        return false
      }

      return true
    })
  }

  const buildMarkers = locs => {
    const allLocations = []
    const accumulatedCoordinates = []

    const builtMarkers = filterOutNullLocations(locs).map(location => {
      // accumulate all the coords into the allCoords array

      const { lat, lng } = location?.geopoint[0]

      accumulatedCoordinates.push({ lat, lng })
      return (
        <LocationMarker
          // eslint-disable-next-line no-underscore-dangle
          key={location.id}
          lat={lat}
          lng={lng}
          onClick={() => hitClick(location)}
        />
      )
    })
    allLocations.push(...builtMarkers)
    setAllCoords(accumulatedCoordinates)
    return allLocations
  }

  useEffect(() => {
    if (!locations) {
      return undefined
    }
    const builtMarkers = buildMarkers(locations)
    setMarkers(builtMarkers)
  }, [locations]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!allCoords) {
      return undefined
    }
    // Center on User
    const getUserLocation = async () => {
      const userCenter = await getDefaultCenter()
      if (!userCenter) {
        // Center on Markers
        const calculatedCenter = findCenter(allCoords)
        setCenter(calculatedCenter)
        return
      }
      setCenter(userCenter)
    }
    getUserLocation()
  }, [allCoords]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // Important! Always set the container height explicitly
    <Box
      sx={{
        height: {
          xs: 'calc(100vh - 56px)',
          sm: 'calc(100vh - 56px)',
          md: 'calc(100vh - 64px)',
          lg: 'calc(100vh - 64px)',
          xl: 'calc(100vh - 64px)',
        },
        width: '100%',
      }}
    >
      {center?.lat && center?.lng && markers ? (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyBOTTnaTYTcp5rfhvnPQQl_Wv6eVc-7OnQ',
          }}
          defaultCenter={center}
          defaultZoom={4.3}
          center={updatedCenter}
          zoom={updatedZoom}
        >
          {markers && markers.map(marker => marker)}
        </GoogleMapReact>
      ) : (
        <Box
          height='100vh'
          width='100%'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant='h4' align='center'>
            {locations?.length >= 0 ? 'Loading Clinics...' : 'No Clinics Found'}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

NationalMap.propTypes = {
  locations: PropTypes.array,
  hitClick: PropTypes.func,
  newCenter: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  ),
}

export default NationalMap
