import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Slide from '@mui/material/Slide'
import Fade from '@mui/material/Fade'

const PageSection = ({
  children,
  image = '',
  startLoaded = false,
  direction = 'right',
  bgImage = '',
  bgColor = 'rgba(0,0,0,0)',
  bgColorSlide = 'rgba(0,0,0,0)',
  bgColorFade = 'rgba(0,0,0,0)',
  height = 700,
  imgWidth = '100%',
  infoWidth = '100%',
  slideBoxShadow = 'none',
  slideBorderRadius = '0',
  childrenOnSlide = false,
  unloadSectionOnScroll = false,
  customZIndex,
  ...props
}) => {
  const windowHeight = window.innerHeight
  const elementRef = useRef(null)
  const [loadSection1, setLoadSection1] = useState(startLoaded)

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current
      if (element) {
        const { top } = element.getBoundingClientRect()
        if (top >= -(height - 150) && top <= windowHeight - 100) {
          setLoadSection1(true)
        } else if (unloadSectionOnScroll) {
          setLoadSection1(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [height, unloadSectionOnScroll, windowHeight])

  return (
    <Box
      ref={elementRef}
      position='relative'
      height={height}
      bgcolor={bgColor}
      zIndex={customZIndex || '0'}
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
      }}
      {...props}
    >
      {direction === 'right' && (
        <>
          <Slide
            direction='right'
            in={loadSection1}
            timeout={{ enter: 1500, exit: 500 }}
            unmountOnExit
            mountOnEnter
          >
            <Box
              position='absolute'
              zIndex='0'
              display='flex'
              justifyContent='center'
              alignItems='center'
              boxShadow={slideBoxShadow}
              borderRadius='undefinedpx'
              bgcolor={bgColorSlide}
              height={height}
              width={imgWidth}
            >
              {childrenOnSlide && children}
              {image !== '' && <img src={image} alt='Mishe Network' height={height * 0.8} />}
            </Box>
          </Slide>
          <Fade in={loadSection1} timeout={{ enter: 2500, exit: 500 }}>
            <Box
              position='absolute'
              right='0'
              zIndex='1'
              display='flex'
              justifyContent='center'
              alignItems='center'
              bgcolor={bgColorFade}
              height={height}
              width={infoWidth}
            >
              {!childrenOnSlide && children}
            </Box>
          </Fade>
        </>
      )}
      {direction === 'left' && (
        <>
          <Slide
            direction='left'
            in={loadSection1}
            timeout={{ enter: 1500, exit: 500 }}
            unmountOnExit
            mountOnEnter
          >
            <Box
              position='absolute'
              right='0'
              zIndex='0'
              display='flex'
              justifyContent='center'
              alignItems='center'
              boxShadow={slideBoxShadow}
              borderRadius='undefinedpx'
              bgcolor={bgColorSlide}
              height={height}
              width={imgWidth}
            >
              {childrenOnSlide && children}
              {image !== '' && <img src={image} alt='Mishe Network' width='100%' />}
            </Box>
          </Slide>
          <Fade in={loadSection1} timeout={{ enter: 2500, exit: 500 }}>
            <Box
              position='absolute'
              left='0'
              zIndex='1'
              display='flex'
              justifyContent='center'
              alignItems='center'
              bgcolor={bgColorFade}
              height={height}
              width={infoWidth}
            >
              {!childrenOnSlide && children}
            </Box>
          </Fade>
        </>
      )}
      {direction === 'up' && (
        <>
          <Slide
            direction='up'
            in={loadSection1}
            timeout={{ enter: 1500, exit: 500 }}
            unmountOnExit
            mountOnEnter
          >
            <Box
              position='absolute'
              zIndex='0'
              display='flex'
              // justifyContent='center'
              boxShadow={slideBoxShadow}
              borderRadius='undefinedpx'
              bgcolor={bgColorSlide}
              height={height}
              width={imgWidth}
            >
              {childrenOnSlide && children}
              {image !== '' && <img src={image} alt='Mishe Network' width='100%' />}
            </Box>
          </Slide>
          <Fade in={loadSection1} timeout={{ enter: 2500, exit: 500 }}>
            <Box
              position='absolute'
              zIndex='1'
              display='flex'
              justifyContent='center'
              alignItems='center'
              bgcolor={bgColorFade}
              height={height}
              width={infoWidth}
            >
              {!childrenOnSlide && children}
            </Box>
          </Fade>
        </>
      )}
      {direction === 'down' && (
        <>
          <Slide
            direction='down'
            in={loadSection1}
            timeout={{ enter: 1500, exit: 500 }}
            unmountOnExit
            mountOnEnter
          >
            <Box
              position='absolute'
              zIndex='0'
              display='flex'
              justifyContent='center'
              alignItems='flex-end'
              boxShadow={slideBoxShadow}
              borderRadius='undefinedpx'
              bgcolor={bgColorSlide}
              height={height}
              width={imgWidth}
            >
              {childrenOnSlide && children}
              {image !== '' && <img src={image} alt='Mishe Network' width='100%' />}
            </Box>
          </Slide>
          <Fade in={loadSection1} timeout={{ enter: 2500, exit: 500 }}>
            <Box
              position='absolute'
              zIndex='1'
              display='flex'
              justifyContent='center'
              alignItems='center'
              bgcolor={bgColorFade}
              height={height}
              width={infoWidth}
            >
              {!childrenOnSlide && children}
            </Box>
          </Fade>
        </>
      )}
    </Box>
  )
}

PageSection.propTypes = {
  children: PropTypes.node.isRequired,
  startLoaded: PropTypes.bool,
  image: PropTypes.string,
  direction: PropTypes.string,
  bgImage: PropTypes.string,
  bgColor: PropTypes.string,
  bgColorSlide: PropTypes.string,
  bgColorFade: PropTypes.string,
  height: PropTypes.number,
  imgWidth: PropTypes.string,
  infoWidth: PropTypes.string,
  slideBoxShadow: PropTypes.string,
  slideBorderRadius: PropTypes.string,
  childrenOnSlide: PropTypes.bool,
  unloadSectionOnScroll: PropTypes.bool,
}

export default PageSection
