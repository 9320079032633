import { useRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'

const CodeEntry = ({ code, setCode }) => {
  const inputsRef = useRef([])

  const focusNext = (pos, backwards = false) => {
    if (pos < 0 || pos > 5) return
    inputsRef.current[pos].focus()
  }

  const handleChange = (e, pos) => {
    const value = e.target.value
    if (value === '' || parseInt(value) >= 0) {
      setCode(code.slice(0, pos) + value + code.slice(pos + 1))
      if (value !== '') focusNext(pos + 1)
    }
  }

  const handleKeyDown = (e, pos) => {
    if (e.key === 'Backspace' && !e.target.value) {
      focusNext(pos - 1)
    }
  }

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, 6)
  }, [])

  return (
    <Box display='flex' justifyContent='center'>
      {Array(6)
        .fill()
        .map((_, i) => (
          <OutlinedInput
            key={i}
            inputRef={ref => (inputsRef.current[i] = ref)}
            value={code[i] || ''}
            onChange={e => handleChange(e, i)}
            onKeyDown={e => handleKeyDown(e, i)}
            inputProps={{ maxLength: 1, pattern: '\\d*', inputMode: 'numeric' }}
            sx={{
              width: '50px',
              height: '50px',
              marginRight: '5px',
              fontSize: '20px',
              fontWeight: 'bold',
              lineHeight: '50px',
              input: {
                textAlign: 'center',
                padding: '0',
              },
            }}
          />
        ))}
    </Box>
  )
}

export default CodeEntry
