import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden'
import Filter1Icon from '@mui/icons-material/Filter1'
import Filter2Icon from '@mui/icons-material/Filter2'
import Filter3Icon from '@mui/icons-material/Filter3'
import Divider from '@mui/material/Divider'
import Button from '../Button'
import Container from '../Container'
import Typography from '../Typography'
import LoadingBar from '../RandomLoadingBar'

const HowMisheWorks = ({ variant = 'desktop' }) => {
  if (variant === 'desktop') {
    return (
      <Box mt={3} width='100%'>
        <Grid
          container
          alignItems='center'
          width='100%'
          style={{
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
            borderRadius: '5px',
            padding: '15px',
          }}
        >
          <Typography variant='h4' color='textPrimary'>
            How Mishe Works
          </Typography>
          <Box mt={2} width='100%' alignItems='center' display='flex'>
            <Filter1Icon fontSize='small' style={{ color: '#303030', marginRight: '10px' }} />
            <Typography variant='body2' weight='light'>
              We pre-negotiate prices for you
            </Typography>
          </Box>
          <Box mt={2} width='100%' alignItems='center' display='flex'>
            <Filter2Icon fontSize='small' style={{ color: '#303030', marginRight: '10px' }} />
            <Typography variant='body2' weight='light'>
              You pre-pay for your care
            </Typography>
          </Box>
          <Box mt={2} mb={2} width='100%' alignItems='center' display='flex'>
            <Filter3Icon fontSize='small' style={{ color: '#303030', marginRight: '10px' }} />
            <Typography variant='body2' weight='light'>
              We schedule your appointment for you
            </Typography>
          </Box>
          <Box mt={1} width='100%'>
            <Divider
              style={{
                marginBottom: '20px',
              }}
            />
            <Typography variant='body2' weight='light' textalignment='justified'>
              We&apos;re always available to answer your questions.
            </Typography>
          </Box>
        </Grid>
      </Box>
    )
  }
  return (
    <Box mt={2} width='100%'>
      <Grid
        container
        alignItems='center'
        width='100%'
        style={{
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
          borderRadius: '5px',
          padding: '15px',
        }}
      >
        <Typography variant='h5' color='textPrimary'>
          How Mishe Works
        </Typography>
        <Box mt={2} width='100%' alignItems='center' display='flex'>
          <Filter1Icon fontSize='small' style={{ color: '#303030', marginRight: '10px' }} />
          <Typography variant='body2' weight='light'>
            We pre-negotiate prices for you
          </Typography>
        </Box>
        <Box mt={2} width='100%' alignItems='center' display='flex'>
          <Filter2Icon fontSize='small' style={{ color: '#303030', marginRight: '10px' }} />
          <Typography variant='body2' weight='light'>
            You pre-pay for your care
          </Typography>
        </Box>
        <Box mt={2} mb={2} width='100%' alignItems='center' display='flex'>
          <Filter3Icon fontSize='small' style={{ color: '#303030', marginRight: '10px' }} />
          <Typography variant='body2' weight='light'>
            We schedule your appointment for you
          </Typography>
        </Box>
        <Box mt={1} width='100%'>
          <Divider
            style={{
              marginBottom: '20px',
            }}
          />
          <Typography variant='body2' weight='light' textalignment='justified'>
            We&apos;re always available to answer your questions.
          </Typography>
        </Box>
      </Grid>
    </Box>
  )
}

HowMisheWorks.propTypes = {
  variant: PropTypes.string,
}

const BookConsultation = ({ variant = 'desktop' }) => {
  if (variant === 'desktop') {
    return (
      <Grid container spacing={3} display='flex'>
        <Grid item md={12} lg={12}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              marginTop: '-50px',
            }}
          >
            {/* Desktop Book Consultation Buttons */}
            <Box
              mb={3}
              width='100%'
              style={{
                position: 'sticky',
                top: '120px',
                zIndex: '1000',
                borderRadius: '5px',
                padding: '15px',
              }}
            >
              <Box alignItems='center' width='100%'>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Button
                    onClick={() => {
                      window.open('https://meet.intercom.com/margie', '_blank')
                    }}
                    display='inline'
                    variant='contained'
                    size='small'
                    fullWidth
                    style={{
                      color: '#fff',
                      width: '50vw',
                      padding: '5px',
                      borderRadius: '5px',
                      backgroundColor: '#662D91',
                      boxShadow: '1px 1px 5px 0px #662D91',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    Schedule free consultation
                  </Button>
                  <Button
                    display='inline'
                    variant='contained'
                    size='small'
                    fullWidth
                    style={{
                      padding: '5px',
                      width: '50vw',
                      fontSize: '20px',
                      border: '1px solid #662D91',
                      borderRadius: '5px',
                      fontWeight: 'bold',
                    }}
                    className='open-live-chat'
                  >
                    Chat with us now
                  </Button>
                </Box>
              </Box>
            </Box>
            {/* End of Desktop Book Consultation Buttons */}
          </Box>
        </Grid>
      </Grid>
    )
  }
  return (
    <Box
      width='100%'
      style={{
        position: 'sticky',
        top: '75px',
        zIndex: '1000',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        borderRadius: '5px',
        padding: '15px',
      }}
    >
      <Box display='block' alignItems='center'>
        <Box>
          <Button
            onClick={() => {
              window.open('https://meet.intercom.com/margie', '_blank')
            }}
            display='inline'
            variant='contained'
            size='small'
            fullWidth
            style={{
              color: '#fff',
              padding: '1px',
              borderRadius: '5px',
              backgroundColor: '#662D91',
              boxShadow: '1px 1px 5px 0px #662D91',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            Schedule free consultation
          </Button>
        </Box>
        <Box mt={1}>
          <Button
            display='inline'
            variant='contained'
            size='small'
            fullWidth
            style={{
              padding: '1px',
              fontSize: '16px',
              border: '1px solid #662D91',
              borderRadius: '5px',
              fontWeight: 'bold',
            }}
            className='open-live-chat'
          >
            Chat with us now
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

BookConsultation.propTypes = {
  variant: PropTypes.string,
}

const NetworkServiceLoader = () => {
  return (
    <>
      {/* Desktop */}
      <Hidden mdDown>
        <Container>
          <Grid container spacing={3} display='flex'>
            <Grid item xs={12} sm={12}>
              <BookConsultation variant='desktop' />
            </Grid>
            <Grid item xs={12} sm={12} mt={2}>
              <LoadingBar speed={20} />
            </Grid>
            <Grid item xs={12} sm={12} mt={2}>
              <HowMisheWorks variant='desktop' />
            </Grid>
          </Grid>
        </Container>
      </Hidden>
      {/* End Desktop */}
      {/* Mobile */}
      <Hidden mdUp>
        <Container>
          <Grid container spacing={3} display='flex'>
            <Grid item xs={12} sm={12}>
              <BookConsultation variant='mobile' />
            </Grid>
            <Grid item xs={12} sm={12} mt={2}>
              <LoadingBar speed={20} />
            </Grid>
            <Grid item xs={12} sm={12} mt={2}>
              <HowMisheWorks variant='mobile' />
            </Grid>
          </Grid>
        </Container>
      </Hidden>
      {/* End Mobile */}
    </>
  )
}

export default NetworkServiceLoader
