import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Box from '@mui/material/Box'
import ReactStars from 'react-rating-stars-component'
import TextTruncate from 'react-text-truncate'
import Container from '../stories/Container'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import Avatar from './OrganizationAvatar'
import PriceTag from '../stories/PriceTag'
import Link from '../stories/Link'
import Zoom from '@mui/material/Zoom'

const ServiceCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(68, 26, 100, 0.2);
  border-radius: 10px;
  padding: 20px 0;
  height: 100%;
  min-height: 220px;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
`
const MoreButton = styled.button`
  cursor: pointer;
  color: #662d91;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 166%;
  background: transparent;
  border: none;
`

const OrganizationServicesCard = ({
  service = '',
  organization = '',
  photo = '',
  price = 0,
  distance = null,
  virtual = null,
  description = '',
  review = null,
  networkSlug = null,
  serviceID = null,
  misheCode = null,
  exactMatch,
  orderRequired,
}) => {
  const [textLimit, setTextLimit] = useState(2)

  const [hover, setHover] = useState(false)

  const [screenSize, setScreenSize] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleChange = () => {
    if (misheCode && networkSlug) {
      setHover(!hover)
    }
  }

  return (
    <ServiceCardContainer>
      <Container>
        <Box display='grid' minHeight='180px'>
          <Box display='flex' alignItems='center' justifyContent='flex-start'>
            <Box display='flex' alignItems='center' justifyContent='flex-start'>
              <Box alignItems='center'>
                <Avatar size='tiny' src={photo} alt={organization} style={{ margin: '0px' }} />
              </Box>
              <Box padding='0 1rem' display='flex' flexDirection='column' justifyContent='center'>
                {exactMatch && (
                  <Zoom in={exactMatch}>
                    <Box color='#7004c6'>
                      <Typography
                        variant='body1'
                        weight='semibold'
                        style={{ fontSize: '14px', marginLeft: '-4px' }}
                      >
                        Procedure Code Match
                      </Typography>
                    </Box>
                  </Zoom>
                )}
                {networkSlug && misheCode ? (
                  <a
                    style={{
                      transition: 'all 0.3s ease',
                      transform: hover ? 'scale(1.05)' : 'scale(1)',
                      pointer: hover ? 'cursor' : 'pointer',
                      textDecoration: 'none',
                    }}
                    href={`/plans/${networkSlug}/service/${misheCode}`}
                  >
                    <Typography
                      variant='h5'
                      style={{
                        margin: '0px',
                        wordBreak: 'break-word',
                        color: hover ? '#652D92' : 'black',
                      }}
                      onMouseEnter={() => handleChange()}
                      onMouseLeave={() => handleChange()}
                    >
                      {service}
                    </Typography>
                  </a>
                ) : (
                  <Typography variant='h5' style={{ margin: '0px', wordBreak: 'break-word' }}>
                    {service}
                  </Typography>
                )}
                <Box display='flex' alignItems='center' justifyContent='flex-start' width='100%'>
                  <ReactStars
                    value={review && review.count ? review.count : 0}
                    size={14}
                    edit={false}
                    activeColor='#652D92'
                    isHalf
                  />
                  <Box ml={2}>
                    <Link
                      display='standard'
                      size='small'
                      to={review && review.link ? review.link : null}
                      target='_blank'
                      type='external'
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      See reviews
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={1}>
            <Typography
              variant='body2'
              gutterBottom
              align={screenSize < 768 ? 'center' : 'left'}
              weight='light'
            >
              {textLimit === 2 && (
                <TextTruncate
                  line={textLimit}
                  element='span'
                  truncateText='…'
                  text={description}
                  textTruncateChild={
                    <MoreButton
                      onClick={e => {
                        e.stopPropagation()
                        setTextLimit(0)
                      }}
                    >
                      show more
                    </MoreButton>
                  }
                />
              )}
              {textLimit === 0 && (
                <>
                  {description}
                  <MoreButton
                    onClick={e => {
                      e.stopPropagation()
                      setTextLimit(2)
                    }}
                  >
                    show less
                  </MoreButton>
                </>
              )}
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' justifyContent='center'>
            {virtual ? (
              <Box width='100%'>
                <Typography mb={1} variant='body2' gutterBottom align='center' weight='light'>
                  Virtual
                </Typography>
              </Box>
            ) : (
              <>
                {distance && (
                  <Box width='100%'>
                    <Typography mb={1} variant='body2' gutterBottom align='center' weight='light'>
                      {distance} miles away
                    </Typography>
                  </Box>
                )}
              </>
            )}
            <Box width='100%'>
              <Box textAlign='center'>{organization}</Box>
            </Box>
          </Box>
          <Box
            display='flex'
            justifySelf={screenSize < 768 ? 'center' : 'end'}
            marginRight={screenSize < 768 ? '0' : '1rem'}
          >
            <Box
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
              textAlign='center'
              height='100%'
            >
              <PriceTag size='large'>{(price && parseFloat(price)?.toFixed(2)) || '---'}</PriceTag>
            </Box>
            {networkSlug && serviceID && !orderRequired && (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                {/* Link to the plan checkout page for the service */}
                <Link to={`/plans/${networkSlug}/service/${serviceID}/checkout`}>
                  <Button fullWidth style={{ height: '45px' }}>
                    <Typography
                      variant='h5'
                      weight='light'
                      style={{ margin: '0px', whiteSpace: 'nowrap' }}
                    >
                      Book Now
                    </Typography>
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </ServiceCardContainer>
  )
}

OrganizationServicesCard.propTypes = {
  distance: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  virtual: PropTypes.bool,
  organization: PropTypes.string,
  price: PropTypes.number,
  service: PropTypes.string,
  description: PropTypes.string,
  photo: PropTypes.string,
  review: PropTypes.shape({
    count: PropTypes.number,
    link: PropTypes.string,
  }),
  networkSlug: PropTypes.string,
  serviceID: PropTypes.string,
  misheCode: PropTypes.string,
}

export default OrganizationServicesCard
