import styled, { css } from 'styled-components'
import MuiTypography from '@mui/material/Typography'

const WarningText = styled(MuiTypography)`
  ${props =>
    props.type === 'info' &&
    css`
      color: ${props.theme.palette.info.dark};
    `}
  ${props =>
    props.type === 'error' &&
    css`
      color: ${props.theme.palette.error.dark};
    `}
  ${props =>
    props.type === 'success' &&
    css`
      color: ${props.theme.palette.success.dark};
    `}
`

export default WarningText
