import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden'
import AppBar from '../stories/AppBar'
import NavLink from '../stories/NavLink'
import DropDownMenu from './DropDownMenu'
import Logo from '../stories/LogoWhite'
import TopCorner from './TopCorner'
// import NavButtonContainer from '../containers/NavButtonContainer'

import { useWhiteLabelingContext } from '../context/WhiteLabelingContext'

const TopBar = () => {
  const { logoSource, planColorSecondary, planColorPrimary } = useWhiteLabelingContext()

  console.log(planColorPrimary, planColorSecondary)
  return (
    <AppBar primaryColor={planColorPrimary} secondaryColor={planColorSecondary}>
      <Box display='flex' alignItems='center' flexGrow={1}>
        <NavLink to='/'>
          <Logo size='semiLarge' source={logoSource} />
        </NavLink>
      </Box>
      <Hidden mdDown>
        <Box display='flex' alignItems='center' flexGrow={1} color={planColorSecondary || '#fff'}>
          {/* <DropDownMenu text='Solutions'>
            <NavLink to='/about' align='left' width='full'>
              Sync
            </NavLink>
            <NavLink to='/blog' align='left' width='full'>
              Direct
            </NavLink>
            <NavLink to='/questions' align='left' width='full'>
              Connect
            </NavLink>
          </DropDownMenu> */}
          {/* <NavButtonContainer style={{ borderRadius: '21.125px' }}>
            <NavLink to='/patients' align='left'>
              Stakeholders
            </NavLink>
          </NavButtonContainer> */}
          {/* <DropDownMenu text='Company'>
            <NavLink to='/patients' align='left' width='full'>
              Team
            </NavLink>
            <NavLink to='/providers' align='left' width='full'>
              Values
            </NavLink>
          </DropDownMenu> */}
          <DropDownMenu
            text='Resources'
            primaryColor={planColorPrimary}
            secondaryColor={planColorSecondary}
          >
            <Box color={planColorSecondary || '#fff'} width='100%'>
              <NavLink
                to='/map'
                align='left'
                width='full'
                style={{ borderRadius: '0 0 21.125px 21.125px' }}
              >
                Clinics Map
              </NavLink>
            </Box>
            {/* <NavLink to='/affiliates' align='left' width='full'>
              Help Central
            </NavLink>
            <NavLink to='/affiliates' align='left' width='full'>
              Info
            </NavLink>
            <NavLink to='/affiliates' align='left' width='full'>
              News
            </NavLink> */}
          </DropDownMenu>
          <DropDownMenu
            text='24/7 Support'
            primaryColor={planColorPrimary}
            secondaryColor={planColorSecondary}
          >
            <Box color={planColorSecondary || '#fff'} width='100%'>
              <NavLink to='tel:601-647-4326' align='left' width='full' external>
                Call
              </NavLink>
            </Box>
            <Box color={planColorSecondary || '#fff'} width='100%'>
              <NavLink align='left' width='full' onClick={() => window?.Intercom('show')}>
                Chat
              </NavLink>
            </Box>
            <Box color={planColorSecondary || '#fff'} width='100%'>
              <NavLink
                to='sms:855-647-4326'
                align='left'
                width='full'
                external
                style={{ borderRadius: '0 0 21.125px 21.125px' }}
              >
                SMS
              </NavLink>
            </Box>
          </DropDownMenu>
        </Box>
      </Hidden>
      <Box display='flex' alignItems='center'>
        <TopCorner primaryColor={planColorPrimary} secondaryColor={planColorSecondary} />
      </Box>
    </AppBar>
  )
}

export default TopBar
