import { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import { PieChart } from '@mui/x-charts/PieChart'
import { useDrawingArea } from '@mui/x-charts/hooks'
import { styled } from '@mui/material/styles'
import MuiTypography from '@mui/material/Typography'
import Fade from '@mui/material/Fade'
import Hidden from '@mui/material/Hidden'

const Hero = () => {
  const [radius, setRadius] = useState(200)
  const [mobileRadius, setMobileRadius] = useState(140)
  const [itemNb, setItemNb] = useState(3)
  const [transactionFeeAmount, setTransactionFeeAmount] = useState(4000)
  const [transactionFeeAmount2, setTransactionFeeAmount2] = useState(4000)
  const transactionFeeRef = useRef(null)
  const transactionFeeRef2 = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      if (!transactionFeeRef.current) {
        transactionFeeRef.current = setInterval(() => {
          setTransactionFeeAmount(a => a - 100)
        }, 50)
      }
      return () => {
        clearInterval(transactionFeeRef.current)
      }
    }, 1500)
  }, [])

  useEffect(() => {
    if (transactionFeeAmount === 0) {
      clearInterval(transactionFeeRef.current)
      setItemNb(2)
    }
    if (!transactionFeeRef2.current && transactionFeeAmount === 0) {
      setTimeout(() => {
        transactionFeeRef2.current = setInterval(() => {
          setTransactionFeeAmount2(a => a - 100)
        }, 50)
      }, 750)
    }
    return () => {
      clearInterval(transactionFeeRef2.current)
    }
  }, [transactionFeeAmount])

  useEffect(() => {
    if (transactionFeeAmount2 === 0) {
      clearInterval(transactionFeeRef2.current)
      setItemNb(1)
      setRadius(0)
      setMobileRadius(0)
    }
  }, [transactionFeeAmount2])

  const data2 = [
    { label: 'Mishe Direct', value: 2000, color: '#dcc4f4' },
    { label: 'Intermediary', value: transactionFeeAmount2, color: '#d8303e' },
    { label: 'Chargemaster', value: transactionFeeAmount, color: '#b2212e' },
  ]

  const StyledText = styled('text')(() => ({
    fill: radius === 0 ? '#652d92' : '#fff',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 50,
    fontWeight: 700,
    fontFamily: 'Poppins',
    // textShadow: '0 0 10px #00000033'
  }))

  const StyledTextTitle = styled('text')(() => ({
    fill: radius === 0 ? '#652d92' : '#fff',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
    fontWeight: 700,
    fontFamily: 'Poppins',
    // textShadow: '0 0 10px #00000033'
  }))
  const StyledTextSubTitle = styled('text')(() => ({
    fill: radius === 0 ? '#652d92' : '#fff',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Poppins',
    // textShadow: '0 0 10px #00000033'
  }))

  const StyledTextMobile = styled('text')(() => ({
    fill: radius === 0 ? '#652d92' : '#fff',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 40,
    fontWeight: 700,
    fontFamily: 'Poppins',
    // textShadow: '0 0 10px #00000033'
  }))

  const StyledTextTitleMobile = styled('text')(() => ({
    fill: radius === 0 ? '#652d92' : '#fff',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
    fontWeight: 700,
    fontFamily: 'Poppins',
    // textShadow: '0 0 10px #00000033'
  }))

  const StyledTextSubTitleMobile = styled('text')(() => ({
    fill: radius === 0 ? '#652d92' : '#fff',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Poppins',
    // textShadow: '0 0 10px #00000033'
  }))

  function PieCenterLabel() {
    const { width, height, left, top } = useDrawingArea()
    return (
      <>
        <StyledText x={left + width / 2} y={top + height / 2}>
          {transactionFeeAmount2 !== 0
            ? '$' + (transactionFeeAmount + transactionFeeAmount2 + 2000).toLocaleString()
            : '80% Savings'}
        </StyledText>
        <StyledTextTitle x={left + width / 2} y={top + height / 2 + 45}>
          {transactionFeeAmount === 0
            ? transactionFeeAmount2 === 0
              ? 'Mishe Direct'
              : 'PPO'
            : 'MSRP'}
        </StyledTextTitle>
        <StyledTextSubTitle x={left + width / 2} y={top + height / 2 + 70}>
          {transactionFeeAmount2 === 0 && '$2000'}
        </StyledTextSubTitle>
      </>
    )
  }

  function PieCenterLabelMobile() {
    const { width, height, left, top } = useDrawingArea()
    return (
      <>
        <StyledTextMobile x={left + width / 2 + 45} y={top + height / 2 - 45}>
          {transactionFeeAmount2 !== 0
            ? '$' + (transactionFeeAmount + transactionFeeAmount2 + 2000).toLocaleString()
            : '80% Savings'}
        </StyledTextMobile>
        <StyledTextTitleMobile x={left + width / 2 + 45} y={top + height / 2 - 5}>
          {transactionFeeAmount === 0
            ? transactionFeeAmount2 === 0
              ? 'Mishe Direct'
              : 'PPO'
            : 'MSRP'}
        </StyledTextTitleMobile>
        <StyledTextSubTitleMobile x={left + width / 2 + 45} y={top + height / 2 + 20}>
          {transactionFeeAmount2 === 0 && '$2000'}
        </StyledTextSubTitleMobile>
      </>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: {
          xs: 'wrap',
          lg: 'nowrap',
        },
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '750px',
        bgcolor: '#652d92',
        position: 'relative',
        padding: {
          xs: 2,
          md: 5,
        },
      }}
    >
      <Box
        position='fixed'
        top='0'
        left='0'
        width='100%'
        height='100vh'
        zIndex='-1'
        bgcolor='#652d92'
      />
      <Hidden mdDown>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            maxWidth: '500px',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <PieChart
            height={600}
            width={600}
            series={[
              {
                data: data2.slice(0, itemNb),
                innerRadius: radius,
                arcLabel: params => params.label ?? '',
                cornerRadius: itemNb > 1 ? 30 : 0,
                paddingAngle: 2,
                startAngle: -180,
              },
            ]}
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'bottom', horizontal: 'left' },
                padding: { top: 0, bottom: 0, left: 0, right: 0 },
                labelStyle: {
                  fontSize: 16,
                  fill: '#fff',
                },
              },
            }}
            tooltip={{
              show: false,
            }}
            sx={{
              [`.MuiPieArc-root`]: {
                stroke: 'none',
              },
              [`& .MuiPieArcLabel-root`]: {
                fill: '#ffffff00',
                fontSize: 20,
                fontWeight: 700,
                textShadow: '0 0 10px #00000033',
              },
            }}
          >
            <PieCenterLabel></PieCenterLabel>
          </PieChart>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box
          sx={{
            display: 'flex',
            maxWidth: '400px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: {
              sm: '-96px',
              md: 0,
            },
          }}
        >
          <PieChart
            height={500}
            width={360}
            series={[
              {
                data: data2.slice(0, itemNb),
                outerRadius: 170,
                innerRadius: mobileRadius,
                arcLabel: params => params.label ?? '',
                cornerRadius: itemNb > 1 ? 30 : 0,
                paddingAngle: 2,
                startAngle: -180,
                cx: 175,
                cy: 200,
              },
            ]}
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'bottom', horizontal: 'left' },
                padding: { top: 0, bottom: 50, left: 10, right: 0 },
                labelStyle: {
                  fontSize: 14,
                  fill: '#fff',
                },
              },
            }}
            tooltip={{
              show: false,
            }}
            sx={{
              [`.MuiPieArc-root`]: {
                stroke: 'none',
              },
              [`& .MuiPieArcLabel-root`]: {
                fill: '#ffffff00',
                fontSize: 20,
                fontWeight: 700,
                textShadow: '0 0 10px #00000033',
              },
              '&&': { touchAction: 'auto' },
            }}
          >
            <PieCenterLabelMobile />
          </PieChart>
        </Box>
      </Hidden>
      <Box
        sx={{
          minWidth: '60%',
          height: '100%',
          padding: {
            xs: 0,
            sm: 2,
            lg: '100px 24px 24px 64px',
          },
          marginTop: {
            xs: '-18px',
            md: 0,
          },
          marginBottom: {
            xs: '72px',
            md: 0,
          },
        }}
      >
        <Fade in={true} timeout={2500}>
          <Box>
            <MuiTypography
              margin='0'
              color='#fff'
              sx={{
                textAlign: {
                  xs: 'center',
                  md: 'left',
                },
                fontSize: {
                  xs: '1.2rem',
                  sm: '1.25rem',
                },
                marginBottom: 1,
                textTransform: 'uppercase',
                letterSpacing: '0.1em',
                width: '100%',
                fontFamily: 'Poppins',
                fontWeight: 500,
              }}
            >
              Healthcare&apos;s Single Payer Platform
            </MuiTypography>
            <Box marginBottom='20px'>
              <MuiTypography
                color='#fff'
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left',
                  },
                  fontSize: {
                    xs: '2.2rem',
                    sm: '3rem',
                    md: '4rem',
                  },
                  lineHeight: {
                    xs: '1.25',
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                }}
              >
                Interfacing employers & providers to simplify exchange
              </MuiTypography>
            </Box>
            <MuiTypography
              margin='0'
              color='#fff'
              sx={{
                textAlign: {
                  xs: 'center',
                  md: 'left',
                },
                fontSize: {
                  xs: '1.35rem',
                  sm: '1.5rem',
                  md: '1.75rem',
                },
                lineHeight: {
                  xs: '1.2',
                },
                marginBottom: {
                  xs: 1,
                  md: 3,
                },
                fontFamily: 'Poppins',
                fontWeight: 300,
              }}
            >
              Automating transparent administrative services.
            </MuiTypography>
          </Box>
        </Fade>
      </Box>
    </Box>
  )
}

export default Hero
