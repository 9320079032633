import styled, { css } from 'styled-components'
import MuiCard from '@mui/material/Card'
import MuiTypography from '@mui/material/Typography'

export const Card = styled(MuiCard)`
  position: relative;
  height: 100%;
  padding: ${props => props.theme.spacing(4)};
  background: ${props => props.theme.palette.background.paper};
  ${props =>
    props.spacing === 'thin' &&
    css`
      padding: ${props.theme.spacing(2)};
    `}
  ${props =>
    props.spacing === 'none' &&
    css`
      padding: ${props.theme.spacing(0)};
    `}
  ${props =>
    props.background === 'smoke' &&
    css`
      background: ${props.theme.palette.background.smoke};
    `}
`

export const CardTitle = styled(MuiTypography)`
  margin-bottom: ${props => props.theme.spacing(1)};
  font-size: 1.125rem;
  font-weight: 500;
  ${props =>
    props.transform === 'lowercase' &&
    css`
      text-transform: lowercase;
    `}
`

export const CardText = styled(MuiTypography)`
  margin-top: ${props => props.theme.spacing(1)};
  font-size: 0.875rem;
  ${props =>
    props.truncate &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
  ${props =>
    props.truncate === 'small' &&
    css`
      -webkit-line-clamp: 3;
    `}
  ${props =>
    props.truncate === 'big' &&
    css`
      -webkit-line-clamp: 10;
    `}
`

export default Card
