import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Grow } from '@mui/material'

const ComponentSequentialLoader = ({
  children,
  length = 500,
  cardsPerRow = 4,
  leftJustified = false,
  noScroll = false,
}) => {
  const [enterView, setEnterView] = useState(0)
  const [centered, setCentered] = useState(true)

  const windowHeight = window.innerHeight
  const elementRef = useRef(null)
  const [notLoaded, setNotLoaded] = useState(!noScroll)

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current
      if (element) {
        const { top } = element.getBoundingClientRect()
        if (top <= windowHeight - 100) {
          setNotLoaded(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [windowHeight])

  useEffect(() => {
    if (leftJustified) {
      setCentered(false)
    } else if (children.length > cardsPerRow) {
      setCentered(false)
    }
  }, [cardsPerRow, children.length, leftJustified])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (notLoaded) {
        return undefined
      }
      if (enterView >= children.length) {
        return undefined
      }
      setEnterView(previous => previous + 1)
    }, length)

    return () => {
      clearInterval(intervalId)
    }
  }, [length, children.length, enterView, notLoaded])

  const buildElements = () => {
    return children.map((child, index) => {
      return (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          key={index} // eslint-disable-line
          sx={{
            width: { xs: '100%', sm: '100%', md: `${100 / cardsPerRow}%` },
          }}
        >
          <Grow in={enterView >= index} timeout={{ enter: 1500, exit: 1500 }}>
            <Box padding='0.5rem' width='100%'>
              {child}
            </Box>
          </Grow>
        </Box>
      )
    })
  }

  return (
    <Box
      display='flex'
      justifyContent={centered ? 'center' : 'flex-start'}
      alignItems='center'
      width='100%'
      flexWrap='wrap'
      ref={elementRef}
    >
      {buildElements()}
    </Box>
  )
}

ComponentSequentialLoader.propTypes = {
  children: PropTypes.array.isRequired,
  length: PropTypes.number,
  cardsPerRow: PropTypes.number,
  leftJustified: PropTypes.bool,
  noScroll: PropTypes.bool,
}

export default ComponentSequentialLoader
