import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
// import { collection } from '../firebase/firestore'

const QuickCheckoutEmrOrders = createContext()

export const useQuickCheckoutOrders = () => useContext(QuickCheckoutEmrOrders)

const QuickCheckoutEmrOrdersProvider = ({ paymentIntent, children }) => {
  const [paymentIntentId, setPaymentIntentId] = useState(paymentIntent) // eslint-disable-line
  const [disabled, setDisabled] = useState(false)
  const [stripeError, setStripeError] = useState(null) // eslint-disable-line
  const [firebaseError, setFirebaseError] = useState(null) // eslint-disable-line

  const value = {
    paymentIntentId,
    setPaymentIntentId,
    stripeError,
    firebaseError,
    disabled,
    setDisabled,
  }
  return <QuickCheckoutEmrOrders.Provider value={value}>{children}</QuickCheckoutEmrOrders.Provider>
}

QuickCheckoutEmrOrdersProvider.propTypes = {
  children: PropTypes.node.isRequired,
  paymentIntent: PropTypes.string.isRequired,
}

export default QuickCheckoutEmrOrdersProvider
