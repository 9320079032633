import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'

const OrganizationPhotos = ({ images }) => {
  return (
    <Section id='photos'>
      <Container>
        <Typography variant='h4' gutterBottom>
          Photos
        </Typography>
        <br />
        <Grid container spacing={4} alignItems='stretch'>
          {images.map(each => (
            <Grid item xs={12} sm={6} lg={4} key={each.name}>
              <Box mb={5}>
                <img src={each.downloadURL} alt={each.name} width='100%' />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  )
}

OrganizationPhotos.propTypes = {
  images: PropTypes.node.isRequired,
}

export default OrganizationPhotos
