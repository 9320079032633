import { useEffect } from 'react'

const HubspotContactForm = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: '8814236',
          formId: '5e8438a4-2214-4b13-bbb3-34a51f8120e8',
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <div>
      <div id='hubspotForm' />
    </div>
  )
}

export const EnrollmentForm = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: '8814236',
          formId: 'f7dfbf30-f278-4573-8195-1df5969a6801',
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <div>
      <div id='hubspotForm' />
    </div>
  )
}

export const ClaimProfileForm = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: '8814236',
          formId: '6b997e10-ce8e-47be-bce8-108568727e5d',
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <div>
      <div id='hubspotForm' />
    </div>
  )
}
export default HubspotContactForm
