import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import ProviderCard from './ProviderCard'
import useProviders from '../hooks/useOrganizationProviders'

const OrganizationDoctors = ({ uid }) => {
  const [providers, hasMore, loadMore] = useProviders(uid)

  if (providers.length === 0) {
    return null
  }

  return (
    <Section id='providers' bgcolor='background.smoke'>
      <Container>
        <Typography variant='h4' gutterBottom>
          Our Providers
        </Typography>
        <br />
        <Grid container spacing={4} alignItems='stretch'>
          {providers.map(each => (
            <Grid item xs={12} sm={6} lg={4} key={each.doc.id}>
              <ProviderCard {...each} />
            </Grid>
          ))}
        </Grid>
        <Box display='flex' justifyContent='center' mt={4}>
          {hasMore && (
            <Button variant='outlined' onClick={loadMore}>
              Load more
            </Button>
          )}
        </Box>
      </Container>
    </Section>
  )
}

OrganizationDoctors.propTypes = {
  uid: PropTypes.string.isRequired,
}

export default OrganizationDoctors
