import PropTypes from 'prop-types'
import styled from 'styled-components'

import MuiBox from '@mui/material/Box'
import MuiToolBar from '@mui/material/Toolbar'
import Container from '../Container'

const StyledBox = styled(MuiBox)`
  background: #662d91;
  color: white;
  box-shadow: ${props => props.theme.shadows[4]};
`
const StyledToolBar = styled(MuiToolBar)`
  padding: 16px 0px;
`

const BottomBar = ({ children, ...props }) => (
  <StyledBox {...props}>
    <Container>
      <StyledToolBar>{children}</StyledToolBar>
    </Container>
  </StyledBox>
)

BottomBar.propTypes = {
  children: PropTypes.node,
}

export default BottomBar
