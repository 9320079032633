import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Notification from './NotificationText'
import Heading from '../stories/Heading'
import Button from '../stories/Button'
import TextField from '../stories/TextField'

const ChangePasswordForm = ({ notification = {}, errors = {}, ...props }) => (
  <form title='change-password-form' {...props}>
    <Heading variant='h4'>Change your Password</Heading>
    <br />
    <TextField
      required
      title='current-password'
      name='currentPassword'
      type='password'
      label='Current password'
      helperText={errors.currentPassword}
    />
    <TextField
      required
      title='new-password'
      name='newPassword'
      type='password'
      label='New password'
      helperText={errors.newPassword}
    />
    <Box mt={2}>
      <Button type='submit' value='Change password' fullWidth>
        save password
      </Button>
      <Notification mt={2} {...notification} />
    </Box>
  </form>
)

ChangePasswordForm.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string,
    severity: PropTypes.string,
  }),
  errors: PropTypes.shape({
    newPassword: PropTypes.string,
    currentPassword: PropTypes.string,
  }),
}

export default ChangePasswordForm
