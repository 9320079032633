function convertToICSDate(dateTime) {
  const year = dateTime.getFullYear().toString()
  const month =
    dateTime.getMonth() + 1 < 10
      ? '0' + (dateTime.getMonth() + 1).toString()
      : (dateTime.getMonth() + 1).toString()
  const day =
    dateTime.getDate() < 10 ? '0' + dateTime.getDate().toString() : dateTime.getDate().toString()
  const hours =
    dateTime.getHours() < 10 ? '0' + dateTime.getHours().toString() : dateTime.getHours().toString()
  const minutes =
    dateTime.getMinutes() < 10
      ? '0' + dateTime.getMinutes().toString()
      : dateTime.getMinutes().toString()

  return year + month + day + 'T' + hours + minutes + '00'
}
export const createDownloadICSFile = ({ title, description, timezone, startTime, endTime }) => {
  const icsBody =
    'BEGIN:VCALENDAR\n' +
    'VERSION:2.0\n' +
    'PRODID:Calendar\n' +
    'CALSCALE:GREGORIAN\n' +
    'METHOD:PUBLISH\n' +
    'BEGIN:VTIMEZONE\n' +
    'TZID:' +
    timezone +
    '\n' +
    'END:VTIMEZONE\n' +
    'BEGIN:VEVENT\n' +
    'SUMMARY:' +
    title +
    '\n' +
    'UID:@Default\n' +
    'SEQUENCE:0\n' +
    'STATUS:CONFIRMED\n' +
    'TRANSP:TRANSPARENT\n' +
    'DTSTART;TZID=' +
    timezone +
    ':' +
    convertToICSDate(startTime) +
    '\n' +
    'DTEND;TZID=' +
    timezone +
    ':' +
    convertToICSDate(endTime) +
    '\n' +
    'DTSTAMP:' +
    convertToICSDate(new Date()) +
    '\n' +
    'DESCRIPTION:' +
    description +
    '\n' +
    'END:VEVENT\n' +
    'END:VCALENDAR\n'

  download(title + '.ics', icsBody)
}
const download = (filename, fileBody) => {
  var element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileBody))
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
