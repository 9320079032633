import PropTypes from 'prop-types'
import { Box, Typography, Link, Button, Divider } from '@mui/material'
import Icon from '../../stories/Icons'

import { handleDownload, dateOptions } from './messageUtils'

const MessageIn = ({ content, author, sendTime, date }) => {
  const { fileType, fileName, url, text } = content

  return (
    <Box
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '0px',
      }}
    >
      <Box
        style={{
          maxWidth: '70%',
          padding: '10px',
          margin: '4px 6px',
          marginTop: '16px',
          backgroundColor: '#ddd',
          borderRadius: '5px',
        }}
      >
        <Typography
          variant='body2'
          style={{
            position: 'relative',
          }}
        >
          <Typography
            variant='caption'
            style={{
              position: 'absolute',
              top: '-36px',
              left: '-16px',
              width: 'max-content',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
            }}
          >
            {author} - {date.toLocaleString('en-US', dateOptions)} {sendTime}
          </Typography>
          {fileType === 'image' && <img src={url} alt={fileName} style={{ maxWidth: '100%' }} />}
          {fileType === 'file' && (
            <>
              <Link
                href={url}
                underline='always'
                sx={{
                  color: '#fff',
                  display: 'block',
                  marginBottom: '4px',
                }}
              >
                {fileName || 'Download File'}
              </Link>
              <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} />
            </>
          )}
          {text}
        </Typography>
      </Box>
      <Box
        style={{
          position: 'absolute',
          bottom: '-1px',
          left: '-8px',
        }}
      >
        <svg height='8' width='16'>
          <polygon
            points='0,8 16,8 16,0'
            style={{
              fill: '#ddd',
            }}
          />
        </svg>
      </Box>
    </Box>
  )
}

MessageIn.propTypes = {
  author: PropTypes.string.isRequired,
  content: PropTypes.shape({
    fileType: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  sendTime: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
}

export const MessageInOrders = ({ content, author, sendTime, date }) => {
  const { fileType, fileName, url, text } = content
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '0px',
      }}
    >
      <Box
        style={{
          position: 'relative',
          maxWidth: '70%',
          padding: '10px',
          margin: '4px 6px',
          marginTop: '16px',
          backgroundColor: '#ddd',
          borderRadius: '5px',
        }}
      >
        {fileType && (
          <Box
            style={{
              position: 'absolute',
              right: '-56px',
              top: 'calc(50% - 20px)',
            }}
          >
            <Button
              style={{
                borderRadius: '50%',
                backgroundColor: '#fff',
                height: '40px',
                width: '40px',
                minWidth: '40px',
                padding: '0px',
                boxShadow: '0px 0px 1px rgba(0,0,0,0.6)',
              }}
              onClick={() => {
                handleDownload(url, fileName)
              }}
            >
              <Icon name='getApp' color='primary' />
            </Button>
          </Box>
        )}
        {fileType === 'image' && (
          <img
            src={url}
            alt='preview'
            style={{
              borderRadius: '4px',
              objectFit: 'contain',
              maxWidth: '200px',
              maxHeight: '200px',
            }}
          />
        )}
        {fileType !== 'image' && (
          <Box
            style={{
              whiteSpace: 'nowrap',
              maxWidth: '200px',
            }}
          >
            <Typography
              variant='body1'
              family='poppins'
              style={{
                color: '#fff',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '100%',
                maxHeight: '24px',
              }}
            >
              {fileName}
            </Typography>
          </Box>
        )}
        {text && <Typography variant='body2'>{text}</Typography>}
        <Typography
          variant='caption'
          style={{
            position: 'absolute',
            top: '-24px',
            left: '-4px',
            color: '#000',
            width: 'max-content',
            whiteSpace: 'nowrap',
            textTransform: 'capitalize',
          }}
        >
          {author} - {date.toLocaleDateString('en-US', dateOptions)} - {sendTime}
        </Typography>
        <Box
          style={{
            position: 'absolute',
            bottom: '-5px',
            left: '-12px',
          }}
        >
          <svg height='8' width='16'>
            <polygon
              points='0,8 16,8 16,0'
              style={{
                fill: '#ddd',
              }}
            />
          </svg>
        </Box>
      </Box>
    </Box>
  )
}

MessageIn.propTypes = {
  author: PropTypes.string.isRequired,
  content: PropTypes.shape({
    fileType: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  sendTime: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
}

export default MessageIn
