import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  page: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#E4E4E4',
  },
  titleSection: {
    width: '100%',
    textAlign: 'center',
    margin: '10px 64px',
    padding: '10px 64px',
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'poppins',
  },
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '64px 0px 80px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  document: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    margin: '10px 64px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  tableItemOne: {
    width: '25%',
    border: 'solid 1px #000',
    borderLeft: 'none',
    padding: '0px 25px 25px 2px',
  },
  tableItemTwo: {
    width: '50%',
    border: 'solid 1px #000',
    padding: '0px 25px 25px 2px',
  },
  tableItemFull: {
    width: '100%',
    border: 'solid 1px #000',
    borderTop: 'none',
    padding: '0px 25px 25px 2px',
  },
  mainInfoContainer: {
    width: '100%',
    margin: '10px 64px',
    display: 'flex',
    flexWrap: 'wrap',
  },
})

const HipaaAuthPDF = () => {
  return (
    <>
      <View style={styles.container}>
        <Document style={styles.document}>
          <Page size='A4' style={styles.page}>
            <View style={styles.titleSection}>
              <Text style={styles.title}>
                AUTHORIZATION FOR RELEASE OF HEALTH INFORMATION PURSUANT TO HIPAA
              </Text>
            </View>
            <View style={styles.tableContainer}>
              <View style={styles.tableItemTwo}>
                <Text>1. Patient Name:</Text>
              </View>
              <View style={styles.tableItemOne}>
                <Text>2. Patient Date of Birth:</Text>
              </View>
              <View style={styles.tableItemOne}>
                <Text>3. Patient Phone Number:</Text>
              </View>
              <View style={styles.tableItemFull}>
                <Text>4. Patient Address:</Text>
              </View>
            </View>
            <View style={styles.mainInfoContainer} />
          </Page>
        </Document>
      </View>
    </>
  )
}

export default HipaaAuthPDF
